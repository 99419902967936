import React from 'react';
import tw, { css, styled } from 'twin.macro';
import { matchPath, NavLink, useLocation } from 'react-router-dom';
import { ProjectStages } from '@asu/services';
import { useProject, useUserTasks } from './hooks';
import { IconHistory } from '../../assets/icons';
import { PROCESS_LIST } from '../../mocks';
import { observer } from 'mobx-react-lite';

interface ProjectPageParams {
  projectId: string;
}

const projectPath = {
  path: '/project/:projectId',
  end: false,
};

const ProcessList = observer(() => {
  const location = useLocation();
  const projectParams = matchPath(projectPath, location.pathname);
  const [isUserTasksLoading, userTasks] = useUserTasks(projectParams.params?.projectId);
  const [isProjectLoading, project] = useProject(projectParams.params?.projectId);

  const isStarted = React.useMemo(() => project?.status !== ProjectStages.NOT_STARTED, [project]);
  const isFinished = React.useMemo(() => project?.status === ProjectStages.DONE, [project]);

  const processGoTo = React.useCallback(
    processKey => {
      const projectId = projectParams.params?.projectId;

      if (isFinished) {
        return `/`;
      }

      if (!isStarted) {
        return `/project/${projectId}/${processKey}/start`;
      }

      if (userTasks.length > 0) {
        return `/project/${projectId}/tasks`;
      }

      return '';
    },
    [isFinished, isStarted, projectParams.params?.projectId, userTasks.length],
  );

  if (isUserTasksLoading || isProjectLoading) {
    return (
      <React.Fragment>
        {Array.from(Array(3), (_, i) => (
          <div key={i} tw="flex items-center space-x-4 my-3">
            <figure tw="w-8 h-8 bg-grey-300 bg-opacity-30 rounded animate-pulse" />
            <figure tw="w-36 h-3 bg-grey-300 bg-opacity-30 rounded animate-pulse" />
          </div>
        ))}
      </React.Fragment>
    );
  }

  // TODO: На бэке должны будут сделать нормальный список процессов, пока хардкод
  if (!isFinished) {
    return (
      <React.Fragment>
        {PROCESS_LIST.map(({ key, name }) => (
          <ProcessItem to={processGoTo(key)} className={({ isActive }) => (isActive ? ' active' : '')} key={key}>
            <IconContainer $isActive={isStarted}>
              <IconHistory tw="h-4 w-4 text-white" />
            </IconContainer>
            <div>
              <h2>{name}</h2>
              {!isStarted && <p tw="text-xs opacity-40">Требуется выбор процесса</p>}
            </div>
          </ProcessItem>
        ))}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {PROCESS_LIST.map(({ key, name }) => (
        <ProcessItem to={processGoTo(key)} className={({ isActive }) => (isActive ? ' active' : '')} key={key}>
          <IconContainer $isActive={isStarted}>
            <IconHistory tw="h-4 w-4 text-white" />
          </IconContainer>
          <div>
            <h2 tw="text-white text-opacity-50">{name}</h2>
            <p tw="text-xs text-white text-opacity-25">Стадия завершена</p>
          </div>
        </ProcessItem>
      ))}
    </React.Fragment>
  );
});

interface IconContainerProps {
  readonly $isActive: boolean;
}

const IconContainer = styled.figure<IconContainerProps>`
  ${tw`background-color[#5E5E61] border border-white border-opacity-10 rounded opacity-60 p-2 mr-4`}
  ${({ $isActive }) => $isActive && tw`text-primary-dark bg-secondary-dark`}
`;

interface ProcessItemProps {
  readonly $isActive?: boolean;
}
const ProcessItem = styled(NavLink)<ProcessItemProps>`
  ${tw`relative flex items-center font-title text-white leading-none py-2 my-1`}
  ${tw`after:(content[''] absolute right-0 top-1/2 transform -translate-y-1/2 width[3px] height[100%] bg-primary hidden)`}
  ${({ $isActive }) => $isActive && tw`after:block`}

  &.active {
    ${tw`after:block`}
  }
`;

export default ProcessList;
