import React from 'react';
import { MatrixCheckbox } from '../../../pages/ProjectStartProcessConfirmPage/local-components/styles';
import { useRole } from '../hooks';
import { matchPath, useLocation } from 'react-router-dom';
import { Modal, Table } from '@asu/ui';
import tw from 'twin.macro';
import { IconPrivacy } from '../../../assets/icons';
import { ModalMatrixCard } from '../../../pages/CreateProjectStage2Page/local-components';
import { useProjectState } from '../../../store';
import { useAuthState } from '@asu/auth';
import { ProjectService } from '@asu/services';
import { AddModalMatrixCard } from '../../../pages/CreateProjectStage2Page/local-components/ModalMatrixCard';

const ModalMatrixRole = React.memo(() => {
  return (
    <Modal.Root
      manual
      trigger={
        <MatrixRoleButton type="button">
          <MatrixRolePlaceholder>
            <IconPrivacy tw="absolute inset-0 m-auto w-4 h-4 text-grey-500" />
          </MatrixRolePlaceholder>
        </MatrixRoleButton>
      }
    >
      <AddModalMatrixRole />
    </Modal.Root>
  );
});

const AddModalMatrixRole = React.memo(() => {
  const store = useProjectState();
  const authStore = useAuthState();

  const [checkTypes, setCheckTypes] = React.useState(null);
  const [allTypes, setAllTypes] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const amIAddedToAssignedUsers = React.useMemo(
    () => !!store.project?.assignedUsers.find(item => item.userId === authStore.currentUser.id),
    [store.project],
  );

  React.useEffect(() => {
    if (amIAddedToAssignedUsers) {
      (async () => {
        const response = await ProjectService.getAllAvalibleRights().fetch();

        setAllTypes(response.data);
        setIsLoading(false);
      })();
      setCheckTypes(store.project?.assignedUsers?.find(el => el.userId === authStore.currentUser.id).projectRole.rights);
    }
  }, [store.project?.assignedUsers, amIAddedToAssignedUsers]);

  const currentUserRoleId = React.useMemo(() => {
    if (amIAddedToAssignedUsers) {
      return store.project?.assignedUsers?.find(el => el.userId === authStore.currentUser.id).projectRole.id;
    }
    return null;
  }, [store.project?.assignedUsers, amIAddedToAssignedUsers]);

  if (isLoading) {
    return null;
  }

  return (
    <AddModalMatrixCard
      rights={store.project?.assignedUsers?.find(el => el.userId === authStore.currentUser.id)?.projectRole.rights}
      checkTypes={checkTypes}
      setCheckTypes={setCheckTypes}
      allTypes={allTypes}
      disabled
      roleId={currentUserRoleId}
    />
  );
});

const Text = tw.p`px-4 py-2 bg-white rounded mb-4`;

const MatrixRoleButton = tw.button`relative focus:outline-none`;
const MatrixRolePlaceholder = tw.div`h-10 w-10 rounded-full bg-gray-200`;

export default ModalMatrixRole;
