import React from 'react';
import { AuthService, IAuthService } from '@asu/services';

export const useUserInformation = (userId?: string): [boolean, IAuthService.IGetCurrentUserResponse | null] => {
  const [userData, setUserData] = React.useState<IAuthService.IGetCurrentUserResponse | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    const request = AuthService.getUser(userId);

    if (userId) {
      setIsLoading(true);

      (async () => {
        const response = await request.fetch();
        const responseData = response.data;

        setUserData(responseData);
        setIsLoading(false);
      })();
    }

    return () => {
      request.abort();
    };
  }, [userId]);

  return [isLoading, userData];
};
