export interface IProcessDescription {
  key: string;
  description: string;
}

interface ITaskCamunda {
  taskDefId: string | null;
  name: string;
  assignedUserId: string | null;
  startDate: string;
  endDate: string;
  taskStatus: 'NOT_STARTED' | 'IN_PROGRESS' | 'WAITING_DEPENDENCY' | 'UNDER_APPROVAL' | 'EXPERT_APPROVE' | 'APPROVER_APPROVE' | 'CONFIRMER_APPROVE' | 'CLOSED';
  subtask: object[] | [];
}

interface IAvailableTask {
  id: string;
  name: string;
  assignee: string;
  created: string;
  due: string;
  followUp: string;
  delegationState: string;
  processDefinitionId: string;
  processInstanceId: string;
  taskDefinitionKey: string;
  prevTask: string;
  isApprove: boolean
}

interface IStartProcessResponse {
  processId: string;
  taskAvailable: IAvailableTask[];
}

interface IUserCamunda {
  id: string;
  userId: string;
  approverType: 'APPROVER' | 'EXPERT' | 'CONFIRMER';
}

interface IMarkupTaskCamunda {
  customTaskId?: string;
  projectId: string;
  markup: string[];
  assignedUserId: string;
  name: string;
  status?: 'NOT_STARTED' | 'ACTIVE' | 'DONE';
  startDate: string;
  estimateEndDate: string;
  endDate?: string;
  subtask?: string[];
  users?: IUserCamunda[];
  initUserId?: string;
}

interface IMarkupTaskBody {
  customTaskId?: string;
  projectId: string;
  markup: string[];
  assignedUserId: string;
  name: string;
  startDate: string;
  estimateEndDate: string;
  initUserId?: string;
}

interface IMarkupTaskUpdate {
  customTaskId?: string;
  name?: string;
  startDate?: string;
  estimateEndate?: string;
  initUserId?: string;
}

interface IUserInITask {
  id: string;
  userId: string;
  type: string;
  approvalSettingsId: string;
}

interface IApproveAndReject {
  createDate: string;
  authorId: string;
  iterationNumber: number;
  message: string;
  id: string;
  taskId: string
}

interface IApprovalSettingsCamunda {
  id: string;
  taskId: string;
  users: IUserInITask[];
}

interface ISolution {
  authorId: string;
  createDate: string;
  name: string;
  description: string;
  result: true;
  id: string;
  taskId: string
}

interface IApprovalUser {
  id: string;
  userId: string;
  type: string;
  approvalSettingsId: string
}

interface IApprovalIterationCamunda {
  id: string;
  state: string;
  users: IApprovalUser[];
  taskId: string
};

interface ITaskCamunda {
  type: string;
  authorId: string;
  status: string;
  createDate: string;
  name: string;
  description: string;
  assignedUserId: string;
  startDate: string;
  dueDate: string;
  endDate: string;
  projectId: string;
  expired: true;
  enableAutostart: true;
  id: string;
  rootTaskId: string;
  parentTaskId: string;
  parentDependencyTaskId: string;
  children: string[];
  dependencies: string[];
  solution: ISolution;
  approvalSettings: IApprovalSettingsCamunda;
  approves: IApproveAndReject[];
  rejects: IApproveAndReject[];
  repeated: true;
  approvalIteration: IApprovalIterationCamunda;
  processId: string;
  customTaskDefId: string;
  taskDefinitionKey: string
}

interface ICantApproveAndReject {
  status: string;
  timestamp: string;
  message: string;
  debugMessage: string;
  subErrors: object[];
  stackTrace: string[];
}

interface IStartProcess {
  businessKey: string;
  processKey: string
}

interface IMessage {
  message: string;
}

export declare namespace ICamundaService {
  type IGetProcessList = IProcessDescription[];
  type IGetHistoryProcessListGantt = { taskList: ITaskCamunda[] };
  type IPostStartProcess = IStartProcessResponse;
  type IGetTasksFromMarkups = IMarkupTaskCamunda[];
  type IGetTaskFromMarkup = IMarkupTaskCamunda;
  type IGetTasks = ITaskCamunda[];
  type ICompleteTask = ITaskCamunda;
  type IApproveTask = ICantApproveAndReject | IApproveAndReject;
  type IRejectTask = ICantApproveAndReject | IApproveAndReject;

  declare namespace Body {
    type IPostStartProcess = IStartProcess;
    type IPostCreateTask = IMarkupTaskBody;
    type IUpdateTaskFromMarkups = IMarkupTaskUpdate;
    type IRejectTask = IMessage;
  }
}
