import AntMentions from 'antd/lib/mentions';
import 'antd/lib/mentions/style/index.css';

interface MentionsProps {
  setValue: any;
  defaultValue: any;
  usersList: any;
}

const Mentions: React.FC<MentionsProps> = ({ setValue, defaultValue, usersList, ...props }) => {
  return (
    <AntMentions
      defaultValue={defaultValue ?? ''}
      onChange={text => {
        setValue('comment', text);
      }}
      {...props}
      rows={3}
    >
      {usersList.length &&
        usersList.map((item: any) => (
          <AntMentions key={item.id} tw="flex justify-between" value={`${item.lastName} ${item.firstName}`}>
            <span tw="text-gray-300 italic">
              {item.lastName} {item.firstName}
            </span>
          </AntMentions>
        ))}
    </AntMentions>
  );
};

export default Mentions;
