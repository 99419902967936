import { Service } from './Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import { IBuildingTask, IControlTask } from './BuildingTaskService.d';
export * from './BuildingTaskService.d';

const BASE_URL = '/building';

export class BuildingTaskService implements Service {
  public static getTasks(taskId: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IBuildingTask[]>(`/v2/tasks/${taskId}`, config);
  }

  public static getCurrentTaskInfo(buildingTaskId: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IBuildingTask[]>(`/building/expert/${buildingTaskId}`, config);
  }

  public static postFileBuildingAttachment(attachmentId: string, body: any, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IBuildingTask[]>(`/building/attachment/${attachmentId}/element`, body, config);
  }

  public static deleteFileBuildingAttachment(attachmentId: string, elementId: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).delete<IBuildingTask[]>(`/building/attachment/${attachmentId}/element/${elementId}`, config);
  }

  public static getGant(taskId: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IBuildingTask[]>(`/v2/tasks/${taskId}/gantt`, config);
  }

  public static getChildrenTasks(taskId: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IBuildingTask[]>(`/v2/tasks/${taskId}/children`, config);
  }

  public static getTaskDependencies(taskId: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IBuildingTask[]>(`/v2/tasks/${taskId}/dependencies`, config);
  }

  public static doneTask(buildingTaskId: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<IBuildingTask[]>(`/v2/build/tasks/${buildingTaskId}/done`, config);
  }

  public static changeInfoBuildingTask(buildingTaskId: string, body: any, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<IBuildingTask[]>(`/v2/build/tasks/${buildingTaskId}`, body, config);
  }

  public static sendTaskToApprove(buildingTaskId: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<IBuildingTask[]>(`/v2/control/tasks/${buildingTaskId}/done`, config);
  }

  public static getAllControlTasks(taskId: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IControlTask[]>(`/v2/control/tasks/${taskId}`, config);
  }

  public static updateTaskSolution(buildingTaskId: string, data: any, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<IBuildingTask[]>(`/v2/control/tasks/${buildingTaskId}/solution`, data, config);
  }

  public static approveBalanseTask(taskId: string, data: any, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IBuildingTask[]>(`/v2/control/tasks/${taskId}/approve`, data, config);
  }

  public static rejectBalanseTask(taskId: string, data: any, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IBuildingTask[]>(`/v2/control/tasks/${taskId}/reject`, data, config);
  }

  public static addInfoExpert(attachmentId: string, data: any, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<IBuildingTask[]>(`/building/expert/${attachmentId}/info`, data, config);
  }

  public static rejectTask(buildingTaskId: string, userId: string, attachmentId: string, message: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IBuildingTask[]>(
      `/building/expert/${buildingTaskId}/reject?userId=${userId}&attachmentId=${attachmentId}`,
      {
        coreReject: {
          message,
        },
      },
      config,
    );
  }

  public static getReport(taskId: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IBuildingTask[]>(`/v2/control/tasks/${taskId}/solution/report`, config);
  }

  public static sendFile(buildingTaskId: string, projectId: string, assignedUserId: string, data: any, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IBuildingTask[]>(
      `/v2/build/tasks/${buildingTaskId}/children/file?projectId=${projectId}&assignedUserId=${assignedUserId}`,
      data,
      config,
    );
  }
}
