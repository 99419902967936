import React from 'react';
import { FilesystemService, IFilesystemService } from '@asu/services';
import { useFileSystemState } from '../../../store';

export const useVersions = (id?) => {
  const store = useFileSystemState();
  const [versions, setVersions] = React.useState<IFilesystemService.IGetVersions | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const getVersions = React.useCallback(async id => {
    if (id) {
      setIsLoading(true);
      try {
        const response = await FilesystemService.getVersions(id, 'g1').fetch();
        const responseData = response.data;

        setVersions(responseData);

        setIsLoading(false);
      } catch (err) {
        console.error('useVersions', err);
      }
    }
  }, []);

  const postVersion = React.useCallback(
    async (id, data) => {
      setIsLoading(true);
      const body: any = new FormData();

      body.append('id', id);
      body.append('document', data);

      try {
        const response = await FilesystemService.updateFile(body, 'g1').fetch();
        const _responseData = response.data;
        getVersions(id);
        setIsLoading(false);
        store.getDirectories(store.rootFolderId);

        return _responseData;
      } catch (err) {
        console.error('updateFile', err);
      }
    },
    [getVersions, store],
  );

  React.useEffect(() => {
    if (id) getVersions(id);
  }, [getVersions, id]);

  return { isLoading, versions, getVersions, postVersion };
};
