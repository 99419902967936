import { autorun, makeAutoObservable, runInAction } from 'mobx';
import { FilesystemService } from '@asu/services';
import { DocumentsTypes, IDirectories, IFiles } from '../../types';

export enum StoreState {
  Inital = 'init',
  Success = 'succes',
  Loading = 'loading',
  BlockerLoading = 'blocker',
  Error = 'error',
}

export enum ExplorerView {
  Grid = 'Grid',
  List = 'List',
}

interface IHistory {
  id: string | null;
  name: string;
}

interface IChecked {
  id: string | null;
  type: string;
}

export class FileSystemStore {
  projectId: string | null = null;
  dirictories: IDirectories[] = [];
  files: IFiles[] = [];
  history: IHistory[] = [];
  state: StoreState = StoreState.Inital;
  progressPercantage: number | null = null;
  currentFolder: IHistory | null = null; // { id: null, name: 'Разделы проекта' };
  rootFolderId = '';
  favorites: any = {};
  view: ExplorerView = ExplorerView.Grid;
  //checkBox
  checkedFiles: IChecked[] = [];
  onlyModels = false;

  constructor(projectId, onlyModels) {
    this.projectId = projectId;
    this.onlyModels = onlyModels;
    makeAutoObservable(this);
  }

  setCheckedFile(data: IChecked) {
    if (this.checkedFiles.find(e => e.id === data.id)) {
      this.checkedFiles.splice(
        this.checkedFiles.findIndex(e => e.id === data.id),
        1,
      );
    } else this.checkedFiles.push({ id: data.id, type: data.type });
  }

  setCheckedAllFile() {
    const newArray = [];
    this.files.map((data: any) => {
      if (this.checkedFiles.find(e => e.id === data.id)) {
        this.checkedFiles.splice(
          this.checkedFiles.findIndex(e => e.id === data.id),
          1,
        );
      } else {
        newArray.push({ id: data.id, type: data.type });
      }

      this.checkedFiles = newArray;
    });
  }

  resetAllFile() {
    this.checkedFiles = [];
  }

  historyReset() {
    this.history = [];
    this.currentFolder = { id: null, name: process.env.NX_SYSTEM_TYPE === 'RZD' ? 'Проектная документация' : 'Трехмерные модели' };
  }

  historyGo(data: IHistory) {
    this.history.push(this.currentFolder);
    this.currentFolder = data;
  }

  historyReplace(data: IHistory) {
    const lastIndex = this.history.findIndex(el => el.id === data.id);
    this.history = this.history.slice(0, lastIndex);
    this.currentFolder = data;
  }

  historyGoBack() {
    const folder = this.history.pop();

    if (folder) {
      this.currentFolder = folder;
    }
  }

  setView(view: ExplorerView) {
    this.view = view;
  }

  async renameDirectory(data: any, folderId) {
    const body: any = {
      id: folderId,
      newName: data.name,
    };

    this.state = StoreState.Loading;

    try {
      const response = await FilesystemService.renameDirectory(body, 'g1').fetch();
      const _responseData = response.data;

      runInAction(() => {
        this.state = StoreState.Success;
        this.getDirectories(this.rootFolderId);
      });
    } catch (err) {
      runInAction(() => {
        this.state = StoreState.Error;
      });
      console.error('renameDerictory', err);
    }
  }

  async renameFile(data: any, folderId) {
    const body = {
      id: folderId,
      newName: data.name,
    };

    this.state = StoreState.Loading;

    try {
      await FilesystemService.renameFile(body).fetch();

      runInAction(() => {
        this.state = StoreState.Success;
        this.getDirectories(this.rootFolderId);
      });
    } catch (err) {
      runInAction(() => {
        this.state = StoreState.Error;
      });
      console.error('renameDerictory', err);
    }
  }

  async addDirectory(data: any) {
    const body: any = {
      baseName: data.name,
      sourceId: this.projectId,
      parentId: this.rootFolderId,
      sourceType: 'PROJECT_ID',
    };

    this.state = StoreState.Loading;

    try {
      const response = await FilesystemService.createDirectory(body, 'g1').fetch();
      const _responseData = response.data;

      runInAction(() => {
        this.state = StoreState.Success;
        this.getDirectories(this.rootFolderId);
      });
    } catch (err) {
      runInAction(() => {
        this.state = StoreState.Error;
      });
      console.error('addDerictory', err);
    }
  }

  async uploadFile(data: any) {
    const body: any = new FormData();

    body.append('projectId', this.projectId);
    body.append('parentId', this.rootFolderId);
    body.append('document', data);

    this.state = StoreState.Loading;

    try {
      await FilesystemService.postFile(body, 'g1', {
        onUploadProgress: progressEvent => {
          runInAction(() => {
            this.progressPercantage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          });
        },
      }).fetch();

      runInAction(() => {
        this.state = StoreState.Success;
        this.progressPercantage = null;
        this.getDirectories(this.rootFolderId);
      });
    } catch (err) {
      runInAction(() => {
        this.state = StoreState.Error;
        this.progressPercantage = null;
        this.getDirectories(this.rootFolderId);
      });
      console.error('uploadFile', err);
    }
  }

  async uploadFiles(files: any[]) {
    for (const file of files) {
      const body: any = new FormData();

      body.append('projectId', this.projectId);
      body.append('parentId', this.rootFolderId);
      body.append('document', file);

      this.state = StoreState.Loading;

      try {
        await FilesystemService.postFile(body, 'g1', {
          onUploadProgress: progressEvent => {
            runInAction(() => {
              this.progressPercantage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            });
          },
        }).fetch();

        runInAction(() => {
          this.state = StoreState.Success;
          this.progressPercantage = null;
          this.getDirectories(this.rootFolderId);
        });
      } catch (err) {
        runInAction(() => {
          this.state = StoreState.Error;
          this.progressPercantage = null;
          this.getDirectories(this.rootFolderId);
        });
        console.error('uploadFile', err);
      }
    }
  }

  async downloadFile(id: string, baseName, ext) {
    this.state = StoreState.Loading;

    try {
      const config: any = {
        responseType: 'blob',
        onDownloadProgress: progressEvent => {
          runInAction(() => {
            this.progressPercantage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          });
        },
      };
      const response = await FilesystemService.getFile(id, 'g1', config).fetch();
      const responseData: any = response.data;
      const blob = new File([responseData], baseName);

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', baseName + '.' + ext);
      document.body.appendChild(link);
      link.click();
      runInAction(() => {
        this.state = StoreState.Success;
      });
    } catch (err) {
      runInAction(() => {
        this.state = StoreState.Error;
      });
      console.error('downloadFile', err);
    }
  }

  async deleteFile(id: string, userId: string) {
    this.state = StoreState.Loading;

    const body = {
      initUserId: userId,
      ids: [id],
    };

    try {
      await FilesystemService.deleteFiles(body).fetch();

      runInAction(() => {
        this.state = StoreState.Success;
        this.getDirectories(this.rootFolderId);
      });
    } catch (err) {
      runInAction(() => {
        this.state = StoreState.Error;
      });
      console.error('deleteFile', err);
    }
  }

  async deleteCheckedFiles(userId: string) {
    this.state = StoreState.Loading;

    const ids = this.checkedFiles.map(item => item.id);

    const body = {
      initUserId: userId,
      ids: ids,
    };

    try {
      await FilesystemService.deleteFiles(body).fetch();

      runInAction(() => {
        this.state = StoreState.Success;
        this.getDirectories(this.rootFolderId);
        this.checkedFiles = [];
      });
    } catch (err) {
      runInAction(() => {
        this.state = StoreState.Error;
      });
      console.error('deleteFile', err);
    }
  }

  async deleteDerictory(id: string) {
    this.state = StoreState.Loading;

    try {
      const response = await FilesystemService.deleteDirectory(id, 'g1').fetch();
      const _responseData = response.data;

      runInAction(() => {
        this.state = StoreState.Success;
        this.getDirectories(this.rootFolderId);
      });
    } catch (err) {
      runInAction(() => {
        this.state = StoreState.Error;
      });
      console.error('deleteDerictory', err);
    }
  }

  async getDirectories(parentId?: string | null) {
    runInAction(() => {
      this.state = StoreState.Loading;
    });

    try {
      let response;
      let responseRootId;

      if (parentId) {
        response = await FilesystemService.getDirectories(parentId, 'g1').fetch();
      } else {
        response = await FilesystemService.getDirectoryRoot(this.projectId, 'g1').fetch();
        responseRootId = await FilesystemService.getDirectoryParentRootId(this.projectId).fetch();
      }
      const responseData: any[] = response.data;

      const data = responseData.filter(el => !el.fileMeta.isDeleted).map(el => ({ ...el.fileMeta }));

      runInAction(() => {
        this.dirictories = data
          .filter(el => el.type === DocumentsTypes.folder)
          .sort((a, b) => {
            const _a = a.baseName.replace(/[^0-9]/g, '');
            const _b = b.baseName.replace(/[^0-9]/g, '');

            if (_a && _b) return _a - _b;

            return a.baseName.localeCompare(b.baseName);
          });
        this.files = data.filter(el => el.type === DocumentsTypes.file || el.type === DocumentsTypes.model);
        this.rootFolderId = parentId ? parentId : responseRootId.data.id;
        this.state = StoreState.Success;
      });
    } catch (err) {
      runInAction(() => {
        this.state = StoreState.Error;
      });
      console.error('getDirectories >>>', err);
    }
  }

  // -------- Favoritres --------
  setFavorites(favoritesList: any) {
    this.favorites = favoritesList;
  }

  addToFavorites(data: IHistory) {
    if (this.projectId) {
      if (!this.favorites[this.projectId]) {
        this.favorites[this.projectId] = [];
      }
      this.favorites[this.projectId].push(data);
    }
  }

  removeFromFavorites(data: IHistory) {
    if (this.projectId && this.favorites[this.projectId]) {
      const removeIndex = this.favorites[this.projectId].findIndex(el => el.id === data.id);
      if (removeIndex !== -1) this.favorites[this.projectId].splice(removeIndex, 1);
    }
  }

  get isFavorite() {
    if (this.projectId && Array.isArray(this.favorites[this.projectId])) {
      return this.favorites[this.projectId].findIndex(el => el.id === this.currentFolder.id) !== -1;
    }

    return false;
  }
}

const _store = (projectId, onlyModels) => {
  const store = new FileSystemStore(projectId, onlyModels);

  autorun(() => {
    if (store.currentFolder) store.getDirectories(store.currentFolder.id);
  });

  autorun(() => {
    const localData = localStorage.getItem('storedFavorites');

    if (localData) {
      const data = JSON.parse(localData);

      // 29.09.2021 Обратная совместимость с прошлой версией, позже можно убрать
      if (Array.isArray(data)) localStorage.removeItem('storedFavorites');

      store.setFavorites(data);
    }
  });

  autorun(() => {
    const localData = localStorage.getItem('explorerView');
    if (localData) store.setView(ExplorerView[localData]);
  });

  autorun(() => {
    if (store.view) localStorage.setItem('explorerView', store.view);
  });

  autorun(() => {
    localStorage.setItem('storedFavorites', JSON.stringify(store.favorites));
  });

  autorun(() => {
    store.checkedFiles = [];
  });

  return store;
};

export default _store;
