import { qParam } from './Service.d';
import { Service } from './Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import type { IBuildingObjectService } from './BuildingObjectService.d';
export * from './BuildingObjectService.d';

const BASE_URL = '/dictionarydr';

export class BuildingObjectService implements Service {
  public static getBuildingObjects(data: IBuildingObjectService.Data.IGetBuildingObjects, config: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IBuildingObjectService.IGetBuildingObjects>(
      '/dictionary/building_object/findAllByProjectIdAndTaskDefId',
      data,
      config,
    );
  }

  public static getBuildingObject(id: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IBuildingObjectService.IGetBuildingObject>(`/dictionary/building_object/${id}`, config);
  }

  public static deleteBuildingObject(id: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).delete<void>(`/dictionary/building_object/delete/${id}`, config);
  }

  public static createBuildingObject(data: IBuildingObjectService.Data.ICreateBuildingObject, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IBuildingObjectService.ICreateBuildingObject>(`/dictionary/building_object`, data, config);
  }

  public static updateBuildingObject(data: IBuildingObjectService.Data.IUpdateBuildingObject, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).put<IBuildingObjectService.IUpdateBuildingObject>(`/dictionary/building_object`, data, config);
  }

  public static approveBuildingObject(id: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IBuildingObjectService.IApproveBuildingObject>(
      `/dictionary/building_object/approve/${id}`,
      { id },
      config,
    );
  }

  public static getDataRequest(data: IBuildingObjectService.Data.IGetDataRequest, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IBuildingObjectService.IGetDataRequest>(`/dictionary/data_request`, data, config);
  }
}
