import React from 'react';
import { Button, Modal, useManualModalState } from '@asu/ui';
import tw from 'twin.macro';
import { useFileSystemState } from '../../../store';

interface AddModalDeleteFolderProps {
  deleteId?: string;
}

interface ModalDeleteFolderProps extends AddModalDeleteFolderProps {
  isOpen: boolean;
  setIsOpen: any;
  openModal: () => void;
  closeModal: () => void;
}

const ModalDeleteFolder = React.memo((props: ModalDeleteFolderProps) => {
  const { deleteId } = props;
  return (
    <Modal.Root manual {...props}>
      <AddModalDeleteFolder deleteId={deleteId} />
    </Modal.Root>
  );
});

const AddModalDeleteFolder = React.memo(({ deleteId }: AddModalDeleteFolderProps) => {
  const store = useFileSystemState();
  const { closeModal } = useManualModalState();

  const onConfirm = () => {
    if (deleteId) {
      store.deleteDerictory(deleteId);
    }
    closeModal();
  };

  return (
    <Modal.Content tw="w-[35vw] min-w-[350px]">
      <Modal.Title>Удаление папки</Modal.Title>
      <div tw="px-5">
        <h2 tw="mt-2">Вы уверены, что хотите безвозвратно удалить эту папку?</h2>
        <div tw="my-2">
          <Button type="button" onClick={onConfirm}>
            Удалить папку
          </Button>
          <Button tw="mt-2 bg-gray-400 hover:bg-gray-500" type="button" onClick={() => closeModal()}>
            Отменить
          </Button>
        </div>
      </div>
    </Modal.Content>
  );
});

export default ModalDeleteFolder;
