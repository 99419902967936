import React from 'react';
import tw, { styled } from 'twin.macro';
import { Link } from 'react-router-dom';
import { useUserInformation } from '../hooks';

type AssigneeType = {
  userId: string;
  className?: string;
};

const Assignee = React.memo<AssigneeType>(({ userId, className }) => {
  const [isLoading, user] = useUserInformation(userId);

  if (isLoading) return <figure tw="h-6 w-full bg-grey-300 bg-opacity-30 rounded animate-pulse" />;

  return (
    <p tw="opacity-50" className={className}>
      {user.lastName + ' ' + user.firstName} | {user.position}
    </p>
  );
});

const TaskItem = ({ params, task, small }) => {
  const key = task.taskDefinitionKey;
  const taskId = task.id;
  const taskDefinitionKey = key.slice(0, key.lastIndexOf('-'));
  const processKey = params.processId;

  const url =
    taskDefinitionKey === 'approve' || taskDefinitionKey === 'confirm'
      ? `/project/${params.projectId}/process/${processKey}/${task.prevTask.slice(
          0,
          task.prevTask.lastIndexOf('-'),
        )}/${taskId}/approve/${key}`
      : `/project/${params.projectId}/process/${processKey}/${taskDefinitionKey}/${taskId}`;

  const isActiveTask = taskDefinitionKey === params.taskDefKey && task.id === params.taskId;

  return (
    <TaskContainer $isActive={isActiveTask} $isSmall={small} to={url}>
      <h1 tw="font-medium">{task.name}</h1>
      {!small &&
        task.assignedUsers.map(({ userId }) => {
          return <Assignee key={userId} userId={userId} />;
        })}
    </TaskContainer>
  );
};

interface TaskContainerProps {
  readonly $isActive: boolean;
  readonly $isSmall: boolean;
}
const TaskContainer = styled(Link)<TaskContainerProps>`
  ${tw`relative font-medium text-white bg-grey-900 rounded transition-colors mb-2 px-4 py-2`}
  ${tw`hover:bg-opacity-75`}

  ${({ $isActive }) => $isActive && tw`before:(content[''] absolute inset-y-0 left-0 w-1 bg-primary rounded-l)`}
  ${({ $isSmall }) => $isSmall && tw`text-sm mb-0 py-1`}
`;

export default TaskItem;
