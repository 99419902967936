import type { AxiosRequestConfig } from 'axios';
import { ApiCaller } from '../utils/ApiCaller';
import { qParam, Service } from './Service';

import type { IUsersService } from './UsersService.d';
export * from './UsersService.d';

const BASE_URL = '/auth';

export class UsersService implements Service {
  public static getUsers(body?: IUsersService.IApplyBody, params?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get</* IUsersService.IGetUsersResponse[] */ any>(`/apply`, params);
  }
}
