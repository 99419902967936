import tw, { styled } from 'twin.macro';
import { Button } from '@asu/ui';
import { IconDocument } from '../../../../assets';
import React from 'react';

const getLink = (file: File): Promise<string> => {
  return new Promise(resolve => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result as string);
  });
};

const download = async (file: File) => {
  const link = await getLink(file);
  const linkEl = document.createElement('a');
  linkEl.href = link;
  linkEl.download = file.name;
  linkEl.click();
  linkEl.remove();
};

interface FileListWrapProps {
  fileList: any;
  showDownload?: any;
  showDelete?: any;
  downloadCallback?: any;
  deleteCallback?: (e: any) => void;
}

const FileListWrap: React.FC<FileListWrapProps> = ({ fileList, showDownload, showDelete, deleteCallback, downloadCallback }) => {
  const onClickDownload = async (file: any) => {
    if (downloadCallback) {
      const content = await downloadCallback(file.id);
      if (!content) return;
      const blob = new Blob([content]);
      file = new File([blob], file.name);
    }
    download(file);
  };

  return (
    <div>
      {fileList &&
        fileList.map((item, index) => (
          <FileStyled key={index}>
            <IconDocument tw="w-6 h-6" />
            <span tw="ml-2 width[250px]">{item.name}</span>
            <div tw="ml-3 flex flex-row">
              {showDownload && (
                <Button tw="h-8 w-20" onClick={() => onClickDownload(item)} type="button">
                  Скачать
                </Button>
              )}
              {showDelete && (
                <Button tw="ml-1 h-8 w-20" onClick={() => deleteCallback(item.id)} type="button">
                  Удалить
                </Button>
              )}
            </div>
          </FileStyled>
        ))}
    </div>
  );
};

export const FileStyled = tw.div`flex flex-row justify-between items-center rounded mt-2 p-2 border-2 border-gray-300`;

export default FileListWrap;
