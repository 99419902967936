import React from 'react';
import tw, { styled } from 'twin.macro';
import { observer } from 'mobx-react-lite';
import { ModalCreate } from '../../Explorer/local-components';

type AddFileProps = {
  className?: string;
};

const AddFile = observer<AddFileProps>(() => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const closeModalCreate = () => {
    setIsOpen(false);
  };

  const openModalCreate = () => {
    setIsOpen(true);
  };

  return (
    <>
      {process.env.NX_SYSTEM_TYPE !== 'GGE' && (
        <Button type="button" onClick={() => setIsOpen(true)}>
          <span tw="font-medium text-sm leading-none">Добавить папку</span>
        </Button>
      )}
      {isOpen && <ModalCreate isOpen={isOpen} setIsOpen={setIsOpen} openModal={openModalCreate} closeModal={closeModalCreate} />}
    </>
  );
});

const Button = styled.button`
  ${tw`flex items-center rounded text-grey-900 cursor-pointer transition-colors duration-150 p-3`}
  ${tw`hocus:(outline-none)`}
  ${tw`opacity-100 hover:(text-grey-500 bg-grey-100) active:(text-grey-900 bg-grey-300)`}
`;

export default AddFile;
