interface IMarkup {
  id: string;
  name: string;
  modelIds: string[];
  projectId: string;
  date: string;
  viewSize: {
    width: number;
    height: number;
    aspect: number;
    fov: number
  };
  markups: object;
  cameraPos: [number,number,number];
  targetPos: [number,number,number];
  priority: string;
  index: number;
}

interface IMarkupCount {
  markupCount: number;
}

interface IBase64 {
  imageBase64: string;
}

export declare namespace IViewerService {
  type IGetModelMarkups = IMarkup[];
  type IGetMarkup = IMarkup;
  type IGetModelMarkupsCount = IMarkupCount;
  type IGetMarkupScreenshot = IBase64;
}
