import React from 'react';
import { INotificationService, NotificationService } from '@asu/services';

export const useNotifications = (id): [boolean, INotificationService.IGetAllNotifications | null, (id: string) => void] => {
  const [notifications, setNotifications] = React.useState<INotificationService.IGetAllNotifications | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const dismissNotification = React.useCallback((id: string) => {
    setNotifications(prevState => [...prevState.filter(el => el.id !== id)]);
  }, []);

  React.useEffect(() => {
    if (id) {
      (async () => {
        setIsLoading(true);
        const response = await NotificationService.getAllNotifications(id).fetch();
        const responseData = response.data;
        setNotifications(responseData);
        setIsLoading(false);
      })();
    }
  }, [id]);

  return [isLoading, notifications, dismissNotification];
};
