import React from 'react';
import tw, { styled } from 'twin.macro';

type WrapperProps = {
  children: any;
};

const Wrapper = React.memo<WrapperProps>(({ children, ...rest }) => {
  return <FoldersGrid {...rest}>{children}</FoldersGrid>;
});

const FoldersGrid = styled.section`
  ${tw`grid gap-4 mb-16`}
  grid-template-columns: repeat(auto-fill, 250px);
`;

type FolderProps = {
  data: any;
  onClick: (data: any) => void;
  onContextMenu: (e: any, data: any) => void;
};

const Folder = React.memo<FolderProps>(({ onClick, onContextMenu, data }) => {
  return (
    <Container onContextMenu={e => onContextMenu(e, data)} onClick={() => onClick(data)}>
      <Name>{data.baseName}</Name>
    </Container>
  );
});

const Name = tw.p`text-white text-sm leading-5 pt-3 pb-3 pl-3 pr-2`;
const Container = tw.div`h-full border-l-4 border-primary-dark rounded bg-grey-900 hover:bg-grey-500 transition-colors duration-150 cursor-pointer`;

const GridView = {
  Folder,
  Wrapper,
};

export default GridView;
