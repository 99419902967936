import { qParam } from './Service.d';
import { Service } from './Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import { ICamundaService } from './CamundaService.d';
export * from './CamundaService.d';

const BASE_URL = '/camunda';

export class CamundaService implements Service {
  public static getProcessList(config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<ICamundaService.IGetProcessList>('/camunda/process/list', config);
  }
  // deprecated
  public static getHistoryProcessList(projectId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<any>(`/camunda/process/history/${projectId}`, config);
  }
  public static getHistoryProcessListGantt(projectId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<ICamundaService.IGetHistoryProcessListGantt>(`/camunda/process/history/${projectId}/gantt`, config);
  }
  public static getApproveHistoryList(taskDefId: qParam, projectId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<any>(`/camunda/process/task/${taskDefId}/approve/history/project/${projectId}`, config);
  }
  public static getProcessDiagram(projectId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<void>(`/camunda/process/${projectId}/file`, config);
  }
  public static postStartProcess(body: ICamundaService.Body.IPostStartProcess, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<ICamundaService.IPostStartProcess>('/camunda/process/start', body, config);
  }
  public static postCreateTask(body: ICamundaService.Body.IPostCreateTask, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<void>('/task/markup', body, config);
  }
  public static postSubscribeUserTask(customTaskId: qParam, userId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<void>(`/task/markup/${customTaskId}/user/${userId}/assign`, config);
  }
  public static updateTaskFromMarkups(body: ICamundaService.Body.IUpdateTaskFromMarkups, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<void>(`/task/markup`, body, config);
  }
  public static getTasksFromMarkups(projectId: qParam, userId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<ICamundaService.IGetTasksFromMarkups>(`/task/markup/project/${projectId}/user/${userId}`, config);
  }
  public static getTaskFromMarkup(id: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<ICamundaService.IGetTaskFromMarkup>(`/task/markup/custom/${id}`, config);
  }
  public static closeTaskFromMarkups(customTaskId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<void>(`/task/markup/${customTaskId}/complete`, config);
  }
  public static getTasks(projectId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<ICamundaService.IGetTasks>(`/camunda/process/task/list/?projectId=${projectId}`, config);
  } // deprecated but using in project
  public static completeTask(taskId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<ICamundaService.ICompleteTask>(`/camunda/process/task/${taskId}/done`, config);
  }
  public static approveTask(taskId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<ICamundaService.IApproveTask>(`/camunda/process/task/${taskId}/approve`, config);
  }
  public static rejectTask(taskId: qParam, body: ICamundaService.Body.IRejectTask, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<ICamundaService.IRejectTask>(`/camunda/process/task/${taskId}/reject`, body, config);
  }
}
