interface IGeoLayer {
  id: string;
  name: string;
  workspace: string;
  version: number;
  isActive: boolean;
  rusName?: string;
  minZoom?: number;
  maxZoom?: number;
  isBase: boolean;
  type?: string;
  projectId?: string;
  href?: string;
}

interface IPostBody {
  shapeFile: string;
  filename: string;
  workspace: string;
  rusName: string;
  projectId: string;
}

interface ITextId {
  id: string;
  text: string;
}

interface IGeoCreateBody {
  projectId: string;
  text: string;
  longitude: number;
  latitude: number;
}

interface IGeoCreateResponse extends IGeoCreateBody {
  id: string;
}

interface IGeoDeleteResponse extends IGeoCreateResponse {
  targetUsers: string[];
}

interface IGeoDeleteResponseNoId extends IGeoCreateBody {
  targetRoles: string[];
}

interface IUSerNote {
  id: string;
  text: string;
}

export declare namespace IGeoService {
  type IGetLayers = IGeoLayer[];
  type IGetActiveProjectLayers = IGeoLayer[];
  type IPostShapes = IGeoLayer;
  type IPostProjectShapes = IGeoLayer;
  type IPostShapeZip = IGeoLayer;
  type IPostProjectShapeZip = IGeoLayer;
  type IGetProjectVersion = IGeoLayer[];
  type ISetVersion = IGeoLayer;
  type IPutNoteProject = IGeoCreateResponse;
  type IGetNoteProject = IGeoCreateResponse[];
  type IDeleteNoteProject = IGeoCreateResponse;
  type IDeleteNoteUsers = IGeoDeleteResponse;
  type IDeleteNoteRoles = IGeoDeleteResponse;
  type IUpdateNoteProject = IGeoCreateResponse;
  type IUpdateNoteUsers = IGeoDeleteResponse;
  type IUpdateNoteRoles = IGeoDeleteResponse;
  type IPutNoteUser = IGeoDeleteResponse;
  type IGetNoteUser = IGeoDeleteResponse[];
  type IPutNoteRole = IGeoDeleteResponse;
  type IGetNoteRole = IGeoDeleteResponse[];

  declare namespace Body {
    type IPostShapes = IPostBody;
    type IPostProjectShapes = IPostBody;
    type IPostShapeZip = IPostBody;
    type IPostProjectShapeZip = IPostBody;
    type IPutNoteProject = IGeoCreateBody;
    type IUpdateNoteProject = ITextId;
    type IUpdateNoteUsers = IUSerNote;
    type IUpdateNoteRoles = IUSerNote;
    type IPutNoteUser = IGeoDeleteResponseNoId;
    type IPutNoteRole = IGeoDeleteResponseNoId;
  }
}
