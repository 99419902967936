export enum DocumentsTypes {
  file = 'DOCUMENT',
  model = 'MODEL',
  folder = 'DIRECTORY',
}

export enum DirectoryTypes {
  FILE = 'DOCUMENT',
  FOLDER = 'FOLDER',
}

export interface IDirectoriesResponse {
  contents: IDirectoriesContent[];
  folderId: string;
}

export interface IDirectoriesContent {
  created: number;
  id: string;
  name: string;
  parentFolderId: string;
  projectId: string;
  type: DirectoryTypes;
}

export interface IDirectories {
  contents: IDirectoriesContent[];
  folderId: string;
}
export interface IFiles {
  contents: IDirectoriesContent[];
  folderId: string;
}

export interface IVerions {
  contents: IDirectoriesContent[];
  fileId: string;
}
