import tw, { styled } from 'twin.macro';
import { observer } from 'mobx-react-lite';
import { ExplorerView, useFileSystemState } from '../../store';
import {
  ModalDeleteFile,
  GridView,
  ListView,
  ModalPhotoPreview,
  ModalPreview,
  ModalMarkupPreview,
  ModalDeleteSomeFiles,
} from './local-components';
import { DocumentsTypes } from '../../types';
import { Menu } from '../../styles';
import React from 'react';
import { ContextMenu } from '..';
import { ModalReName } from '../Explorer/local-components';
import { useVersions } from '../MetaData/hooks';
import { useMarkUp, useProjectMeta, useModelGantt } from './hooks';
import ModalMeta from '../MetaData/ModalMeta';
import { useAuthState } from '@asu/auth';

type FilesProps = {
  id?: string;
};

let timer;

const FileVariants = {
  [ExplorerView.Grid]: GridView,
  [ExplorerView.List]: ListView,
};

// getMeta: (modelId: string, projectId: string) => Promise<any[]>;
const FileMetaContainer = ({ View, innerMetaFile, ganttModels, getMeta, data, ...rest }) => {
  const [metaFile, setMetaFile] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const meta = await getMeta(data.id, data.projectId);
      setMetaFile(meta);
    })();
  }, [data]);

  React.useEffect(() => {
    (async () => {
      const meta = await getMeta(data.id, data.projectId);
      setMetaFile(meta);
    })();
  }, [innerMetaFile]);

  const isEngineering = metaFile.find(meta => meta.key === 'engineering' && meta.value.find(item => item.id === data.id));
  const isModel = metaFile.find(meta => meta.key === 'models' && meta.value.find(item => item.id === data.id));

  return (
    <View
      key={data.id}
      isUsingInGantt={ganttModels.includes(data.id)}
      isEngineering={isEngineering}
      isModel={isModel}
      data={data}
      {...rest}
    />
  );
};

const FileView = observer<any>(({ id, innerMetaFile, ganttModels, getMeta, ...rest }) => {
  const store = useFileSystemState();

  const View = React.useMemo(() => FileVariants[store.view], [store.view]);

  if (store.files.length > 0) {
    return (
      <View.Wrapper id={id}>
        {store.files.map(data => (
          <FileMetaContainer
            key={data.id}
            ganttModels={ganttModels}
            View={View.File}
            innerMetaFile={innerMetaFile}
            getMeta={getMeta}
            data={data}
            {...rest}
          />
        ))}
      </View.Wrapper>
    );
  }

  if (store.files.length === 0 && store.dirictories.length === 0) {
    return <p tw="text-center text-grey-300 text-xl">Папка пустая</p>;
  }

  return null;
});

const Files = observer<FilesProps>(({ id }) => {
  const store = useFileSystemState();
  const auth = useAuthState();
  const ref = React.useRef<null | any>();
  const [event, setEvent] = React.useState<React.MouseEvent<HTMLDivElement, MouseEvent> | null>(null);
  const [isOpenMeta, setIsOpenMeta] = React.useState<boolean>(false);
  const [isOpenDeleteFile, setIsOpenDeleteFile] = React.useState<boolean>(false);
  const [isOpenDeleteSomeFiles, setIsOpenDeleteSomeFiles] = React.useState<boolean>(false);

  const [isPreviewOpen, setIsPeviewOpen] = React.useState<boolean>(false);
  const [isMarkupOpen, setIsMarkupOpen] = React.useState<boolean>(false);

  const [isOpenReName, setIsOpenReName] = React.useState<boolean>(false);

  const [isOpenPreview, setIsOpenPreview] = React.useState<boolean>(false);

  const [selectedFile, setSelectedFile] = React.useState<any>(null);

  const { addModel, deleteModel, getMeta } = useProjectMeta(store.projectId);

  const { ganttModels, addModelToGantt, deleteModelFromGantt, patchModelIdFromGantt } = useModelGantt(store.projectId);

  const [metaFile, setMetaFile] = React.useState([]);

  const { getVersions, postVersion } = useVersions();

  // TODO (Maxim Shkividorov): По райтклику существует перезагрузка contextMenuContentModel из-за selectedFile, это нормально?
  const { isLoading, markupsCount } = useMarkUp(selectedFile?.id);

  const onClickReName = () => {
    setIsOpenReName(true);
  };

  const fetchMetaFile = async () => {
    const meta = await getMeta(selectedFile.id, selectedFile.projectId);

    setMetaFile(meta);
  };

  React.useEffect(() => {
    if (selectedFile) {
      fetchMetaFile();
    }
  }, [selectedFile]);

  const onMetaAction = async (action: Promise<any>) => {
    await action;
    fetchMetaFile();
  };

  const onClickAddNewVersion = () => {
    ref.current?.click();
  };

  const onUpdateVersion = (newFileId: string) => {
    patchModelIdFromGantt(selectedFile?.id, newFileId);
  };

  const isEngineering = metaFile.find(meta => meta.key === 'engineering' && meta.value.find(item => item.id === selectedFile?.id));
  const isModel = metaFile.find(meta => meta.key === 'models' && meta.value.find(item => item.id === selectedFile?.id));
  const isModelIntoGantt = ganttModels.includes(selectedFile?.id);

  const contextMenuContentModel = (
    <Menu.Container>
      <Menu.Item onClick={() => setIsPeviewOpen(true)}>Открыть 3D модель</Menu.Item>
      {process.env.NX_SYSTEM_TYPE === 'RZD' && (
        <>
          {!auth.currentUser.permissionsBlacklist?.includes('MARK_AS_ENGINEERING') && !isEngineering ? (
            <Menu.Item
              onClick={() => {
                onMetaAction(addModel(selectedFile.id, selectedFile.baseName || 'Безымянный.ifc', 'engineering'));
              }}
            >
              Отметить как инженерное изыскание
            </Menu.Item>
          ) : (
            <Menu.Item onClick={() => onMetaAction(deleteModel(selectedFile.id, 'engineering'))}>Убрать как инженерное изыскание</Menu.Item>
          )}
          {!auth.currentUser.permissionsBlacklist?.includes('MARK_AS_PRIMARY_MODEL') &&
            (!isModel ? (
              <Menu.Item onClick={() => onMetaAction(addModel(selectedFile.id, selectedFile.baseName || 'Безымянный.ifc', 'models'))}>
                Сделать основной моделью
              </Menu.Item>
            ) : (
              <Menu.Item onClick={() => onMetaAction(deleteModel(selectedFile.id, 'models'))}>Убрать с основной модели</Menu.Item>
            ))}
        </>
      )}

      {process.env.NX_SYSTEM_TYPE === 'RZD' && (
        <Menu.Item onClick={isModelIntoGantt ? () => deleteModelFromGantt(selectedFile?.id) : () => addModelToGantt(selectedFile?.id)}>
          Участвует в графике производства работ
        </Menu.Item>
      )}
      {!isLoading && markupsCount > 0 && <Menu.Item onClick={() => setIsMarkupOpen(true)}>Открыть замечания модели</Menu.Item>}
      <Menu.Devider />
      <Menu.Item onClick={() => store.downloadFile(selectedFile.id, selectedFile.baseName || 'Безымянный', 'ifc')}>Скачать файл</Menu.Item>
      <Menu.Item tw="text-primary-dark" onClick={() => setIsOpenDeleteFile(true)}>
        Удалить файл
      </Menu.Item>
      <Menu.Item onClick={onClickReName}>Переименовать файл</Menu.Item>
      <Menu.Item onClick={onClickAddNewVersion}>Загрузить новую версию</Menu.Item>
      <Menu.Item
        onClick={() => {
          getVersions(selectedFile.id);
          setIsOpenMeta(true);
        }}
      >
        Свойства
      </Menu.Item>
    </Menu.Container>
  );

  const contextMenuContentFile = (
    <Menu.Container>
      {store.checkedFiles.length > 0 ? (
        <Menu.Item tw="text-primary-dark" onClick={() => setIsOpenDeleteSomeFiles(true)}>
          Удалить выбранные файлы
        </Menu.Item>
      ) : (
        <Menu.Item tw="text-primary-dark" onClick={() => setIsOpenDeleteFile(true)}>
          Удалить файл
        </Menu.Item>
      )}

      <Menu.Item onClick={() => store.downloadFile(selectedFile.id, selectedFile.baseName || 'Безымянный', selectedFile.ext)}>
        Скачать файл
      </Menu.Item>
      <Menu.Item onClick={onClickReName}>Переименовать файл</Menu.Item>
      <Menu.Item onClick={onClickAddNewVersion}>Загрузить новую версию</Menu.Item>
      <Menu.Item
        onClick={() => {
          getVersions(selectedFile.id);
          setIsOpenMeta(true);
        }}
      >
        Свойства
      </Menu.Item>
    </Menu.Container>
  );

  const onModalClose = () => {
    setIsPeviewOpen(false);
    setIsMarkupOpen(false);
    setIsOpenPreview(false);
    setSelectedFile(null);
  };

  const onContextMenu = (e, data) => {
    e.preventDefault();
    setEvent(e);
    setSelectedFile(data);
  };

  const onClickOutside = () => {
    setEvent(null);
  };

  const onClickModel = data => {
    setIsPeviewOpen(true);
    setSelectedFile(data);
  };

  const closeModalReName = () => {
    setIsOpenReName(false);
    setSelectedFile(null);
  };

  const openModalReName = () => {
    setIsOpenReName(true);
  };

  const closeModalMeta = () => {
    setIsOpenMeta(false);
    setSelectedFile(null);
  };

  const openModalMeta = () => {
    setIsOpenMeta(true);
  };

  const closeModalDeleteFile = () => {
    setIsOpenDeleteFile(false);
    setIsOpenDeleteSomeFiles(false);
    setSelectedFile(null);
  };

  const openModalDeleteFile = () => {
    setIsOpenDeleteFile(true);
  };

  const closeModalPhotoPreview = () => {
    setIsOpenPreview(false);
    setSelectedFile(null);
  };

  const openModalPhotoPreview = () => {
    setIsOpenPreview(true);
  };

  const onClickFile = (e, imgSrc, data) => {
    clearTimeout(timer);
    if (e.detail === 1) {
      timer = setTimeout(() => imgSrc && setIsOpenPreview(true), 200);
    } else if (e.detail === 2) {
      store.downloadFile(selectedFile.id, selectedFile.baseName, selectedFile.ext);
    }
    setSelectedFile(data);
  };

  return (
    <>
      <FileView
        id={id}
        innerMetaFile={metaFile}
        ganttModels={ganttModels}
        getMeta={getMeta}
        onClickModel={onClickModel}
        onContextMenu={onContextMenu}
        onClickFile={onClickFile}
      />
      {event && (
        <ContextMenu
          event={event}
          onClickOutside={onClickOutside}
          content={selectedFile?.type === DocumentsTypes.model ? contextMenuContentModel : contextMenuContentFile}
        />
      )}
      {isOpenDeleteFile && (
        <ModalDeleteFile
          deleteId={selectedFile?.id}
          isOpen={isOpenDeleteFile}
          setIsOpen={setIsOpenDeleteFile}
          openModal={openModalDeleteFile}
          closeModal={closeModalDeleteFile}
        />
      )}
      {isOpenDeleteSomeFiles && (
        <ModalDeleteSomeFiles
          deleteIdsArray={store.checkedFiles}
          isOpen={isOpenDeleteSomeFiles}
          setIsOpen={setIsOpenDeleteSomeFiles}
          openModal={openModalDeleteFile}
          closeModal={closeModalDeleteFile}
        />
      )}

      {isOpenPreview && (
        <ModalPhotoPreview
          id={selectedFile?.id}
          isOpen={isOpenPreview}
          setIsOpen={setIsOpenPreview}
          openModal={openModalPhotoPreview}
          closeModal={closeModalPhotoPreview}
        />
      )}
      <ModalPreview
        modelId={selectedFile?.id}
        isOpen={isPreviewOpen}
        setIsOpen={setIsPeviewOpen}
        openModal={() => setIsPeviewOpen(true)}
        closeModal={() => setIsPeviewOpen(false)}
      />
      <ModalMarkupPreview
        sourceId={selectedFile?.sourceId}
        modelId={selectedFile?.id}
        markupsCount={markupsCount}
        isOpen={isMarkupOpen}
        setIsOpen={setIsMarkupOpen}
        openModal={() => setIsMarkupOpen(true)}
        closeModal={() => setIsMarkupOpen(false)}
      />
      {selectedFile && isOpenMeta && (
        <ModalMeta
          onUpdateVersion={onUpdateVersion}
          info={selectedFile}
          selectedFile={selectedFile}
          onClickModel={onClickModel}
          isOpen={isOpenMeta}
          setIsOpen={setIsOpenMeta}
          openModal={openModalMeta}
          closeModal={closeModalMeta}
        />
      )}

      {isOpenReName && (
        <ModalReName
          name={selectedFile?.baseName}
          id={selectedFile?.id}
          type={selectedFile?.type}
          isOpen={isOpenReName}
          setIsOpen={setIsOpenReName}
          openModal={openModalReName}
          closeModal={closeModalReName}
        />
      )}

      {/* input for upload new version file */}
      {selectedFile?.id && (
        <input
          type="file"
          id="file"
          tw="hidden"
          ref={ref}
          onChange={async event => {
            const file = await postVersion(selectedFile?.id, event.target.files[0]);

            if (file?.fileMeta?.id) onUpdateVersion(file.fileMeta.id);
          }}
        />
      )}
    </>
  );
});

export default Files;
