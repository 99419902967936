import React from 'react';
import dayjs from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';
import 'twin.macro';

import { Button, Modal, Input, Textarea } from '@asu/ui';

import { getBrowser } from '../utils';

// TODO: Заменить, когда появится инжект через webpack
// const systemVersion = PACKAGE_VERSION ?? '-';
const systemVersion = '-';

// TODO: Заменить, когда появится инжект через webpack
// const builtAt = BUILT_AT ?? '-';
const builtAt = '-';

declare global {
  interface Window {
    asubim_handled_errors: Array<{ text: string; filename: string }>;
  }
}
window.asubim_handled_errors = [];

export const addBugReport = (event, hint) => {
  const date = new Date();
  const buitAtDate = new Date(builtAt);

  let text = `Страница ${document.location.href} \n`;
  text += `Время формирования ошибки: ${date.toLocaleString()} \n`;
  text += `Версия сборки: ${systemVersion} \n`;
  text += `Время сборки: ${buitAtDate.toLocaleString()} \n`;
  text += `Пользователь (node_user): ${localStorage.getItem('node_user') || 'не найден'} \n`;
  text += `Пароль (node_pass): ${localStorage.getItem('node_pass') || 'не найден'} \n`;

  text += '\n\nSentry event JSON: \n' + JSON.stringify(event);
  text += '\n\nSentry hint JSON: \n' + JSON.stringify(hint);

  const filename = `${date.toISOString()}.error.log`;

  window.asubim_handled_errors.push({ text, filename });
};

const gitlabBugReport = async (formData, error, componentStack = '') => {
  const browser = getBrowser();

  const text = `### Основные сведения
<pre>
Страница: ${document.location.href}
Время формирования ошибки: ${dayjs().format('HH:mm:ss DD.MM.YYYY')}
Версия Frontend: ${systemVersion} ${dayjs(builtAt).format('HH:mm:ss DD.MM.YYYY')}
Пользователь: ${localStorage.getItem('node_user') || 'не найден'}
Браузер: ${browser.name} ${browser.version}
Контур: Dev
</pre>

### Шаги для повторения
<pre>
${formData.repeatSteps}
</pre>

### Результат
<pre>
${formData.result}
</pre>

### Ожидаемый результат
<pre>
${formData.expectedResult}
</pre>

### Error message
<pre>
${error.message}
</pre>

### Stack trace
<pre>
${error.stack}
</pre>

### React stack trace
<pre>
${componentStack}
</pre>`;

  const epicObj = {
    title: formData.name || 'Неизвестная ошибка',
    description: text,
    labels: 'priority::high,type::bug',
  };

  console.error('error >>>', error);
  console.error('test >>>', text);

  const requestEpic = await fetch('https://gitlab.sintez-at.ru/api/v4/groups/114/epics', {
    method: 'POST',
    headers: {
      'PRIVATE-TOKEN': process.env.NX_APP_GITLABTOKEN,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(epicObj),
  });
  const responseEpicData = await requestEpic.json();

  const issueObj = {
    title: formData.name || 'Неизвестная ошибка',
    description: text,
    labels: 'priority::high,type::bug',
    epic_id: responseEpicData.id,
  };

  const requestIssue = await fetch('https://gitlab.sintez-at.ru/api/v4/projects/324/issues', {
    method: 'POST',
    headers: {
      'PRIVATE-TOKEN': 'glpat-V3zKiPmrD6cpBcS_DpmE',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(issueObj),
  });
  const responseIssueData = await requestIssue.json();
};

export const generateBugReport = () => {
  if (!window.asubim_handled_errors || !window.asubim_handled_errors.length) {
    alert('Список ошибок пуст');
    return;
  }

  window.asubim_handled_errors.forEach(report => {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(report.text));
    element.setAttribute('download', report.filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  });
};

/**
 * https://reactjs.org/docs/error-boundaries.html
 */
export class ErrorBoundary extends React.Component {
  state = { hasError: false, error: null, errorInfo: null, image: null };

  static displayName = 'ErrorBoundary';

  createGitLabReport = formData => {
    gitlabBugReport(formData, this.state.error);
  };

  catchError = event => {
    this.setState(prevState => ({ ...prevState, hasError: true, error: event.error }));
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    this.setState(prevState => ({ ...prevState, hasError: true, error: error, errorInfo: info.componentStack }));
    // gitlabBugReport({}, error, info.componentStack);
  }

  componentDidMount() {
    // if (process.env.NX_SYSTEM_TYPE === 'RZD') this.loadImage('photo1');
    // window.addEventListener('error', this.catchError);
  }

  componentWillUnmount() {
    // window.removeEventListener('error', this.catchError);
  }

  loadImage = imageName => {
    /*  import(`./assets/${imageName}.jpg`).then(image => {
      this.setState(prevState => ({
        ...prevState,
        image: image.default,
      }));
    }); */
  };

  render() {
    if (this.state.hasError) {
      return (
        <div tw="container mx-auto px-4 flex flex-col items-center justify-center min-h-screen py-6">
          {/*  {process.env.NX_SYSTEM_TYPE === 'RZD' && (
            <div tw="flex flex-col justify-end w-[600px] h-[450px] px-6 py-4 mb-6 shadow-xl">
              {this.state.image && <img tw="h-auto w-full mb-4" src={this.state.image} alt="Train crash" />}
              <p tw="text-grey-900 font-light text-xs">
                Крупнейшая в истории России и СССР железнодорожная катастрофа, произошедшая 4 июня 1989 года в Иглинском районе Башкирской
                АССР в 11 км от города Аша (Челябинская область) на перегоне Аша — Улу-Теляк.
              </p>
            </div>
          )} */}
          {/*   <figure tw="block h-[3px] w-[200px] bg-grey-100 rounded-full my-6" /> */}
          <h1 tw="text-grey-900 font-title text-2xl font-medium">Что-то пошло не такъ...</h1>
          <p tw="text-grey-900 text-sm text-center mb-4">
            Благо мы уже не въ XX веке и вы можете сообщить нам о проблеме нажатием всего на одну кнопку <br />
            или попробовать сделать задуманное снова
          </p>
          <h2 tw="text-grey-900 text-sm font-medium mb-2">Доступные варианты решения</h2>
          <div tw="flex space-x-4">
            <Button onClick={() => window.location.reload()} tw="py-2 px-4 bg-primary hover:bg-primary-dark/75">
              Перезагрузить страницу
            </Button>
            {process.env.NODE_ENV !== 'production' && (
              <Modal.Root trigger={<Button tw="py-2 px-4 bg-gray-400 hover:bg-gray-400/75">Создать Issue на GitLab</Button>}>
                <GitlabModalReport error={this.state.error} errorInfo={this.state.errorInfo} />
              </Modal.Root>
            )}
            <Button onClick={generateBugReport} tw="py-2 px-4 bg-gray-400 hover:bg-gray-400/75">
              Получить отчёт об ошибке
            </Button>
          </div>

          <details style={{ whiteSpace: 'pre-wrap' }}>
            <summary tw="text-xs text-grey-300 cursor-pointer mt-8 py-4">Подробности ошибки</summary>
            {this.state.error?.toString()}
            <br />
            {this.state.errorInfo}
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}

type GitlabModalReportProps = {
  error: any;
  errorInfo: any;
};
const GitlabModalReport = React.memo<GitlabModalReportProps>(({ error, errorInfo }) => {
  const methods = useForm();

  const onSubmit = data => {
    gitlabBugReport(data, error, errorInfo);
  };

  return (
    <Modal.Content>
      {!methods.formState.isSubmitted ? (
        <>
          <Modal.Title>Создание Issue на GitLab</Modal.Title>
          <Modal.Desciprion>Пожалйста, заполните следующее поля для полноты описания найденной ошибки.</Modal.Desciprion>

          <FormProvider {...methods}>
            <form tw="px-4 py-2" onSubmit={methods.handleSubmit(onSubmit)}>
              <Input type="text" placeholder="Название ошибки" withLabel {...methods.register('name')} />
              <Textarea placeholder="Шаги для повторения" withLabel {...methods.register('repeatSteps')} />
              <Textarea placeholder="Результат" withLabel {...methods.register('result')} />
              <Textarea placeholder="Ожидаемый результат" withLabel {...methods.register('expectedResult')} />

              <Button type="submit">Создать Issue</Button>
            </form>
          </FormProvider>
        </>
      ) : (
        <>
          <Modal.Title>Issue успешно создано</Modal.Title>
          <Modal.Desciprion>Работа над исправлением начнётся в ближайшее время.</Modal.Desciprion>

          <div tw="mx-4 py-2">
            <Button onClick={() => window.location.reload()} tw="py-2 px-4 bg-primary hover:bg-primary-dark/75">
              Перезагрузить страницу
            </Button>
          </div>
        </>
      )}
    </Modal.Content>
  );
});

// const Button = tw.button`bg-gray-400 hover:bg-gray-400/75 transition-colors text-white text-sm rounded-md px-4 py-2`;
