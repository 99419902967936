import dayjs from 'dayjs';
import { useFileSystemState } from '../../../store';
import React from 'react';
import tw, { styled } from 'twin.macro';
import { DragAndDropVersions } from '../../DragAndDropVersions';
import { useVersions } from '../hooks';
import { IconCloud } from '../../../assets';
import { Tooltip } from '@asu/ui';

// eslint-disable-next-line @typescript-eslint/ban-types
type VersionsProps = {
  selectedId: string;
};

const Versions = React.memo<VersionsProps>(({ selectedId }) => {
  const store = useFileSystemState();
  const { isLoading, versions, postVersion } = useVersions(selectedId);

  return (
    <Container>
      {!isLoading ? (
        versions?.map((data, id) => {
          return (
            <BodyWrapper key={id}>
              <VersionBlock>
                <TextWrapper>
                  <Title>{data.fileMeta.baseName}</Title>
                  <Description>
                    {dayjs.unix(data.fileMeta.modifiedAt).format('DD.MM.YYYY HH:mm:ss')} | {data.fileMeta.size} байт
                  </Description>
                </TextWrapper>
                <Tooltip mouseEnterDelay={0.1} placement="top" title="Скачать версию">
                  <IconWrapper
                    onClick={() => {
                      store.downloadFile(data.fileMeta.id, data.fileMeta.baseName, data.fileMeta.ext);
                    }}
                  >
                    <IconCloud tw="color[#eb0000] h-6 w-6" />
                  </IconWrapper>
                </Tooltip>
              </VersionBlock>
            </BodyWrapper>
          );
        })
      ) : (
        <div tw="px-4 pt-4">
          <div tw="flex flex-wrap">
            {Array.from(Array(2), (_, i) => (
              <BlockPlaceholder key={i} />
            ))}
          </div>
        </div>
      )}
      <DragAndDropVersions onUpdateVersion={newFileId => null} selectedId={selectedId} postVersion={postVersion} />
    </Container>
  );
});

const BodyWrapper = tw.div`px-4 my-4`;
const VersionBlock = tw.div`flex justify-between`;
const TextWrapper = styled.div`
  max-width: 20rem;
`;
const Title = tw.h2`font-medium text-sm overflow-hidden overflow-ellipsis`;
const Description = tw.p`text-xs text-gray-500`;
const IconWrapper = tw.div`flex items-center cursor-pointer`;
const Container = styled.main`
  ${tw`height[93%] w-full overflow-y-scroll`}

  ::-webkit-scrollbar {
    width: 3px;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d1d5db;
    border-radius: 20px;
    opacity: 0.2;
  }
`;

const BlockPlaceholder = tw.div`height[40px] w-full bg-grey-300 bg-opacity-30 rounded animate-pulse mb-3`;

export default Versions;
