import React from 'react';
import { Button, Modal, Input, useManualModalState } from '@asu/ui';
import tw from 'twin.macro';
import { FormProvider, useForm } from 'react-hook-form';
import { useFileSystemState } from '../../../store';

const typeManager = {
  FILE: 'MODEL' && 'DOCUMENT',
  FOLDER: 'DIRECTORY',
};

interface AddModalRenameFolderProps {
  id: string;
  type: string;
}

interface ModalReNameProps extends AddModalRenameFolderProps {
  isOpen: boolean;
  setIsOpen: any;
  name: string;
  openModal: () => void;
  closeModal: () => void;
}

const ModalReName = React.memo((props: ModalReNameProps) => {
  const { type, id } = props;
  return (
    <Modal.Root manual {...props}>
      <AddModalRenameFolder type={type} id={id} />
    </Modal.Root>
  );
});

const AddModalRenameFolder = React.memo(({ type, id }: AddModalRenameFolderProps) => {
  const methods = useForm();
  const store = useFileSystemState();
  const { closeModal } = useManualModalState();

  const onSubmit = async data => {
    typeManager['FILE'] === type ? store.renameFile(data, id) : store.renameDirectory(data, id);
    closeModal();
  };

  return (
    <Modal.Content tw="w-[35vw] min-w-[350px]">
      <Modal.Title>Переименовать</Modal.Title>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Input cross type="text" {...methods.register('name', { required: true })} placeholder={'Введите новое наименование'} />

          <StyledButton type="submit">Подтвердить</StyledButton>
        </Form>
      </FormProvider>
    </Modal.Content>
  );
});

const Form = tw.form`px-5 mt-2`;
const StyledButton = tw(Button)`my-2`;

export default ModalReName;
