import React from 'react';
import store from './store';

const StoreFileSystem = React.createContext<any>(null);

export function useFileSystemState() {
  const context = React.useContext(StoreFileSystem);

  if (context === undefined) {
    throw new Error('useFileSystemState must be used within a FileSystemProvider');
  }

  return context;
}

type FileSystemStoreProviderProps = {
  onlyModels?: boolean;
  projectId: string;
  children: React.ReactChild;
};

export const FileSystemStoreProvider = React.memo<FileSystemStoreProviderProps>(({ children, onlyModels, projectId }) => {
  if (projectId === undefined) {
    throw new Error('useFileSystemState must have projectId argument');
  }

  return <StoreFileSystem.Provider value={store(projectId, onlyModels)}>{children}</StoreFileSystem.Provider>;
});
