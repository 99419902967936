import React from 'react';
import ReactDOM from 'react-dom';
import tw from 'twin.macro';

type OverlayProps = {
  children: React.ReactNode;
  style?: any;
};

const Overlay = React.memo<OverlayProps>(({ children, style }) => {
  const content = (
    <Container style={style}>
      <div tw="pointer-events-auto">{children}</div>
    </Container>
  );
  return ReactDOM.createPortal(content, document.getElementById('react-portal'));
});

const Container = tw.div`fixed inset-0 h-screen w-screen overflow-hidden pointer-events-none`;

export default Overlay;
