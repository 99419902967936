import React from 'react';
import tw, { styled } from 'twin.macro';
import { useCamunda } from '../hooks';
import TaskItem from './TaskItem';
import { log } from 'util';

type ListViewProps = {
  params: any;
};

const ListView = React.memo<ListViewProps>(({ params }) => {
  const [isLoadingTasks, tasks] = useCamunda(params.projectId);

  if (isLoadingTasks) {
    return (
      <Scrollview>
        <Container>
          {Array.from(Array(7), (_, i) => (
            <div key={i} tw="h-16 w-48 bg-grey-300 bg-opacity-30 rounded animate-pulse mr-4" />
          ))}
        </Container>
      </Scrollview>
    );
  }

  return (
    <Scrollview>
      <Container tw="space-x-4">
        {tasks
          .filter(task => task.name === 'Контроль строительства')
          .map(task => (
            <TaskItem key={task.id} task={task} small={false} params={params} />
          ))}
      </Container>
    </Scrollview>
  );
});

const Scrollview = tw.section`h-24 width[calc(100vw - 270px)] border-b border-grey-100`;
const Container = styled.div`
  ${tw`h-full w-full flex items-center overflow-x-auto px-6`}

  ::-webkit-scrollbar {
    ${tw`h-1 w-1 rounded-lg`}
  }

  ::-webkit-scrollbar-track {
    ${tw`bg-white rounded-lg`}
  }

  ::-webkit-scrollbar-thumb {
    ${tw`bg-grey-300 rounded-full border-2 border-white opacity-20`}
  }
`;

export default ListView;
