import React from 'react';
import { ProjectService } from '@asu/services';
import { useAuthState } from '@asu/auth';

export const useTasks = (): [boolean, any] => {
  const [tasks, setTasks] = React.useState<any | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const authStore = useAuthState();

  React.useEffect(() => {
    (async () => {
      setIsLoading(true);

      const request = await ProjectService.getUserProjects(authStore.currentUser.id).fetch();
      const responseProjects = request.data;

      const projects = responseProjects.filter(el => el.tasks.length > 0).map(el => el.tasks);

      setTasks(projects);
      setIsLoading(false);
    })();
  }, [authStore.currentUser.id]);

  return [isLoading, tasks];
};
