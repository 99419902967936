import tw, { styled } from 'twin.macro';
import { default as AntdSelect } from 'antd/lib/select';
import 'antd/lib/select/style/index.css';

declare type SelectInterface = typeof AntdSelect;

const StyledAntdSelect = styled(AntdSelect)`
  &&&.ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    ${tw`leading-10 max-w-2xl`}
  }
  &&&.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    ${tw`h-10`}
  }
  &&&.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    ${tw`rounded-lg h-10`}
  }
  &&&.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    ${tw`border-primary-light`}
  }
  &&&.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
    ${tw`border-primary-light border-opacity-75`}
  }

  .ant-select-selector {
    ${tw``}
  }
`;

const Select: SelectInterface = StyledAntdSelect;

export default Select;
