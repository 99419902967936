import tw, { styled } from 'twin.macro';
import AntdTooltip from 'antd/lib/tooltip';
import 'antd/lib/tooltip/style/index.css';

declare type TooltipInterface = typeof AntdTooltip;

const StyledAntdTooltip = styled(AntdTooltip)``;

const Tooltip: TooltipInterface = StyledAntdTooltip;

export default Tooltip;
