import { ProjectService } from '@asu/services';

type MetaValue = { key: string; value: any };

const keys = ['engineering', 'models'];

// TODO: Refactoring
export const useProjectMeta = (projectId: string | null) => {
  const fetchProjectMeta = async (projectIdInner?: string) => {
    try {
      if (!projectIdInner && !projectId) {
        throw new Error('projectIdInner and projectId are undefined');
      }
      const response = await ProjectService.getProjectMeta(projectIdInner ?? projectId).fetch();

      const data = response.data;

      if (data) return data;
    } catch (err) {
      console.warn(err);
    }
  };

  const addModel = async (modelId: string, name: string, key: 'engineering' | 'models') => {
    const projectMeta = await fetchProjectMeta();

    if (!projectMeta) return;

    const models = projectMeta.find(meta => meta.key === key);
    const anotherKey = keys.filter(anotherKey => anotherKey !== key)[0];

    await deleteModel(modelId, anotherKey as 'engineering' | 'models');

    // Если нет поля models
    if (!models) {
      const modelsObject = {
        key,
        value: JSON.stringify([{ id: modelId, name }]),
      };

      ProjectService.addProjectMeta(projectId, key, modelsObject).fetch();

      return;
    }
    const modelsValues = models.value ? JSON.parse(models.value) : [];

    if (!modelsValues) return;

    const modelIsAlreadyAdded = modelsValues.find(model => model.id === modelId);

    if (modelIsAlreadyAdded) return;

    modelsValues.push({
      id: modelId,
      name,
    });

    const response = await ProjectService.addProjectMeta(projectId, key, { key, value: JSON.stringify(modelsValues) }).fetch();

    return response.data;
  };

  const deleteModel = async (modelId: string, key: 'engineering' | 'models') => {
    const projectMeta = await fetchProjectMeta();

    if (!projectMeta) return;

    const models = projectMeta.find(meta => meta.key === key);

    // Если нет поля models
    if (!models) return;

    const modelsValues = models.value ? JSON.parse(models.value) : [];

    if (!modelsValues) return;

    const filtered = modelsValues.filter(model => model.id !== modelId);

    const response = await ProjectService.addProjectMeta(projectId, key, { key, value: JSON.stringify(filtered) }).fetch();

    return response.data;
  };

  const getMeta = async (modelId: string, projectId: string): Promise<any[]> => {
    const projectMeta = await fetchProjectMeta(projectId);

    if (!projectMeta) return [];

    const models = projectMeta.filter(meta => keys.includes(meta.key));

    if (!models) return [];

    const parsedModels = models.map(model => {
      const arr = model.value ? JSON.parse(model.value) : [];
      model.value = arr.filter(modelValue => modelValue.id === modelId);
      return model;
    });

    return parsedModels;
  };

  const updateMetaVersion = async (modelId: string, newModelId: string) => {
    let key = null;
    const projectMeta = await fetchProjectMeta(projectId);

    if (!projectMeta) return [];

    const models = projectMeta.filter(meta => keys.includes(meta.key));

    if (!models) return [];

    models.forEach(model => {
      const arr = model.value ? JSON.parse(model.value) : [];

      const currentModel = arr.find(item => item.id === modelId);

      if (currentModel) {
        currentModel.id = newModelId;
        key = model.key;
      }

      model.value = arr;
    });

    if (!key) return;

    await ProjectService.addProjectMeta(projectId, key, {
      key,
      value: JSON.stringify(models.find(model => model.key === key).value),
    }).fetch();
  };

  return {
    getMeta,
    addModel,
    deleteModel,
  };
};
