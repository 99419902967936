import React, { ReactElement } from 'react';
import tw from 'twin.macro';

export enum Size {
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'Large',
}

export enum Theme {
  Dark = 'DARK',
  Light = 'LIGHT',
}

const ButtonSizeMap = {
  [Size.Small]: tw`w-4 h-4`,
  [Size.Medium]: tw`w-6 h-6`,
  [Size.Large]: tw`w-8`,
};

const ButtonThemeMap = {
  [Theme.Dark]: tw`bg-gray-700 text-white`,
  [Theme.Light]: tw`hover:bg-gray-200`,
};

type IconButtonProps = {
  size?: Size;
  theme?: Theme;
  label?: string;
  children?: ReactElement;
  onClick?: () => void;
};

/**
 * @param size - Button size. Default - Medium
 * @param children - Children element - icon
 * @param label - Text label
 * @param onClick - Click callback
 */
export const IconButton = React.memo<IconButtonProps>(({ children, onClick, label, size = Size.Medium, theme = Theme.Light }) => {
  return (
    <Wrapper type="button" onClick={onClick}>
      <Icon css={[ButtonSizeMap[size], ButtonThemeMap[theme]]}>{children}</Icon>
      {label && <Label>{label}</Label>}
    </Wrapper>
  );
});

const Wrapper = tw.button`flex justify-center items-center bg-transparent border-0 gap-2`;
const Icon = tw.span`flex justify-center items-center rounded`;
const Label = tw.span`text-base hover:underline`;
