export const localeDate = (date, type = 'dateTime'): string => {
  let options;
  switch (type) {
    case 'dateTime':
      options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
      break;
    case 'date':
      options = { year: 'numeric', month: 'long', day: 'numeric' };
      break;
    case 'shortDateTime':
      options = {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      };
      break;
    case 'dateForInput':
      options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      };
      break;
    case 'shortDate':
    default:
      options = { year: '2-digit', month: '2-digit', day: '2-digit' };
  }
  return new Date(+date).toLocaleDateString('ru-RU', options);
};
