interface IDictionaryNew {
  id?: string;
  paragraph: string;
  subParagraph: string;
  objectName: string;
  groupName: string;
  borderFrom: number;
  borderTo: number;
  unit: string;
  projectPercent: number;
  workPercent: number;
  constA?: number;
  constB?: number;
  isMultiply: boolean;
  monthQuantity: number;
  extraMonthUnit: number
}

interface ICheckBorderBody {
  id: string;
  value: string;
}

interface IFileId {
  fileId: string;
}

interface ICostEstimateFile {
  id?: string;
  projectId?: string;
  taskDefId?: string;
  fileId: string;
}

export declare namespace IDictionaryService {
  type IGetDictionaries = IDictionaryNew[];
  type IGetDictionary = IDictionaryNew;
  type IPostSmetaFile = ICostEstimateFile;
  type IPatchSmetaFile = ICostEstimateFile;
  type IGetSmetaFile = ICostEstimateFile;
  type IDeleteSmetaFile = ICostEstimateFile;
  type IPostKPFile = ICostEstimateFile;
  type IPatchKPFile = ICostEstimateFile;
  type IGetKPFile = ICostEstimateFile;
  type IDeleteKPFile = ICostEstimateFile;

  declare namespace Body {
    type ICheckBorder = ICheckBorderBody;
    type IPostSmetaFile = IFileId;
    type IPatchSmetaFile = IFileId;
    type IPostKPFile = IFileId;
    type IPatchKPFile = IFileId;
  }
}
