import { qParam } from './Service.d';
import { Service } from './Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import { IDocument } from '../types';
import type { IViewerService } from './ViewerService.d';
export * from './ViewerService.d';

const BASE_URL = '/viewer';

export class ViewerService implements Service {
  public static getModelMarkups(id: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IViewerService.IGetModelMarkups>(`/markup/model/${id}`, config);
  }
  public static getMarkup(markup: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IViewerService.IGetMarkup>(`/markup/markup/${markup}`, config);
  }
  public static getModelMarkupsCount(id: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IViewerService.IGetModelMarkupsCount>(`/markup/model/${id}/count`, config);
  }
  public static getMarkupScreenshot(id: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IViewerService.IGetMarkupScreenshot>(`/markup/markup/${id}/screenshot`, config);
  }
  public static postBcf(fileData: IDocument, access: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<any>('/markup/export/bcf', fileData, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  }
}
