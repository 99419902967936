import { Route } from 'react-router-dom';
import { WithCamundaNavigation } from '../components';
import {
  TechRoadsOfferPage,
  ProjectInitialPermitsDoc,
  ProjectCalendarPlan,
  DesignAssignmentPage,
  EstimatePage,
  ProjectApprovalPage,
  ConstructorControlPage,
  ConstructorTaskPage,
} from '../pages';

const approvalRoute = () => {
  return <Route path={'approve/:approveId/*'} element={<ProjectApprovalPage />} />;
};

export const dynamicProcessRouter = () => {
  return (
    <Route path="/project/:projectId/process/:processId">
      <Route
        path={'dictionary_data/:taskId/*'}
        element={
          <WithCamundaNavigation>
            <TechRoadsOfferPage />
          </WithCamundaNavigation>
        }
      >
        {approvalRoute()}
      </Route>
      <Route
        path={'classifier_data/:taskId/*'}
        element={
          <WithCamundaNavigation>
            <ProjectInitialPermitsDoc />
          </WithCamundaNavigation>
        }
      >
        {approvalRoute()}
      </Route>
      <Route
        path={'dictionary_calc_calendar/:taskId/*'}
        element={
          <WithCamundaNavigation>
            <ProjectCalendarPlan />
          </WithCamundaNavigation>
        }
      >
        {approvalRoute()}
      </Route>
      <Route
        path={'document/:taskId/*'}
        element={
          <WithCamundaNavigation>
            <DesignAssignmentPage />
          </WithCamundaNavigation>
        }
      >
        {approvalRoute()}
      </Route>
      <Route
        path={'dictionary_calc_cost/:taskId/*'}
        element={
          <WithCamundaNavigation>
            <EstimatePage />
          </WithCamundaNavigation>
        }
      >
        {approvalRoute()}
      </Route>
      <Route
        path={'building_management/:taskId/*'}
        element={
          <WithCamundaNavigation>
            <ConstructorControlPage />
          </WithCamundaNavigation>
        }
      >
        {approvalRoute()}
      </Route>
      <Route
        path={'expert/:taskId/*'}
        element={
          <WithCamundaNavigation>
            <ConstructorControlPage />
          </WithCamundaNavigation>
        }
      >
        {approvalRoute()}
      </Route>
      <Route
        path={'task/:taskId/*'}
        element={
          <WithCamundaNavigation>
            <ConstructorTaskPage />
          </WithCamundaNavigation>
        }
      >
        {approvalRoute()}
      </Route>
    </Route>
  );
};

export default dynamicProcessRouter;
