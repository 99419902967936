import React from 'react';
import tw, { styled } from 'twin.macro';
// TODO: Поменять import на @asu/...
import { useProjectState } from 'apps/arm/src/app/store';
import { AuthService, uriPrefix, ViewerService } from '@asu/services';
import { Checkbox, IconFullscreen, Input, LoadingIndicator, Select, Modal, useManualModalState } from '@asu/ui';
import { useBcf, useCreateTask } from '../hooks';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { observer } from 'mobx-react-lite';

const ViewerBlock = React.memo<any>(({ id, name, className, onClick, onClickCheck }) => {
  const [isLoading, setIsLoading] = React.useState<any>(true);
  const [screenshot, setScreenshot] = React.useState<any>(null);

  React.useEffect(() => {
    (async () => {
      if (id) {
        try {
          const response = await ViewerService.getMarkupScreenshot(id).fetch();
          const responseData = response.data;

          setScreenshot(responseData.imageBase64);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      }
    })();
  }, [id]);

  if (id && isLoading) {
    return <figure className={className} tw="bg-grey-300 bg-opacity-30 rounded animate-pulse" />;
  }

  return (
    <div className={className} onClick={onClick} tw="relative cursor-pointer">
      <Block>{screenshot && <img tw="h-full w-full object-cover rounded-md border border-primary-dark" src={screenshot} alt="" />}</Block>
      <p>{name}</p>
      <IconWrapper>
        <Checkbox
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onClickCheck(id, e);
          }}
        />
      </IconWrapper>
    </div>
  );
});

const Block = tw.figure`relative rounded overflow-hidden -z-1`;
const IconWrapper = tw.div`absolute top-1 right-2 z-50`;

const { Option } = Select;

const Task = observer<any>(({ onSubmit }) => {
  const methods = useForm({ mode: 'onChange' });
  const [users, setUsers] = React.useState(null);
  const store: any = useProjectState();
  const project = store.project;

  React.useEffect(() => {
    if (project?.assignedUsers) {
      (async () => {
        const users = await Promise.all(
          project.assignedUsers.map(async user => {
            const response = await AuthService.getUser(user.userId).fetch();
            const data = response.data;

            return {
              ...user,
              name: data.lastName + ' ' + data.firstName,
            };
          }),
        );

        setUsers(users);
      })();
    }
  }, [project]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} tw="px-3 mb-4">
        <div tw="mb-4">
          <p>Наименование задания</p>
          <Input type="text" {...methods.register('text', { required: true })} tw="height[45px]" />
        </div>
        <div tw="flex flex-col">
          <div tw="w-full mb-4">
            <p>Ответственное лицо</p>
            <Controller
              control={methods.control}
              rules={{
                required: true,
              }}
              name="name"
              render={({ field: { ref, value, onChange } }) => (
                <CustomSelect onChange={onChange} value={value} ref={ref}>
                  {users?.map(user => (
                    <Option key={user.id} value={user.userId}>
                      {user.name}
                    </Option>
                  ))}
                </CustomSelect>
              )}
            />
          </div>
          <InputWrapper>
            <p>Дата начала</p>
            <Input {...methods.register('startDate', { required: true })} tw="height[40px] w-full" id="estimateDate" type="date" />
          </InputWrapper>
          <InputWrapper>
            <p>Дата конца</p>
            <Input {...methods.register('endDate', { required: true })} tw="height[40px] w-full" id="estimateDate" type="date" />
          </InputWrapper>
        </div>
        <div tw="flex justify-end pr-3 pb-3">
          <Button htmlType="submit" $isActive={methods.formState.isValid}>
            Сформировать
          </Button>
        </div>
      </form>
    </FormProvider>
  );
});

interface AddModalMarkupPreviewProps {
  modelId: string;
  sourceId: string;
  markupsCount?: number;
  isOpen: boolean;
}

interface ModalMarkupPreviewProps extends AddModalMarkupPreviewProps {
  setIsOpen: any;
  openModal: () => void;
  closeModal: () => void;
}

const ModalMarkupPreview = React.memo((props: ModalMarkupPreviewProps) => {
  const { isOpen, modelId, sourceId, markupsCount } = props;
  return (
    <Modal.Root manual {...props}>
      <AddModalMarkupPreview modelId={modelId} sourceId={sourceId} markupsCount={markupsCount} isOpen={isOpen} />
    </Modal.Root>
  );
});

const AddModalMarkupPreview = React.memo(({ modelId, sourceId, markupsCount, isOpen }: AddModalMarkupPreviewProps) => {
  const { closeModal } = useManualModalState();

  const [markups, setMarkups] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState<any>(true);
  const [showIFrame, setShowIFrame] = React.useState<any>(false);
  const [selectedMarkup, setSelectedMarkup] = React.useState(null);
  const [checkedMarkUps, setCheckedMarkUps] = React.useState<any[]>([]);
  const [isOpenTask, setIsOpenTask] = React.useState<boolean>(false);
  const { postTask } = useCreateTask();
  const { postBcf } = useBcf();

  const onClickCheck = React.useCallback(
    (id, e) => {
      e.target.checked ? setCheckedMarkUps([...checkedMarkUps, id]) : setCheckedMarkUps(checkedMarkUps.filter(i => i !== id));
    },
    [checkedMarkUps],
  );

  React.useEffect(() => {
    if (isOpen && markupsCount > 0) {
      (async () => {
        try {
          const response = await ViewerService.getModelMarkups(modelId).fetch();
          const responseData = response.data;

          setShowIFrame(false);
          setMarkups(responseData);
          setIsLoading(false);
        } catch (error) {
          console.error('error', error);
          setIsLoading(false);
          setShowIFrame(true);
        }
      })();
    }
  }, [isOpen, markupsCount, modelId]);

  React.useEffect(() => {
    if (isOpen && markupsCount === 0) setShowIFrame(true);
  }, [isOpen, markupsCount]);

  const url = React.useMemo(() => {
    return selectedMarkup
      ? `${uriPrefix}/viewer/#/${sourceId}?models=${modelId}&markup=${selectedMarkup}`
      : `${uriPrefix}/viewer/#/${sourceId}?models=${modelId}`;
  }, [modelId, sourceId, selectedMarkup]);

  const onSubmit = (form: any) => {
    const body = {
      projectId: sourceId,
      markup: checkedMarkUps,
      assignedUserId: form.name,
      name: form.text,
      startDate: new Date(form.startDate).getTime() / 1000,
      estimateEndDate: new Date(form.endDate).getTime() / 1000,
    };

    postTask(body);
    closeModal();
  };

  return (
    <ModalContent tw="w-full" $isActive={isOpenTask} style={{ aspectRatio: '1/1' }}>
      <Modal.Title>{process.env.NX_SYSTEM_TYPE === 'GGE' ? 'Замечания' : 'Просмотр модели'}</Modal.Title>
      {[
        {
          onClick: () => {
            window.open(url, '_blank').focus();
            closeModal();
          },
          element: <IconFullscreen tw="h-5 w-5 text-white" />,
        },
      ]?.map(({ onClick, element }, i) => (
        <IconContainer key={i} onClick={onClick}>
          {element}
        </IconContainer>
      ))}
      {showIFrame ? (
        <div tw="h-[80vh]">
          <iframe title="Model preview" width="100%" height="100%" src={url} />
        </div>
      ) : isLoading ? (
        <div tw="h-[50vh] w-full flex items-center justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        markupsCount > 0 && (
          <div tw="h-[calc(100% - 2.8rem)] w-full overflow-y-auto">
            <Container>
              {markups.map(markup => (
                <div tw="flex justify-start items-start" key={markup.id}>
                  <ViewerBlock
                    tw="w-64"
                    id={markup.id}
                    name={markup.name}
                    onClick={() => {
                      setSelectedMarkup(markup.id);
                      setShowIFrame(true);
                    }}
                    onClickCheck={onClickCheck}
                  />
                </div>
              ))}
            </Container>
            {process.env.NX_SYSTEM_TYPE !== 'GGE' && (
              <div tw="flex flex-row justify-end items-end">
                <ButtonWrapper>
                  <Button $isActive={checkedMarkUps.length > 0} onClick={() => checkedMarkUps.length > 0 && setIsOpenTask(!isOpenTask)}>
                    Создать задание на основе
                  </Button>
                </ButtonWrapper>
                <ButtonWrapper>
                  <Button $isActive={checkedMarkUps.length} onClick={() => checkedMarkUps.length > 0 && postBcf(checkedMarkUps)}>
                    Выгрузить в BCF
                  </Button>
                </ButtonWrapper>
              </div>
            )}
            {isOpenTask && checkedMarkUps.length > 0 && <Task markups={checkedMarkUps} projectId={sourceId} onSubmit={onSubmit} />}
          </div>
        )
      )}
    </ModalContent>
  );
});

const IconContainer = tw.figure`z-10 h-5 absolute right-[35px] top-[12px] items-center text-black transition-all duration-150 cursor-pointer px-4`;

const Container = styled.div`
  ${tw`grid grid-cols-4 flex-1 gap-4 p-4 space-x-4 overflow-y-auto`}

  ::-webkit-scrollbar {
    ${tw`w-1 h-1 rounded-lg`}
  }

  ::-webkit-scrollbar-track {
    ${tw`bg-white rounded-lg`}
  }

  ::-webkit-scrollbar-thumb {
    ${tw`border-2 border-white rounded-full bg-grey-300 opacity-20`}
  }
`;
const Button = styled.button<any>`
  ${({ $isActive }) =>
    $isActive ? tw`bg-gradient-to-r from-primary-dark to-primary-light` : tw`bg-gradient-to-r from-gray-600 to-gray-700`}
  ${tw`px-4 py-2 mt-2 text-white rounded-md`}
`;
const InputWrapper = tw.div`mb-4`;
const CustomSelect = styled(Select)`
  ${tw`w-full`}
`;

const ButtonWrapper = tw.div`pr-5 pb-2`;

const ModalContent = styled(Modal.Content)<any>`
  ${({ $isActive }: any) => ($isActive ? tw`h-[80vh]` : tw`h-[35vh]`)}
  ${process.env.NX_SYSTEM_TYPE === 'GGE' && tw`h-[80vh]`}
`;

export default ModalMarkupPreview;
