import React from 'react';
import { ProjectService, IProjectService } from '@asu/services';

export const useCamunda = (projectId: string): [boolean, any | null] => { // [typeErrorNote] - any, потому что метод устарел, но используется в прокете
  const [camunda, setCamunda] = React.useState<IProjectService.IGetTasks | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    (async () => {
      setIsLoading(true);

      const response = await ProjectService.getTasks(projectId).fetch();
      const responseData = response.data;

      // const tasks = responseData.filter(
      //   el =>
      //     !el.taskDefinitionKey.includes('expert') && // TODO: убрать это условие, сейчас нужно из-за бага на бэке
      //     !el.processDefinitionId.includes('gantt-task') &&
      //     !el.processDefinitionId.includes('process-subtask') &&
      //     !el.processDefinitionId.includes('subprocess-approve-custom'),
      // );

      setCamunda(responseData);
      setIsLoading(false);
    })();
  }, [projectId]);

  return [isLoading, camunda];
};
