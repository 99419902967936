import React from 'react';
import { ViewerService } from '@asu/services';

export const useBcf = () => {
  const [isLoading, setIsLoading] = React.useState(true);

  const postBcf = React.useCallback(async id => {
    try {
      const config: any = { responseType: 'blob' };
      setIsLoading(true);
      const response = await ViewerService.postBcf(id, 'g1').fetch();
      const responseData: any = response.data;
      const blob = new File([responseData], 'BCF');

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'BCF' + '.' + 'bcf');
      document.body.appendChild(link);
      link.click();

      setIsLoading(false);
    } catch (error) {
      setIsLoading(true);
      console.error('error', error);
    }
  }, []);

  return { isLoading, postBcf };
};
