import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'twin.macro';
import dayjs from 'dayjs';

// import { IconPlus } from '../../assets';
import { capitalize, getUrl, statusInfo } from '../../utils';

const Task = React.memo<{ info: any; authors: any; projectId: string }>(({ info = {}, authors, projectId }) => {
  return (
    <SubTaskInner key={info.id}>
      <div tw="flex justify-between items-center w-full mb-1">
        <div tw="flex flex-col">
          <LinkTask to={getUrl(projectId, info.id)}>
            <Status color={statusInfo[info.coreTask.status].color} />
            {info.coreTask.name}
          </LinkTask>
          {info.coreTask.assignedUserId || info.coreTask.authorId ? (
            <Author>{`Статус: ${statusInfo[info.coreTask.status]?.text ?? '-'} | ${capitalize(
              authors[info.coreTask.assignedUserId || info.coreTask.authorId].lastName,
            )} ${authors[info.coreTask.assignedUserId || info.coreTask.authorId].firstName}`}</Author>
          ) : null}
        </div>
        <Date>{dayjs.unix(info.coreTask.createDate).format('HH:MM DD.MM.YYYY')}</Date>
      </div>
      {/* <Line />
      <div tw="flex mt-2">
        <Block>
          <Label>
            Фотографии
          </Label>
          <div tw="flex">
            <Square />
            <Square />
            <Square />
            <AddButton><IconPlus tw="text-white h-2 w-2" /></AddButton>
          </div>
        </Block>
        <Block>
          <Label>
            Файлы
          </Label>
          <div tw="flex">
            <Square />
            <Square />
            <Square />
            <AddButton><IconPlus tw="text-white h-2 w-2" /></AddButton>
          </div>
        </Block>
        <Block>
          <Label>
            Комментарии
          </Label>
          <div tw="flex">
            <OpenComment>
              Открыть комментарии (20)
            </OpenComment>
          </div>
        </Block>
        <Block>
          <Label>
            Результаты измерений
          </Label>
          <div tw="flex">
            <Square />
            <Square />
            <ResultEditing>Открыть</ResultEditing>
          </div>
        </Block>
        <Block>
          <Label>
            Список элементов BIM-модели
          </Label>
          <div tw="flex">
            <Text>Всего элементов: 112</Text>
          </div>
        </Block>
        <Block>
          <Label>
            Быстрые действия
          </Label>
          <div tw="flex">
            <SubmitCommnet>Дать замечание</SubmitCommnet>
          </div>
        </Block>
      </div> */}
    </SubTaskInner>
  );
});

const Status = styled.div<{ color: string }>`
  min-width: 12px;
  min-height: 12px;
  margin-right: 8px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
`;
const LinkTask = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
`;
const ParentTaskTitle = styled.p`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
`;
const Author = styled.p`
  margin-top: 2px;
  font-size: 14px;
  color: #b9b9b9;
`;
const SubTaskInner = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  padding: 12px 20px;
  background-color: #333337;
  border-radius: 4px;
`;
const Date = styled.div`
  min-width: 150px;
  color: #b9b9b9;
  font-size: 14px;
`;
const Line = styled.div`
  width: calc(100% + 40px);
  height: 1px;
  margin: 0 -20px;
  background-color: rgba(196, 196, 196, 0.15);
`;
const Label = styled.p`
  margin-bottom: 6px;
  font-size: 12px;
  color: #ce242a;
`;
const Square = styled.div`
  width: 22px;
  height: 22px;
  margin-right: 4px;
  background-color: #c4c4c4;
  border-radius: 4px;
`;
const Block = styled.div`
  margin-right: 12px;
`;
const OpenComment = styled.button`
  margin-top: 2px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  background-color: transparent;
  outline: none;
  border: none;
`;
const ResultEditing = styled.button`
  width: 76px;
  text-align: center;
  font-size: 12px;
  color: #ffffff;
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 4px;
  outline: none;
`;
const SubmitCommnet = styled.button`
  padding: 4px 13px;
  font-size: 12px;
  color: #ffffff;
  background-color: #ce242a;
  border-radius: 4px;
  outline: none;
  border: none;
`;
const AddButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border: 1px solid #ffffff;
  border-radius: 4px;
`;
const Text = styled.p`
  margin-top: 2px;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
`;

export default Task;
