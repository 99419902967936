export type CommentEntityType =
  | 'CLASSIFIER_DATA_REQUEST_ENTITY_TAB'
  | 'CLASSIFIER_DATA_REQUEST_ENTITY_REQUEST'
  | 'CLASSIFIER_DATA_REQUEST_ENTITY_RESPONSE'
  | 'DICTIONARY_DATA_REQUEST_ENTITY_REQUEST'
  | 'DICTIONARY_DATA_REQUEST_ENTITY_BUILDING_OBJECT'
  | 'DOCUMENT_ATTRIBUTE'
  | 'DOCUMENT_ATTRIBUTE_DATA'
  | 'MARKUP_TASK_ENTITY'
  | 'BUILDING_MANAGEMENT_TASK'
  | 'BUILDING_MANAGEMENT_TASK_EXPERT';

export interface IPostComment {
  projectId: string;
  authorId: string;
  commentEntityId: string;
  commentEntityName: string;
  commentEntityType: CommentEntityType;
  fileIds: string[];
  mentionIds: string[];
  text: string;
}

export interface IComment extends IPostComment {
  id: string;
  files: any[];
  createdAt: number;
  modifiedAt: number;
  isDeleted: boolean;
}

type FilemetaType =
  | 'DIRECTORY'
  | 'DOCUMENT'
  | 'CLASSIFIER_DOCUMENT'
  | 'MODEL'
  | 'MODEL_DATA'
  | 'MODEL_DATA_SIMPLIFIED'
  | 'IMAGE'
  | 'COMMENT_DOCUMENT'
  | 'BUILDING_MANAGEMENT_DOCUMENT';

export interface IFilemeta {
  id: string;
  parentId?: string;
  projectId: string;
  versionParentId?: string;
  version: number;
  baseName: string;
  size: number;
  ext?: string;
  type: FilemetaType;
  createdAt: number;
  modifiedAt: number;
  isDeleted: boolean;
  previewId?: string;
  preview?: string;
  author: string;
}

interface ICommentResponse {
  id: string;
  projectId: string;
  authorId: string;
  commentEntityId: string;
  commentEntityName: string;
  commentEntityType: CommentEntityType;
  fileIds: string[];
  files: IFilemeta[];
  mentionIds: string[];
  createdAt: string;
  modifiedAt: string;
  text: string;
  isDeleted: boolean;
}

interface IUpdateCommentBody {
  id: string;
  text: string;
}

interface ICuttedComment {
  projectId: string;
  commentEntityId: string;
}

export declare namespace ICommentService {
  type ISaveComment = ICommentResponse;
  type IUpdateComment = ICommentResponse;
  type IGetComment = ICommentResponse[];
  type IDeleteComment = ICommentResponse;

  declare namespace Body {
    type ISaveComment = IPostComment;
    type IUpdateComment = IUpdateCommentBody;
    type IGetComment = ICuttedComment;
  }
}
