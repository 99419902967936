import './antd-motion.css';
import { createGlobalStyle } from 'styled-components';
import tw from 'twin.macro';
// import './fonts.css';

export const DefaultGlobalStyle = createGlobalStyle`
  #root {
    position: relative;
    z-index: 1;
    min-height: 100%;
    min-width: 100%;
  }
  #react-portal {
    position: relative;
    z-index: 10;
  }

  /* Set core body defaults */
  body {
    ${tw`font-sans`}
    font-size: 16px;
    min-height: 100vh;
    text-rendering: geometricPrecision;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @media (max-width: 1024px) {
    body, html {
      font-size: 14px;
    }
  }

  /* Remove all animations and transitions for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    *, *::before, *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    position: relative;
    top: -1px;
  }

  .ant-select-dropdown {
    pointer-events: auto;
  }

  .mapboxgl-popup-close-button {
   color: #E1E1E1;
   font-size: 30px;
   right: 4px !important; 
   top: 6px !important;
  }

  .mapboxgl-popup-content {
    border-radius: 8px !important;
    border: 2px solid rgba(196, 196, 196, 0.5);
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }
`;
