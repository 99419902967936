import { lazyLoad } from '@asu/utils';

export const ProjectStartProcessConfirmPage = lazyLoad(
  () => import('./ProjectStartProcessConfirmPage'),
  module => module.default,
);
export const ProjectStartProcessConfirmPageGGE = lazyLoad(
  () => import('./ProjectStartProcessConfirmPageGGE'),
  module => module.default,
);
