import React from 'react';
import tw, { styled } from 'twin.macro';
import { useParams } from 'react-router-dom';

import { BackButton, ListView, ToolbarTaskList } from './local-components';

enum CamundaNavigationView {
  diagram = 'diagram',
  list = 'list',
}

const CamundaNavigation = React.memo(() => {
  const params = useParams();
  const [view, setView] = React.useState<CamundaNavigationView>(
    CamundaNavigationView[localStorage.getItem('camundaNavigationView')] || CamundaNavigationView.list,
  );
  const [expanded, setExpanded] = React.useState<boolean>(localStorage.getItem('camundaNavigationExpanded') === 'true');

  // const onCompleteTask = async () => {
  //   try {
  //     await CamundaService.completeTask(params.taskId).fetch();
  //     navigate(`/project/${params.projectId}/tasks`);
  //   } catch (e) {
  //     notification.open({
  //       message: 'Доступ ограничен',
  //       description: 'У вас не хватает прав для изменений в проекте.',
  //     });
  //   }
  // };

  return (
    <section tw="w-full overflow-hidden">
      <Toolbar>
        <BackButton tw="border-r border-grey-100" />

        {expanded && (
          <div tw="flex items-center h-full text-sm border-r border-grey-100 space-x-4 px-4">
            <p tw="font-medium text-grey-900">Вариант отображения:</p>
            {/* <ViewButton
              $isActive={view === CamundaNavigationView.diagram}
              onClick={() => {
                localStorage.setItem('camundaNavigationView', CamundaNavigationView.diagram);
                setView(CamundaNavigationView.diagram);
              }}
            >
              Диаграмма
            </ViewButton> */}
            <ViewButton
              $isActive={view === CamundaNavigationView.list}
              onClick={() => {
                localStorage.setItem('camundaNavigationView', CamundaNavigationView.list);
                setView(CamundaNavigationView.list);
              }}
            >
              Список
            </ViewButton>
          </div>
        )}

        {!expanded && (
          <div tw="flex-1 flex items-center h-full border-r border-grey-100">
            <p tw="font-medium px-4">Задачи:</p>
            <ToolbarTaskList params={params} />
          </div>
        )}

        {/* {params.taskDefKey !== 'task' && (
          <Button theme={Theme.Primary} tw="w-[250px]" type="button" onClick={onCompleteTask}>
            Завершить задачу
          </Button>
        )} */}
      </Toolbar>
      <div tw="relative">
        {expanded && view === CamundaNavigationView.list && <ListView params={params} />}
        <div tw="absolute inset-x-0 -bottom-3 flex justify-center">
          <ExpandButton
            onClick={() =>
              setExpanded(prevState => {
                const newState: any = !prevState;
                localStorage.setItem('camundaNavigationExpanded', newState);
                return newState;
              })
            }
            type="button"
          >
            <figure tw="w-24 h-1 rounded-b bg-primary" />
          </ExpandButton>
        </div>
      </div>
    </section>
  );
});

const Toolbar = tw.header`flex justify-between h-10 width[calc(100vw - 270px)] border-b border-grey-100`;
const ExpandButton = tw.button`px-4 pb-2 focus:outline-none`;

interface ViewButtonProps {
  readonly $isActive: boolean;
}
const ViewButton = styled.button<ViewButtonProps>`
  ${tw`px-4 py-1 text-white rounded bg-grey-300 focus:outline-none`}

  ${({ $isActive }) => $isActive && tw`bg-primary`}
`;

export default CamundaNavigation;
