import tw from 'twin.macro';
import { Route, Routes, useLocation } from 'react-router-dom';

import { TasktrackerRouter, GlobalWorkGroupsPage } from '@asu/tasktracker';

import { Header, SideBar } from '../components';
import { ProjectStoreProvider, WithProjectStoreWrapper } from '../store';
import { dynamicProcessRouter } from './dynamicProcessRouter';

import {
  ArchivePage,
  AddProjectStage1Page,
  AddProjectStage2Page,
  CreateProjectStage1Page,
  CreateProjectStage2Page,
  CreateProjectStage2PageGGE,
  ProjectStartProcessConfirmPageGGE,
  FilesystemPage,
  GanttPage,
  ProjectDashboardPage,
  ProjectInitialPermitsDoc,
  ProjectsPage,
  ProjectStartProcessConfirmPage,
  ProjectStartProcessPage,
  ProjectTaskListPage,
  ReferencesPage,
  TaskPage,
} from '../pages';

const RouterWrapper = () => {
  const location = useLocation();
  return (
    <ProjectStoreProvider>
      <Container>
        {(process.env.NX_SYSTEM_TYPE === 'RZD' || (location.pathname !== '/' && location.pathname !== '/archive')) && <SideBar />}
        <WithProjectStoreWrapper>
          <main id="main-content" tw="width[calc(100% - 270px)] min-h-screen flex flex-col flex-1">
            <Header />
            <Routes>
              <Route path="/" element={<ProjectsPage />} />
              <Route path="/references" element={<ReferencesPage />} />
              <Route path="/projects" element={<ProjectsPage />} />
              <Route path="/archive" element={<ArchivePage />} />
              <Route path="/projects/add/s1" element={<CreateProjectStage1Page />} />
              <Route path="/projects/add/s2" element={<CreateProjectStage2Page />} />
              <Route path="/projects/add/s2-gge" element={<CreateProjectStage2PageGGE />} />
              <Route path="/projects/add-with-template/s1" element={<AddProjectStage1Page />} />
              <Route path="/projects/add-with-template/s2/:templateId/:projectId" element={<AddProjectStage2Page />} />
              <Route path="/project/:projectId/dashboard" element={<ProjectDashboardPage />} />
              <Route path="/project/:projectId/filesystem" element={<FilesystemPage />} />
              <Route path="/project/:projectId/tasks" element={<ProjectTaskListPage />} />
              <Route path="/project/:projectId/gantt" element={<GanttPage />} />
              <Route path="/project/:projectId/:processId/start" element={<ProjectStartProcessPage />} />
              <Route path="/project/:projectId/:processId/start/:processKey/confirm" element={<ProjectStartProcessConfirmPage />} />
              <Route path="/project/:projectId/role" element={<ProjectStartProcessConfirmPage />} />
              <Route path="/project/:projectId/role-gge" element={<ProjectStartProcessConfirmPageGGE />} />
              <Route path="/task" element={<TaskPage />} />
              <Route path="/tasktracker/global-workgroups" element={<GlobalWorkGroupsPage />} />
              <Route path="/tasktracker/*" element={<TasktrackerRouter />} />
              <Route path="/project/:projectId/tasktracker/*" element={<TasktrackerRouter />} />
              {dynamicProcessRouter()}
              <Route path="/project/:id/:processContainerId/initial-permits-doc" element={<ProjectInitialPermitsDoc />} />
            </Routes>
          </main>
        </WithProjectStoreWrapper>
      </Container>
    </ProjectStoreProvider>
  );
};

const Container = tw.div`flex`;

export default RouterWrapper;
