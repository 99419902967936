import React from 'react';
import tw from 'twin.macro';
import { convertBlobToBase64 } from '@asu/utils';
import { FilesystemService } from '@asu/services';
import { LoadingIndicator } from '@asu/ui';
import { Modal } from '@asu/ui';

interface AddModalPhotoPreview {
  id: string;
}

interface ModalPhotoPreviewProps extends AddModalPhotoPreview {
  isOpen: boolean;
  setIsOpen: any;
  openModal: () => void;
  closeModal: () => void;
}

const PhotoViewer = React.memo<any>(({ id }) => {
  const [src, setSrc] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      if (id) {
        try {
          const config: any = { responseType: 'blob' };
          const response = await FilesystemService.getFile(id, 'g1', config).fetch();
          const responseData: any = response.data;
          const base64Src = await convertBlobToBase64(responseData);
          setSrc(base64Src as any);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          setIsError(true);
        }
      }
    })();
  }, [id]);

  if (isLoading) {
    return (
      <div tw="flex items-center justify-center min-height[60vh]">
        <LoadingIndicator />
      </div>
    );
  }

  if (isError) return <div>Не удалось загрузить изображение</div>;

  return (
    <div tw="flex">
      <img tw="h-auto w-full flex-1 object-contain" src={src} alt="Preview" />
    </div>
  );
});

const ModalPhotoPreview = React.memo((props: ModalPhotoPreviewProps) => {
  const { id } = props;
  return (
    <Modal.Root manual {...props}>
      <AddModalPhotoPreview id={id} />
    </Modal.Root>
  );
});

const AddModalPhotoPreview = React.memo(({ id }: AddModalPhotoPreview) => {
  return (
    <Modal.Content tw="w-[35vw] min-w-[350px]">
      <Modal.Title>Просмотр изображения</Modal.Title>
      <PhotoViewer id={id} />
    </Modal.Content>
  );
});

export default ModalPhotoPreview;
