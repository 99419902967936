/**
 * Asynchronously loads the component for TasktrackerRouter
 */

import { lazyLoad } from '@asu/utils';

export const TasktrackerRouter = lazyLoad(
  () => import('./TasktrackerRouter'),
  module => module.default,
);
