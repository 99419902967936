import React from 'react';
import tw from 'twin.macro';
import { Header, Explorer, SideBar, Toolbar, AlertModal, DragAndDrop } from './components';
import { FileSystemStoreProvider } from './store';

type FileSystemProps = {
  onlyModels?: boolean;
  className?: string;
  projectId: string;
  style?: React.CSSProperties;
};

const FileSystem = React.memo<FileSystemProps>(({ onlyModels, className, style, projectId }) => {
  const ref = React.useRef(null);

  return (
    <FileSystemStoreProvider onlyModels={onlyModels} projectId={projectId}>
      <Container className={className} style={style} ref={ref}>
        <Toolbar tw="h-12" />
        <div tw="relative flex height[calc(100% - 3rem)]">
          {!onlyModels && <SideBar />}
          <div tw="relative flex-1">
            <Header />
            <Explorer />
            <DragAndDrop />
          </div>
          <AlertModal />
        </div>
      </Container>
    </FileSystemStoreProvider>
  );
});

const Container = tw.div`relative z-0 border bg-white border-grey-100 overflow-hidden`;

export default FileSystem;
