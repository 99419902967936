import { qParam } from './Service.d';
import { Service } from './Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import type { IDocumentAttributeDataService } from './DocumentAttributeDataService.d'

const BASE_URL = '/attribute';

export class DocumentAttributeDataService implements Service {
  public static getDocumentAttributeData(attributeId: qParam, projectId: qParam, taskDefId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IDocumentAttributeDataService.IGetDocumentAttributeData>(`/data/${attributeId}`, {
      ...config,
      headers: {
        attributeId: attributeId,
        projectId: projectId,
        taskDefId: taskDefId,
      },
    });
  }

  public static getListDocumentAttributeData(
    attributeId: qParam,
    projectId: qParam,
    taskDefId: qParam,
    data: IDocumentAttributeDataService.Body.IGetListDocumentAttributeData,
    config?: AxiosRequestConfig,
  ) {
    return new ApiCaller(BASE_URL).post<IDocumentAttributeDataService.IGetListDocumentAttributeData>(`/attribute/data/${attributeId}/list`, data, {
      ...config,
      headers: {
        attributeId: attributeId,
        projectId: projectId,
        taskDefId: taskDefId,
      },
    });
  }

  public static postApproveAttributeData(attributeId: qParam, projectId: qParam, taskDefId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<void>(
      `/attribute/data/${attributeId}/approve`,
      {},
      {
        ...config,
        headers: {
          attributeId: attributeId,
          projectId: projectId,
          taskDefId: taskDefId,
        },
      },
    );
  }
}
