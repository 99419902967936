import { qParam } from './Service.d';
import { Service } from './Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import type { ICommentService } from './CommentService.d';
export * from './CommentService.d';

const BASE_URL = '/comment';

//TODO: types in comment body is wrong
export class CommentService implements Service {
  public static saveComment = (body: any, config?: AxiosRequestConfig) => {
    return new ApiCaller(BASE_URL).post<ICommentService.ISaveComment>(`/comment`, body, config);
  };

  public static updateComment = (body: ICommentService.Body.IUpdateComment, config?: AxiosRequestConfig) => {
    return new ApiCaller(BASE_URL).patch<ICommentService.IUpdateComment>(`/comment`, body, config);
  };

  public static getComments = (comment: ICommentService.Body.IGetComment, config?: AxiosRequestConfig) => {
    return new ApiCaller(BASE_URL).get<ICommentService.IGetComment>(
      `/comment/source/${comment.projectId}/entity/${comment.commentEntityId}`,
      config,
    );
  };

  public static deleteComment = (id: qParam, config?: AxiosRequestConfig) => {
    return new ApiCaller(BASE_URL).delete<ICommentService.IDeleteComment>(`/comment/${id}`, config);
  };
}
