  export declare namespace IBMRService {
  interface IBuildingMeasureResult {
    id: string;
    projectId: string;
    taskDefId: string;
    measureFileId: string;
    resultFileId?: string;
    modelId: string;
    modifiedAt: string;
  }

  interface IDtoCreateMeasureResult extends Omit<IBuildingMeasureResult, 'id' | 'resultFileId'> {
    positionX: number;
    positionY: number;
    positionZ: number;
    rotationX: number;
    rotationY: number;
    rotationZ: number;
  }
}
