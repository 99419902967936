import React from 'react';
import useSWR, { useSWRConfig } from 'swr';

import { CommentExpand } from './local-components';
import { CommentService, AuthService, FilesystemService, IComment, ProjectService, IProjectService, fetcher } from '@asu/services';
import { useAuthState } from '@asu/auth';
import { onAsyncAction } from '../../utils';

type ICommentsProps = Pick<IComment, 'projectId' | 'commentEntityName' | 'commentEntityType' | 'commentEntityId'> & {
  params: {
    refreshIntervalMs: number;
  };
};

const prepareComments = (comments: IComment[], user, users) => {
  return comments
    .map(comment => ({
      ...comment,
      author: users.find(user => user.id === comment.authorId) ?? null,
      files: comment.files
        .filter(file => file?.isDeleted === false)
        .map(file => ({
          id: file.id,
          name: `${file.baseName}.${file.ext}`,
        })),
      permissions: {
        edit: comment.authorId === user.id,
        delete: comment.authorId === user.id,
        read: true,
      },
    }))
    .filter(comment => comment?.isDeleted === false)
    .sort((a, b) => b.createdAt - a.createdAt);
};

const Comments: React.FC<ICommentsProps> = ({ projectId, commentEntityId, commentEntityName, commentEntityType, params }) => {
  const { mutate } = useSWRConfig();
  const authStore = useAuthState();

  const { data: project } = useSWR(`project_${projectId}`, () =>
    fetcher<IProjectService.IGetProject>(ProjectService.getProject(projectId).fetch()),
  );

  const { data: users } = useSWR(project && authStore.currentUser ? `project_users_${projectId}` : null, async () => {
    const response = await ProjectService.getAssignedUsers(projectId).fetch();
    const projectUserIds = response.data.assignedUsers.map(user => user.userId);

    if (!projectUserIds) return;

    const getUsers = projectUserIds.map(userId => {
      const responsePending = AuthService.getUser(userId).fetch();
      return onAsyncAction(responsePending);
    });

    const users = await Promise.all(getUsers);

    return users;
  });

  const { data: comments } = useSWR(
    users?.length > 0 ? `comments ${commentEntityId}` : null,
    async () => {
      const comments = await onAsyncAction(CommentService.getComments({ projectId, commentEntityId }).fetch());

      return prepareComments(comments, authStore.currentUser, users);
    },
    {
      refreshInterval: params.refreshIntervalMs,
    },
  );

  const updateHandler = async ({ id, text }: { id: string; text: string }) => {
    const updatingComments = comments.map(comment => {
      if (comment.id === id) comment.text = text;

      return comment;
    });
    mutate(`comments ${commentEntityId}`, updatingComments);

    await onAsyncAction(CommentService.updateComment({ id, text }).fetch());

    mutate(`comments ${commentEntityId}`);
  };

  const deleteHandler = async (id: string) => {
    await onAsyncAction(CommentService.deleteComment(id).fetch());

    mutate(`comments ${commentEntityId}`);
  };

  const uploadFilesHandler = async (fileList: File[]): Promise<string[]> => {
    const rootFolder = await onAsyncAction(FilesystemService.getCommentSectionFileMeta(projectId, 'g1').fetch());

    if (rootFolder?.id) {
      const rootFolderId = rootFolder.id;
      const files = await Promise.all(
        fileList.map(file => {
          const body: any = new FormData();

          body.append('parentId', rootFolderId);
          body.append('projectId', projectId);
          body.append('document', file);

          return onAsyncAction(FilesystemService.uploadCommentDocument(body, 'g1').fetch());
        }),
      );
      return files.map(file => file?.fileMeta.id).filter(file => file);
    }

    return [];
  };

  const saveCommentHandler = async ({ text, fileList }: { text: string; fileList: File[] }) => {
    let fileIds = [];
    if (fileList.length > 0) fileIds = await uploadFilesHandler(fileList);

    await onAsyncAction(
      CommentService.saveComment({
        sourceType: 'PROJECT_ID',
        sourceId: projectId,
        authorId: authStore.currentUser.id,
        commentEntityId,
        commentEntityName,
        commentEntityType,
        fileIds,
        mentionIds: [],
        text,
      }).fetch(),
    );

    mutate(`comments ${commentEntityId}`);
  };

  const downloadFile = async (id: string) => {
    const file = await onAsyncAction(FilesystemService.downloadCommentDocument(id, 'g1', { responseType: 'blob' }).fetch());

    return file;
  };

  const deleteFile = async (id: string) => {
    await onAsyncAction(FilesystemService.deleteCommentDocument(id, 'g1').fetch());

    mutate(`comments ${commentEntityId}`);
  };

  return (
    <CommentExpand
      user={authStore.currentUser}
      data={comments}
      deleteFile={deleteFile}
      downloadFile={downloadFile}
      onDelete={deleteHandler}
      update={updateHandler}
      save={saveCommentHandler}
    />
  );
};

export default Comments;
