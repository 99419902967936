import React from 'react';
import tw, { styled } from 'twin.macro';
import { Link } from 'react-router-dom';
// import { MDXProvider } from '@mdx-js/react';
import { IconClose, IconSnow } from '../../../assets/icons';

type NotificationItemProps = {
  element: {
    id: string;
    projectId: string;
    projectTitle: string;
    name: string;
    params: {
      date: string;
      parentFolderName: string;
      notificationTriggerUserName: string;
      id: string;
      baseName: string;
    };
    isNew: boolean;
    timestamp: number;
    isDeleted: boolean;
  };
  onDismiss: (id: string) => void;
};

const NotificationItem = React.memo<NotificationItemProps>(({ element, onDismiss }) => {
  const [activeNotification, setActiveNotification] = React.useState(false);

  const NotificationBody = React.lazy(() =>
    import(`../notifications/${element.name}.mdx`).catch(() => import('../notifications/fallback.mdx')),
  );

  return (
    <BlockWrapper>
      <Block $isActive={activeNotification} onClick={() => setActiveNotification(!activeNotification)}>
        <React.Suspense fallback={<div style={{ height: '200px' }} />}>
          <NotificationField>
            <NotificationBody
              {...element}
              components={{
                // Map `h1` (`# heading`) to use `h2`s.
                h1: props => (
                  <TitleWrapper>
                    <IconSnow tw="text-primary-dark w-5 h-5 mr-2" />
                    <Title {...props} />
                  </TitleWrapper>
                ),
                p: props => <p tw="text-sm" {...props} />,
                // wrapper: ({ components, ...rest }) => <main {...rest} />,
              }}
            />
          </NotificationField>
        </React.Suspense>
      </Block>
      {/* {activeNotification && notificationItem?.actionUrl && (
        <Basement>
          <Title>Быстрое действие</Title>
          <div tw="mt-2">
            <BottomWrapper>
              <ButtonEnter to={notificationItem?.actionUrl(element)}>{notificationItem?.buttonTitle}</ButtonEnter>
            </BottomWrapper>
          </div>
        </Basement>
      )} */}
      <ItemCloseButton type="button" tw="absolute right-2 top-2 p-2 hocus:outline-none" onClick={() => onDismiss(element.id)}>
        <IconClose tw="text-black opacity-50 h-2 w-2" />
      </ItemCloseButton>
    </BlockWrapper>
  );
});

interface BlockProps {
  readonly $isActive: boolean;
}
const Block = styled.div<BlockProps>`
  ${tw`border-2 border-gray-200 relative bg-gray-100 cursor-pointer`}
  ${({ $isActive }) => ($isActive ? tw`rounded-t-lg` : tw`rounded-lg`)}
`;

const ItemCloseButton: any = tw.button`transition-opacity opacity-0`;
const Basement = tw.div`w-full border-2 rounded-b-lg border-grey-300 bg-gray-300 px-4 py-3`;
const BlockWrapper = styled.div`
  ${tw`relative mb-3 select-none w-full`}

  &:hover {
    ${ItemCloseButton} {
      ${tw`opacity-100`}
    }
  }
`;

const Title = tw.h1`text-base font-bold`;
const TitleWrapper = tw.div`flex items-center leading-tight mb-1`;
const NotificationField = tw.div`p-4 rounded`;

const BottomWrapper = tw.div`flex flex-row`;
const ButtonEnter = styled(Link)`
  ${tw`height[32px] width[126px] flex items-center justify-center bg-gradient-to-r from-primary-dark to-primary-light rounded-md text-white w-full mr-1`}
`;

export default NotificationItem;
