import React from 'react';
import tw from 'twin.macro';
import { IconFullscreen } from '@asu/ui';
import { uriPrefix } from '@asu/services';
import { Modal, useManualModalState } from '@asu/ui';
import { useFileSystemState } from '../../../store';

interface AddModalPreviewProps {
  modelId?: string;
  compare?: boolean;
}

interface ModalPreviewProps extends AddModalPreviewProps {
  isOpen: boolean;
  setIsOpen: any;
  openModal: () => void;
  closeModal: () => void;
}

const ModalPreview = React.memo((props: ModalPreviewProps) => {
  const { modelId, compare } = props;
  return (
    <Modal.Root manual {...props}>
      <AddModalPreview modelId={modelId} compare={compare} />
    </Modal.Root>
  );
});

const AddModalPreview = React.memo(({ modelId, compare }: AddModalPreviewProps) => {
  const store = useFileSystemState();
  const url = React.useMemo(() => {
    if (compare) {
      const modelsId = modelId.split(',');
      return `${uriPrefix}/viewer/#/${store.projectId}/compare?current=${modelsId[0]}&old=${modelsId[1]}`;
    }
    return `${uriPrefix}/viewer/#/${store.projectId}?models=${modelId}`;
  }, [modelId, store, compare]);

  const { closeModal } = useManualModalState();

  return (
    <Modal.Content tw="w-full h-[80%]">
      <Modal.Title>Просмотр модели</Modal.Title>
      {[
        {
          onClick: () => {
            window.open(url, '_blank').focus();
            closeModal();
          },
          element: <IconFullscreen tw="h-5 w-5 text-white" />,
        },
      ]?.map(({ onClick, element }, i) => (
        <IconContainer key={i} onClick={onClick}>
          {element}
        </IconContainer>
      ))}
      <iframe title="Model preview" width="100%" height="100%" src={url} />
    </Modal.Content>
  );
});

const IconContainer = tw.figure`z-10 h-5 absolute right-[35px] top-[12px] items-center text-black transition-all duration-150 cursor-pointer px-4`;

export default ModalPreview;
