import { KeycloakAuth } from '@asu/auth';
import { AppRouter } from './router';
import AntdConfigProvider from 'antd/lib/config-provider';
import AntdLocale from 'antd/es/locale/ru_RU';

function App() {
  return (
    <KeycloakAuth>
      <AntdConfigProvider locale={AntdLocale}>
        <AppRouter />
      </AntdConfigProvider>
    </KeycloakAuth>
  );
}

export default App;
