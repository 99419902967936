import tw, { styled } from 'twin.macro';
import { observer } from 'mobx-react-lite';
import { IconModel } from '../../../assets';
import { useFileSystemState } from '../../../store';
import React from 'react';
import ModalPreview from '../../Files/local-components/ModalPreview';

const Model = observer<any>(() => {
  const store = useFileSystemState();
  const [isPreviewOpen, setIsPreviewOpen] = React.useState<boolean>(false);
  const [selectedModels, setSelectedModels] = React.useState();

  const onClick = () => {
    setSelectedModels(store.checkedFiles.map(e => e.id).join(','));
    setIsPreviewOpen(true);
  };

  const is3DModel =
    store.checkedFiles.length > 0 &&
    !store.checkedFiles.find(e => e.type === 'DOCUMENT') &&
    store.checkedFiles.find(e => e.type === 'MODEL');

  return (
    <>
      {is3DModel && (
        <Button type="button" onClick={onClick}>
          <IconModel tw="w-4 h-4 mr-2" />
          <span tw="font-medium text-sm leading-none">Открыть 3D модель</span>
        </Button>
      )}
      <ModalPreview
        modelId={selectedModels}
        isOpen={isPreviewOpen}
        setIsOpen={setIsPreviewOpen}
        openModal={() => setIsPreviewOpen(true)}
        closeModal={() => setIsPreviewOpen(false)}
      />
    </>
  );
});

const Button = styled.button`
  ${tw`flex items-center rounded text-grey-900 cursor-pointer transition-colors duration-150 p-3`}
  ${tw`hocus:(outline-none)`}
`;

export default Model;
