import React from 'react';
import tw from 'twin.macro';
import { Input } from '@asu/ui';
import { Modal } from '../../../components';
import { FormProvider, useForm } from 'react-hook-form';
import { useFileSystemState } from '../../../store';

type ReNameModalProps = {
  name: any;
  id: any;
  type: any;
  isOpen: boolean;
  onClose: () => void;
};

const typeManager = {
  FILE: 'MODEL' && 'DOCUMENT',
  FOLDER: 'DIRECTORY',
};

const ReNameModal = React.memo<ReNameModalProps>(props => {
  const store = useFileSystemState();
  const methods = useForm();

  React.useEffect(() => {
    methods.reset({ name: props.name });
  }, [props, methods.reset]);

  const onSubmit = data => {
    typeManager['FILE'] === props.type ? store.renameFile(data, props.id) : store.renameDirectory(data, props.id);
    props.onClose();
  };

  return (
    <>
      {/* <Modal title="Переименовать" bodyStyle={{ padding: '0.5rem 1rem' }} {...props}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Input {...methods.register('name', { required: true })} placeholder="Название" />
            {methods.formState.errors.name && <span tw="text-sm text-primary-dark mt-2">Ошибка: Поле должно быть заполненно</span>}
            <Button type="submit">Подтвердить</Button>
          </form>
        </FormProvider>
      </Modal> */}
    </>
  );
});

const Button = tw.button`height[64px] w-full bg-gradient-to-r from-primary-dark to-primary-light border border-white border-opacity-10 rounded-lg text-lg font-bold text-white focus:outline-primary mt-4 mb-2`;

export default ReNameModal;
