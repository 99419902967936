import { Checkbox } from '@asu/ui';
import { DocumentsTypes } from '../../../types';
import React from 'react';
import tw, { css, styled } from 'twin.macro';
import { useFileSystemState } from '../../../store';
import { FilesystemService, ViewerService } from '@asu/services';
import { convertBlobToBase64 } from '@asu/utils';
import { useMarkUp } from '../hooks';
import { IconEngineeringIndocator, IconMainModel, IconNetworkSchedule } from '../../../assets/icons';

import ToolTip from 'antd/lib/tooltip';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';

type WrapperProps = {
  children: any;
};

const Wrapper = React.memo<WrapperProps>(({ children, ...rest }) => {
  return <FilesGrid {...rest}>{children}</FilesGrid>;
});

const FilesGrid = styled.section`
  ${tw`grid gap-4 mb-16`}
  grid-template-columns: repeat(auto-fill, 250px);
`;

const COLORS = {
  unknown: ['#C6C6C6', '#898989'],
  docx: ['#175ABC', '#0F3F91'],
  xlsx: ['#4D976E', '#207144'],
  pdf: ['#A80403', '#550401'],
  pptx: ['#EC6E1D', '#DA4C13'],
  rvt: ['#6F92DC', '#143C8C'],
  '3ds': ['#007E7D', '#026261'],
};

const TRANSLATE = {
  docx: 'W',
  doc: 'W',
  pptx: 'P',
  xlsx: 'E',
};

const IMAGE_VARIANTS = { png: 'png', jpg: 'jpg', jpeg: 'jpeg', bmp: 'bmp', svg: 'svg', webp: 'webp' };

type FileProps = {
  data: any;
  check: any;
  onClickModel: (data: any) => void;
  onContextMenu: (e: any, data: any) => void;
  onClickFile: (e: any, imgSrc: any, data: any) => void;
  isEngineering: boolean;
  isModel: boolean;
  isUsingInGantt: boolean;
};

const File = observer<FileProps>(({ onClickModel, onContextMenu, data, isModel, isUsingInGantt, isEngineering, onClickFile }) => {
  const store = useFileSystemState();
  const [check, setCheck] = React.useState(false);
  const [imgSrc, setImgSrc] = React.useState<string | null>(null);
  const { isLoading, markupsCount } = useMarkUp(data.id);

  const onClickCheck = data => {
    setCheck(!check);
    store.setCheckedFile(data);
  };

  React.useEffect(() => {
    if (!store.checkedFiles.length) {
      setCheck(false);
    }

    store.checkedFiles.forEach(checked => {
      if (checked.id === data.id) {
        setCheck(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.checkedFiles]);

  React.useEffect(() => {
    (async () => {
      if (IMAGE_VARIANTS[data.ext]) {
        try {
          const config: any = { responseType: 'blob' };
          const response = await FilesystemService.getFile(data.id, 'g1', config).fetch();
          const responseData: any = response.data;
          const base64Src = await convertBlobToBase64(responseData);
          setImgSrc(base64Src as any);
        } catch (err) {
          console.error('downloadImageFile', err);
        }
      }
    })();
  }, [data, imgSrc]);

  return (
    <Container $isActiveCheck={check}>
      {data.type === DocumentsTypes.model ? (
        <FileBox $isActiveCheck={check}>
          <div tw="h-full" onContextMenu={e => onContextMenu(e, data)} onClick={() => onClickModel(data)}>
            <NameModel>
              {data.baseName}
              {process.env.NX_SYSTEM_TYPE === 'GGE' && (
                <>
                  <p tw="block text-grey-500">Версия {data.version}</p>
                  <p tw="block text-grey-500">{dayjs.unix(data.createdAt).format('DD.MM.YYYY HH:mm:ss')}</p>
                </>
              )}
            </NameModel>

            <IconIndicatorModelBlock>
              {isEngineering && (
                <ToolTip title="Инженерное изыскание">
                  <IconIndicatorWrapper $color="blue">
                    <IconEngineeringIndocator />
                  </IconIndicatorWrapper>
                </ToolTip>
              )}
              {isModel && (
                <ToolTip title="Основная модель">
                  <IconIndicatorWrapper $color="orange">
                    <IconMainModel />
                  </IconIndicatorWrapper>
                </ToolTip>
              )}
              {isUsingInGantt && (
                <ToolTip title="Участвует в сетевом графике">
                  <IconIndicatorWrapper $color="purple">
                    <IconNetworkSchedule />
                  </IconIndicatorWrapper>
                </ToolTip>
              )}
            </IconIndicatorModelBlock>

            {!isLoading ? (
              markupsCount > 0 && (
                <p tw="absolute bottom-3 left-12 text-xs color[#007e7d] hidden lg:inline-block">Доступных замечания: {markupsCount}</p>
              )
            ) : (
              <div tw="absolute bottom-3 left-12 h-3 w-2/4 bg-grey-300 bg-opacity-30 rounded animate-pulse" />
            )}
            <div tw="absolute inset-x-0 bottom-0">
              <ExtensionModel>{data.ext.toUpperCase()}</ExtensionModel>
            </div>
          </div>
          <IconWrapper>
            <Checkbox onClick={() => onClickCheck(data)} checked={check} />
          </IconWrapper>
        </FileBox>
      ) : (
        <FileBox $isActiveCheck={check}>
          <div tw="h-full" onContextMenu={e => onContextMenu(e, data)} onClick={e => onClickFile(e, imgSrc, data)}>
            {imgSrc && (
              <>
                <Image src={imgSrc} alt="" />
                <ImageContainer />
              </>
            )}
            <NameFile $isImage={imgSrc}>{data.baseName}</NameFile>
            <div tw="absolute inset-x-0 bottom-0">
              <ExtensionFile $color={COLORS[data.ext] || COLORS.unknown}>
                {data.ext.length > 0 ? TRANSLATE[data.ext] || data.ext.toUpperCase() : 'BIN'}
              </ExtensionFile>
            </div>
          </div>
          <IconWrapper>
            <Checkbox onClick={() => onClickCheck(data)} checked={check} />
          </IconWrapper>
        </FileBox>
      )}
    </Container>
  );
});

const IconIndicatorModelBlock = styled.div`
  ${tw`absolute flex lg:right-0 lg:bottom-0 lg:mr-4 lg:mb-4 sm:bottom-[10px] sm:right-8 z-30`}
`;

const IconIndicatorWrapper = styled.div`
  ${tw`flex items-center justify-center rounded-full w-4 h-4`}
  ${({ $color }: { $color: string }) => {
    switch ($color) {
      case 'purple':
        return tw`background-color[#A017E0]`;
      case 'orange':
        return tw`background-color[orange]`;
      default:
        return tw`background-color[#30d5c8]`;
    }
  }}

  &:not(:first-child) {
    ${tw`ml-2`}
  }
  svg {
    ${tw`width[10px] height[10px]`}
  }
`;

const IconWrapper = styled.div`
  ${tw`absolute sm:top-2 right-2 opacity-0 z-30`}
`;

const Container = styled.div<any>`
  ${tw`relative`}
  ${({ $isActiveCheck }) =>
    $isActiveCheck &&
    css`
      ${IconWrapper} {
        ${tw`block opacity-100`}
      }
    `}

  &:hover {
    ${IconWrapper} {
      ${tw`block opacity-100`}
    }
  }
`;

const FileBox = styled.div<any>`
  ${tw`lg:height[250px] sm:height[37px] color[#F1F1F1] cursor-pointer border rounded relative break-words h-full`}
  ${({ $isActiveCheck }) => $isActiveCheck && tw`border-red-600 border-b-0`}
`;

const GridView = {
  File,
  Wrapper,
};

const NameModel = styled.div`
  ${tw`flex flex-col sm:max-w-[195px] lg:max-w-[227px] sm:items-center sm:h-full lg:items-start relative z-0 pl-12 lg:p-4 text-black sm:truncate lg:whitespace-normal `}
`;
const NameFile = styled.div<any>`
  ${tw`pl-12 lg:p-4 absolute inset-0 sm:top-2 lg:top-0 z-10 font-medium text-sm sm:whitespace-nowrap lg:whitespace-normal sm:overflow-hidden sm:overflow-ellipsis`}
  ${({ $isImage }) => ($isImage ? tw`text-white` : tw`text-black`)}
`;
const Image = tw.img`w-full h-full absolute inset-0 object-cover object-bottom`;
const ImageContainer = styled.div`
  ${tw`inset-0 absolute`}
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.56) 0%, rgba(255,255,255,0.3) 100%);
`;

const ExtensionModel = styled.div`
  ${tw`w-10 h-10 flex items-center justify-center rounded-tr-md text-white`}
  ${tw`after:(content[''] h-1 width[calc(100% - 2.5rem)] absolute right-0 bottom-0)`}
  background: #007e7d;
  :after {
    background: linear-gradient(90deg, #007e7d, #026261 110%);
  }
`;

interface StyledExtensionParams {
  readonly $color: string[];
}

const ExtensionFile = styled.div<StyledExtensionParams>`
  background: ${({ $color }) => $color[0]};
  ${tw`w-10 h-10 flex items-center justify-center rounded-tr-md text-white`}
  ${tw`after:(content[''] h-1 width[calc(100% - 2.5rem)] absolute right-0 bottom-0)`}

  :after {
    ${({ $color }) => `background: linear-gradient(90deg, ${$color[0]}, ${$color[1]} 110%)`}
  }
`;

export default GridView;
