import React from 'react';
import tw, { styled } from 'twin.macro';
import type { CheckboxProps } from 'antd/lib/checkbox';
import { default as AntdCheckbox } from 'antd/lib/checkbox';
import 'antd/lib/checkbox/style/index.css';

const StyledAntdCheckbox = styled(AntdCheckbox)`
  ${tw`cursor-pointer text-grey-500`}

  .ant-checkbox {
    ${tw`text-grey-500`}
  }

  .ant-checkbox-inner {
    ${tw`border-red-500!`}
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    ${tw`bg-primary border-primary-dark`}
  }

  &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    ${tw`border-primary-dark`}
  }

  .ant-checkbox-checked::after {
    ${tw`border border-primary-light`}
  }
`;

const Checkbox: React.FC<CheckboxProps & React.RefAttributes<unknown>> = StyledAntdCheckbox;

export default Checkbox;



