import React from 'react';
import tw, { styled } from 'twin.macro';
import { IconClose, IconMessenger } from '../../assets/icons';
import { Overlay } from '../Overlay';

type MessengerCenterProps = {
  onClose: () => void;
};

const MessengerCenter: React.FC<MessengerCenterProps> = ({ onClose }) => {
  return (
    <Overlay>
      <Container>
        <header tw="relative height[81px] flex items-center border-b border-grey-300 border-opacity-30">
          <Notification type="button">
            <IconMessenger tw="h-7 w-7 text-gray-200" />
          </Notification>
          <h1 tw="text-lg font-bold text-grey-900">Сообщения</h1>
          <button tw="absolute inset-y-0 right-0 flex items-center p-4 focus:outline-none" type="button" onClick={onClose}>
            <IconClose tw="h-5 w-5 text-grey-300" />
          </button>
        </header>
        <main tw="flex-1 flex justify-center items-center">
          <p tw="text-lg text-grey-500">Новых сообщений нет</p>
        </main>
      </Container>
    </Overlay>
  );
};

const Container = styled.div`
  ${tw`fixed left[270px] width[325px] top-0 flex flex-col bottom-0 z-40`}

  background: radial-gradient(100% 4700% at 0% 0%, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.65) 100%);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(22px);
`;
const Notification = tw.button`padding-left[26px] mr-3 h-full flex items-center justify-center cursor-pointer hocus:outline-none`;

export default MessengerCenter;
