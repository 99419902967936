import { qParam } from './Service.d';
import { Service } from './Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import type { IRoleService } from './RoleService.d';
export * from './RoleService.d';

const BASE_URL = '/role';

export class RoleService implements Service {
  public static getGlobalTemplates(config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IRoleService.IGetGlobalTemplates>('/template', config);
  }
  public static getRole(roleId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IRoleService.IGetRole>(`/template/${roleId}`, config);
  }
  public static getAccessList(roleId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IRoleService.IGetAccessList>(`/accesslist/${roleId}`, config);
  }
}
