type ProcessTask = 'CLASSIFIER_DATA'
  | 'DICTIONARY_DATA'
  | 'DOCUMENT'
  | 'DICTIONARY_CALC_COST'
  | 'DICTIONARY_CALC_CALENDAR'
  | 'BUILDING_MANAGEMENT'
  | 'TASK_TRACKER_DEFAULT';

type TypeRead = 'NONE' | 'READ' | 'READ_WRITE';

interface IAccessItem {
  id: string;
  processTask: ProcessTask;
  read: TypeRead;
  chat: TypeRead;
  dataRequest: TypeRead;
  dataInput: TypeRead;
  dataComment: TypeRead;
  expert: TypeRead;
  approve: TypeRead;
  accept: TypeRead;
}

interface IRoleTemplateGRS {
  id?: string;
  name?: string;
  isDefault?: boolean;
  accessList: IAccessItem[];
}

export declare namespace IGlobalRolesService {
  type IGetGlobalRoleTemplates = IRoleTemplateGRS[];
  type IPatchAccessListGlobalRole = IAccessItem;
  type ICreateGlobalRoleTemplates = void;

  declare namespace Body {
    type IPatchAccessListGlobalRole = IAccessItem;
  }
}
