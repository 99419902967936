import { qParam } from './Service.d';
import { Service } from './Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import type { IProjectTemplateService } from './ProjectTemplateService.d';
export * from './ProjectTemplateService.d';

const BASE_URL = '/projectt';

export class ProjectTemplateService implements Service {
  public static getTemplates(config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IProjectTemplateService.IGetTemplates>('/template', config);
  }
  public static getTemplate(id: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IProjectTemplateService.IGetTemplate>(`/template/${id}`, config);
  }
  public static getTemplateRoles(id: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IProjectTemplateService.IGetTemplateRoles>(`/role/${id}`, config);
  }
}
