import React from 'react';
import { ProjectService } from '@asu/services';

export const useModelGantt = (projectId: string) => {
  const [ganttModels, setGanttModels] = React.useState<string[]>([]);

  React.useEffect(() => {
    (async () => {
      const response = await ProjectService.getModelsGantt(projectId).fetch();
      const data = response.data;
      if (data) setGanttModels(data.map(model => model.modelId));
    })();
  }, []);

  const addModelToGantt = (modelId: string) => {
    const newGanttModels = [...ganttModels, modelId];
    ProjectService.addModelToGantt(projectId, [modelId]).fetch();
    setGanttModels(newGanttModels);
  };

  const deleteModelFromGantt = (modelId: string) => {
    const newGanttModels = ganttModels.filter(_modelId => _modelId !== modelId);
    ProjectService.deleteModelFromGantt(projectId, [modelId]).fetch();
    setGanttModels(newGanttModels);
  };

  const patchModelIdFromGantt = (modelId: string, newModelId: string) => {
    const newGanttModels = [...ganttModels];
    newGanttModels[ganttModels.indexOf(modelId)] = newModelId;

    ProjectService.patchModelIdFromGantt(projectId, modelId, newModelId).fetch();
    setGanttModels(newGanttModels);
  };

  return {
    addModelToGantt,
    deleteModelFromGantt,
    patchModelIdFromGantt,
    ganttModels,
  };
};
