import React from 'react';
import { Outlet } from 'react-router-dom';
import { CamundaNavigation } from '.';

const WithCamundaNavigation = React.memo(({ children }) => {
  return (
    <>
      <CamundaNavigation />
      {children}
      <Outlet />
    </>
  );
});

export default WithCamundaNavigation;
