import tw from 'twin.macro';
import { observer } from 'mobx-react-lite';
import { LoadingIndicator } from '@asu/ui';
import { StoreState, useFileSystemState } from '../../store';

const AlertModal = observer(() => {
  const store = useFileSystemState();

  //TODO: Не ясно как это вызывается, поэтому пришлось закомменитровать
  if (store.state === StoreState.BlockerLoading) {
    return (
      <>
        {/* <Modal>
          <Title>
            <LoadingIndicator tw="mr-2" />
            Пожалуйста, подождите окончания действия
          </Title>
        </Modal> */}
      </>
    );
  }

  return null;
});

const Modal = tw.div`absolute inset-x-0 top-9 flex items-center bg-transparent justify-center cursor-not-allowed z-40`;
const Title = tw.h1`flex items-center justify-center text-white text-lg bg-grey-500 bg-opacity-75 rounded-lg border border-grey-300 border-opacity-30 backdrop-filter backdrop-blur-xl px-4 py-2`;

export default AlertModal;
