import { qParam } from './Service.d';
import { Service } from './Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import type { INotificationService } from './NotificationService.d';
export * from './NotificationService.d';

const BASE_URL = '/notification';

export class NotificationService implements Service {
  public static postNotificationDismiss(data: INotificationService.Body.IPostNotificationDismiss, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<INotificationService.IPostNotificationDismiss>('/notification/view/many', data, config);
  }
  public static getAllNotifications(userId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<INotificationService.IGetAllNotifications>(`/notification/find/findAllByUserIdAndIsNewTrue`, { userId }, config);
  }
  public static getAllNotificationsByProjects(body: INotificationService.Body.IGetAllNotificationsByProjects, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<INotificationService.IGetAllNotificationsByProjects>(`/notification/find/findAllByProjects`, body, config);
  }
}
