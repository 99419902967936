interface IAccess {
  id: string;
  processTask: string;
  read: string;
  chat: string;
  dataRequest: string;
  dataInput: string;
  dataComment: string;
  expert: string;
  approve: string;
  accept: string
}

interface IRoleTemplate {
  id: string;
  name: string;
  isDefault: boolean;
  accessList: IAccess[];
}


export declare namespace IRoleService {
  type IGetGlobalTemplates = IRoleTemplate[];
  type IGetRole = IRoleTemplate;
  type IGetAccessList = IAccess[];
}
