import { lazyLoad } from '@asu/utils';

export const CreateProjectStage2Page = lazyLoad(
  () => import('./CreateProjectStage2Page'),
  module => module.default,
);
export const CreateProjectStage2PageGGE = lazyLoad(
  () => import('./CreateProjectStage2PageGGE'),
  module => module.default,
);
