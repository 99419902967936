import React from 'react';
import { Button, Modal, Input, useManualModalState } from '@asu/ui';
import tw from 'twin.macro';
import { FormProvider, useForm } from 'react-hook-form';
import { useFileSystemState } from '../../../store';

interface ModalCreateProps {
  isOpen: boolean;
  setIsOpen: any;
  openModal: () => void;
  closeModal: () => void;
}

const ModalCreate = React.memo((props: ModalCreateProps) => {
  return (
    <Modal.Root manual {...props}>
      <AddModalRenameFolder />
    </Modal.Root>
  );
});

const AddModalRenameFolder = React.memo(() => {
  const methods = useForm();
  const store = useFileSystemState();
  const { closeModal } = useManualModalState();

  const onSubmit = data => {
    store.addDirectory(data);
    methods.reset({ name: 'Безымянная' });
    closeModal();
  };

  return (
    <Modal.Content tw="w-[35vw] min-w-[350px]">
      <Modal.Title>Создать папку</Modal.Title>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Input type="text" defaultValue="Безымянная" {...methods.register('name', { required: true })} placeholder="Название папки" />
          {methods.formState.errors.name && (
            <span tw="text-sm text-primary-dark mt-2">Ошибка: Поле "Название папки" должно быть заполненно</span>
          )}

          <StyledButton type="submit">Подтвердить</StyledButton>
        </Form>
      </FormProvider>
    </Modal.Content>
  );
});

const Form = tw.form`px-5 mt-2`;
const StyledButton = tw(Button)`my-2`;

export default ModalCreate;
