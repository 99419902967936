import React from 'react';
import tw from 'twin.macro';

type ErrorMessageProps = {
  errors: any;
  name: string;
  render: (message: any) => any;
};

export const ErrorMessage = React.memo<ErrorMessageProps>(({ errors, name, render }) => {
  const isError = errors[name];

  const el = render(errors[name] || { message: '' });

  const className = el.props.className + ' block text-sm font-medium transition-all text-error';

  return React.cloneElement(el, {
    className: className,
    style: {
      opacity: isError ? '1' : '0',
      transform: isError ? 'translateY(4px)' : 'translateY(0px)',
    },
  });
});
