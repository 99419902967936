import React from 'react';
import tw, { styled } from 'twin.macro';
import { observer } from 'mobx-react-lite';
import { useAuthState } from '@asu/auth';
import { IconLogoutGGE } from '../../../assets/icons';

const renderPermissions = roles => {
  let role = 'Неизвестно';

  if (roles?.includes('ПЛАТФОРМА 3Д ПРОСМОТР')) role = 'Просмотр';
  if (roles?.includes('ПЛАТФОРМА 3Д МОДЕРИРОВАНИЕ')) role = 'Модератор';
  if (roles?.includes('ПЛАТФОРМА 3Д АДМИНИСТРИРОВАНИЕ')) role = 'Администратор';
  if (roles?.includes('ПЛАТФОРМА 3Д СУПЕР АДМИНИСТРАТОР')) role = 'Супер администратор';

  return role;
};

const AccountBlockGGE = observer(() => {
  const authStore = useAuthState();

  const firstName = React.useMemo(() => authStore.currentUser?.firstName?.slice(0, 1), [authStore.currentUser]);
  const givenName = React.useMemo(() => authStore.currentUser?.givenName?.slice(0, 1), [authStore.currentUser]);

  if (!authStore.currentUser) {
    return (
      <FooterWrapper>
        <PhotoBlock />
      </FooterWrapper>
    );
  }

  return (
    <FooterWrapper>
      <InfoWrapper>
        <UserName>{`${authStore.currentUser.lastName} ${firstName}. ${givenName}.`}</UserName>
        <UserRole>{renderPermissions(authStore.currentUser.roles)}</UserRole>
      </InfoWrapper>

      <button tw="flex justify-center items-center rotate-180 ml-3" onClick={() => authStore.logout()}>
        <IconLogoutGGE tw="h-8 w-8 text-black" />
      </button>
    </FooterWrapper>
  );
});

const FooterWrapper = tw.div`flex items-center pr-5`;
const InfoWrapper = tw.div``;
const PhotoBlock = styled.figure`
  ${tw`relative width[48px] height[48px] flex-shrink-0 border border-black rounded-3xl mr-3 hover:bg-white/20 cursor-pointer`}
`;
const UserName = tw.p`font-title text-xl text-black font-medium leading-6`;
const UserRole = tw.p`text-grey-500 text-sm font-thin leading-none opacity-75`;

export default AccountBlockGGE;
