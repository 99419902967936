import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import { Service } from './Service';
import { qParam } from './Service.d';
import type { IGeometryService } from './GeometryService.d';

const BASE_URL = '/geometry';

export class GeometryService implements Service {
  // *** geometry *** //
  public static getGeometry(geometryId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IGeometryService.IGetGeometry>(`/geometry/${geometryId}`, config);
  }
  public static postRoad(body: IGeometryService.IBody, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IGeometryService.IPostGeometrySave>('/geometry/save', body, config);
  }
  public static getGeometries(body: IGeometryService.IBody, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IGeometryService.IPostGeometryFilter>('/geometry/filters', body, config);
  }
  public static deleteGeometry(geometryId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).delete<IGeometryService.IDeleteGeometry>(`/geometry/${geometryId}/delete`, config);
  }
  // *** workspace *** //
  public static postWorkspace(body: IGeometryService.IBody, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IGeometryService.IPostWorkspaceSave>('/workspace/save', body, config);
  }
  public static getWorkspaces(filter?: IGeometryService.IBody, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IGeometryService.IPostWorkspaceFilter>('/workspace/filters', filter, config);
  }
  public static deleteWorkspace(workspaceId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).delete<IGeometryService.IDeleteGeometry>(`/workspace/${workspaceId}/delete`, config);
  }
}
