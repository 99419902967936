type TElementType = 'PHOTO' | 'FILE' | 'METRIC';
type TStatus = 'IN_PROGRESS' | 'UNDER_APPROVAL' | 'EXPERT_APPROVE' | 'APPROVER_APPROVE' | 'CONFIRMER_APPROVE' | 'CLOSED';
type TAproveUser = 'APPROVER' | 'EXPERT' | 'CONFIRMER';

interface ICoordinate {
  x: number;
  y: number;
  z: number;
}

interface ICoreInfo {
  createDate: number;
  authorId: string;
  message: string;
  id: string;
  taskId: string;
}

interface ICoreSolution {
  authorId: string;
  createDate: number;
  name: string;
  description: string;
  id: string;
  taskId: string;
}

interface IElement {
  id: string;
  fileId: string;
  preview: string;
  type: TElementType;
  properties: string[];
  angle: {
    position: ICoordinate;
    target: ICoordinate;
  };
}

export interface IBuildingTask {
  id: string;
  children: IBuildingTask[];
  approves: [
    {
      id: string;
      coreApprove: ICoreInfo;
      attachment: {
        id: string;
        elements: IElement[];
      };
    },
  ];
  rejects: [
    {
      id: string;
      coreReject: ICoreInfo;
      attachment: {
        id: string;
        elements: IElement[];
      };
    },
  ];
  solution: {
    id: string;
    coreSolution: ICoreSolution;
  };
  coreTask: {
    authorId: string;
    status: TStatus;
    createDate: number;
    name: string;
    description: string;
    assignedUserId: string;
    startDate: number;
    dueDate: number;
    projectId: string;
    id: string;
    rootTaskId: string;
    parentTaskId: string;
    children: string[];
    solution: ICoreSolution;
    approvalSettings: {
      approveUsers: [
        {
          id: string;
          type: TAproveUser;
        },
      ];
    };
    approves: ICoreInfo[];
    rejects: ICoreInfo[];
    processId: string;
  };
  subtype: string;
  adskPosition: string;
  unit: string;
  value: number;
  properties: string[];
}

export interface IControlTask {
  id: string;
  coreTaskId: string;
  name: string;
  inspectorId: string[];
  buildingTaskAssignee: string[];
  "status": string;
  "identifiedBreaches": string;
  "createDate": number;
  "repeated": boolean;
}
