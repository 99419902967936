import React from 'react';
import tw, { styled } from 'twin.macro';
import { useNavigate } from 'react-router-dom';
import { useCamunda, useCamundaHistory } from '../hooks';

const SKELETON = [
  [
    { id: null, name: 'ТПД', taskDefKey: 'dictionary_data', isAvailable: false },
    { id: null, name: 'ИРД', taskDefKey: 'classifier_data', isAvailable: false },
  ],
  [
    { id: null, name: 'Согласование', taskDefKey: 'approve', prevTask: 'dictionary_data', isAvailable: false },
    { id: null, name: 'Согласование', taskDefKey: 'approve', prevTask: 'classifier_data', isAvailable: false },
  ],
  [{ id: null, name: 'Задание на проектирование', taskDefKey: 'document', isAvailable: false }],
  [{ id: null, name: 'Согласование', taskDefKey: 'approve', prevTask: 'document', isAvailable: false }],
  [{ id: null, name: 'Смета ПИР', taskDefKey: 'dictionary_calc_cost', isAvailable: false }],
  [{ id: null, name: 'Согласование', taskDefKey: 'approve', prevTask: 'dictionary_calc_cost_', isAvailable: false }],
  [{ id: null, name: 'Календарный план ПИР', taskDefKey: 'dictionary_calc_calendar', isAvailable: false }],
  [{ id: null, name: 'Согласование', taskDefKey: 'approve', prevTask: 'dictionary_calc_calendar_', isAvailable: false }],
];

const formatCamundaProcess = taskList => {
  const list = [];

  taskList.forEach((tasks, i, arr) => {
    const nextEl = arr[i + 1];

    const map = tasks
      .filter((task: any) => task.taskDefKey !== 'approve')
      .map(task => {
        const findEl: any = nextEl?.find((el: any) => {
          const prevTask = el.prevTask.lastIndexOf('-') !== -1 ? el.prevTask.slice(0, el.prevTask.lastIndexOf('-')) : el.prevTask;
          return el.taskDefKey === 'approve' && prevTask === task.taskDefKey;
        });

        if (findEl) {
          if (!task.dependencies) task.dependencies = [];
          if (!task.dependencies.find(el => el.prevTask === findEl.prevTask || el.id === findEl.processInstanceId)) {
            task.dependencies.push(findEl);
          }

          return task;
        }

        return task;
      });

    if (map.length > 0) list.push(map);
  });

  return list;
};

const DiagramView: React.FC<any> = ({ params }) => {
  const navigate = useNavigate();
  const [isLoadingCamunda, camunda] = useCamunda(params.projectId);
  const [isLoadingCamundaHistory, camundaHistory] = useCamundaHistory(params.projectId);

  const camundaTaskList = React.useMemo(() => {
    if (camunda && camundaHistory) {
      camundaHistory.processHistoryTasks.forEach(currentTask => {
        const taskDefKey = currentTask.taskDefinitionKey.slice(0, currentTask.taskDefinitionKey.lastIndexOf('_'));

        SKELETON.forEach((tasks, i) => {
          tasks.forEach((task, j) => {
            if (taskDefKey === task.taskDefKey) {
              SKELETON[i][j] = {
                ...SKELETON[i][j],
                ...currentTask,
                isAvailable: true,
              };
            }
          });
        });
      });

      camunda.forEach(currentTask => {
        const taskDefKey = currentTask.taskDefinitionKey.slice(0, currentTask.taskDefinitionKey.lastIndexOf('-'));
        const prevTask = currentTask.prevTask.slice(0, currentTask.prevTask.lastIndexOf('-'));

        SKELETON.forEach((tasks, i) => {
          tasks.forEach((task: any, j) => {
            if (taskDefKey !== 'approve' && taskDefKey === task.taskDefKey) {
              SKELETON[i][j] = {
                ...SKELETON[i][j],
                ...currentTask,
                isAvailable: true,
              };
            }

            if (taskDefKey === 'approve' && taskDefKey === task.taskDefKey && prevTask === task.prevTask) {
              SKELETON[i][j] = {
                ...SKELETON[i][j],
                ...currentTask,
                isAvailable: true,
              };
            }
          });
        });
      });

      return formatCamundaProcess(SKELETON);
    }

    return [];
  }, [camunda, camundaHistory, params]);

  if (isLoadingCamunda || isLoadingCamundaHistory) {
    return (
      <Scrollview>
        <div tw="h-40 width[calc(100vw - 270px)] flex items-center border-b border-grey-100">
          {Array.from(Array(7), (_, i) => (
            <div key={i} tw="h-9 w-32 bg-grey-300 bg-opacity-30 rounded animate-pulse mx-8" />
          ))}
        </div>
      </Scrollview>
    );
  }

  return (
    <Scrollview>
      <Container>
        {camundaTaskList.map((tasks, i) => (
          <TaskGroup key={i}>
            {tasks.map((task, i) => {
              const isActiveTask = task.taskDefKey === params.taskDefKey && task.id === params.taskId;
              const page = `/project/${params.projectId}/process/${params.processId}/${task.taskDefKey}/${task.id}`;

              if (task.dependencies) {
                return (
                  <TaskChain key={i}>
                    <TaskItem
                      disabled={!task.isAvailable}
                      onClick={() => navigate(page)}
                      $isAvailable={task.isAvailable}
                      $isActive={isActiveTask}
                    >
                      {task.name}
                    </TaskItem>
                    {task.dependencies.map((taskDep, i) => {
                      const isActiveTask = taskDep.taskDefKey === params.taskDefKey && taskDep.id === params.taskId;
                      const page =
                        taskDep.taskDefKey === 'approve' || taskDep.taskDefKey === 'confirm'
                          ? `/project/${params.projectId}/process/${params.processId}/${task.taskDefKey}/${task.id}/approve/${taskDep.id}`
                          : `/project/${params.projectId}/process/${params.processId}/${taskDep.taskDefKey}/${taskDep.id}`;

                      return (
                        <TaskItem
                          key={i}
                          disabled={!taskDep.isAvailable}
                          onClick={() => navigate(page)}
                          $isAvailable={taskDep.isAvailable}
                          $isActive={taskDep.id === params.approveId || isActiveTask}
                        >
                          {taskDep.name}
                        </TaskItem>
                      );
                    })}
                  </TaskChain>
                );
              }

              return (
                <TaskItem
                  key={i}
                  disabled={!task.isAvailable}
                  onClick={() => navigate(page)}
                  $isAvailable={task.isAvailable}
                  $isActive={isActiveTask}
                >
                  {task.name}
                </TaskItem>
              );
            })}
          </TaskGroup>
        ))}
      </Container>
    </Scrollview>
  );
};

const Scrollview = tw.section`h-40 width[calc(100vw - 270px)] border-b border-grey-100`;
const Container = styled.div`
  ${tw`h-full w-full flex items-center overflow-x-auto px-6`}

  ::-webkit-scrollbar {
    ${tw`h-1 w-1 rounded-lg`}
  }

  ::-webkit-scrollbar-track {
    ${tw`bg-white rounded-lg`}
  }

  ::-webkit-scrollbar-thumb {
    ${tw`bg-grey-300 rounded-full border-2 border-white opacity-20`}
  }
`;

const TaskGroup = styled.div`
  ${tw`relative flex-shrink-0 flex flex-col justify-around`}
  ${tw`not-first:(pl-16) not-first:after:(content[''] absolute top-1/2 transform -translate-y-1/2 left-0 height[1px] w-16 background-color[#D2D3D4])`}
`;

interface TaskItemParams {
  readonly $isActive: boolean;
  readonly $isAvailable: boolean;
}

const TaskItem = styled.button<TaskItemParams>`
  ${tw`relative text-sm rounded border border-grey-300 text-grey-300 px-4 py-2 mx-4 my-2`}
  ${({ $isAvailable }) => $isAvailable && tw`text-primary-dark bg-white border-primary-dark`}
  ${({ $isActive }) => $isActive && tw`text-white bg-primary border-primary-light`}
`;

const TaskChain = styled.div`
  ${tw`relative flex-shrink-0 flex justify-around`}

  ${TaskItem} {
    ${tw`relative`}
    ${tw`not-first:(ml-16) not-first:after:(content[''] absolute top-1/2 transform -translate-y-1/2 -left-16 height[1px] w-12 background-color[#D2D3D4])`}
  }
`;

export default DiagramView;
