import React from 'react';
import { ProjectService, IProjectService } from '@asu/services';

export const useProject = (id): [boolean, IProjectService.IGetProject | null] => {
  const [project, setProject] = React.useState<IProjectService.IGetProject | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (id) {
      (async () => {
        setIsLoading(true);

        const response = await ProjectService.getProject(id).fetch();
        const responseData = response.data;

        setProject(responseData);
        setIsLoading(false);
      })();
    }
  }, [id]);

  return [isLoading, project];
};
