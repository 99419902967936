import { Service } from './Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import { ICalendarEstimateService } from './CalendarEstimateService.d';
export * from './CalendarEstimateService.d';
const BASE_URL = '/dictionaryc';

export class CalendarEstimateService implements Service {
  public static getDictionaryCalendarEstimate(data: ICalendarEstimateService.Data.IGetDictionaryCalendarEstimate, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<ICalendarEstimateService.IGetDictionaryCalendarEstimate>(`/dictionary/calendar_estimate`, data, config);
  }
}
