import Keycloak from 'keycloak-js';

const url =
  process.env.NODE_ENV !== 'production'
    ? process.env.NX_APP_KEYCLOAK_SERVER_URI + '/auth' ?? ''
    : process.env.NX_APP_P_KEYCLOAK_SERVER_URI + '/auth' ?? '';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
export const keycloak = Keycloak({
  url,
  realm: 'asubim',
  clientId: process.env.NX_SYSTEM_TYPE === 'GGE' ? 'frontend-platform3d' : 'frontend-asubim',
});
