import React from 'react';
import tw, { styled } from 'twin.macro';
import { observer } from 'mobx-react-lite';
import { useAuthState } from '@asu/auth';
import { IconLogout } from '../../../assets/icons';

const AccountBlock = observer(() => {
  const authStore = useAuthState();

  const firstName = React.useMemo(() => authStore.currentUser?.firstName?.slice(0, 1), [authStore.currentUser]);
  const givenName = React.useMemo(() => authStore.currentUser?.givenName?.slice(0, 1), [authStore.currentUser]);

  if (!authStore.currentUser) {
    return (
      <FooterWrapper>
        <PhotoBlock />
        <div tw="mt-1">
          <div tw="h-4 w-40 bg-grey-300 bg-opacity-30 rounded animate-pulse mb-2" />
          <div tw="h-3 w-32 bg-grey-300 bg-opacity-30 rounded animate-pulse" />
        </div>
      </FooterWrapper>
    );
  }

  return (
    <FooterWrapper>
      {/* {process.env.NX_SYSTEM_TYPE !== 'AUTO' && (
        <Button onClick={() => window.location.replace('/admin')}>Перейти в панель администрирования</Button>
      )} */}
      <div tw="flex flex-row items-center">
        <PhotoBlock onClick={() => authStore.logout()}>
          <IconLogout tw="h-8 w-8 text-white" />
        </PhotoBlock>
        <InfoWrapper>
          <UserName>{`${authStore.currentUser.lastName} ${firstName}. ${givenName}.`}</UserName>
          <UserRole>{authStore.currentUser.position}</UserRole>
        </InfoWrapper>
      </div>
    </FooterWrapper>
  );
});

const FooterWrapper = tw.div`flex flex-col gap-4 items-center border-t border-grey-300 border-opacity-25 pt-6 pb-10`;
const InfoWrapper = tw.div``;
const PhotoBlock = styled.figure`
  ${tw`relative width[48px] height[48px] flex-shrink-0 opacity-25 border border-white rounded-3xl mr-3 hover:bg-white/20 cursor-pointer`}

  svg {
    ${tw`absolute inset-0 m-auto text-white transition-all opacity-100`}
  }

  // &:hover svg {
  //   ${tw`opacity-100`}
  // }
`;
const UserName = tw.p`font-title text-xl text-white font-medium leading-6`;
const UserRole = tw.p`text-white text-sm font-thin leading-none opacity-75`;

export default AccountBlock;
