import React from 'react';
import tw, { styled } from 'twin.macro';
import { default as AntdMenu } from 'antd/lib/menu';
import 'antd/lib/menu/style/index.css';

const StyledAntdMenu = styled(AntdMenu)``;

const Menu: any = StyledAntdMenu;

export default Menu;
