export interface IClassifierValue {
  id: string;
  name: string;
  note: string;
}

export interface IClassifier {
  id: string;
  code: string;
  name: string;
  values: IClassifierValue[];
}
