import React from 'react';
import tw, { styled } from 'twin.macro';
import { observer } from 'mobx-react-lite';
import { useFileSystemState } from '../../../store';

type AddFileProps = {
  className?: string;
};

const AddFile = observer<AddFileProps>(() => {
  const store = useFileSystemState();
  const ref = React.useRef<HTMLInputElement | null>();

  const onClick = () => {
    if (ref.current) {
      ref.current.value = null;
      ref.current.click();
    }
  };

  const onSelectedFile = event => {
    if (event.target.files.length <= 1) {
      store.uploadFile(event.target.files[0]);
    }
    if (event.target.files.length > 1) {
      store.uploadFiles(event.target.files);
    }
  };

  return (
    <div>
      <input type="file" tw="hidden" ref={ref} multiple onChange={onSelectedFile} />
      <Button name="button" value="Upload" onClick={onClick}>
        <span tw="font-medium text-sm leading-none">Добавить файл</span>
      </Button>
    </div>
  );
});

const Button = styled.button`
  ${tw`flex items-center rounded text-grey-900 cursor-pointer transition-colors duration-150 p-3`}
  ${tw`hocus:(outline-none)`}
  ${tw`opacity-100 hover:(text-grey-500 bg-grey-100) active:(text-grey-900 bg-grey-300)`}
`;

export default AddFile;
