export interface ITaskAttachment {
  id: string;
  fileId: string;
}

interface IFileIdTS {
  fileId: string;
}

export interface ITrackerEvent {
  id: string;
}
export interface ITrackerGroup {
  id: string;
  name: string;
  description: string;
  ownerId: string;
  projectId: string;
  // events: ITrackerEvent[];
}
export enum ApprovalStateEnum {
  WAITING = 'WAITING',
  EXPERT = 'EXPERT',
  APROVE = 'APROVE',
  CONFIRM = 'CONFIRM',
  CLOSED = 'CLOSED',
}
export enum ApprovalTypeEnum {
  APPROVER = 'APPROVER',
  EXPERT = 'EXPERT',
  CONFIRMER = 'CONFIRMER',
}
export interface IApprovalUsers {
  id: string;
  userId: string;
  type: ApprovalTypeEnum;
  approvalSettingsId?: string;
}
export interface IApprovalIteration {
  id: string;
  state: ApprovalStateEnum;
  users: IApprovalUsers[];
  taskId: string;
}
export interface IApprovalSettings {
  is: string;
  taskId: string;
  users: IApprovalUsers[];
}
export interface ITaskApprove {
  createDate: string;
  authorId: string;
  iterationNumber: number;
  message: string;
  id: string;
  taskId: string;
}
export enum TaskTypeEnum {
  CLASSIFIER_DATA = 'CLASSIFIER_DATA',
  DICTIONARY_DATA = 'DICTIONARY_DATA',
  DOCUMENT = 'DOCUMENT',
  DICTIONARY_CALC_COST = 'DICTIONARY_CALC_COST',
  DICTIONARY_CALC_CALENDAR = 'DICTIONARY_CALC_CALENDAR',
  BUILDING_MANAGEMENT = 'BUILDING_MANAGEMENT',
  TASK_TRACKER_DEFAULT = 'TASK_TRACKER_DEFAULT',
}
export enum TaskStatusEnum {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING_DEPENDENCY = 'WAITING_DEPENDENCY',
  UNDER_APPROVAL = 'UNDER_APPROVAL',
  EXPERT_APPROVE = 'EXPERT_APPROVE',
  APPROVER_APPROVE = 'APPROVER_APPROVE',
  CONFIRMER_APPROVE = 'CONFIRMER_APPROVE',
  CLOSED = 'CLOSED',
}
export interface ITask {
  type: TaskTypeEnum;
  authorId: string;
  status: TaskStatusEnum;
  createDate: string;
  name: string;
  description: string;
  assignedUserId: string;
  startDate: string;
  dueDate: string;
  endDate: string;
  projectId: string;
  expired: boolean;
  enableAutostart: boolean;
  id: string;
  rootTaskId: string;
  parentTaskId: string;
  parentDependencyTaskId: string;
  children: string[];
  dependencies: string[];
  solution: ITaskSolution;
  approvalSettings: IApprovalSettings;
  approves: ITaskApprove[];
  rejects: ITaskReject[];
  repeated: boolean;
  approvalIteration: IApprovalIteration;
  processId: string;
  customTaskDefId: string;
  taskDefinitionKey: string;
}
export interface ITaskReject {
  createDate: string;
  authorId: string;
  iterationNumber: number;
  message: string;
  id: string;
  taskId: string;
}
export interface ITaskSolution {
  authorId: string;
  createDate: string;
  name: string;
  description: string;
  result: boolean;
  id: string;
  taskId: string;
}
export enum SecurityLevelEnum {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}
export enum PriorityTaskEnum {
  NONE = 'NONE',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}
export interface ITrackerTaskLabel {
  id?: string;
  name: string;
  description: string;
  color: string;
}
export interface ITrackerTask {
  id: string;
  name: string;
  description: string;
  taskAdminId: string;
  authorId: string;
  assignedUserId: string;
  startDate: string;
  securityLevel: SecurityLevelEnum;
  priority: PriorityTaskEnum;
  events: ITrackerEvent[];
  labels: ITrackerTaskLabel[];
  projectId: string;
  groupId: string;
  solution: ITrackerTaskSolution[];
  coreTask: ITask;
  parentTaskId: string;
  children: any;
}
export interface ITrackerTaskSolution {
  id: string;
  solutionText: string;
  solutionAttachments: ITaskAttachment[];
  coreSolution: ITaskSolution;
}

interface ITrackerTaskSolutionBody {
  id: string;
  solutionText: string;
  solutionAttachments: ITaskAttachment[];
}
export enum UpdateTaskStatusEnum {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING_DEPENDENCY = 'WAITING_DEPENDENCY',
  UNDER_APPROVAL = 'UNDER_APPROVAL',
  EXPERT_APPROVE = 'EXPERT_APPROVE',
  APPROVER_APPROVE = 'APPROVER_APPROVE',
  CONFIRMER_APPROVE = 'CONFIRMER_APPROVE',
  CLOSED = 'CLOSED',
}
export interface IChecklist {
  id?: string;
  name: string;
  items?: IChecklistItem[];
}
export interface IChecklistItem {
  id?: string;
  name: string;
  isDone?: boolean;
  weight: any;
}
export interface ITrackerGroupCreation {
  id: string;
  name: string;
  description: string;
  ownerId: string;
  projectId: string;
  users: ITrackerUser[];
}
export interface ITrackerUser {
  userId: string;
  isAdmin: boolean;
}

interface ITaskNew {
  id: string;
  name: string;
  description: string;
  taskAdminId: string;
  authorId: string;
  assignedUserId: string;
  isClosed: boolean;
  startDate: string;
  securityLevel: SecurityLevelEnum;
  priority: PriorityTaskEnum;
  labels: ITrackerTaskLabel[];
  projectId: string;
  groupId: string;
  solution: ITrackerTaskSolution;
  coreTask: ITask;
  weight: number;
}

interface IUpdateLabel {
  name: string;
  description: string;
  color: string;
}

interface IStatus {
  status: string;
}

interface IProjectId {
  projectId: string;
}

interface ITaskUpdate {
  name?: string;
  description?: string;
  priority?: string;
  startDate?: string;
  dueDate?: string;
}

interface ITrackerGroupCreationBody {
  name: string;
  description: string;
  ownerId: string;
  projectId?: string;
  users?: ITrackerUser[];
}

interface IGroupUpdateBody {
  name: string;
  description: string;
  projectId: string;
}

interface ISolutionText {
  solutionText: string;
}

export declare namespace ITasktrackerService {
  type IAllAttachmentsInTask = ITaskAttachment[];
  type ICreateAttachmentInTask = ITaskAttachment[];
  type IPatchAttachmentInTask = ITaskAttachment;
  type IGetGroupLabels = ITrackerTaskLabel[];
  type ICreateGroupLabel = ITrackerTaskLabel;
  type IEditGroupLabel = ITrackerTaskLabel;
  type IGetChecklists = IChecklist[];
  type ICreateChecklist = IChecklist;
  type ICreateChecklistItem = IChecklistItem;
  type ISetStatusChecklistItem = IChecklistItem;
  type ICreateTask = ITaskNew;
  type ISetTaskStatus = ITaskNew;
  type IBindProjectToStatus = ITaskNew;
  type IAddLabelToTask = ITrackerTaskLabel;
  type IUpdateLabelsInTask = Array<string>;
  type IGetTask = ITaskNew;
  type IUpdateTask = ITaskNew;
  type IGetAllTasksWhereUserIsExecutive = ITaskNew[];
  type IGetTaskObserversList = ITrackerUser[];
  type ICreateGroup = ITrackerGroupCreation;
  type IAddUserToGroup = ITrackerGroup;
  type IGetGroup = ITrackerGroup;
  type IUpdateGroup = ITrackerGroup;
  type IRemoveUserFromGroup = ITrackerGroup;
  type IGetUserGroupsList = ITrackerGroup[];
  type IGetGroupUsersList = ITrackerUser[];
  type IGetGroupTasks = ITaskNew[];
  type IGetGroupsByProject = ITrackerGroup[];
  type IGetTaskbyTaskId = ITaskNew;
  type IUpdateTaskStatus = ITaskNew;
  type IGetLinkedTasks = ITaskNew[];
  type IGetLinksWithTasks = ITaskNew[];
  type ISolutionTask = ITrackerTaskSolution;
  type IUpdateSolutionTask = ITrackerTaskSolution;
  type IAddAttachmentToSolutionTask = ITrackerTaskSolution;
  type IDeleteAttachmentToSolutionTask = ITrackerTaskSolution;
  type IUpdateAttachmentToSolutionTask = ITrackerTaskSolution;

  declare namespace Body {
    type ICreateAttachmentInTask = ITaskAttachment;
    type IPatchAttachmentInTask = IFileIdTS;
    type ICreateGroupLabel = ITrackerTaskLabel;
    type IEditGroupLabel = IUpdateLabel;
    type ICreateChecklist = IChecklist;
    type ICreateChecklistItem = IChecklistItem;
    type ICreateTask = ITrackerTask;
    type ISetTaskStatus = IStatus;
    type IBindProjectToStatus = IProjectId;
    type IUpdateTask = ITaskUpdate;
    type ICreateGroup = ITrackerGroupCreationBody;
    type IUpdateGroup = IGroupUpdateBody;
    type IUpdateTaskStatus = IStatus;
    type ISolutionTask = ITrackerTaskSolutionBody;
    type IUpdateSolutionTask = ISolutionText;
    type IAddAttachmentToSolutionTask = ITaskAttachment;
    //ITrackerTaskSolutionBody
  }
}
