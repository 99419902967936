import React from 'react';
import tw, { styled } from 'twin.macro';

import { formatBytes } from '@asu/utils';
import { useUserInformation } from '../hooks/useUserInformation';

type MetaDataProps = {
  info: any;
};
const MetaData = React.memo<MetaDataProps>(({ info }) => {
  const [isLoading, user] = useUserInformation(info.author);

  return (
    <Container>
      {info ? (
        <BodyWrapper>
          <Text>Наименование: {info.baseName}</Text>
          <Text>Размер: {formatBytes(info.size)}</Text>
          <Text>
            Тип: {info.type === 'DOCUMENT' && 'Документ'} ({info.ext})
          </Text>
          <Text>Версия: {info.version}</Text>
          <Text>Автор: {user?.lastName} {user?.firstName} {user?.middleName}</Text>
        </BodyWrapper>
      ) : (
        <div tw="px-4 pt-4">
          <div tw="flex flex-wrap">
            {Array.from(Array(6), (_, i) => (
              <BlockPlaceholder key={i} />
            ))}
          </div>
        </div>
      )}
    </Container>
  );
});

const Text = tw.p`pl-1 mb-1 overflow-hidden overflow-ellipsis whitespace-nowrap`;
const BodyWrapper = tw.div`px-4 my-4`;
const BlockPlaceholder = tw.div`height[20px] w-full bg-grey-300 bg-opacity-30 rounded animate-pulse mb-3`;
const Container = styled.main`
  ${tw`height[93%] w-full overflow-y-scroll`}

  ::-webkit-scrollbar {
    width: 3px;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d1d5db;
    border-radius: 20px;
    opacity: 0.2;
  }
`;

export default MetaData;
