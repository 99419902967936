import React from 'react';
import { useLocation } from 'react-router-dom';
import { ProjectService, IProjectService } from '@asu/services';

export const useUserTasks = (id?: string): [boolean, IProjectService.IGetTasks | []] => {
  const location = useLocation();
  const [tasks, setTasks] = React.useState<IProjectService.IGetTasks | null>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (id) {
      (async () => {
        setIsLoading(true);

        const response = await ProjectService.getTasks(id).fetch();
        const responseData = response.data;

        setTasks(responseData);
        setIsLoading(false);
      })();
    }
  }, [location, id]);

  return [isLoading, tasks];
};
