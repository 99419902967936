import React from 'react';
import { CamundaService } from '@asu/services';

export const useCamundaHistory = (projectId: string): [boolean, any] => { // [typeErrorNote] - any, потому что метод устарел, но используется в проекте
  const [camunda, setCamunda] = React.useState<any | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    (async () => {
      setIsLoading(true);

      const response = await CamundaService.getHistoryProcessList(projectId).fetch();
      const responseData = response.data;

      setCamunda(responseData);
      setIsLoading(false);
    })();
  }, [projectId]);

  return [isLoading, camunda];
};
