import * as THREE from 'three';
import { GLTFLoader as _GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader as _DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

const dracoLoader = new _DRACOLoader();
dracoLoader.setDecoderPath('/assets/draco/');
dracoLoader.setDecoderConfig({ type: 'js' });
const GLTFLoader = new _GLTFLoader();
GLTFLoader.setDRACOLoader(dracoLoader);

export { THREE, GLTFLoader };
