interface IMetaTemplate {
  id: string;
  key: string;
  value: string;
  templateId: string;
}

export interface ICreateTemplateBody {
  id?: string;
  templateName: string;
  externalId?: string;
  objectName?: string;
  startDate?: number;
  endDate?: number;
  lat?: number;
  lon?: number;
  totalCost?: number;
  realisationCost?: number;
  projectCostAtStart?: number;
  isProjectStarted?: boolean;
  meta?: IMetaTemplate[];
  type: 'LINEAR' | 'PLANAR' | 'SIMPLE' | string;
}

export declare namespace IAdministrationService {
  namespace Body {
    type ICreateTemplate = ICreateTemplateBody;
  }
}
