interface IAttribute {
  id: string;
  attributeOrder: number;
  title: string;
  linkedDataType: string;
  linkedDataId: string;
  attributes: string[];
}

export interface IDocumentTemplate {
  id: string;
  name: string;
  description: string;
  attributes: IAttribute[];
}

export declare namespace ITemplateService {
  type IGetTemplates = IDocumentTemplate[];
}
