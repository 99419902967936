import React from 'react';
import 'twin.macro';
import { observer } from 'mobx-react-lite';
import { ExplorerView, useFileSystemState } from '../../store';
import { GridView, ListView, ModalDeleteFolder } from './local-components';
import { Menu } from '../../styles';
import { ContextMenu } from '../ContextMenu';
import { ModalReName } from '../Explorer/local-components';

type FolderProps = {
  id?: string;
};

const FolderVariants = {
  [ExplorerView.Grid]: GridView,
  [ExplorerView.List]: ListView,
};

const FolderView = observer<any>(({ id, ...rest }) => {
  const store = useFileSystemState();

  const View = React.useMemo(() => FolderVariants[store.view], [store.view]);

  if (store.dirictories.length > 0) {
    return (
      <View.Wrapper id={id}>
        {store.dirictories.map(data => (
          <View.Folder key={data.id} data={data} {...rest} />
        ))}
      </View.Wrapper>
    );
  }

  return null;
});

const Folders = observer<FolderProps>(({ id }) => {
  const store = useFileSystemState();
  const [event, setEvent] = React.useState<React.MouseEvent<HTMLDivElement, MouseEvent> | null>(null);
  const [isOpenDeleteFolder, setIsOpenDeleteFolder] = React.useState<boolean>(false);
  const [selectedFolder, setSelectedFolder] = React.useState<any>(null);
  const [isOpenReName, setIsOpenReName] = React.useState<boolean>(false);

  const onClickOutside = () => {
    setEvent(null);
  };

  const onClick = data => {
    store.historyGo({ id: data.id, name: data.baseName });
  };

  const onContextMenu = (e, data) => {
    e.preventDefault();
    setEvent(e);
    setSelectedFolder(data);
  };

  const onClickDelete = () => {
    setIsOpenDeleteFolder(true);
  };

  const closeModalReName = () => {
    setIsOpenReName(false);
    setSelectedFolder(null);
  };

  const openModalReName = () => {
    setIsOpenReName(true);
  };

  const closeModalDeleteFolder = () => {
    setIsOpenDeleteFolder(false);
    setSelectedFolder(null);
  };

  const openModalDeleteFolder = () => {
    setIsOpenDeleteFolder(true);
  };

  const contextMenuContent = (
    <Menu.Container>
      <Menu.Item tw="text-primary-dark" onClick={onClickDelete}>
        Удалить папку
      </Menu.Item>
      <Menu.Item onClick={openModalReName}>Переименовать папку</Menu.Item>
    </Menu.Container>
  );

  return (
    <>
      {isOpenReName && (
        <ModalReName
          name={selectedFolder?.baseName}
          id={selectedFolder?.id}
          type={selectedFolder?.type}
          isOpen={isOpenReName}
          setIsOpen={setIsOpenReName}
          openModal={openModalReName}
          closeModal={closeModalReName}
        />
      )}
      <FolderView id={id} onClick={onClick} onContextMenu={onContextMenu} />
      {event && <ContextMenu event={event} onClickOutside={onClickOutside} content={contextMenuContent} />}
      {isOpenDeleteFolder && (
        <ModalDeleteFolder
          deleteId={selectedFolder?.id}
          isOpen={isOpenDeleteFolder}
          setIsOpen={setIsOpenDeleteFolder}
          openModal={openModalDeleteFolder}
          closeModal={closeModalDeleteFolder}
        />
      )}
    </>
  );
});

export default Folders;
