interface CalendarEstimateBody {
  projectId: string;
  taskDefId: string;
}

interface CalendarEstimate {
  id: string;
  name: string;
  unit: string;
  months: number;
  value: number;
  stage: 'NOT_STARTED' | 'ACTIVE' | 'DONE';
}


export declare namespace ICalendarEstimateService {
  type IGetDictionaryCalendarEstimate = CalendarEstimate[];

  declare namespace Data {
    type IGetDictionaryCalendarEstimate = CalendarEstimateBody;
  }
}
