interface IGanttModelBody {
  projectId: string;
  ids: string[];
}

interface IGanttModel {
  id: string;
  projectId: string;
  modelId: string;
}

interface IAccessPS {
  id: string;
  processTask: string;
  read: AccessRole;
  chat: AccessRole;
  dataRequest: AccessRole;
  dataInput: AccessRole;
  dataComment: AccessRole;
  accept: AccessRole;
  approve: AccessRole;
  expert: AccessRole;
}

interface IProjectAssignedUsersRole {
  id: string;
  name: string;
  accessList: IAccessPS[];
}

export interface IProjectAssignedUsers {
  id: string;
  projectRole: IProjectAssignedUsersRole;
  userId: string;
}

interface IProjectAssignedUsersBody {
  id: string;
  userId: string;
}

export interface IProjectMeta {
  key?: string;
  value?: string;
}

export interface IProjectResponseBody {
  id?: string;
  templateId?: string;
  title?: string;
  description?: string;
  objectName?: string;
  startDate?: number;
  endDate?: number;
  lat: number;
  lon: number;
  totalCost?: number;
  realisationCost?: number;
  projectCostAtStart?: number;
  documentationId?: string;
  stage?: 'NOT_STARTED' | 'ACTIVE' | 'DONE';
  status?: 'NOT_STARTED' | 'ACTIVE' | 'DONE';
  assignedUsers?: IProjectAssignedUsersBody[];
  ganttModels?: IGanttModel[];
  meta?: IProjectMeta[];
  type?: 'LINEAR' | 'PLANAR' | 'SIMPLE';
}

interface IProjectResponse {
  id: string;
  templateId: string;
  title: string;
  description: string;
  objectName: string;
  startDate: number;
  endDate: number;
  lat: number;
  lon: number;
  totalCost: number;
  realisationCost: number;
  projectCostAtStart: number;
  documentationId: string;
  stage: 'NOT_STARTED' | 'ACTIVE' | 'DONE';
  status: 'NOT_STARTED' | 'ACTIVE' | 'DONE';
  assignedUsers: IProjectAssignedUsers[];
  ganttModels: IGanttModel[];
  meta: IProjectMeta[];
  type: 'LINEAR' | 'PLANAR' | 'SIMPLE';
  authorId: string;
}

interface IApprovalUserProject {
  id: string;
  userId: string;
  type: string;
  approvalSettingsId: string;
}

interface IApprove {
  createDate: string;
  authorId: string;
  iterationNumber: number;
  message: string;
  id: string;
  taskId: string;
}

interface IReject {
  createDate: string;
  authorId: string;
  iterationNumber: number;
  message: string;
  id: string;
  taskId: string;
}

interface IUser1 {
  id: string;
  userId: string;
  type: string;
  approvalSettingsId: string;
}

interface ISolutionProject {
  authorId: string;
  createDate: string;
  name: string;
  description: string;
  result: boolean;
  id: string;
  taskId: string;
}

interface IApprovalSetting {
  id: string;
  taskId: string;
  users: IApprovalUserProject[];
}

interface IApprovalIterationProject {
  id: string;
  state: string;
  users: IUser1[];
  taskId: string;
}

type UserType =
  | 'CLASSIFIER_DATA'
  | 'DICTIONARY_DATA'
  | 'DOCUMENT'
  | 'DICTIONARY_CALC_COST'
  | 'DICTIONARY_CALC_CALENDAR'
  | 'BUILDING_MANAGEMENT'
  | 'TASK_TRACKER_DEFAULT';
type UserStatus =
  | 'NOT_STARTED'
  | 'IN_PROGRESS'
  | 'WAITING_DEPENDENCY'
  | 'UNDER_APPROVAL'
  | 'EXPERT_APPROVE'
  | 'APPROVER_APPROVE'
  | 'CONFIRMER_APPROVE'
  | 'CLOSED';

export interface IUserTask {
  type: UserType;
  authorId: string;
  status: UserStatus;
  createDate: string;
  name: string;
  description: string;
  assignedUserId: string;
  startDate: string;
  dueDate: string;
  endDate: string;
  projectId: string;
  expired: boolean;
  enableAutostart: boolean;
  id: string;
  rootTaskId: string;
  parentTaskId: string;
  parentDependencyTaskId: string;
  children: string[];
  dependencies: string[];
  solution: ISolutionProject;
  approvalSettings: IApprovalSetting;
  approves: IApprove[];
  rejects: IReject[];
  repeated: boolean;
  approvalIteration: IApprovalIterationProject;
  processId: string;
  customTaskDefId: string;
  taskDefinitionKey: string;
}

interface IUserProject {
  project: IProjectResponse;
  tasks: IUserTask[];
}

interface IProjectMeta {
  key: string;
  value: string;
}

interface IProjectRole {
  roleId: string;
  assignedGroupId: string;
}

interface IProjectTemplate {
  id: string;
  templateName: string;
  externalId?: string;
  objectName?: string;
  startDate?: string;
  endDate?: string;
  lat?: number;
  lon?: number;
  totalCost?: number;
  realisationCost?: number;
  projectCostAtStart?: number;
  isProjectStarted?: boolean;
  meta?: IProjectMeta[];
  roles?: IProjectRole[];
  type?: 'LINEAR' | 'PLANAR' | 'SIMPLE';
}

interface IAssignedUser {
  id: string;
  role: {
    id: string;
    name: string;
    accessList: IAccessPS[];
  };
  userId: string;
}

interface IRole {
  id: string;
  name: string;
  accessList: IAccessPS[];
}

export declare namespace IProjectService {
  type IAddModelToGantt = IProjectResponse;
  type IDeleteModelFromGantt = IProjectResponse;
  type IGetModelsGantt = IGanttModel[];
  type IGetProjects = IProjectResponse[];
  type IPatchProject = IProjectResponse;
  type IGetUserProjects = IUserProject[];
  type IGetProject = IProjectResponse;
  type IGetProjectMeta = IProjectMeta[];
  type ICreateProject = IProjectResponse;
  type IStartProjectTemplate = IProjectResponse;
  type ISetRole = IAssignedUser;
  type IGetTasks = IUserTask[];

  declare namespace Body {
    type IPatchProject = IProjectResponseBody;
    type IAddProjectMeta = IProjectMeta;
    type ICreateProject = IProjectTemplate;
    type IStartProjectTemplate = IProjectTemplate;
    type ISetRole = IProjectAssignedUsersBody;
    type IEditRole = IRole;
  }
}
