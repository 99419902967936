import { qParam } from './Service.d';
import { Service } from './Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import type { IProjectService } from './ProjectService.d';
export * from './ProjectService.d';

const BASE_URL = '/project';

export class ProjectService implements Service {
  public static patchModelIdFromGantt(projectId: qParam, modelId: qParam, newModelId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<void>(`/gantt/model/${modelId}?projectId=${projectId}&newModelId=${newModelId}`, config);
  }
  public static deleteModelFromGantt(projectId: qParam, ids: qParam[], config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IProjectService.IDeleteModelFromGantt>(`/gantt/model/delete`, { projectId, ids }, config);
  }
  public static addModelToGantt(projectId: qParam, ids: qParam[], config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IProjectService.IAddModelToGantt>(`/gantt/model/add`, { projectId, ids }, config);
  }
  public static getModelsGantt(projectId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IProjectService.IGetModelsGantt>(`/gantt/model/${projectId}`, config);
  }
  public static getProjects(config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IProjectService.IGetProjects>('/project', config);
  }
  public static patchProject(data: IProjectService.Body.IPatchProject, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<IProjectService.IPatchProject>('/project', data, config);
  }
  public static getUserProjects(userId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IProjectService.IGetUserProjects>(`/v2/user/${userId}`, config);
  }
  public static getArchivedUserProjects(userId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IProjectService.IGetUserProjects>(`/v2/user/${userId}/archive`, config);
  }
  public static getProject(id: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IProjectService.IGetProject>(`/project/${id}`, config);
  }
  public static getProjectMeta(id: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IProjectService.IGetProjectMeta>(`/meta/${id}`, config);
  }
  public static updateProjectMeta(pid: qParam, key: qParam, body: any, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IProjectService.IGetProjectMeta>(`/meta/${pid}/key/${key}`, body, config);
  }
  public static addProjectMeta(id: qParam, key: qParam, data: IProjectService.Body.IAddProjectMeta, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<void>(`/meta/${id}/key/${key}`, data, config);
  }
  public static createProject(data: IProjectService.Body.ICreateProject, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IProjectService.ICreateProject>('/project', data, config);
  }
  public static startProjectTemplate(data: IProjectService.Body.IStartProjectTemplate, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IProjectService.IStartProjectTemplate>('/project', data, config);
  }
  public static setRole(projectId: qParam, data: IProjectService.Body.ISetRole, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IProjectService.ISetRole>(`/user/${projectId}`, data, config);
  }
  public static editRole(projectId: qParam, userId: qParam, data: IProjectService.Body.IEditRole, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).put<void>(`/user/user/${userId}/project/${projectId}`, data, config);
  }
  public static deleteRole(roleId: qParam, projectId: qParam, userId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).delete<void>(`/roles/${roleId}/users/${userId}/projects/${projectId}`, config);
  }
  public static getTasks(projectId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IProjectService.IGetTasks>(`/projects/${projectId}/tasks/active`, config);
  }
  public static postStartProcess(projectId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<any>(`/projects/${projectId}/processes/start`, config);
  } // добавить типизацию
  public static getProcessDiagram(config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<any>(`/projects/processes/file`, config);
  }
  public static getGlobalTemplates(config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<any>(`/roles/global`, config);
  }
  public static getAllAvalibleRights(config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<any>(`/roles/rights`, config);
  }
  public static getAssignedUsers(projectId, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<any>(`/project/${projectId}/users`, config);
  }
  public static setLocalRole(projectId: string, userId, data: any, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<any>(`/roles/projects/${projectId}?userId=${userId}`, data, config);
  }
  public static setLocalRoleNew(roleId: qParam, projectId: qParam, userId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<any>(`/roles/${roleId}/projects/${projectId}?userId=${userId}`, userId, config);
  }
  public static startProjectWithoutTemplate(userId: string, data: any, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<any>(`/project/templatelessness?userId=${userId}`, data, config);
  }
  public static getRole(roleId: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<any>(`/roles/${roleId}`, config);
  }
  public static putNewRoleAssets(roleId: string, body, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).put<any>(`/roles/${roleId}`, body, config);
  }
  public static putNewGlobalRoleAssets(globalRoleId: string, body, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).put<any>(`/roles/global/${globalRoleId}`, body, config);
  }
  public static getMeta(projectId: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<any>(`/meta/${projectId}`, config);
  }
  public static getActiveTasks(projectId: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<any>(`/projects/${projectId}/tasks/active`, config);
  }
  public static getClosedTasks(projectId: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<any>(`/projects/${projectId}/tasks/closed`, config);
  }
}
