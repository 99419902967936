import React from 'react';
import tw, { styled } from 'twin.macro';
import { useVirtual } from 'react-virtual';
import { NotificationService } from '@asu/services';
import { IconBell, IconClose } from '../../assets/icons';
import { Overlay } from '../Overlay';
import { NotificationItem } from './local-components';

const useVirtualResizeObserver = options => {
  const measureRefCacheRef = React.useRef({});
  const elCacheRef = React.useRef({});

  const roRef = React.useRef(
    new ResizeObserver(entries => {
      entries.forEach(entry => {
        const el = entry.target;
        const index = el.getAttribute('data-index');
        measureRefCacheRef.current[index](el);
      });
    }),
  );

  React.useEffect(() => {
    const ro = roRef.current;
    return () => {
      ro.disconnect();
    };
  }, []);

  const rowVirtualizer = useVirtual(options);

  const refs = React.useMemo(() => {
    const obj = {};
    for (let i = 0; i < options.size; i++) {
      obj[i] = el => {
        if (elCacheRef.current[i]) {
          roRef.current.unobserve(elCacheRef.current[i]);
        }

        if (el) {
          // sync
          measureRefCacheRef.current[i](el);

          el.setAttribute('data-index', i);
          roRef.current.observe(el);
        }

        elCacheRef.current[i] = el;
      };
    }
    return obj;
  }, [options.size]);

  for (let i = 0; i < rowVirtualizer.virtualItems.length; i++) {
    const item = rowVirtualizer.virtualItems[i];
    if (item.measureRef !== refs[item.index]) {
      measureRefCacheRef.current[item.index] = item.measureRef;
    }
    item.measureRef = refs[item.index];
  }

  return rowVirtualizer;
};

type NotificationCenterProps = {
  onClose: () => void;
  isOpen: boolean;
  wsnotifications: any;
  historyNotifications: any[];
  isLoadingHistoryNotifications: boolean;
  user: any | null;
  onDismissSuccessCallback: (id: string) => void;
  onWsDismissSuccessCallback: (id: string) => void;
};

// TODO: Убрать WS и объеденить с history
const NotificationCenter: React.FC<NotificationCenterProps> = React.memo(
  ({
    isLoadingHistoryNotifications,
    historyNotifications,
    onDismissSuccessCallback,
    onWsDismissSuccessCallback,
    wsnotifications,
    user,
    isOpen,
    onClose,
  }) => {
    const ref = React.useRef(null);

    React.useEffect(() => {
      function handleClickOutside(event: MouseEvent): void {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          onClose();
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    });

    const onDismiss = async id => {
      if (user) {
        try {
          const data = (await NotificationService.postNotificationDismiss({ ids: [id], userId: user.id }).fetch()).data;
          onDismissSuccessCallback(id);
        } catch (error) {
          console.log('FAILD DISMISS');
        }
      }
    };

    const onWsDismiss = async id => {
      if (user) {
        try {
          const data = (await NotificationService.postNotificationDismiss({ ids: [id], userId: user.id }).fetch()).data;
          onWsDismissSuccessCallback(id);
        } catch (error) {
          console.log('FAILD DISMISS');
        }
      }
    };

    const NotificationsList = ({ notificationItems }) => {
      const parentRef = React.useRef();

      const { totalSize, virtualItems } = useVirtualResizeObserver({
        size: notificationItems?.length || 0,
        parentRef,
      });

      if (isLoadingHistoryNotifications) {
        return (
          <main tw="px-4 pt-2 overflow-hidden">
            {[...Array(10)].map(() => (
              <figure tw="h-24 w-full bg-grey-300 bg-opacity-30 rounded animate-pulse mb-4" />
            ))}
          </main>
        );
      }

      if (!isLoadingHistoryNotifications) {
        return (
          <MainContainer ref={parentRef}>
            <InnerContainer style={{ height: `${totalSize}px` }}>
              {/* {wsnotifications.map(element => (
                <NotificationItem onClose={onClose} key={element.id} onDismiss={onWsDismiss} element={element} />
              ))} */}
              {virtualItems.map(({ index, start, measureRef }) => (
                <VirtualNotificationContainer
                  key={notificationItems[index].id}
                  style={{ transform: `translateY(${start}px)` }}
                  ref={measureRef}
                >
                  <NotificationItem
                    onDismiss={notificationItems[index].isWs ? onWsDismiss : onDismiss}
                    element={notificationItems[index]}
                    onClose={onClose}
                  />
                </VirtualNotificationContainer>
              ))}
            </InnerContainer>
          </MainContainer>
        );
      }

      return (
        <main tw="flex-1 flex justify-center items-center">
          <p tw="text-lg text-grey-500">Новых уведомлений нет</p>
        </main>
      );
    };

    return (
      isOpen && (
        <Overlay>
          <Container ref={ref}>
            <header tw="relative height[81px] flex items-center border-b border-grey-300 border-opacity-30">
              <Notification type="button" onClick={onClose}>
                <IconBell tw="text-gray-200" />
              </Notification>
              <h1 tw="text-lg font-bold text-grey-900" onClick={onClose}>
                Уведомления
              </h1>
              <button tw="absolute inset-y-0 right-0 flex items-center p-4 focus:outline-none" type="button" onClick={onClose}>
                <IconClose tw="h-5 w-5 text-grey-300" />
              </button>
            </header>
            <NotificationsList notificationItems={[...wsnotifications, ...historyNotifications]} />
          </Container>
        </Overlay>
      )
    );
  },
);

const VirtualNotificationContainer = tw.div`absolute top-0 left-0 w-full`;
const InnerContainer = tw.div`relative w-full`;
const MainContainer = styled.main`
  ${tw`w-full h-screen px-4 pt-6 overflow-x-hidden overflow-y-scroll`}
  ::-webkit-scrollbar {
    ${tw`width[7px] rounded-lg`}
  }

  ::-webkit-scrollbar-track {
    ${tw`bg-transparent rounded-lg`}
  }

  ::-webkit-scrollbar-thumb {
    ${tw`border-2 border-transparent bg-grey-300 rounded-3xl opacity-20`}
  }
`;

const Container = styled.div`
  ${tw`fixed left[270px] width[325px] backdrop-blur-xl top-0 flex flex-col bottom-0 border-r border-grey-500/20 z-40`}

  background: radial-gradient(100% 4700% at 0% 0%, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.65) 100%);
`;
const Notification = tw.button`padding-left[26px] mr-3 h-screen flex items-center justify-center cursor-pointer hocus:outline-none`;

export default NotificationCenter;
