import React from 'react';
import tw, { styled } from 'twin.macro';
import type { TableProps } from 'antd/lib/table';
import { default as AntdTable } from 'antd/lib/table';
import 'antd/lib/table/style/index.css';
import 'antd/lib/pagination/style/index.css';

const StyledAntdTable = styled(AntdTable)`
  .ant-table-content {
    ${tw`rounded-lg overflow-hidden`}
  }
  .ant-table-thead {
    ${tw`rounded-lg overflow-hidden`}
  }
  .ant-table-thead th.ant-table-column-has-sorters:hover {
    ${tw`bg-grey-900 opacity-95`}
  }
  .ant-table-thead > tr > th {
    ${tw`font-title text-base text-white bg-grey-900 py-6!`}
    ${tw`first:rounded-bl-lg last:rounded-br-lg`}
    ${tw`border-grey-500! border-opacity-10`}
  }
  .ant-pagination-item:hover {
    ${tw`border-primary-light border-opacity-50`}
  }
  .ant-pagination-item:hover a {
    ${tw`text-primary-light text-opacity-50`}
  }
  .ant-pagination-item-active {
    ${tw`border-primary-dark`}
  }
  .ant-pagination-item-active a {
    ${tw`text-primary-dark`}
  }
  .ant-table-container {
    ${tw`border-white!`}
  }
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
    ${tw`border-grey-300 border-opacity-20`}
  }

  .ant-table-row-expand-icon {
    ${tw`bg-primary text-white rounded`}
  }
  .ant-table-selection-col,
  .ant-table-selection-column {
    display: none;
  }
`;

const Table: React.FC<TableProps<any> & React.RefAttributes<unknown>> = StyledAntdTable;

export default Table;
