import React from 'react';
import { CamundaService } from '@asu/services';

export const useCreateTask = () => {
  const [isLoading, setIsLoading] = React.useState(true);

  const postTask = React.useCallback(async body => {
    try {
      setIsLoading(true);
      await CamundaService.postCreateTask(body).fetch();

      setIsLoading(false);
    } catch (error) {
      setIsLoading(true);
      console.error('error >>>', error);
    }
  }, []);

  return { isLoading, postTask };
};
