import React from 'react';
import tw, { styled } from 'twin.macro';
import { observer } from 'mobx-react-lite';

import { IconLoop, IconBlock, IconStarO, IconStar } from '../../assets';
import { ExplorerView, useFileSystemState } from '../../store';
import { HistoryDropdown } from './local-components';
import { Tooltip } from '@asu/ui';

// eslint-disable-next-line @typescript-eslint/ban-types
type MainPageProps = {};

const Header = React.memo<MainPageProps>(() => {
  const store = useFileSystemState();
  return (
    <Container>
      <HeaderWrapper>
        <HistoryDropdown />
        <Title />
      </HeaderWrapper>

      <Toolbar>
        <Tooltip mouseEnterDelay={0.1} placement="left" title={process.env.NX_SYSTEM_TYPE === 'RZD' ? 'Вид папок' : 'Вид файлов'}>
          <IconBlock
            tw="color[#333] opacity-75 cursor-pointer"
            onClick={() => {
              store.view === ExplorerView.Grid ? store.setView(ExplorerView.List) : store.setView(ExplorerView.Grid);
            }}
          />
        </Tooltip>
        {process.env.NX_SYSTEM_TYPE !== 'GGE' && (
          <Tooltip mouseEnterDelay={0.1} placement="top" title="Избранное">
            <FavoriteIcon />
          </Tooltip>
        )}
        {/* <IconLoop tw="color[#333] opacity-75" /> */}
      </Toolbar>
    </Container>
  );
});

const FavoriteIcon = observer(() => {
  const store = useFileSystemState();

  const onClickBtn = () => {
    if (store.favorites[store.projectId]?.some(el => el.id === store.currentFolder.id)) {
      store.removeFromFavorites(store.currentFolder);
    } else {
      store.addToFavorites(store.currentFolder);
    }
  };

  if (!store.currentFolder?.id) {
    return null;
  }

  return (
    <button type="button" tw="transition-opacity hover:opacity-75 focus:outline-none">
      {store.isFavorite ? (
        <IconStar tw="color[#333] h-6 w-6 opacity-75" onClick={onClickBtn} />
      ) : (
        <IconStarO tw="color[#333] h-6 w-6 opacity-75" onClick={onClickBtn} />
      )}
    </button>
  );
});

const Title = observer(() => {
  const store = useFileSystemState();

  return <StyledTitle>{store.currentFolder?.name || 'Неизвестная папка'}</StyledTitle>;
});

const Container = styled.header`
  ${tw`height[85px] absolute backdrop-filter backdrop-blur-xl flex items-end justify-between w-full z-40`}
  background: radial-gradient(100% 4705% at 0% 0%, rgba(255, 255, 255, 0.42) 0%, rgba(255, 255, 255, 0.06) 100%);
`;

const HeaderWrapper = tw.div`mb-2 pl-6 pr-12 overflow-hidden whitespace-nowrap`;
const Toolbar = tw.div`flex ml-auto space-x-4 mb-4 mr-8`;

const StyledTitle = tw.h1`color[#333] font-title font-medium text-xl xl:text-2xl overflow-hidden overflow-ellipsis`;

export default Header;
