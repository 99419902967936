import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import axios from 'axios';
import { LoadingIndicator } from '../assets';
import { keycloak } from '../keycloak';

let axiosAuthInterceptorRequest = null;

const authIntercept = accessToken => request => {
  request.headers.Authorization = 'Bearer ' + accessToken;
  return request;
};

type WithRefreshTokenProps = { children: any };
const WithRefreshToken = React.memo<WithRefreshTokenProps>(({ children }) => {
  const keycloak = useKeycloak();

  React.useLayoutEffect(() => {
    if (axiosAuthInterceptorRequest !== null) axios.interceptors.request.eject(axiosAuthInterceptorRequest);
    axiosAuthInterceptorRequest = axios.interceptors.request.use(authIntercept(keycloak.keycloak.token));
    localStorage.setItem('access_token', keycloak.keycloak.token);

    keycloak.keycloak.onAuthRefreshSuccess = () => {
      if (axiosAuthInterceptorRequest !== null) axios.interceptors.request.eject(axiosAuthInterceptorRequest);
      axiosAuthInterceptorRequest = axios.interceptors.request.use(authIntercept(keycloak.keycloak.token));
      localStorage.setItem('access_token', keycloak.keycloak.token);
    };
  }, [keycloak]);

  return children;
});

type AuthRouterProps = { children: any };
const AuthRouter = React.memo<AuthRouterProps>(({ children }) => {
  const location: any = useLocation();
  const navigate = useNavigate();
  const { keycloak, initialized } = useKeycloak();

  const currentLocationState = location.state;

  React.useEffect(() => {
    if (initialized && !keycloak?.authenticated) keycloak.login();
    if (initialized && keycloak?.authenticated && currentLocationState?.from) navigate(currentLocationState.from);
  }, [initialized, keycloak?.authenticated]);

  if (initialized && keycloak?.authenticated) {
    // return <Navigate to={currentLocationState?.from as string} />;
    return <WithRefreshToken>{children}</WithRefreshToken>;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100vw', overflow: 'hidden' }}>
      <LoadingIndicator style={{ height: '1.5rem', width: '1.5rem', color: '#afafaf' }} />
    </div>
  );
});

type AuthRouterWithAuthProviderProps = { children: any };
const AuthRouterWithAuthProvider = React.memo<AuthRouterWithAuthProviderProps>(props => {
  return (
    <ReactKeycloakProvider authClient={keycloak}>
      <AuthRouter {...props} />
    </ReactKeycloakProvider>
  );
});

export default AuthRouterWithAuthProvider;
