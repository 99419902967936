import React from 'react';
import List from 'antd/lib/list';
import ListItem from 'antd/lib/list/Item';
import { CommentForm } from '..';
import { Comment } from '..';
import tw from 'twin.macro';

import 'antd/lib/list/style/index.css';
import 'antd/lib/empty/style/index.css';

interface CommentExpandProps {
  data: any;
  user: any;
  downloadFile: (e: any) => void;
  update: (e: any) => void;
  onDelete: (e: any) => void;
  deleteFile: (e: any) => void;
  save: (e: any) => void;
}

const CommentExpand: React.FC<CommentExpandProps> = ({ save, data, update, onDelete, deleteFile, user, downloadFile }) => {
  const [showForm, setShowForm] = React.useState(false);

  return (
    <>
      {!showForm ? (
        <div
          onClick={() => setShowForm(true)}
          tw="flex flex-col items-center rounded py-1 px-0 cursor-pointer border-dashed border-2 border-gray-300"
        >
          Добавить комментарий
        </div>
      ) : (
        <CommentForm save={save} update={update} userName={`${user.lastName} ${user.firstName}`} setShow={setShowForm} />
      )}
      <List
        dataSource={data}
        itemLayout="horizontal"
        pagination={{ defaultPageSize: 5, size: 'small' }}
        renderItem={(comment: any) => (
          <ListItem key={comment.commentId}>
            <Comment
              downloadFile={downloadFile}
              deleteFile={deleteFile}
              update={update}
              onDelete={onDelete}
              comment={comment}
              key={comment.commentId}
            />
          </ListItem>
        )}
      />
    </>
  );
};

export default CommentExpand;
