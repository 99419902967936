import 'twin.macro';

type notificationType = {
  title: string;
  body: (element: ElementType) => string | React.ReactElement | any;
  buttonTitle?: string;
  actionUrl?: (element: ElementType) => string;
};

// export type ElementType = {
//   id: string;
//   projectId: string;
//   projectTitle: string;
//   name: string;
//   params: {
//     date: string;
//     parentFolderName: string;
//     notificationTriggerUserName: string;
//     id: string;
//     baseName: string;
//     classifierValueId?: string;
//     oldBaseName?: string;
//   };
//   isNew: boolean;
//   timestamp: number;
//   isDeleted: boolean;
// };

export type ElementType = {
  id: string;
  sourceId: string;
  notificationSourceType: 'PROJECT_ID' | 'WORK_GROUP';
  sourceTitle: string;
  name: string;
  params: any;
  isNew: boolean;
  timestamp: number;
  isDeleted: boolean;
};

export const notificationsList = new Map<string, notificationType>([
  [
    'processing.document.assign.confirmers',
    {
      title: 'Назначение утверждающих на ЗП',
      body: () => 'Назначение утверждающих на ЗП',
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.files.comment_document.delete',
    {
      title: 'Удален комментарий в запросе ИРД',
      body: element => `Необходимо согласовать результаты проведения проверки по проекту «${element.sourceTitle}»`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.files.comment_document.upload',
    {
      title: 'Загружен файл в комментарий в запросе ИРД',
      body: element =>
        `В проект «${element.sourceTitle}» в комментарий к задаче ТПД был загружен файл ${
          element.params.baseName && `«${element.params.baseName}»`
        }`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.files.model.update',
    {
      title: 'Загрузка новой версии IFC модели',
      body: element => `Модель под названием ${element.params.baseName} была загружена/обработана`,
    },
  ],
  [
    'processing.files.model.upload',
    {
      title: 'Загрузка IFC-файла',
      body: element =>
        `В проект «${element.sourceTitle}» в директорию «${element.params.parentFolderName}» загружен IFC-файл «${element.params.baseName}»`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/filesystem`,
    },
  ],
  [
    'processing.files.model_data.upload',
    {
      title: 'Загружена IFC модель',
      body: element => {
        return (
          <p>
            Модель под названием <span tw="font-bold">{element.params.baseName}</span> была загружена/обработана
          </p>
        );
      },
    },
  ],
  [
    'processing.classifier.response.create',
    {
      title: 'Создание ответа на запрос в задаче ИРД',
      body: element => `Создан ответ на запрос данных в задаче ИРД в проекте «${element.sourceTitle}», необходимо подтвердить ответ`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.classifier.request.update',
    {
      title: 'Обновление запроса данных в задаче ИРД',
      body: element => `Обновлен запрос данных в задаче ИРД в проекте «${element.sourceTitle}», необходимо сформировать ответ`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.classifier.request.create',
    {
      title: 'Создание запроса в задаче ИРД',
      body: element => `Создан запрос данных в задаче ИРД в проекте «${element.sourceTitle}», необходимо сформировать ответ`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.classifier.request.delete',
    {
      title: 'Удаление запроса данных',
      body: () => `Удаление запроса данных`,
      buttonTitle: 'Перейти',
      actionUrl: element =>
        element.params.classifierValueId
          ? `/project/${element.sourceId}/process/process-planning/classifier_data/${element.params.classifierValueId}`
          : `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.classifier.request.reject',
    {
      title: 'Отклонение ответа на запрос в задаче ИРД',
      body: element => `Отклонен ответ на запрос данных в задаче ИРД в проекте «${element.sourceTitle}», необходимо внести корректировки`,
      buttonTitle: 'Перейти',
      actionUrl: element =>
        element.params.classifierValueId
          ? `/project/${element.sourceId}/process/process-planning/classifier_data/${element.params.classifierValueId}`
          : `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.classifier.task.approver.approve',
    {
      title: 'Согласование задачи ИРД',
      body: element => `Завершена задача ИРД в проекте «${element.sourceTitle}», необходимо согласовать задачу`,
      buttonTitle: 'Перейти',
      actionUrl: element =>
        element.params.classifierValueId
          ? `/project/${element.sourceId}/process/process-planning/classifier_data/${element.params.classifierValueId}`
          : `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.classifier.task.confirmer.approve',
    {
      title: 'Утверждение задачи ИРД',
      body: element => `Согласована задача ИРД в проекте «${element.sourceTitle}», необходимо утвердить задачу`,
      buttonTitle: 'Перейти',
      actionUrl: element =>
        element.params.classifierValueId
          ? `/project/${element.sourceId}/process/process-planning/classifier_data/${element.params.classifierValueId}`
          : `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.classifier.task.approver.reject',
    {
      title: 'Отклонение задачи согласования ИРД',
      body: element => `Отклонена задача ИРД в проекте «${element.sourceTitle}», необходимо внести изменения`,
      buttonTitle: 'Перейти',
      actionUrl: element =>
        element.params.classifierValueId
          ? `/project/${element.sourceId}/process/process-planning/classifier_data/${element.params.classifierValueId}`
          : `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.classifier.request.approve',
    {
      title: 'Подтверждение запроса данных ИРД',
      body: element => `Вам необходимо утвердить запрос ИРД в проекте ${element.sourceTitle}`,
      buttonTitle: 'Перейти',
      actionUrl: element =>
        element.params.classifierValueId
          ? `/project/${element.sourceId}/process/process-planning/classifier_data/${element.params.classifierValueId}`
          : `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.camunda.task.classifier.data.request.start',
    {
      title: 'Создание задачи ИРД',
      body: element => `Создана задача ИРД в проекте «${element.sourceTitle}», необходимо сформировать запрос данных`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.request.approve',
    {
      title: 'Подтверждение запроса данных ТПД',
      body: element => `Вам необходимо утвердить запрос ТПД в проекте ${element.sourceTitle}`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.request.create',
    {
      title: 'Подтверждение запроса данных ТПД',
      body: element => `Создание запроса данных`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.response.create',
    {
      title: 'Создание ответа на запрос в задаче ИРД',
      body: element => `Создан ответ на запрос данных в задаче ИРД в проекте «${element.sourceTitle}», необходимо подтвердить ответ`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.camunda.task.dictionary.data.request.start',
    {
      title: 'Создание задачи ТПД',
      body: element => `Создана задача ТПД в проекте «${element.sourceTitle}», необходимо сформировать строительный объект`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.classifier.data.request.assign.approvers',
    {
      title: 'Согласование ИРД',
      body: () => `Вам необходимо согласовать запрос ИРД`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.data.request.assign.approvers',
    {
      title: 'Согласование ТПД',
      body: element => `Вам необходимо согласовать запрос ТПД в проекте`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.document.assign.approvers',
    {
      title: 'Назначение согласующих на ЗП',
      body: () => `Назначение согласующих на ЗП`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.calc.calendar.assign.approvers',
    {
      title: 'Назначение согласующих на КП ПИР',
      body: () => `Назначение согласующих на КП ПИР`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.unknown.assign.confirmers',
    {
      title: 'Назначение утверждающих на задачу неизвестного типа',
      body: () => `Назначение утверждающих на задачу неизвестного типа`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.classifier.data.request.assign.confirmers',
    {
      title: 'Утверждение ИРД',
      body: () => `Вам необходимо утвердить запрос ИРД`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.data.request.assign.confirmers',
    {
      title: 'Утверждение ТПД',
      body: () => `Вам необходимо утвердить запрос ТПД`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.document.assign.confirmers',
    {
      title: 'Назначение утверждающих на ЗП',
      body: () => `Назначение утверждающих на ЗП`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.calc.cost.assign.approvers',
    {
      title: 'Назначение согласующих на Смета ПИР',
      body: element => `Вам необходимо согласовать смету на ПИР в проекте ${element.sourceTitle}`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.calc.cost.assign.confirmers',
    {
      title: 'Назначение утверждающих на Смета ПИР',
      body: () => `Назначение утверждающих на Смета ПИР`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.calc.calendar.assign.confirmers',
    {
      title: 'Назначение утверждающих на КП ПИР',
      body: () => ``,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.models.conversion.done',
    {
      title: 'Конвертация IFC-файла',
      body: element =>
        `В проекте «${element.sourceTitle}» завершена конвертация файла модели «${element.params.baseName}». Директория «${element.params.parentFolderName}»`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/filesystem`,
    },
  ],
  [
    'processing.files.model.rename',
    {
      title: 'Переименование IFC-файла',
      body: element =>
        `В проекте «${element.sourceTitle}» в директории «${element.params.parentFolderName}» был переименован IFC-файл с «${element.params.oldBaseName}» на «${element.params.baseName}»`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/filesystem`,
    },
  ],
  [
    'processing.files.model.update',
    {
      title: 'Обновление IFC-файла',
      body: element =>
        `В проекте «${element.sourceTitle}» в директории «${element.params.parentFolderName}» обновлен IFC-файл «${element.params.baseName}». Доступна новая версия`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/filesystem`,
    },
  ],
  [
    'processing.files.model.delete',
    {
      title: 'Удаление IFC-файла',
      body: element =>
        `В проекте «${element.sourceTitle}» из директории «${element.params.parentFolderName}» удалён IFC-файл "${element.params.baseName}"`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/filesystem`,
    },
  ],
  [
    'processing.camunda.markup.task.complete',
    {
      title: 'Завершение задачи из замечания к BIM-модели',
      body: element => `Завершена задача из замечания к BIM-модели в проекте «${element.sourceTitle}» .`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.camunda.markup.task.update',
    {
      title: 'Обновление задачи из замечания к BIM-модели',
      body: element => `Обновлена задача из замечания к BIM-модели в проекте «${element.sourceTitle}», необходимо исправить замечания`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.camunda.markup.sub_task.create',
    {
      title: 'Подзадача создана',
      body: () => `Требуется внесение изменений`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.camunda.markup.task.create',
    {
      title: 'Создание задачи из замечания к BIM-модели',
      body: element => `Создана задача из замечания к BIM-модели в проекте «${element.sourceTitle}», необходимо исправить замечания`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.files.image.upload',
    {
      title: 'Изображение загружено',
      body: element => `Картинка под названием «${element.params.baseName}» была загружена`,
    },
  ],
  [
    'processing.dictionary.building_object.delete',
    {
      title: 'Удаление строительного объекта в задаче ТПД',
      body: element => `Удален строительный объект в задаче ТПД в проекте «${element.sourceTitle}», необходимо внести новые данные`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.building.management.properties.without.photo',
    {
      title: `Нет фотофиксации`,
      body: element => `В проекте «${element.sourceTitle}» в задаче проверки «${element.params.taskName}» отсутствует фотофиксация объекта`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.building.management.expertise.need.approve',
    {
      title: 'Проведена проверка',
      body: element => `Необходимо согласовать результаты проведения проверки по проекту «${element.sourceTitle}»`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.building_object.update',
    {
      title: 'Обновление строительного объекта в задаче ТПД',
      body: element => `Обновлен строительный объект в задаче ТПД в проекте «${element.sourceTitle}», необходимо подтвердить новые данные`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.building_object.create',
    {
      title: 'Подтверждение строительного объекта в задаче ТПД',
      body: element => `Создан строительный объект в задаче ТПД в проекте «${element.sourceTitle}», необходимо подтвердить`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.files.file.rename',
    {
      title: 'Переименование файла',
      body: element =>
        `В проекте «${element.sourceTitle}» в директории «${element.params.parentFolderName}» был переименован файл с «${element.params.oldBaseName}» на «${element.params.baseName}»`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/filesystem`,
    },
  ],
  [
    'processing.files.file.move',
    {
      title: 'Перемещение файла',
      body: element => `Файл ${element.params[0]} был перемещен`,
    },
  ],
  [
    'processing.files.file.update',
    {
      title: 'Обновление файла',
      body: element =>
        `В проекте «${element.sourceTitle}» в директории «${element.params.parentFolderName}» обновлён файл «${element.params.baseName}». Доступна новая версия`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/filesystem`,
    },
  ],
  [
    'processing.files.file.upload',
    {
      title: 'Загрузка файла',
      body: element =>
        `В проект «${element.sourceTitle}» в директорию «${element.params.parentFolderName}» загружен файл «${element.params.baseName}»`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/filesystem`,
    },
  ],
  [
    'processing.files.file.upload.dictionary-calc-processing-service-cost',
    {
      title: 'Загрузка файла в задачу Смета ПИР',
      body: element => `В проекте «${element.sourceTitle}», в задачу Смета ПИР был загружен файл «${element.params.baseName}»`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.files.file.update.dictionary-calc-processing-service-cost',
    {
      title: 'Обновление файла в задаче Смета ПИР',
      body: element => `В проекте «${element.sourceTitle}», в задаче Смета ПИР был обновлён файл «${element.params.baseName}»`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.calendar.task.file.unpin',
    {
      title: 'Удалён файл из задачи КП ПИР',
      body: element => `В проекте «${element.sourceTitle}», в задаче КП ПИР был удалён файл «${element.params.baseName}»`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.data.request.task.reject',
    {
      title: 'Отклонение согласования задачи ТПД',
      body: element => `Отклонена задача ТПД в проекте «${element.sourceTitle}», необходимо внести изменения`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.cost.task.reject',
    {
      title: 'Отклонение согласования задачи Смета ПИР',
      body: element => `Отклонена задача Смета ПИР в проекте «${element.sourceTitle}», необходимо внести изменения`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.document.task.reject',
    {
      title: 'Отклонение согласования задачи ЗП',
      body: element => `Отклонена задача ЗП в проекте «${element.sourceTitle}», необходимо внести изменения`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.document.task.approver.approve',
    {
      title: 'Согласование задачи ЗП',
      body: element => `Завершена задача ЗП в проекте «${element.sourceTitle}», необходимо согласовать задачу`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.calendar.confirmer.approve',
    {
      title: 'Утверждение задачи КП ПИР',
      body: element => `Согласована задача КП ПИР в проекте «${element.sourceTitle}», необходимо утвердить задачу`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.document.task.confirmer.approve',
    {
      title: 'Утверждение задачи ЗП',
      body: element => `Согласована задача ЗП в проекте «${element.sourceTitle}», необходимо утвердить задачу`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.document.task.confirmer.approve',
    {
      title: 'Утверждение задачи ЗП',
      body: element => `Согласована задача ЗП в проекте «${element.sourceTitle}», необходимо утвердить задачу`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.camunda.task.dictionary.calc.cost.start',
    {
      title: 'Создана задача Смета ПИР',
      body: element => `Создана задача Смета ПИР в проекте «${element.sourceTitle}», необходимо внести изменения`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.document.task.approver.approve',
    {
      title: 'Согласование задачи ЗП',
      body: element => `Завершена задача ЗП в проекте «${element.sourceTitle}», необходимо согласовать задачу`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.calendar.task.reject',
    {
      title: 'Отклонение согласования задачи КП ПИР',
      body: element => `Отклонена задача КП ПИР в проекте «${element.sourceTitle}», необходимо внести изменения`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.files.file.upload.dictionary-calc-processing-service-calendar',
    {
      title: 'Загрузка файла в задачу КП ПИР',
      body: element => `В проекте «${element.sourceTitle}», в задачу КП ПИР был загружен файл «${element.params.baseName}»`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.files.file.update.dictionary-calc-processing-service-calendar',
    {
      title: 'Обновление файла в задаче КП ПИР',
      body: element => `В проекте «${element.sourceTitle}», в задаче КП ПИР был обновлён файл «${element.params.baseName}»`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.cost.task.file.unpin',
    {
      title: 'Удалён файл из задачи Смета ПИР',
      body: element => `В проекте «${element.sourceTitle}», в задаче Смета ПИР был удалён файл «${element.params.baseName}»`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.cost.approver.approve',
    {
      title: 'Согласование задачи Смета ПИР',
      body: element => `Завершена задача Смета ПИР в проекте «${element.sourceTitle}», необходимо согласовать задачу`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/dashboard`,
    },
  ],
  [
    'processing.dictionary.cost.confirmer.approve',
    {
      title: 'Утверждение задачи Смета ПИР',
      body: element => `Согласована задача Смета ПИР в проекте «${element.sourceTitle}», необходимо утвердить задачу`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/dashboard`,
    },
  ],
  [
    'processing.camunda.task.dictionary.calc.calendar.start',
    {
      title: 'Создана задача КП ПИР',
      body: element => `Создана задача КП ПИР в проекте «${element.sourceTitle}», необходимо внести изменения.`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/dashboard`,
    },
  ],
  [
    'processing.files.file.delete',
    {
      title: 'Удаление файла',
      body: element =>
        `В проекте «${element.sourceTitle}» из директории «${element.params.parentFolderName}» был удалён файл «${element.params.baseName}»`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/filesystem`,
    },
  ],
  [
    'processing.files.directory.create_project_template',
    {
      title: 'Создание директории по шаблону',
      body: element => `Папка ${element.params[0]} была создана по шаблону`,
    },
  ],
  [
    'processing.files.directory.create',
    {
      title: 'Создание директории',
      body: element => `В проекте «${element.sourceTitle}» создана директория «${element.params.baseName}»`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/filesystem`,
    },
  ],
  [
    'processing.files.directory.rename',
    {
      title: 'Переименование директории',
      body: element =>
        `В проекте «${element.sourceTitle}» директория «${element.params.oldBaseName}» была переименована в «${element.params.baseName}»`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/filesystem`,
    },
  ],
  [
    'processing.files.directory.delete',
    {
      title: 'Удаление директории',
      body: element => `В проекте «${element.sourceTitle}» удалена директория «${element.params.baseName}»`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/filesystem`,
    },
  ],
  [
    'processing.files.building_management_document.upload',
    {
      title: 'Загрузка файла КС',
      body: element => `В задаче КС был загружен файл «${element.params.baseName}»`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.files.building_management_document.image.upload',
    {
      title: 'Загрузка фотографии КС',
      body: element => `В задаче КС была загружена фотография «${element.params.baseName}»`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.building.measure.create',
    {
      title: 'Загрузка результатов измерения',
      body: element =>
        `В проекте «${element.sourceTitle}» в проверку задачи строительства загружены результаты приборов строительного контроля`,
      buttonTitle: 'Перейти',
      actionUrl: element => `project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.building.management.task.list.create',
    {
      title: 'Загрузка графика производства работ',
      body: () => `В задаче КС был загружен график производства работ`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.building.management.task.dates.update',
    {
      title: 'Изменение срока выполнения задачи КС',
      body: () => `В задаче был изменён срок выполнения задачи стройки`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.building.management.task.control.done',
    {
      title: 'Согласование результатов проведения проверки',
      body: element => `Проведена проверка в проекте «${element.sourceTitle}», необходимо согласовать результаты`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.building.management.task.control.reject',
    {
      title: 'Отклонение результатов проведения проверки',
      body: element => `Проведена проверка в проекте «${element.sourceTitle}», необходимо согласовать результаты`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.building.management.task.building.fix.create',
    {
      title: 'Сформирована задача на исправление замечаний',
      body: element => `Проведена проверка в проекте «${element.sourceTitle}», необходимо выполнить исправление замечаний`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.building.management.task.control.duplicate.create',
    {
      title: 'Повторная инспекция',
      body: element => `Проведена проверка в проекте «${element.sourceTitle}», необходимо провести повторную инспекцию`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.camunda.task.building.management.start',
    {
      title: 'Создана задача КС',
      body: element => `Создана задача КС в проекте «${element.sourceTitle}», необходимо внести изменения`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.camunda.task.document.start',
    {
      title: 'Создание задачи ЗП',
      body: element => `Создана задача ЗП в проекте «${element.sourceTitle}», необходимо внести данные`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.calendar.approver.approve',
    {
      title: 'Согласование задачи КП ПИР',
      body: element => `Завершена задача КП ПИР в проекте «${element.sourceTitle}», необходимо согласовать задачу`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.data.request.approver.approve',
    {
      title: 'Согласование задачи ТПД',
      body: element => `Завершена задача ТПД в проекте «${element.sourceTitle}», необходимо согласовать задачу`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.data.request.confirmer.approve',
    {
      title: 'Утверждение задачи ТПД',
      body: element => `Завершена задача ТПД в проекте «${element.sourceTitle}», необходимо утвердить задачу`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.data.request.task.conformer.reject',
    {
      title: 'Отклонение утверждения задачи ТПД',
      body: element => `Отклонена задача ТПД в проекте «${element.sourceTitle}», необходимо внести изменения`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.classifier.task.confirmer.reject',
    {
      title: 'Отклонение утверждения задачи ИРД',
      body: element => `Отклонена задача ИРД в проекте «${element.sourceTitle}», необходимо внести изменения`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.document.task.confirmer.reject',
    {
      title: 'Отклонение утверждения задачи ЗП',
      body: element => `Отклонена задача ЗП в проекте «${element.sourceTitle}», необходимо внести изменения`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.cost.task.confirmer.reject',
    {
      title: 'Отклонение утверждения задачи Смета ПИР',
      body: element => `Отклонена задача ЗП в проекте «${element.sourceTitle}», необходимо внести изменения`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.dictionary.calendar.task.confirmer.reject',
    {
      title: 'Отклонение утверждения задачи КП ПИР',
      body: element => `Отклонена задача КП ПИР в проекте «${element.sourceTitle}», необходимо внести изменения`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
  [
    'processing.files.building_management_document.measurement.result.upload',
    {
      title: 'Загрузка результатов измерения',
      body: element => `В проекте «${element.sourceTitle}» в задаче КС были загружены результаты измерений`,
      buttonTitle: 'Перейти',
      actionUrl: element => `/project/${element.sourceId}/tasks`,
    },
  ],
]);
