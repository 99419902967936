// import { IClassifier; IClassifierdrValue } from '@asu/services';
interface ITabFileId {
  fileId: string;
}

export interface ITabResponse {
  id: string;
  createDate: string;
  approveStatus: string;
  text: string;
  author: string;
  rejectClassifierValueId: string;
  attachment: ITabFileId[];
}

export interface ITabRequest {
  id: string;
  classifierValueId: string;
  authorId: string;
  assignedUserId: string;
  createDate: string;
  estimateDate: string | number;
  note: string;
  executionStatus: string;
  responses: ITabResponse[];
}

interface IDataRequestFacade {
  id: string;
  classifierValueId: string;
  authorId: string;
  assignedUserId: string;
  estimateDate: string | number;
  note: string;
  responses?: ITabResponse[];
}

export interface ITabFacade {
  id: string;
  projectId: string;
  classifierId: string;
  name: string;
  taskDefId: string;
  requests?: ITabRequest[];
}

interface ITabFacadeBody {
  projectId: string;
  taskDefId: string;
}

interface ICreateRequestBody extends ITabFacadeBody {
  tabId?: string;
}

interface IRejectResponseBody {
  responseId: string;
  authorId: string;
  rejectClassifierValueId: string;
}

interface IClassifierdrValue {
  id?: string;
  name?: string;
  note?: string;
}

interface ITabTemplate {
  id: string;
  name: string;
  classifierId: string;
}

interface ITaskTabTemplate {
  id: string;
  name: string;
  tabs: ITabTemplate[];
}

export declare namespace IClassifierdrService {
  type ICreateTab = ITabFacade;
  type IGetTabs = ITabFacade[];
  type ICreateRequest = ITabFacade;
  type IUpdateRequest = ITabFacade;
  type ICreateResponse = ITabResponse;
  type IGetClassifierdrValues = IClassifierdrValue[];
  type IGetTemplates = ITaskTabTemplate[];

  declare namespace Body {
    type ICreateTab = ITabFacade;
    type ICreateRequest = IDataRequestFacade;
    type IUpdateRequest = IDataRequestFacade;
    type IRejectResponse = IRejectResponseBody;
    type IApproveResponse = ITabFacadeBody;
  }
}
