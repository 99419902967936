import React from 'react';
import { AuthService, IAuthService } from '@asu/services';

export const useUsers = () => {
  const [users, setUsers] = React.useState<IAuthService.IGetUserResponse[] | null>(null);

  const getUsers = React.useCallback(
    async id => {
      try {
        const request = await AuthService.getUser(id).fetch();
        const response = request.data;
        setUsers([...users, response]);
      } catch (error) {
        console.error('getUsers >>>', error);
      }
    },
    [users],
  );

  // React.useEffect(() => {
  //   getUsers(id);
  // }, [getUsers, id]);

  return { users, getUsers };
};
