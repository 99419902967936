import { FileAddOutlined, FileOutlined, LoadingOutlined } from '@ant-design/icons';
import Upload from 'antd/lib/upload';
import { Button } from '../Button';
import { UploadProps, RcFile } from 'antd/lib/upload';

type Props = {
  disabled: boolean;
  loading: boolean;
  fileList: UploadProps['fileList'];
  customRequest: UploadProps['customRequest'];
  setFileList: (files: RcFile[]) => void;
  size: any;
  title?: string;
  showButton: boolean;
  showMessage: (type: string, text: string) => void;
};

const UploadButton: React.FC<Props> = ({
  disabled,
  loading = false,
  fileList,
  setFileList,
  size = 'middle',
  title,
  showButton = true,
  showMessage,
  customRequest,
}) => {
  const props: Partial<UploadProps> = {
    onRemove: () => setFileList([]),
    beforeUpload: file => {
      if (file.size === 0) {
        showMessage('info', 'Размер файла не может быть 0 байт');
        return false;
      }
      setFileList([file]);
      return true;
    },
    // customRequest: () => dispatch(request({ ...args, file: fileList[0] })),
    customRequest,
    fileList,
    iconRender: () => (loading ? <LoadingOutlined /> : <FileOutlined />),
    showUploadList: false,
  };

  return (
    <Upload {...props}>
      {showButton ? (
        <Button disabled={disabled} icon={<FileAddOutlined />}>
          {title ?? 'Загрузить файл'}
        </Button>
      ) : (
        title ?? 'Загрузить файл'
      )}
    </Upload>
  );
};

export default UploadButton;
