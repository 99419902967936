import React from 'react';
import { Button, Modal, useManualModalState } from '@asu/ui';
import tw from 'twin.macro';
import { useFileSystemState } from '../../../store';
import { useAuthState } from '@asu/auth';

interface AddModalDeleteFilesProps {
  deleteIdsArray: string[];
}

interface ModalDeleteFileProps extends AddModalDeleteFilesProps {
  isOpen: boolean;
  setIsOpen: any;
  openModal: () => void;
  closeModal: () => void;
}

const ModalDeleteSomeFiles = React.memo((props: ModalDeleteFileProps) => {
  const { deleteIdsArray } = props;
  return (
    <Modal.Root manual {...props}>
      <AddModalDeleteFile deleteIdsArray={deleteIdsArray} />
    </Modal.Root>
  );
});

const AddModalDeleteFile = React.memo(({ deleteIdsArray }: AddModalDeleteFilesProps) => {
  const store = useFileSystemState();
  const authStore = useAuthState();
  const { closeModal } = useManualModalState();

  const onConfirm = () => {
    deleteIdsArray.forEach(id => {
      if (id) {
        store.deleteCheckedFiles(authStore.currentUser.id);
      }
    });
    closeModal();
  };

  return (
    <Modal.Content tw="w-[35vw] min-w-[350px]">
      <Modal.Title>Удаление нескольких файлов</Modal.Title>
      <div tw="px-5">
        <h2 tw="mt-2">Вы уверены, что хотите безвозвратно удалить выбранные файлы?</h2>
        <div tw="my-2">
          <Button type="button" onClick={onConfirm}>
            Удалить файлы
          </Button>
          <Button tw="mt-2 bg-gray-400 hover:bg-gray-500" type="button" onClick={() => closeModal()}>
            Отменить
          </Button>
        </div>
      </div>
    </Modal.Content>
  );
});

export default ModalDeleteSomeFiles;
