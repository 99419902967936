import React from 'react';
import tw, { styled } from 'twin.macro';
import { useCamunda } from '../hooks';
import TaskItem from './TaskItem';

type ToolbarTaskListProps = {
  params: any;
};

const ToolbarTaskList = React.memo<ToolbarTaskListProps>(({ params }) => {
  const [isLoadingTasks, tasks] = useCamunda(params.projectId);

  const filteredTasksList = React.useMemo(() => {
    return !isLoadingTasks && tasks.filter(task => task.name === 'Контроль строительства');
  }, [tasks, isLoadingTasks]);

  if (isLoadingTasks) {
    return (
      <Scrollview>
        <Container>
          {Array.from(Array(5), (_, i) => (
            <div key={i} tw="h-7 w-48 bg-grey-300 bg-opacity-30 rounded animate-pulse mr-4" />
          ))}
        </Container>
      </Scrollview>
    );
  }

  return (
    <Scrollview>
      <Container tw="space-x-4">
        {filteredTasksList.map(task => (
          <TaskItem key={task.id} task={task} small={true} params={params} />
        ))}
      </Container>
    </Scrollview>
  );
});

const Scrollview = tw.section`relative h-full w-full border-b border-grey-100`;
const Container = styled.div`
  ${tw`absolute inset-0 h-full w-full flex items-center overflow-x-auto pr-6`}

  ::-webkit-scrollbar {
    ${tw`h-1 w-1 rounded-lg`}
  }

  ::-webkit-scrollbar-track {
    ${tw`bg-white rounded-lg`}
  }

  ::-webkit-scrollbar-thumb {
    ${tw`bg-grey-300 rounded-full border-2 border-white opacity-20`}
  }
`;

export default ToolbarTaskList;
