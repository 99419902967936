class RoleMatrixInfo {
  static ProjectRolesTitles = {
    READ_TASK_CLASSIFIER_DATA: 'Просмотр данных ИРД',
    DONE_TASK_CLASSIFIER_DATA: 'Завершение задачи ИРД',
    APPROVE_TASK_CLASSIFIER_DATA: 'Согласование задачи ИРД',
    CONFIRM_TASK_CLASSIFIER_DATA: 'Утверждение задачи ИРД',
    CREATE_TAB: 'Создание вкладки в ИРД',
    CREATE_AND_APPROVE_DATA_REQUEST: 'Создание запроса данных и подтверждение ответов на запросы данных',
    DELETE_DATA_REQUEST: 'Удаление запросов данных',
    UPDATE_DATA_REQUEST: 'Обновление запросов данных',
    CREATE_DATA_RESPONSE: 'Создание ответов на запрос данных',
    READ_TASK_DICTIONARY_DATA: 'Просмотр данных ТПД',
    DONE_TASK_DICTIONARY_DATA: 'Завершение задачи ТПД',
    APPROVE_TASK_DICTIONARY_DATA: 'Согласование задачи ТПД',
    CONFIRM_TASK_DICTIONARY_DATA: 'Утверждение задачи ТПД',
    CREATE_BUILDING_OBJECT: 'Добавление строительного объекта',
    UPDATE_BUILDING_OBJECT: 'Обновление строительного объекта',
    DELETE_BUILDING_OBJECT: 'Удаление строительного объекта',
    APPROVE_BUILDING_OBJECT: 'Подтверждение строительного объекта',
    READ_TASK_DOCUMENT: 'Просмотр данных в задаче Задание на проектирование',
    DONE_TASK_DOCUMENT: 'Завершение задачи Задание на проектирование',
    APPROVE_TASK_DOCUMENT: 'Согласование задачи Задание на проектирование',
    CONFIRM_TASK_DOCUMENT: 'Утверждение задачи Задание на проектирование',
    CREATE_ATTRIBUTE_DATA: 'Добавление предложения на заполнение строки или подстроки в документе',
    APPROVE_ATTRIBUTE_DATA: 'Подтверждение предложения заполнения строки или подстроки в документе',
    READ_TASK_COST: 'Просмотр данных Сметы',
    DONE_TASK_COST: 'Завершение задачи Сметы',
    APPROVE_TASK_COST: 'Согласование задачи Сметы',
    CONFIRM_TASK_COST: 'Утверждение задачи Сметы',
    UPDATE_COST: 'Обновить данные в Смете',
    DELETE_COST: 'Удалить данные в Смете',
    READ_TASK_CALENDAR: 'Просмотр данных Календарного плана',
    DONE_TASK_CALENDAR: 'Завершение задачи Календарного плана',
    APPROVE_TASK_CALENDAR: 'Согласование задачи Календарного плана',
    CONFIRM_TASK_CALENDAR: 'Утверждение Календарного плана',
    UPDATE_CALENDAR: 'Обновить данные в Календарном плане',
    DELETE_CALENDAR: 'Удалить данные в Календарном плане',
    READ_TASK_BUILDING_MANAGEMENT: 'Просмотр данных задачи Контроля строительства',
    DONE_TASK_BUILDING_MANAGEMENT: 'Завершение задачи КС',
    APPROVE_TASK_BUILDING_MANAGEMENT: 'Согласование задачи КС',
    CONFIRM_TASK_BUILDING_MANAGEMENT: 'Утверждение задачи КС',
    SET_MEASURE_RESULT: 'Загрузка результатов измерений',
    SET_MEASURE_RESULT_COORDS: 'Изменение координат результатов измерений',
    CREATE_TASK: 'Создание задачи строительства (в т.ч. дочерних задач)',
    UPDATE_TASK: 'Обновление информации в задачах строительства',
    REQUEST_TASK_APPROVE: 'Завершение задачи строительства с дальнейшим переходом к задаче экспертизы',
    APPROVE_EXPERT: 'Согласование/Отклонение задачи экспертизы',
    UPDATE_EXPERT_TASK: 'Обновление задачи инспекции',
    CREATE_EXPERT_SOLUTION_ELEMENT: 'Добавление данных в задачах инспекции',
    UPDATE_EXPERT_SOLUTION_ELEMENT: 'Обновление данных в задачах инспекции',
    DELETE_EXPERT_SOLUTION_ELEMENT: 'Удаление данных в задачах инспекции',
  };

  static ServicesTitles = {
    CLASSIFIER_DATA: 'ИРД',
    DICTIONARY_DATA: 'ТПД',
    DICTIONARY_CALC_COST: 'Смета',
    DICTIONARY_CALC_CALENDAR: 'Календарный план',
    BUILDING_MANAGEMENT: 'Контроль строительства (КС)',
    COMMENT_SERVICE: 'Сервис комментариев',
  };

  static HideRolesInMatrix = {
    DOWNLOAD_IFC: true,
    READ_CLIPPING: true,
    MODIFY_CLIPPING: true,
    READ_IOT: true,
    MODIFY_IOT: true,
    READ_CLASSIFIER_DATA_REQUEST_ENTITY_TAB_COMMENT: true,
    CREATE_CLASSIFIER_DATA_REQUEST_ENTITY_TAB_COMMENT: true,
    UPDATE_CLASSIFIER_DATA_REQUEST_ENTITY_TAB_COMMENT: true,
    DELETE_CLASSIFIER_DATA_REQUEST_ENTITY_TAB_COMMENT: true,
    READ_CLASSIFIER_DATA_REQUEST_ENTITY_REQUEST_COMMENT: true,
    CREATE_CLASSIFIER_DATA_REQUEST_ENTITY_REQUEST_COMMENT: true,
    UPDATE_CLASSIFIER_DATA_REQUEST_ENTITY_REQUEST_COMMENT: true,
    DELETE_CLASSIFIER_DATA_REQUEST_ENTITY_REQUEST_COMMENT: true,
    READ_CLASSIFIER_DATA_REQUEST_ENTITY_RESPONSE_COMMENT: true,
    CREATE_CLASSIFIER_DATA_REQUEST_ENTITY_RESPONSE_COMMENT: true,
    UPDATE_CLASSIFIER_DATA_REQUEST_ENTITY_RESPONSE_COMMENT: true,
    DELETE_CLASSIFIER_DATA_REQUEST_ENTITY_RESPONSE_COMMENT: true,
    READ_DICTIONARY_DATA_REQUEST_ENTITY_REQUEST_COMMENT: true,
    CREATE_DICTIONARY_DATA_REQUEST_ENTITY_REQUEST_COMMENT: true,
    UPDATE_DICTIONARY_DATA_REQUEST_ENTITY_REQUEST_COMMENT: true,
    DELETE_DICTIONARY_DATA_REQUEST_ENTITY_REQUEST_COMMENT: true,
    READ_DICTIONARY_DATA_REQUEST_ENTITY_BUILDING_OBJECT_COMMENT: true,
    CREATE_DICTIONARY_DATA_REQUEST_ENTITY_BUILDING_OBJECT_COMMENT: true,
    UPDATE_DICTIONARY_DATA_REQUEST_ENTITY_BUILDING_OBJECT_COMMENT: true,
    DELETE_DICTIONARY_DATA_REQUEST_ENTITY_BUILDING_OBJECT_COMMENT: true,
    READ_DOCUMENT_ATTRIBUTE_COMMENT: true,
    CREATE_DOCUMENT_ATTRIBUTE_COMMENT: true,
    UPDATE_DOCUMENT_ATTRIBUTE_COMMENT: true,
    DELETE_DOCUMENT_ATTRIBUTE_COMMENT: true,
    READ_DOCUMENT_ATTRIBUTE_DATA_COMMENT: true,
    CREATE_DOCUMENT_ATTRIBUTE_DATA_COMMENT: true,
    UPDATE_DOCUMENT_ATTRIBUTE_DATA_COMMENT: true,
    DELETE_DOCUMENT_ATTRIBUTE_DATA_COMMENT: true,
    READ_MARKUP_TASK_ENTITY_COMMENT: true,
    CREATE_MARKUP_TASK_ENTITY_COMMENT: true,
    UPDATE_MARKUP_TASK_ENTITY_COMMENT: true,
    DELETE_MARKUP_TASK_ENTITY_COMMENT: true,
    READ_BUILDING_MANAGEMENT_TASK_COMMENT: true,
    CREATE_BUILDING_MANAGEMENT_TASK_COMMENT: true,
    UPDATE_BUILDING_MANAGEMENT_TASK_COMMENT: true,
    DELETE_BUILDING_MANAGEMENT_TASK_COMMENT: true,
    READ_BUILDING_MANAGEMENT_TASK_EXPERT_COMMENT: true,
    CREATE_BUILDING_MANAGEMENT_TASK_EXPERT_COMMENT: true,
    UPDATE_BUILDING_MANAGEMENT_TASK_EXPERT_COMMENT: true,
    DELETE_BUILDING_MANAGEMENT_TASK_EXPERT_COMMENT: true,
  };

  public static getProjectRolesTitles = role => {
    const result = RoleMatrixInfo.ProjectRolesTitles[role];
    if (result) return result;
    return role;
  };

  public static getServicesTitles = role => {
    const result = RoleMatrixInfo.ServicesTitles[role];
    if (result) return result;
    return role;
  };

  public static isNeedHide = role => {
    const result = RoleMatrixInfo.HideRolesInMatrix[role];
    return !!result;
  };
}

export default RoleMatrixInfo;
