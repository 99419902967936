import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Mentions, FileListWrap } from '..';
import { Button, Theme } from '@asu/ui';
import tw from 'twin.macro';
import Avatar from 'antd/lib/avatar';
import Upload from 'antd/lib/upload';
import Comment from 'antd/lib/comment';
import 'antd/lib/comment/style/index.css';
import 'antd/lib/upload/style/index.css';

type Inputs = {
  comment?: any;
  setShow?: any;
  userAvatarUrl?: string;
  userName?: string;
  save?: (e: any) => void;
  update?: (e: any) => void;
};

const CommentForm: React.FC<Inputs> = ({ setShow, comment, userName, userAvatarUrl, save, update }) => {
  const { handleSubmit, reset, control, setValue } = useForm<Inputs>({
    mode: 'onBlur',
  });
  const [fileList, setFileList] = React.useState([]);

  React.useEffect(() => {
    if (!comment) return;
    setValue('comment', comment.text);
  }, [setValue, comment]);

  const onSubmit = form => {
    comment ? update({ id: comment.id, text: form.comment }) : save({ text: form.comment, fileList });
    setShow(false);
    setValue('comment', '');
    reset();
  };

  const uploadProps = {
    onRemove: () => setFileList([]),
    beforeUpload: file => {
      if (file.size === 0) {
        return false;
      }
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
    showUploadList: false,
    showList: 0,
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Comment
        actions={[
          <Button theme={Theme.Dark} type="submit" key={1} tw="mr-3">
            {comment ? 'Изменить комментарий' : 'Добавить комментарий'}
          </Button>,
          <Upload key={2} {...uploadProps}>
            {!comment && (
              <Button theme={Theme.Dark} tw="ml-3" onClick={e => e.preventDefault()}>
                Добавить документ
              </Button>
            )}
          </Upload>,
          <Button theme={Theme.Dark} tw="ml-3" key={3} onClick={() => setShow(false)}>
            Отмена
          </Button>,
        ]}
        author={<p tw="text-black">{userName}</p>}
        avatar={<Avatar alt="изображение пользователя" src={userAvatarUrl} />}
        content={
          <div tw="flex flex-col">
            <Controller
              render={() => <Mentions defaultValue={comment?.text} setValue={setValue} usersList={[]} />}
              control={control}
              name="comment"
              rules={{
                required: {
                  value: true,
                  message: 'Обязательное поле',
                },
              }}
            />
            <FileListWrap fileList={fileList} />
          </div>
        }
        datetime={<span>{new Date().toLocaleString()}</span>}
      />
    </form>
  );
};

export default CommentForm;
