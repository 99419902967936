import { qParam } from './Service.d';
import { Service } from './Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import type { IDictionaryService } from './DictionaryService.d';
export * from './DictionaryService.d';

const BASE_URL = '/dictionarydr';
const CUSTOM_BASE_URL = '/dictionaryc';

export class DictionaryService implements Service {
  public static getDictionaries(config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IDictionaryService.IGetDictionaries>('/dictionary', config);
  }

  public static getDictionary(id: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IDictionaryService.IGetDictionary>(`/dictionary/${id}`, config);
  }

  public static checkBorder(data: IDictionaryService.Body.ICheckBorder, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<boolean>(`/dictionary/checkBorder`, data, config);
  }

  public static rejectTask(taskId: string, body: any, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<null>(
      `/tasks/${taskId}/reject`,
      {
        id: taskId,
        coreReject: {
          message: body.message,
        },
      },
      config,
    );
  }
  public static approveTask(taskId: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<boolean>(
      `/tasks/${taskId}/approve`,
      {
        id: taskId,
        coreApprove: {
          message: 'string',
        },
      },
      config,
    );
  }

  public static postSmetaFile(
    projectId: qParam,
    taskDefId: qParam,
    bodySmeta: IDictionaryService.Body.IPostSmetaFile,
    config?: AxiosRequestConfig,
  ) {
    return new ApiCaller(CUSTOM_BASE_URL).post<IDictionaryService.IPostSmetaFile>(
      `/dictionary/cost_estimate/project/${projectId}/task/${taskDefId}`,
      bodySmeta,
      config,
    );
  }

  public static patchSmetaFile(
    projectId: qParam,
    taskDefId: qParam,
    bodySmeta: IDictionaryService.Body.IPatchSmetaFile,
    config?: AxiosRequestConfig,
  ) {
    return new ApiCaller(CUSTOM_BASE_URL).patch<IDictionaryService.IPatchSmetaFile>(
      `/dictionary/cost_estimate/project/${projectId}/task/${taskDefId}`,
      bodySmeta,
      config,
    );
  }

  public static getSmetaFile(projectId: qParam, taskDefId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(CUSTOM_BASE_URL).get<IDictionaryService.IGetSmetaFile>(
      `/dictionary/cost_estimate/project/${projectId}/task/${taskDefId}`,
      config,
    );
  }

  public static deleteSmetaFile(fileId: qParam, projectId: qParam, taskDefId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(CUSTOM_BASE_URL).delete<IDictionaryService.IDeleteSmetaFile>(
      `/dictionary/cost_estimate/project/${projectId}/task/${taskDefId}?fileId=${fileId}`,
      config,
    );
  }

  public static postKPFile(
    projectId: qParam,
    taskDefId: qParam,
    bodySmeta: IDictionaryService.Body.IPostKPFile,
    config?: AxiosRequestConfig,
  ) {
    return new ApiCaller(CUSTOM_BASE_URL).post<IDictionaryService.IPostKPFile>(
      `/dictionary/calendar_estimate/project/${projectId}/task/${taskDefId}`,
      bodySmeta,
      config,
    );
  }

  public static patchKPFile(
    projectId: qParam,
    taskDefId: qParam,
    bodySmeta: IDictionaryService.Body.IPatchSmetaFile,
    config?: AxiosRequestConfig,
  ) {
    return new ApiCaller(CUSTOM_BASE_URL).patch<IDictionaryService.IPatchSmetaFile>(
      `/dictionary/calendar_estimate/project/${projectId}/task/${taskDefId}`,
      bodySmeta,
      config,
    );
  }

  public static getKPFile(projectId: qParam, taskDefId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(CUSTOM_BASE_URL).get<IDictionaryService.IGetKPFile>(
      `/dictionary/calendar_estimate/project/${projectId}/task/${taskDefId}`,
      config,
    );
  }

  public static deleteKPFile(fileId: qParam, projectId: qParam, taskDefId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(CUSTOM_BASE_URL).delete<IDictionaryService.IDeleteKPFile>(
      `/dictionary/calendar_estimate/project/${projectId}/task/${taskDefId}?fileId=${fileId}`,
      config,
    );
  }
  public static endTaskByTaskId(taskId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<any>(`/tasks/${taskId}/done`, {}, config);
  }

  public static doneTaskDictinaryCalcCost(taskId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(CUSTOM_BASE_URL).patch<any>(`/cost-estimate/tasks/${taskId}/done`, {}, config);
  }

  public static acceptTaskDictinaryCalcCost(taskId: qParam, body: any, config?: AxiosRequestConfig) {
    return new ApiCaller(CUSTOM_BASE_URL).post<any>(`/cost-estimate/tasks/${taskId}/approve`, body, config);
  }
  public static rejectTaskDictinaryCalcCost(taskId: qParam, body: any, config?: AxiosRequestConfig) {
    return new ApiCaller(CUSTOM_BASE_URL).post<any>(
      `/cost-estimate/tasks/${taskId}/reject`,
      {
        id: taskId,
        coreReject: {
          message: body.message,
        },
      },
      config,
    );
  }
  public static doneTaskDictinaryCalendar(taskId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(CUSTOM_BASE_URL).patch<any>(`/calendar-estimate/tasks/${taskId}/done`, {}, config);
  }
  public static acceptTaskDictinaryCalendar(taskId: qParam, body: any, config?: AxiosRequestConfig) {
    return new ApiCaller(CUSTOM_BASE_URL).post<any>(`/calendar-estimate/tasks/${taskId}/approve`, body, config);
  }

  public static rejectTaskDictinaryCalendar(taskId: qParam, body: any, config?: AxiosRequestConfig) {
    return new ApiCaller(CUSTOM_BASE_URL).post<any>(
      `/calendar-estimate/tasks/${taskId}/reject`,
      {
        id: taskId,
        coreReject: {
          message: body.message,
        },
      },
      config,
    );
  }
}
