import { ProjectStages } from './../types/index';

type IFileMetaType =
  | 'DIRECTORY'
  | 'DOCUMENT'
  | 'CLASSIFIER_DOCUMENT'
  | 'MODEL'
  | 'MODEL_DATA'
  | 'MODEL_DATA_SIMPLIFIED'
  | 'IMAGE'
  | 'COMMENT_DOCUMENT'
  | 'BUILDING_MANAGEMENT_DOCUMENT';

interface IFileMeta {
  id: string;
  parentId?: string;
  projectId: string;
  versionParentId?: string;
  version: number;
  baseName: string;
  size: number;
  ext?: string;
  type: IFileMetaType;
  createdAt: number;
  modifiedAt: number;
  isDeleted: boolean;
  previewId?: string;
  preview?: string;
  author: string;
}

interface IUploadDocumentResponse {
  fileMeta: IFileMeta;
}

interface IFileName {
  filename: string;
}

interface IDictionaryCreate {
  projectId: string;
  baseName: string;
  parentId: string;
}

interface ICreateTemplate {
  projectId: string;
  type: 'LINEAR' | 'PLANAR' | 'SIMPLE';
}

interface IFileRename {
  id: string;
  newName: string;
}

interface IGanttModelFS {
  id: string;
  projectId: string;
  modelId: string;
}

interface IProjectAssignedUsersRoleFS {
  id: string;
  name: string;
  accessList: {
    read: AccessRole;
    chat: AccessRole;
    dataRequest: AccessRole;
    dataInput: AccessRole;
    dataComment: AccessRole;
    accept: AccessRole;
    approve: AccessRole;
    expert: AccessRole;
  };
}

interface IProjectAssignedUsersFS {
  role: IProjectAssignedUsersRoleFS;
  userId: string;
}

interface IProjectMetaFS {
  key: string;
  value: string;
}

interface IProjectResponseFS {
  id: string;
  templateId: string;
  title: string;
  description: string;
  objectName: string;
  startDate: number;
  endDate: number;
  lat: number;
  lon: number;
  totalCost: number;
  realisationCost: number;
  projectCostAtStart: number;
  documentationId: string;
  stage: ProjectStages;
  status: ProjectStages;
  assignedUsers: IProjectAssignedUsersFS[];
  ganttModels: IGanttModelFS[];
  meta: IProjectMetaFS[];
  type: string;
  authorId: string;
}

interface IIds {
  ids: string[];
}

interface IMarkupTask {
  customTaskId?: string;
  projectId: string;
  markup: string[];
  assignedUserId: string;
  name: string;
  startDate: string;
  estimateEndDate: string;
  initUserId?: string;
}

interface IUploadDocumentBody {
  document: string;
  parentId: string;
  projectId: string;
}

interface IUpdateFileBody {
  document: string;
  id: string;
}

export declare namespace IFilesystemService {
  type IUploadCommentDocument = IUploadDocumentResponse;
  type IGetCommentSectionFileMeta = IFileMeta;
  type IGetDirectoryRoot = IUploadDocumentResponse[];
  type IGetDirectories = IUploadDocumentResponse[];
  type IGetVersions = IUploadDocumentResponse[];
  type IUpdateFile = IUploadDocumentResponse;
  type IDeleteDirectory = IFileName[];
  type ICreateDirectory = IFileMeta;
  type IDirectoryTemplate = IFileMeta[];
  type IRenameDirectory = IUploadDocumentResponse;
  type IRenameFile = IFileRename;
  type IGetProject = IProjectResponseFS;
  type IPostFileBuilding = IUploadDocumentResponse;
  type IPostFileWithPreviewBuilding = IUploadDocumentResponse;
  type IGetRootFolderProjectBuilding = IFileMeta;
  type IGetMetaInformationBuildingTaskFiles = IFileMeta;
  type IGetBuildingFileMeta = IFileMeta[];
  type IPostFile = IUploadDocumentResponse;

  declare namespace Body {
    type IUploadCommentDocument = IUploadDocumentBody;
    type ICreateDirectory = IDictionaryCreate;
    type IDirectoryTemplate = ICreateTemplate;
    type IRenameDirectory = IFileRename;
    type IPostFileBuilding = IUploadDocumentBody;
    type IPostFileWithPreviewBuilding = IUploadDocumentBody;
    type IRenameFile = IUploadDocumentResponse;
    type IGetBuildingFileMeta = IIds;
    type IPostCreateTask = IMarkupTask;
    type IUpdateFile = IUpdateFileBody;
  }
}
