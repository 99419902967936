import React from 'react';
import { ProjectService, IProjectService } from '@asu/services';

export const useProject = id => {
  const [project, setProject] = React.useState<IProjectService.IGetProject | null>(null);

  const getProjects = React.useCallback(async id => {
    try {
      const request = await ProjectService.getProject(id).fetch();
      const response = request.data;

      setProject(response);
    } catch (error) {
      console.error('getProjects', error);
    }
  }, []);

  React.useEffect(() => {
    getProjects(id);
  }, [getProjects, id]);

  return { project, getProjects };
};
