import React from 'react';
import tw from 'twin.macro';
import { Rnd } from 'react-rnd';
import { FileSystem } from '@asu/filesystem';
import { Overlay } from '../../Overlay';
import { IconClose, IconFullscreen, IconMaximize, IconWindows } from '../../../assets/icons';
import { useNavigate } from 'react-router-dom';

type DocumentMenuOverlayProps = {
  children: (boolean, any) => React.ReactChild;
  projectId: string;
};

const minMarginY = 0;
const minMarginX = 100;

const onlyModels = process.env.NX_SYSTEM_TYPE === 'RZD';

const DocumentMenuOverlay = React.memo<DocumentMenuOverlayProps>(({ projectId, children }) => {
  const navigate = useNavigate();
  const [max, setMax] = React.useState(false);
  const lastState = React.useRef(null);
  const [state, setState] = React.useState<any>({ width: window.innerWidth * 0.55, height: window.innerHeight * 0.45, x: 300, y: 150 });
  const [isActive, setIsActive] = React.useState(false);

  const onClose = () => {
    setIsActive(prevState => projectId && !prevState);
  };

  const onMaximize = () => {
    if (!max) {
      lastState.current = state;
    }
    if (max) {
      setState(prevState => (lastState.current ? { ...prevState, x: lastState.current.x, y: lastState.current.y } : prevState));
      lastState.current = null;
    }

    setMax(prevState => !prevState);
  };

  const onMinimize = () => {
    setState(prevState => (lastState.current ? { ...lastState.current } : prevState));
    lastState.current = null;
    setMax(false);
    return;
  };

  const onNewWindow = () => {
    setIsActive(false);
    navigate(`/project/${projectId}/filesystem`);
  };

  return (
    <>
      {children(isActive, onClose)}
      {isActive && projectId && (
        <Overlay>
          <Rnd
            size={{ width: max ? window.innerWidth : state.width, height: max ? window.innerWidth : state.height }}
            position={{
              ...state,
              x: max ? 0 : Math.min(window.innerWidth - minMarginX, Math.max(-state.width + minMarginX, state.x)),
              y: max ? 0 : Math.min(window.innerHeight - minMarginY, Math.max(minMarginY, state.y)),
            }}
            onDragStart={(e: any, d) => {
              // TODO: Сделать отслеживани позивии при onDrag и лишь в этом случае откреплять, а не по Timeout

              if (max && lastState.current) {
                setMax(false);
                setState({
                  ...state,
                  x: e.pageX - lastState.current.width / 2,
                  y: d.y,
                  width: lastState.current.width,
                  height: lastState.current.height,
                });
              }
            }}
            onDragStop={(e, d) => {
              setState({ ...state, x: d.x, y: d.y });
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
              setState({
                width: parseInt(ref.style.width),
                height: parseInt(ref.style.height),
                ...position,
              });
            }}
            dragHandleClassName="window-bar"
            style={{ boxShadow: '0px 22px 38px rgba(0, 0, 0, 0.1076), 0px 2px 17px rgba(0, 0, 0, 0.0674)' }}
            tw="overflow-hidden rounded bg-transparent w-full h-full"
          >
            <header
              className="window-bar"
              tw="relative flex items-center justify-between h-8 text-center bg-white"
              onDoubleClick={() => (max ? onMinimize() : onMaximize())}
            >
              <h1 tw="font-title text-sm px-4">Документация | Файловая система</h1>
            </header>
            <div tw="absolute top-0 right-0 h-8">
              <div tw="flex h-full">
                <IconContainer onClick={onNewWindow}>
                  <IconFullscreen tw="height[18px] width[18px]" />
                </IconContainer>
                <IconContainer onClick={onMaximize}>{max ? <IconWindows tw="h-3 w-3" /> : <IconMaximize tw="h-3 w-3" />}</IconContainer>
                <IconContainer onClick={onClose}>
                  <IconClose tw="h-3 w-3" />
                </IconContainer>
              </div>
            </div>
            <main tw="height[calc(100% - 2rem)] w-full overflow-y-hidden">
              <FileSystem onlyModels={!onlyModels} tw="h-full w-full" projectId={projectId} />
            </main>
          </Rnd>
        </Overlay>
      )}
    </>
  );
});

const IconContainer = tw.figure`flex items-center h-full text-black hover:(bg-grey-300 bg-opacity-25) transition-all duration-150 cursor-pointer px-4`;

export default DocumentMenuOverlay;
