import React from 'react';
import { IViewerService, ViewerService } from '@asu/services';

export const useMarkUp = id => {
  const [markupsCount, setMarkupsCount] = React.useState<number | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const getMarkUp = React.useCallback(async id => {
    try {
      setIsLoading(true);
      const response = await ViewerService.getModelMarkupsCount(id).fetch();
      const responseData = response.data;

      setMarkupsCount(responseData.markupCount);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(true);
      console.error('error', error);
    }
  }, []);

  React.useEffect(() => {
    if (id) getMarkUp(id);
  }, [getMarkUp, id]);

  return { isLoading, markupsCount, getMarkUp };
};
