import React from 'react';
import tw, { styled } from 'twin.macro';
import { useFormContext } from 'react-hook-form';
import { IconClose } from '../../assets';
import { ErrorMessage } from './ErrorMessage';

interface InputProps {
  type: string;
  placeholder?: string;
  className?: string;
  defaultValue?: string;
  onChange?: (e: any) => void;
  value?: string | number | any;
  id?: string;
  readOnly?: boolean;
  hidden?: boolean;
  errorMessage?: string;
  name: string;
  labelClassName?: string;
  withLabel?: boolean;
  icon?: any;
  disabled?: boolean;
  cross?: boolean;
}

const Input = React.forwardRef((props: InputProps, ref: React.LegacyRef<HTMLInputElement>) => {
  const { className, errorMessage, name, placeholder, labelClassName, withLabel, icon, cross, ...rest } = props;
  const {
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const value = watch(name);
  const isNotEmpty = value?.length > 0;

  const onReset = () => {
    setValue(name, '', { shouldDirty: false, shouldValidate: false, shouldTouch: false });
    clearErrors(name);
  };

  return (
    <div tw="relative mb-2" className={`group ${className || ''}`}>
      {isNotEmpty && withLabel && (
        <Label className={labelClassName} name={name}>
          {placeholder}
        </Label>
      )}
      <Container tw="relative">
        {icon && (
          <i tw="flex justify-center items-center ml-3 absolute top-1/2 left-0 transform -translate-y-1/2 transition-opacity w-5 h-5 text-gray-400">
            {icon}
          </i>
        )}

        <StyledInput $isActiveIcon={icon} {...rest} placeholder={placeholder} name={name} type={props.type} ref={ref} />
        {cross && isNotEmpty && (
          <Button type="button" tw="opacity-0 group-hover:opacity-100" onClick={onReset}>
            <IconClose className="h-[10px] w-[10px] text-black-300" />
          </Button>
        )}
      </Container>
      <ErrorMessage errors={errors} name={name} render={({ message }) => <p className="pl-2">{message}</p>} />
    </div>
  );
});

interface LabelProps {
  children: any;
  className?: string;
  name: string;
}

const Label = React.memo<LabelProps>(({ className, children, name }) => {
  const { watch } = useFormContext();

  const url = watch(name);
  const isNotEmpty = url?.length > 0;

  return (
    <label
      htmlFor="url"
      tw="block absolute top-1 left-3 text-xs text-gray-500 mb-1 z-10"
      className={className}
      style={{ opacity: isNotEmpty ? '1' : '0', transform: isNotEmpty ? '' : '' }}
    >
      {children}
    </label>
  );
});

const Container = tw.div`flex border border-grey-300 rounded-md focus:outline-primary`;
const StyledInput = styled.input<any>`
  ${tw`flex-1 h-full py-4 text-sm bg-white border-none rounded-md outline-none pr-9 focus:ring-1 focus:ring-primary-light`}
  ${({ $isActiveIcon }) => ($isActiveIcon ? tw`pl-10` : tw`pl-3`)}
`;
const Button = tw.button`flex items-center justify-center h-10 w-10 absolute top-1/2 right-0 transform -translate-y-1/2 transition-opacity`;

export default Input;
