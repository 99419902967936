import React from 'react';
import tw, { styled } from 'twin.macro';

export const enum Theme {
  Primary = 'primary',
  Dark = 'dark',
}

type ButtonProps = {
  className?: string;
  children?: React.ReactChild;
  onClick?: (e?: any) => void;
  disabled?: boolean;
  htmlType?: string;
  loading?: boolean;
  type?: any;
  style?: any;
  size?: string;
  icon?: JSX.Element;
  theme?: Theme;
  title?: string;
};

const themeMap = {
  primary: tw`bg-primary-dark border border-primary-light border-opacity-10 hover:(bg-primary-light border-primary-dark)`,
  dark: tw`bg-grey-900 border-grey-900 hover:(bg-grey-900 bg-opacity-90 border-grey-900)`,
};

const Button = React.memo<ButtonProps>(({ children, icon, theme, title, ...rest }) => {
  return (
    <StyledButton theme={theme} {...rest}>
      {icon && <Icon>{icon}</Icon>}
      {title && title}
      {children}
    </StyledButton>
  );
});

const Icon = tw.i`flex justify-center items-center`;
const StyledButton = styled.button`
  ${tw`w-full minHeight[35px] flex gap-2 items-center justify-center text-white hover:bg-opacity-90 rounded-lg px-4 py-2 transition duration-150`}
  ${({ theme }) => themeMap[theme] || themeMap['primary']}
`;
export default Button;
