import { Service } from './Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import { ICostEstimateService } from './CostEstimateService.d'

const BASE_URL = '/dictionaryc';

export class CostEstimateService implements Service {
  public static getCostEstimate(data: ICostEstimateService.Body.IGetCostEstimate, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<ICostEstimateService.IGetCostEstimate>('/dictionary/cost_estimate', data, config);
  }
}
