import React from 'react';
import tw, { styled } from 'twin.macro';
import { observer } from 'mobx-react-lite';

import { IconHome, IconStar, IconDownArrow, IconFolder } from '../../assets';
import { useFileSystemState } from '../../store/FileSystemStore';
import { IconClose } from '@asu/ui';

const Folder = observer<any>(({ data }) => {
  const store = useFileSystemState();

  return (
    <InfoTextBlock>
      <WrapperArrow onClick={() => store.historyGo(data)}>
        <StyledIconDownArrow />
      </WrapperArrow>
      <StyledIconFolder tw="ml-4 mr-2" />
      <Name onClick={() => store.historyGo(data)}>{data.name}</Name>
    </InfoTextBlock>
  );
});

// eslint-disable-next-line @typescript-eslint/ban-types
type SideBarProps = {};

const SideBar = observer<SideBarProps>(() => {
  const store = useFileSystemState();

  return (
    <Container>
      <Section>
        <SectionTitle>Основные</SectionTitle>
        <FolderList>
          <FolderItem onClick={() => store.historyReset()}>
            <IconHome tw="color[#767D89]" />
            <p tw="color[#767D89]">Разделы проекта</p>
          </FolderItem>
        </FolderList>
      </Section>
      <Section>
        <SectionTitle>Избранное</SectionTitle>
        <div tw="flex flex-col text-center items-center my-4 px-4">
          {store.favorites[store.projectId]?.length > 0 ? (
            store.favorites[store.projectId].map((item: any, key: any) => (
              <FavoriteBlock key={key}>
                <FavoriteRemove type="button" onClick={() => store.removeFromFavorites(item)}>
                  <IconClose tw="h-3 w-3 margin-top[2px]" />
                </FavoriteRemove>
                <FavoriteIcon>
                  <IconStar tw="text-primary-dark h-6 w-6" />
                </FavoriteIcon>
                <FavoriteText onClick={() => store.historyGo({ id: item.id, name: item.name })}>{item.name}</FavoriteText>
              </FavoriteBlock>
            ))
          ) : (
            <>
              <IconStar tw="text-grey-300 h-6 w-6" />
              <p tw="text-grey-300">Список избранного пока пуст</p>
            </>
          )}
        </div>
      </Section>
    </Container>
  );
});

const Container = tw.aside`width[260px] space-y-6 h-full border-r border-grey-100 py-6 px-4`;
const Section = tw.div``;
const SectionTitle = tw.h1`font-title font-medium text-grey-300 text-sm`;
const FolderList = tw.ul`ml-2`;
const FolderItem = tw.li`flex items-center space-x-2 my-2 cursor-pointer`;
const FavoriteRemove: any = tw.button`absolute h-full flex items-center transition-opacity opacity-0`;
const FavoriteText: any = tw.p`transition-transform truncate cursor-pointer`;
const FavoriteIcon: any = tw.figure`transition-transform pt-0.5 pr-1`;
const FavoriteBlock = styled.div`
  ${tw`relative flex flex-row width[230px] transition-opacity duration-200 hover:opacity-75 mb-2`}

  &:hover {
    ${FavoriteRemove} {
      ${tw`opacity-100`}
    }

    ${FavoriteText}, ${FavoriteIcon} {
      ${tw`transform translate-x-3`}
    }
  }
`;

const WrapperArrow = tw.figure`flex min-width[1rem] w-4 h-4 bg-gray-400 rounded-md items-center justify-center cursor-pointer`;
const StyledIconDownArrow = tw(IconDownArrow)`text-white w-1.5`;
const StyledIconFolder = tw(IconFolder)`color[#767D89] min-width[1rem] w-4 h-4`;
const InfoTextBlock = tw.div`items-center flex h-full cursor-pointer pl-3`;
const Name = tw.p`text-black text-sm pl-0.5 overflow-hidden overflow-ellipsis whitespace-nowrap`;

export default SideBar;
