import React from 'react';
import { observer } from 'mobx-react-lite';
import tw, { styled } from 'twin.macro';
import { useFileSystemState } from '../../../store';

// eslint-disable-next-line @typescript-eslint/ban-types
type HistoryDropdownProps = {};

const HistoryDropdown = observer<HistoryDropdownProps>(() => {
  const [isOpen, setIsOpen] = React.useState(false);
  const store = useFileSystemState();

  const onRightClick = e => {
    e.preventDefault();
    setIsOpen(true);
    document.addEventListener('click', () => setIsOpen(false), { once: true, passive: true });
  };

  return (
    <div onContextMenu={onRightClick} onClick={() => setIsOpen(false)}>
      <PrevTitle />
      {isOpen && (
        <Dropdown>
          {store.history.map(folder => (
            <DropdownItem key={folder.id} onClick={() => store.historyReplace(folder)}>
              {folder.name}
            </DropdownItem>
          ))}
          <DropdownItem tw="before:bg-primary">{store.currentFolder.name}</DropdownItem>
        </Dropdown>
      )}
    </div>
  );
});

const Dropdown = styled.div`
  background: radial-gradient(100% 4705% at 0% 0%, #ffffff 100%, rgba(255, 255, 255, 0.65) 100%);
  border: 1px solid rgba(162, 162, 162, 0.05);
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.13);

  ${tw`absolute z-10 left-4 top-4 w-2/3 rounded-md`}
`;

const DropdownItem = styled.p`
  ${tw`relative cursor-pointer text-sm font-light pl-6 pr-2 py-2 transition-colors duration-200 truncate hover:(bg-grey-300 bg-opacity-25)`}
  ${tw`before:(absolute left-2 transform top-1/2 -translate-y-1/2 content[''] w-2 h-2 rounded-full bg-grey-300)`}
  ${tw`not-first:after:(absolute content[''] left[11px] -top-2 height[17px] width[1px] bg-grey-300)`}
`;

const PrevTitle = observer(() => {
  const store = useFileSystemState();

  return store.history.length ? (
    <StyledPrevTitle
      onClick={() => {
        if (store.history.length) {
          store.historyGoBack();
        }
      }}
    >
      {store.history[store.history.length - 1].name}
    </StyledPrevTitle>
  ) : (
    <></>
  );
});

const StyledPrevTitle = tw.p`cursor-pointer overflow-hidden overflow-ellipsis opacity-50`;

export default HistoryDropdown;
