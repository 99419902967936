import { qParam, Service } from './Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import type { IClassifierdrService } from './ClassifierdrService.d';
export * from './ClassifierdrService.d';
// import { ITab, IRequest, IResponse, IGetTemplatesResponse } from './responses';

const BASE_URL = '/classifierdr';

export class ClassifierdrService implements Service {
  public static createTab(taskId, body: IClassifierdrService.Body.ICreateTab, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IClassifierdrService.ICreateTab>(`/tasks/${taskId}/tabs`, body, config);
  }

  public static getTabs(projectId: qParam, taskDefId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IClassifierdrService.IGetTabs>(`/tab/${projectId}/${taskDefId}`, config);
  }

  public static getTabsByTaskId(taskId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<any>(`/tasks/${taskId}/tabs`, config);
  }

  public static getTab(tabId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<any>(`/tasks/tabs/${tabId}`, config);
  }

  public static createRequest(tabId: qParam, body: IClassifierdrService.Body.ICreateRequest, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IClassifierdrService.ICreateRequest>(`/tasks/tabs/${tabId}/requests`, body, config);
  }

  public static updateRequest(body: IClassifierdrService.Body.IUpdateRequest, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<IClassifierdrService.IUpdateRequest>(`/request`, body, config);
  }
  public static updateRequestNew(requestId: string, body: any, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<IClassifierdrService.IUpdateRequest>(`/tasks/tabs/requests/${requestId}`, body, config);
  }
  public static deleteRequest(requestId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).delete<void>(`/tasks/tabs/requests/${requestId}`, config);
  }

  public static createResponse(requestId: string, body, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<any>(`/tasks/tabs/requests/${requestId}/responses`, body, config);
  }

  public static rejectResponse(responseId: string, body, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<null>(`/tasks/tabs/requests/responses/${responseId}/reject`, body, config);
  }

  public static approveResponse(responseId: string, body, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<null>(`/tasks/tabs/requests/responses/${responseId}/approve`, body, config);
  }

  public static getClassifierdrValues(id: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IClassifierdrService.IGetClassifierdrValues>(`/classifier/${id}/values`, config);
  }

  public static getTemplates(config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IClassifierdrService.IGetTemplates>('/template', config);
  }
  public static completeTask(taskId: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<null>(`/tasks/${taskId}/done`, {}, config);
  }
  public static approveTask(taskId: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<null>(
      `/tasks/${taskId}/approve`,
      {
        id: taskId,
        coreApprove: {
          message: 'string',
        },
      },
      config,
    );
  }
  public static rejectTask(taskId: string, body: any, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<null>(
      `/tasks/${taskId}/reject`,
      {
        id: taskId,
        coreReject: {
          message: body.message,
        },
      },
      config,
    );
  }
  public static getFullTask(coreTaskId, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<any>(`/tasks?coreTaskId=${coreTaskId}`, config);
  } // добавить типизацию
}
