import dayjs from 'dayjs';
import { useFileSystemState } from '../../../store';
import React from 'react';
import tw, { styled } from 'twin.macro';
import { DragAndDropVersions } from '../../DragAndDropVersions';
import { useProjectMeta, useVersions } from '../hooks';
import { IconCloud, IconModel } from '../../../assets';
import { Button, Checkbox, Tooltip } from '@asu/ui';
import { observer } from 'mobx-react-lite';
import ModalPreview from '../../Files/local-components/ModalPreview';

// eslint-disable-next-line @typescript-eslint/ban-types
type VersionsIFCProps = {
  info: any;
  selectedId: string;
  selectedFile: any;
  onClickModel: (data: any) => void;
  onUpdateVersion: (newFileId: string) => void;
};

const Version = observer<any>(({ key, onCheckedFilesIFC, data, onClickModel }) => {
  const store = useFileSystemState();
  const [check, setCheck] = React.useState(false);

  const onClickCheck = data => {
    setCheck(!check);
    onCheckedFilesIFC(data);
  };

  return (
    <BodyWrapper key={key}>
      <VersionBlock>
        <div tw="flex items-center">
          <CheckboxWrapper>
            <Checkbox onClick={() => onClickCheck(data.fileMeta)} checked={check} />
          </CheckboxWrapper>
          <TextWrapper>
            <Title>{data.fileMeta.baseName}</Title>
            <Description>
              {dayjs.unix(data.fileMeta.modifiedAt).format('DD.MM.YYYY HH:mm:ss')} | {data.fileMeta.size} байт
            </Description>
          </TextWrapper>
        </div>

        <IconWrapper>
          <Tooltip mouseEnterDelay={0.1} placement="top" title="Открыть модель">
            <IconModel onClick={() => onClickModel(data.fileMeta)} tw="height[20px] mr-2" />
          </Tooltip>
          <Tooltip mouseEnterDelay={0.1} placement="top" title="Скачать версию">
            <IconCloud
              onClick={() => {
                store.downloadFile(data.fileMeta.id, data.fileMeta.baseName, data.fileMeta.ext);
              }}
              tw="color[#eb0000] h-6 w-6"
            />
          </Tooltip>
        </IconWrapper>
      </VersionBlock>
    </BodyWrapper>
  );
});

const VersionsIFC = React.memo<VersionsIFCProps>(({ selectedId, selectedFile, onUpdateVersion, onClickModel }) => {
  const { isLoading, versions, postVersion } = useVersions(selectedId);
  const [selectedModels, setSelectedModels] = React.useState();
  const [isPreviewOpen, setIsPeviewOpen] = React.useState<boolean>(false);
  const [checkedFiles, setCheckedFiles] = React.useState<any>([]);

  const { updateMetaVersion } = useProjectMeta(selectedFile.projectId);

  const onClickEnter = () => {
    const files = checkedFiles
      .sort((a, b) => b.version - a.version)
      .map(el => el.id)
      .join(',');
    setSelectedModels(files);
    setIsPeviewOpen(true);
  };

  const onCheckedFilesIFC = data => {
    if (checkedFiles.find(e => e === data.id)) {
      checkedFiles.splice(
        checkedFiles.findIndex(e => e === data.id),
        1,
      );
    } else setCheckedFiles(prevState => [...prevState, data]);
  };

  const onModalClose = () => {
    setIsPeviewOpen(false);
  };

  return (
    <Container>
      {!isLoading ? (
        versions?.map(data => (
          <Version
            key={data.fileMeta.id}
            onCheckedFilesIFC={onCheckedFilesIFC}
            selectedFile={selectedFile}
            data={data}
            onClickModel={onClickModel}
          />
        ))
      ) : (
        <div tw="px-4 pt-4">
          <div tw="flex flex-wrap">
            {Array.from(Array(6), (_, i) => (
              <BlockPlaceholder key={i} />
            ))}
          </div>
        </div>
      )}

      {process.env.NX_SYSTEM_TYPE !== 'GGE' && checkedFiles.length === 2 && <ButtonEnter onClick={onClickEnter}>Сравнить</ButtonEnter>}

      <DragAndDropVersions
        onUpdateVersion={onUpdateVersion}
        updateMetaVersion={updateMetaVersion}
        selectedId={selectedId}
        postVersion={postVersion}
      />
      {isPreviewOpen && (
        <ModalPreview
          modelId={selectedModels}
          compare={true}
          isOpen={isPreviewOpen}
          setIsOpen={setIsPeviewOpen}
          openModal={() => setIsPeviewOpen(true)}
          closeModal={() => setIsPeviewOpen(false)}
        />
      )}
    </Container>
  );
});

const CheckboxWrapper = styled.div`
  ${tw``}
`;

const ButtonEnter = styled(Button)`
  ${tw`w-40  ml-4 mt-4 font-bold`}
`;

const BodyWrapper = tw.div`px-4 mt-4`;
const VersionBlock = tw.div`flex justify-between relative`;
const TextWrapper = styled.div`
  max-width: 20rem;
  ${tw`ml-3`}
`;
const Title = tw.h2`font-medium text-sm overflow-hidden overflow-ellipsis`;
const Description = tw.p`text-xs text-gray-500`;
const IconWrapper = tw.div`flex items-center cursor-pointer`;
const Container = styled.main`
  ${tw`height[93%] w-full overflow-y-scroll relative`}

  ::-webkit-scrollbar {
    width: 3px;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d1d5db;
    border-radius: 20px;
    opacity: 0.2;
  }
`;

const BlockPlaceholder = tw.div`height[40px] w-full bg-grey-300 bg-opacity-30 rounded animate-pulse mb-3`;

export default VersionsIFC;
