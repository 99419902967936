import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';

import { DefaultGlobalStyle } from '@asu/ui';
import { ErrorBoundary, addBugReport } from '@asu/utils';
import { GlobalStyles as TailwindGlobalStyles } from 'twin.macro';

import App from './app/app';

if (process.env.NODE_ENV === 'production') {
  (async () => {
    const Sentry = await import('@sentry/react');

    Sentry.init({
      dsn: 'https://c5d332872a6d40078d13c1b75133efb2@o372054.ingest.sentry.io/6003538',
      release: PACKAGE_VERSION,
      beforeSend(event, hint) {
        addBugReport(event, hint);
        return event;
      },
    });
  })();
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
    <TailwindGlobalStyles />
    <DefaultGlobalStyle />
  </React.StrictMode>,
  document.getElementById('root'),
);
