import tw, { styled } from 'twin.macro';
import { observer } from 'mobx-react-lite';
import { Checkbox } from '@asu/ui';
import { useFileSystemState } from '../../store';
import { AddFile, AddFolder, Back, LoadingProgressBar, ModelButton } from './local-components';

type ToolbarProps = { className?: string };
const Toolbar = observer<ToolbarProps>(({ className }) => {
  const fileSystemStore = useFileSystemState();
  return (
    <Container className={className}>
      <div tw="flex">
        <div tw="border-r border-grey-100 pr-4 mr-4">
          <Back />
        </div>

        {process.env.NX_SYSTEM_TYPE === 'GGE' && <ModelButton />}
        <AddFolder />
        <AddFile />
        {process.env.NX_SYSTEM_TYPE !== 'GGE' && <ModelButton />}
      </div>

      <div tw="flex">
        {process.env.NX_SYSTEM_TYPE === 'GGE' ? (
          <Checkbox
            onClick={() => {
              if (fileSystemStore.checkedFiles.length) {
                fileSystemStore.resetAllFile();
              } else {
                fileSystemStore.setCheckedAllFile();
              }
            }}
          >
            <span tw="font-medium text-sm leading-none">Выделить все</span>
          </Checkbox>
        ) : (
          <>
            <Button name="button" onClick={() => fileSystemStore.setCheckedAllFile()}>
              <span tw="font-medium text-sm leading-none">Выделить все</span>
            </Button>
            <Button name="button" onClick={() => fileSystemStore.resetAllFile()}>
              <span tw="font-medium text-sm leading-none">Сбросить</span>
            </Button>
          </>
        )}
      </div>

      <LoadingProgressBar />
    </Container>
  );
});

const Container = tw.header`relative flex items-center border-b border-grey-100 px-4 justify-between`;
const Button = styled.button`
  ${tw`flex items-center rounded text-grey-900 cursor-pointer transition-colors duration-150 p-3`}
  ${tw`hocus:(outline-none)`}
  ${tw`opacity-100 hover:(text-grey-500 bg-grey-100) active:(text-grey-900 bg-grey-300)`}
`;

export default Toolbar;
