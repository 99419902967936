//TODO(Maxim Shkividorov): Удалить это дерьмо
export const get3DModelByMockProject = id => {
  switch (id) {
    case '23e84bec-101f-4782-bdf8-75d36fac3b87': // Второй главный путь на перегоне Ния – Таковка
      return {
        id: '3d-model-rails',
        lon: 106.8065,
        lat: 56.563,
        modelPath: '/assets/rails.glb',
        centerY: 0,
        positionAngleY: 0, // Angle
        positionY: -215,
        defaultPitch: [60],
        defaultZoom: [16],
        modelId: '90725df2-c6c9-4f09-8d86-b9c136566191',
      };
    case 'ecc24895-662d-42d2-85a3-f844a831d369': // Станция Хвойная
      return {
        id: '3d-model-xvoynaya',
        lon: 34.5005,
        lat: 58.8934,
        modelPath: '/assets/xvoynaya.glb',
        centerY: 0,
        positionAngleY: -0.06, // Angle
        positionY: 7, // Angle
        defaultPitch: [60],
        defaultZoom: [18],
        modelId: 'a5823303-0206-417a-89bd-22b010872c6b',
      };
    case '28504f01-03d0-442b-ad47-4e39b0a00a51': // Строительство АБК на ПТО станции Выборг Откябрьская дирекция инфраструктуры
      return {
        id: '3d-model-house',
        lon: 28.760177,
        lat: 60.7153,
        modelPath: '/assets/house.glb',
        centerY: 0,
        positionAngleY: -1.3, // Angle
        positionY: 0, // Angle
        defaultPitch: [60],
        defaultZoom: [18],
        modelId: '6ccf572d-30ed-4dab-9ada-0ae3286a1fd5',
      };
    case '1f7355bc-bed6-4817-90bc-cb954383108d': // Железнодорожный мост через реку Кола
      return {
        id: '3d-model-bridge',
        lon: 33.034066,
        lat: 68.866957,
        modelPath: '/assets/bridge.glb',
        centerY: 0,
        positionAngleY: -1.2, // Angle
        positionY: 0, // Angle
        defaultPitch: [60],
        defaultZoom: [17],
        modelId: '3f6ab7b1-bd7c-4f96-a584-7551161ccdbd',
      };
    default:
      return null;
  }
};
