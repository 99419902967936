import React from 'react';
import tw from 'twin.macro';
import { Popover as PopoverH } from '@headlessui/react';

const StyledContent = tw(PopoverH.Panel)`absolute right-6 flex flex-col gap-y-2 p-4 bg-white rounded border border-[#EFEFEF] shadow-md`;
const StyledTitle = tw.h1`text-[#404040] text-sm`;
const StyledFooter = tw.footer``;

const PopoverTitle = ({ children }) => <StyledTitle>{children}</StyledTitle>;

interface PopoverContentProps {
  children: React.ReactNode;
  action?: () => void;
}
const PopoverContent = ({ children, action }: PopoverContentProps) => {
  const Content = () => {
    React.useEffect(() => {
      return () => {
        action && action();
      };
    }, []);
    return <>{children}</>;
  };

  return (
    <StyledContent unmount={true}>
      <Content />
    </StyledContent>
  );
};

const PopoverClose = ({ children }) => {
  return <div>{children}</div>;
};

const PopoverRoot = ({ children, trigger }) => {
  return (
    <PopoverH>
      <PopoverH.Button>{trigger}</PopoverH.Button>
      {children}
    </PopoverH>
  );
};
const PopoverFooter = ({ children }) => <StyledFooter>{children}</StyledFooter>;

const Popover = {
  Root: PopoverRoot,
  Title: PopoverTitle,
  Content: PopoverContent,
  Close: PopoverClose,
  Footer: PopoverFooter,
};

export default Popover;
