export { ReactComponent as IconStar } from './star-ico.svg';
export { ReactComponent as IconStarO } from './star-ico-o.svg';
export { ReactComponent as IconLoop } from './loop-ico.svg';
export { ReactComponent as IconBlock } from './block-ico.svg';
export { ReactComponent as IconHome } from './home-ico.svg';
export { ReactComponent as IconDownArrow } from './down-arrow-ico.svg';
export { ReactComponent as IconFolder } from './folder-ico.svg';
export { ReactComponent as IconCloud } from './cloud-ico.svg';
export { ReactComponent as IconBack } from './back-ico.svg';
export { ReactComponent as IconModel } from './three-d-model-ico.svg';
export { ReactComponent as IconNetworkSchedule } from './network-schedule-ico.svg';
export { ReactComponent as IconMainModel } from './3d-model-indicator-ico.svg';
export { ReactComponent as IconEngineeringIndocator } from './engineering-indocator-ico.svg';
