import { qParam } from './Service.d';
import { Service } from './/Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import type { IGlobalRolesService } from './GlobalRolesService.d'
export * from './GlobalRolesService.d';

const BASE_URL = '/role';

const defaultAccessList = [
  {
    processTask: 'CLASSIFIER_DATA',
    read: 'NONE',
    chat: 'NONE',
    dataRequest: 'NONE',
    dataInput: 'NONE',
    dataComment: 'NONE',
    accept: 'NONE',
    approve: 'NONE',
    expert: 'NONE',
  },
  {
    processTask: 'DICTIONARY_CALC_COST',
    read: 'NONE',
    chat: 'NONE',
    dataRequest: 'NONE',
    dataInput: 'NONE',
    dataComment: 'NONE',
    accept: 'NONE',
    approve: 'NONE',
    expert: 'NONE',
  },
  {
    processTask: 'DICTIONARY_DATA',
    read: 'NONE',
    chat: 'NONE',
    dataRequest: 'NONE',
    dataInput: 'NONE',
    dataComment: 'NONE',
    accept: 'NONE',
    approve: 'NONE',
    expert: 'NONE',
  },
  {
    processTask: 'DOCUMENT',
    read: 'NONE',
    chat: 'NONE',
    dataRequest: 'NONE',
    dataInput: 'NONE',
    dataComment: 'NONE',
    accept: 'NONE',
    approve: 'NONE',
    expert: 'NONE',
  },
  {
    processTask: 'DICTIONARY_CALC_CALENDAR',
    read: 'NONE',
    chat: 'NONE',
    dataRequest: 'NONE',
    dataInput: 'NONE',
    dataComment: 'NONE',
    accept: 'NONE',
    approve: 'NONE',
    expert: 'NONE',
  },
];

export class GlobalRolesService implements Service {
  public static getGlobalRoleTemplates(config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IGlobalRolesService.IGetGlobalRoleTemplates>('/template', config);
  }

  public static createGlobalRoleTemplates(name: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IGlobalRolesService.ICreateGlobalRoleTemplates>(
      '/template',
      {
        name,
        accessList: defaultAccessList,
      },
      config,
    );
  }

  public static deleteGlobalRoleTemplates(roleTemplateId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).delete<void>(`/template/${roleTemplateId}`, config);
  }

  public static patchAccessListGlobalRole(accessList: IGlobalRolesService.Body.IPatchAccessListGlobalRole, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<IGlobalRolesService.IPatchAccessListGlobalRole>('/accesslist', accessList, config);
  }
}
