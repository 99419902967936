import React from 'react';
import tw, { styled } from 'twin.macro';
import { ContextMenu } from '../ContextMenu';
import { Files } from '../Files';
import { Folders } from '../Folders';
import { Menu } from '../../styles';
import { useFileSystemState } from '../../store';
import { ModalCreate } from './local-components';

// eslint-disable-next-line @typescript-eslint/ban-types
type MainPageProps = {
  model?: boolean;
};

const MainPage: React.FC<MainPageProps> = React.memo(() => {
  const store = useFileSystemState();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [event, setEvent] = React.useState<React.MouseEvent<HTMLDivElement, MouseEvent> | null>(null);

  React.useEffect(() => {
    store.historyReset();
  }, [store]);

  const onContextMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.id === 'contextMenuExplorer') {
      setEvent(e);
    }
  };

  const closeModalCreate = () => {
    setIsOpen(false);
  };

  const openModalCreate = () => {
    setIsOpen(true);
  };

  const contextMenuContent = (
    <Menu.Container>
      <Menu.Item onClick={() => setIsOpen(true)}>Создать папку</Menu.Item>
    </Menu.Container>
  );

  return (
    <MainBlock onContextMenu={onContextMenu} id="contextMenuExplorer">
      <Container id="contextMenuExplorer">
        {/* {!store.onlyModels && <Folders id="contextMenuExplorer" />} */}
        <Folders id="contextMenuExplorer" />
        <Files id="contextMenuExplorer" />
      </Container>
      {process.env.NX_SYSTEM_TYPE !== 'GGE' && event && (
        <ContextMenu event={event} onClickOutside={() => setEvent(null)} content={contextMenuContent} />
      )}
      {isOpen && <ModalCreate isOpen={isOpen} setIsOpen={setIsOpen} openModal={openModalCreate} closeModal={closeModalCreate} />}
    </MainBlock>
  );
});

const Container = tw.div`flex flex-col h-full w-full px-8 mt-28`;

const MainBlock = styled.main`
  ${tw`h-full w-full overflow-y-scroll`}

  ::-webkit-scrollbar {
    width: 7px;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d1d5db;
    border-radius: 20px;
    border: 2px solid #fff;
    opacity: 0.2;
  }
`;

export default MainPage;
