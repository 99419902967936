import type { AxiosResponse, CancelTokenSource } from 'axios';

export default class DEPRECATEDApiRequest<T = any> {
  private _isCancelled = false;
  private _isFinished = false;

  public get isCancelled(): boolean {
    return this._isCancelled;
  }

  public get isFinished(): boolean {
    return this._isFinished;
  }

  constructor(private _request: Promise<AxiosResponse<T>>, private _source: CancelTokenSource) {}

  public cancel(message?: string): void {
    if (!this._isCancelled && !this._isFinished) {
      this._isCancelled = true;
      this._source.cancel(message);
    }
  }

  public get data(): Promise<T> {
    return this._request.then(r => {
      this._isFinished = true;
      return r.data;
    });
  }
}
