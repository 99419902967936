interface INotificationView {
  ids: string[];
  userId: string;
}

interface INotificationFlag {
  id: string;
  notificationId: string;
  isNew: boolean;
  isDeleted: boolean;
  targetUser?: string;
}

interface IUserId {
  userId: string;
}


interface IParams {
  additionalProp1?: string;
  additionalProp2?: string;
  additionalProp3?: string;
}

interface INotificationResponse {
  id: string;
  projectId: string;
  projectTitle: string;
  name: string;
  params: IParams;
  isNew: boolean;
  timestamp: string;
  isDeleted: boolean;
}

interface INotificationFind {
  projectIds?: string[];
  userId?: string;
  isNew?: boolean;
  isDeleted?: boolean;
}

interface INotificationViewByProject {
  projectId: string;
  notifications: INotificationResponse[];
}

export declare namespace INotificationService {
  type IPostNotificationDismiss = INotificationFlag[];
  type IGetAllNotifications = INotificationResponse[];
  type IGetAllNotificationsByProjects = INotificationViewByProject[];

  declare namespace Body {
    type IPostNotificationDismiss = INotificationView;
    type IGetAllNotificationsByProjects = INotificationFind[];
  }
}
