import React from 'react';
import tw from 'twin.macro';

import { Modal, Button, useManualModalState, Theme, Checkbox } from '@asu/ui';
import { groupBy, uuidv4 } from '@asu/utils';
import { IconEdit } from '../../../assets';
import RoleMatrixInfo from '../../../utils/RoleMatrixInfo';
import { ProjectService } from '@asu/services';

const obj = {
  CLASSIFIER_DATA: 'ИРД',
  DOCUMENT: 'Задание на проектирование',
  DICTIONARY_CALC_CALENDAR: 'Календарный план ПИР',
  DICTIONARY_DATA: 'ТПД',
  DICTIONARY_CALC_COST: 'Смета ПИР',
  BUILDING_MANAGEMENT: 'Контроль строительства',
  COMMENT_SERVICE: 'COMMENT_SERVICE',
  VIEWER_SERVICE: 'VIEWER_SERVICE',
  MODELS_SERVICE: 'MODELS_SERVICE',
};

interface ModalMatrixCardProps {
  roleId: any;
  rights?: any;
  onSelect?: any;
  title?: any;
  buttonName?: string;
  disabled?: boolean;
  checkTypes: any;
  setCheckTypes: (e: any) => void;
  role?: any;
  rolesHideInfo?: any;
  servicesHideInfo?: any;
  allTypes: any;
}

const ModalMatrixCard = React.memo((props: ModalMatrixCardProps) => {
  return (
    <Modal.Root
      manual
      trigger={
        <Button
          theme={Theme.Dark}
          type="button"
          tw="w-min whitespace-nowrap mr-3 max-h-[40px] font-semibold"
          // icon={!props.title && <IconEdit tw="text-white h-4 w-4" />}
          title={props.title}
        />
      }
    >
      <AddModalMatrixCard {...props} />
    </Modal.Root>
  );
});

export const AddModalMatrixCard = React.memo((props: ModalMatrixCardProps) => {
  const {
    role,
    roleId,
    onSelect,
    title = 'Редактирование прав',
    disabled = false,
    rights,
    checkTypes,
    setCheckTypes,
    servicesHideInfo,
    allTypes,
  } = props;

  const { closeModal } = useManualModalState();

  const groupedRights = React.useMemo(() => groupBy(rights, 'serviceType'), [rights]);

  const saveAccessList = async () => {
    // TODO: (Maxim) Это что такое вообще???
    // role.rights = checkTypes;
    const res = await ProjectService.getRole(roleId).fetch();

    if (res.data.isGlobal) {
      await ProjectService.putNewGlobalRoleAssets(roleId, checkTypes).fetch();
    }
    if (!res.data.isGlobal) {
      await ProjectService.putNewRoleAssets(roleId, checkTypes).fetch();
    }

    closeModal();
  };

  const handleCheck = (e, rightType, serviceType) => {
    if (!e.target.checked) {
      setCheckTypes(checkTypes.filter(el => el.rightType !== rightType));
    } else {
      setCheckTypes(prevState => [...prevState, { rightType, serviceType }]);
    }
  };

  return (
    <Modal.Content tw="max-h-[80vh] w-[60vw]">
      <Modal.Title>Ролевая матрица</Modal.Title>
      <div tw="p-4">
        <section tw="border rounded-md overflow-auto max-h-[60vh]">
          {allTypes &&
            Object.keys(allTypes).map(right => {
              const isSectionEmpty =
                groupedRights[right]?.filter(permission => !RoleMatrixInfo.isNeedHide(permission.rightType))?.length < 1;

              return (
                <div key={right} style={{ display: isSectionEmpty && 'none' }}>
                  <header tw="w-full p-3 py-5 bg-gray-400 text-white text-lg font-semibold">
                    <h1>{RoleMatrixInfo.getServicesTitles(obj[right])}</h1>
                  </header>

                  <ul tw="p-5 py-3">
                    {allTypes[right].map(permission => (
                      <li
                        key={permission}
                        tw="py-1 hover:bg-gray-300 w-full flex justify-between"
                        style={{ display: RoleMatrixInfo.isNeedHide([permission.rightType]) && 'none' }}
                      >
                        <p>{RoleMatrixInfo.getProjectRolesTitles(permission)}</p>
                        <Checkbox
                          tw="mr-3"
                          disabled={disabled}
                          defaultChecked={checkTypes?.find(el => el.rightType === permission)}
                          onChange={e => handleCheck(e, permission, right)}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
        </section>

        {!disabled && (
          <Button tw="mt-2 w-full" onClick={saveAccessList}>
            Сохранить
          </Button>
        )}
      </div>
    </Modal.Content>
  );
});

export default ModalMatrixCard;
