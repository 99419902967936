import type { AxiosRequestConfig } from 'axios';
import { ApiCaller } from '../utils/ApiCaller';
import { qParam, Service } from './Service';

import type { IAuthService } from './AuthService.d';
export * from './AuthService.d';

const BASE_URL = '/auth';

export class AuthService implements Service {
  public static getUser(userId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IAuthService.IGetUserResponse>(`/auth/proxy/user/${userId}`, config);
  }
  public static getUsers(config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IAuthService.IGetUsersResponse>('/auth/proxy/user', config);
  }
  public static getCurrentUser(config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IAuthService.IGetCurrentUserResponse>('/auth/proxy/user/current', config);
  }
  public static getGroups(config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IAuthService.IGetGroupsResponse>('/auth/proxy/group', config);
  }
  public static getGroupMembers(groupId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IAuthService.IGetGroupMembersResponse>(`/auth/proxy/group/${groupId}/members`, config);
  }
  public static getGroupUser(userId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IAuthService.IGetGroupUserResponse>(`/auth/proxy/group/user/${userId}`, config);
  }
}
