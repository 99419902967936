import { AxiosRequestConfig } from 'axios';
export interface IDataBuildingObject {
  projectId: string;
  dictionaryId: string;
  taskDefId: string;
  name: string;
  unit: string;
  value: number;
  status: 'NOT_ACCEPTED' | 'ACCEPTED' | 'REJECTED';
  note?: string;
  initUserId?: string;
}

// export interface IPutDataBuildingObject {
//   id: string;
//   value: number;
//   note: string;
// }

// export interface ICreateDataRequest {
//   projectId: string;
//   authorId: string;
//   assignedUserId: string;
//   fileId: string;
//   estimateAt: string;
//   status: string;
//   note: string;
// }

interface ICreateDataRequest {
  projectId: string;
  authorId: string;
  assignedUserId: string;
  fileId: string;
  estimateAt: string;
  status: 'IN_PROGRESS' | 'DONE';
  initUserId?: string;
  note: string;
}

export interface IGetBuildingObjectsData {
  projectId: string;
  taskDefId: string;
}

export interface IBuildingObject {
  id: string;
  projectId: string;
  requestId: string;
  dictionaryId: string;
  taskDefId: string;
  name: string;
  unit: string;
  value: number;
  status: 'NOT_ACCEPTED' | 'ACCEPTED' | 'REJECTED';
  note: string;
}

export interface IDataRequest {
  id: string;
  projectId: string;
  authorId: string;
  assignedUserId: string;
  fileId: string;
  createdAt: string;
  estimateAt: string;
  status: 'IN_PROGRESS' | 'DONE';
  note?: string;
}

// export interface IBuildingObjectNew {
//   projectId: string;
//   requestId: string;
//   dictionaryId: string;
//   taskDefId: string;
//   name: string;
//   unit: string;
//   value: number;
//   status: string;
//   initUserId: string;
//   note: string
// }

export interface IUpdateObjectNew {
  id: string;
  value: number;
  userId?: string;
  note: string;
}

export interface IApproveBody {
  id: string;
  projectId: string;
  taskDefId: string;
}

export declare namespace IBuildingObjectService {
  type IGetBuildingObjects = IBuildingObject[];
  type IGetBuildingObject = IBuildingObject;
  type ICreateBuildingObject = IBuildingObject;
  type IUpdateBuildingObject = IBuildingObject;
  type IApproveBuildingObject = IBuildingObject;
  type IGetDataRequest = IDataRequest;

  declare namespace Data {
    type IGetBuildingObjects = IGetBuildingObjectsData;
    type ICreateBuildingObject = IDataBuildingObject;
    type IUpdateBuildingObject = IUpdateObjectNew;
    type IApproveBuildingObject = IApproveBody;
    type IGetDataRequest = ICreateDataRequest;
  }
}
