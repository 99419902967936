import tw, { styled } from 'twin.macro';

type LoadingIndicatorPros = {
  className?: string;
};

const LoadingIndicator: React.FC<LoadingIndicatorPros> = props => {
  return (
    <Loading {...props}>
      <div />
      <div />
      <div />
      <div />
    </Loading>
  );
};

const Loading = styled.figure`
  ${tw`height[22px] width[22px] relative inline-block text-primary-dark`}

  & div {
    ${tw`w-4 h-4 absolute margin[2px] border-radius[50%] border-2 border-current`}
    animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: currentColor transparent transparent transparent;
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default LoadingIndicator;
