import React from 'react';
import tw, { styled } from 'twin.macro';
import { observer } from 'mobx-react-lite';
import { StoreState, useFileSystemState } from '../../../store';

const animationTime = 800;
const intervalTimeout = 20;
const widthVelocity = (intervalTimeout * 2) / animationTime;

// eslint-disable-next-line @typescript-eslint/ban-types
type LoadingProgressBarProps = {};

// TODO: Перевести на transform
const LoadingProgressBar = observer<LoadingProgressBarProps>(() => {
  const store = useFileSystemState();
  const ref = React.useRef(null);
  const targetReached = React.useRef(false);
  const interval = React.useRef<any>(false);
  const width = React.useRef(0);
  const target = React.useRef(9);

  const animateWidth = React.useCallback(value => {
    const stopProgress = () => {
      if (width.current >= 100) {
        clearTimeout(interval.current as any);
        interval.current = null;
        setTimeout(() => {
          if (ref.current) ref.current.style.opacity = 0;
          width.current = 0;
        }, 150);
        return;
      }
    };

    if (ref.current) {
      if (width.current >= target.current && !targetReached.current) {
        targetReached.current = true;
      }

      if (value && value >= 100) {
        width.current = 100;
        stopProgress();
      } else {
        if (width.current <= 65) {
          width.current += !targetReached.current ? widthVelocity : widthVelocity / 50;
        } else if (width.current <= 85) {
          width.current += !targetReached.current ? widthVelocity / 2 : widthVelocity / 50;
        } else {
          width.current += !targetReached.current ? widthVelocity / 50 : widthVelocity / 150;
        }
      }
      ref.current.style.width = width.current + '%';
    }
  }, []);

  const startInterval = React.useCallback(() => {
    if (!interval.current) {
      ref.current.style.opacity = 100;
      interval.current = setInterval(animateWidth, intervalTimeout);
    }
  }, []);

  const startProgressBar = React.useCallback(() => {
    target.current = 65;
    ref.current.style.opacity = 100;
    startInterval();
  }, []);

  const stopProgressBar = React.useCallback(() => {
    target.current = 200;
    targetReached.current = false;
    animateWidth(200);
  }, []);

  React.useEffect(() => {
    if (store.state === StoreState.Inital || store.state === StoreState.Loading || store.state === StoreState.BlockerLoading) {
      startProgressBar();
    }

    if (store.state === StoreState.Success) {
      stopProgressBar();
    }
  }, [startProgressBar, stopProgressBar, store.state]);

  /* React.useEffect(() => {
    if (store.progressPercantage) animateWidth(store.progressPercantage);
  }, [animateWidth, startInterval, store.progressPercantage]); */

  return <ProgressBar ref={ref} />;
});

const ProgressBar = styled.figure`
  ${tw`absolute -bottom-1 -left-px height[3px] rounded-r-full transition-opacity opacity-75 z-50`}
  background: linear-gradient(90deg, #ce242a 0%, #ee3422 100%);

  &:before {
    ${tw`content[''] absolute top-0 right-0 height[3px] w-5 opacity-30 rounded-full`}
    box-shadow: #ce242a 1px 0 4px 1px;
    clip: rect(-6px, 26px, 14px, 10px);
  }

  &:after {
    ${tw`content[''] absolute top-0 right[-80px] height[3px] width[180px] opacity-30 rounded-full`}
    box-shadow: #ce242a 1px 0 4px 1px;
    clip: rect(-6px, 90px, 14px, -6px);
  }
`;

export default LoadingProgressBar;
