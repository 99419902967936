export const defaultMapboxStyle = {
  version: 8,
  name: 'Basic',
  metadata: {
    'mapbox:type': 'default',
    'mapbox:origin': 'basic-v1',
    'mapbox:sdk-support': { android: '9.3.0', ios: '5.10.0', js: '2.0.0' },
    'mapbox:autocomposite': true,
    'mapbox:groups': {
      'Transit, transit-labels': { name: 'Transit, transit-labels', collapsed: false },
      'Administrative boundaries, admin': { name: 'Administrative boundaries, admin', collapsed: false },
      'Road network, bridges': { name: 'Road network, bridges', collapsed: false },
      'Land, water, & sky, water': { name: 'Land, water, & sky, water', collapsed: false },
      'Road network, tunnels': { name: 'Road network, tunnels', collapsed: false },
      'Road network, road-labels': { name: 'Road network, road-labels', collapsed: false },
      'Buildings, built': { name: 'Buildings, built', collapsed: false },
      'Natural features, natural-labels': { name: 'Natural features, natural-labels', collapsed: false },
      'Road network, surface': { name: 'Road network, surface', collapsed: false },
      'Land, water, & sky, built': { name: 'Land, water, & sky, built', collapsed: false },
      'Walking, cycling, etc., barriers-bridges': { name: 'Walking, cycling, etc., barriers-bridges', collapsed: false },
      'Place labels, place-labels': { name: 'Place labels, place-labels', collapsed: false },
      'Buildings, extruded': { name: 'Buildings, extruded', collapsed: false },
      'Point of interest labels, poi-labels': { name: 'Point of interest labels, poi-labels', collapsed: false },
      'Walking, cycling, etc., tunnels': { name: 'Walking, cycling, etc., tunnels', collapsed: false },
      'Walking, cycling, etc., walking-cycling-labels': { name: 'Walking, cycling, etc., walking-cycling-labels', collapsed: false },
      'Walking, cycling, etc., surface': { name: 'Walking, cycling, etc., surface', collapsed: false },
      'Transit, built': { name: 'Transit, built', collapsed: false },
      'Land, water, & sky, land': { name: 'Land, water, & sky, land', collapsed: false },
    },
    'mapbox:trackposition': true,
  },
  center: [35.77299234319423, 58.172536869994104],
  zoom: 8.922021459399337,
  bearing: 32.799999999999955,
  pitch: 37,
  sources: { composite: { url: '', type: 'vector' } },
  sprite: '',
  glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
  layers: [
    {
      id: 'land',
      type: 'background',
      layout: { visibility: 'none' },
      paint: { 'background-color': 'rgb(235, 243, 245)' },
      metadata: { 'mapbox:featureComponent': 'land-and-water', 'mapbox:group': 'Land, water, & sky, land' },
    },
    {
      minzoom: 5,
      layout: { visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'land-and-water', 'mapbox:group': 'Land, water, & sky, land' },
      filter: ['==', ['get', 'class'], 'national_park'],
      type: 'fill',
      source: 'composite',
      id: 'national-park',
      paint: { 'fill-color': 'hsl(133, 38%, 100%)', 'fill-opacity': ['interpolate', ['linear'], ['zoom'], 5, 0, 6, 0.5, 10, 0.5] },
      'source-layer': 'landuse_overlay',
    },
    {
      minzoom: 5,
      layout: { visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'land-and-water', 'mapbox:group': 'Land, water, & sky, land' },
      filter: [
        'match',
        ['get', 'class'],
        ['park', 'airport', 'glacier', 'pitch', 'sand', 'facility'],
        true,
        'cemetery',
        true,
        'school',
        true,
        'hospital',
        true,
        false,
      ],
      type: 'fill',
      source: 'composite',
      id: 'landuse',
      paint: {
        'fill-color': [
          'interpolate',
          ['linear'],
          ['zoom'],
          15,
          [
            'match',
            ['get', 'class'],
            'park',
            'hsl(133, 38%, 100%)',
            'airport',
            'hsl(135, 34%, 95%)',
            'cemetery',
            'hsl(115, 35%, 100%)',
            'glacier',
            'hsl(197, 23%, 100%)',
            'hospital',
            'hsl(200, 23%, 91%)',
            'pitch',
            'hsl(133, 39%, 95%)',
            'sand',
            'hsl(192, 37%, 93%)',
            'school',
            'hsl(148, 20%, 85%)',
            'rgb(219, 233, 237)',
          ],
          16,
          [
            'match',
            ['get', 'class'],
            'park',
            'hsl(133, 38%, 100%)',
            'airport',
            'hsl(135, 48%, 93%)',
            'cemetery',
            'hsl(115, 35%, 100%)',
            'glacier',
            'hsl(197, 23%, 100%)',
            'hospital',
            'hsl(200, 11%, 93%)',
            'pitch',
            'hsl(133, 39%, 95%)',
            'sand',
            'hsl(192, 37%, 93%)',
            'school',
            'hsl(148, 20%, 85%)',
            'rgb(219, 233, 237)',
          ],
        ],
        'fill-opacity': ['interpolate', ['linear'], ['zoom'], 5, 0, 6, ['match', ['get', 'class'], 'glacier', 0.5, 1]],
      },
      'source-layer': 'landuse',
    },
    {
      minzoom: 15,
      layout: { visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'land-and-water', 'mapbox:group': 'Land, water, & sky, land' },
      filter: ['==', ['get', 'class'], 'pitch'],
      type: 'line',
      source: 'composite',
      id: 'pitch-outline',
      paint: { 'line-color': 'hsl(115, 22%, 90%)' },
      'source-layer': 'landuse',
    },
    {
      id: 'waterway',
      type: 'line',
      source: 'composite',
      'source-layer': 'waterway',
      minzoom: 8,
      layout: { 'line-cap': ['step', ['zoom'], 'butt', 11, 'round'], 'line-join': 'round', visibility: 'none' },
      paint: {
        'line-color': 'hsl(197, 33%, 81%)',
        'line-width': [
          'interpolate',
          ['exponential', 1.3],
          ['zoom'],
          9,
          ['match', ['get', 'class'], ['canal', 'river'], 0.1, 0],
          20,
          ['match', ['get', 'class'], ['canal', 'river'], 8, 3],
        ],
        'line-opacity': ['interpolate', ['linear'], ['zoom'], 8, 0, 8.5, 1],
      },
      metadata: { 'mapbox:featureComponent': 'land-and-water', 'mapbox:group': 'Land, water, & sky, water' },
    },
    {
      id: 'water',
      type: 'fill',
      source: 'composite',
      'source-layer': 'water',
      layout: { visibility: 'none' },
      paint: { 'fill-color': 'hsl(197, 33%, 81%)' },
      metadata: { 'mapbox:featureComponent': 'land-and-water', 'mapbox:group': 'Land, water, & sky, water' },
    },
    {
      minzoom: 13,
      layout: { visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'land-and-water', 'mapbox:group': 'Land, water, & sky, built' },
      filter: ['all', ['==', ['geometry-type'], 'Polygon'], ['==', ['get', 'class'], 'land']],
      type: 'fill',
      source: 'composite',
      id: 'land-structure-polygon',
      paint: { 'fill-color': 'rgb(235, 243, 245)' },
      'source-layer': 'structure',
    },
    {
      minzoom: 13,
      layout: { 'line-cap': 'round', visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'land-and-water', 'mapbox:group': 'Land, water, & sky, built' },
      filter: ['all', ['==', ['geometry-type'], 'LineString'], ['==', ['get', 'class'], 'land']],
      type: 'line',
      source: 'composite',
      id: 'land-structure-line',
      paint: { 'line-width': ['interpolate', ['exponential', 1.99], ['zoom'], 14, 0.75, 20, 40], 'line-color': 'rgb(235, 243, 245)' },
      'source-layer': 'structure',
    },
    {
      minzoom: 11,
      layout: { visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'transit', 'mapbox:group': 'Transit, built' },
      filter: ['all', ['==', ['geometry-type'], 'Polygon'], ['match', ['get', 'type'], ['runway', 'taxiway', 'helipad'], true, false]],
      type: 'fill',
      source: 'composite',
      id: 'aeroway-polygon',
      paint: {
        'fill-color': ['interpolate', ['linear'], ['zoom'], 15, 'hsl(135, 26%, 86%)', 16, 'hsl(135, 15%, 88%)'],
        'fill-opacity': ['interpolate', ['linear'], ['zoom'], 11, 0, 11.5, 1],
      },
      'source-layer': 'aeroway',
    },
    {
      minzoom: 9,
      layout: { visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'transit', 'mapbox:group': 'Transit, built' },
      filter: ['==', ['geometry-type'], 'LineString'],
      type: 'line',
      source: 'composite',
      id: 'aeroway-line',
      paint: {
        'line-color': ['interpolate', ['linear'], ['zoom'], 15, 'hsl(135, 26%, 86%)', 16, 'hsl(135, 15%, 88%)'],
        'line-width': [
          'interpolate',
          ['exponential', 1.5],
          ['zoom'],
          9,
          ['match', ['get', 'type'], 'runway', 1, 0.5],
          18,
          ['match', ['get', 'type'], 'runway', 80, 20],
        ],
      },
      'source-layer': 'aeroway',
    },
    {
      minzoom: 14,
      layout: { visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'walking-cycling', 'mapbox:group': 'Walking, cycling, etc., tunnels' },
      filter: [
        'all',
        ['==', ['get', 'structure'], 'tunnel'],
        ['==', ['get', 'class'], 'path'],
        ['!=', ['get', 'type'], 'steps'],
        ['==', ['geometry-type'], 'LineString'],
      ],
      type: 'line',
      source: 'composite',
      id: 'tunnel-path',
      paint: {
        'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 15, 1, 18, 4],
        'line-color': 'rgb(202, 223, 229)',
        'line-dasharray': [
          'step',
          ['zoom'],
          ['literal', [1, 0]],
          15,
          ['literal', [1.75, 1]],
          16,
          ['literal', [1, 0.75]],
          17,
          ['literal', [1, 0.5]],
        ],
      },
      'source-layer': 'road',
    },
    {
      minzoom: 14,
      layout: { visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'walking-cycling', 'mapbox:group': 'Walking, cycling, etc., tunnels' },
      filter: ['all', ['==', ['get', 'structure'], 'tunnel'], ['==', ['get', 'type'], 'steps'], ['==', ['geometry-type'], 'LineString']],
      type: 'line',
      source: 'composite',
      id: 'tunnel-steps',
      paint: {
        'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 15, 1, 16, 1.6, 18, 6],
        'line-color': 'rgb(202, 223, 229)',
        'line-dasharray': [
          'step',
          ['zoom'],
          ['literal', [1, 0]],
          15,
          ['literal', [1.75, 1]],
          16,
          ['literal', [1, 0.75]],
          17,
          ['literal', [0.3, 0.3]],
        ],
      },
      'source-layer': 'road',
    },
    {
      minzoom: 13,
      layout: { visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'walking-cycling', 'mapbox:group': 'Walking, cycling, etc., tunnels' },
      filter: [
        'all',
        ['==', ['get', 'structure'], 'tunnel'],
        ['==', ['get', 'class'], 'pedestrian'],
        ['==', ['geometry-type'], 'LineString'],
      ],
      type: 'line',
      source: 'composite',
      id: 'tunnel-pedestrian',
      paint: {
        'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 14, 0.5, 18, 12],
        'line-color': 'rgb(202, 223, 229)',
        'line-dasharray': ['step', ['zoom'], ['literal', [1, 0]], 15, ['literal', [1.5, 0.4]], 16, ['literal', [1, 0.2]]],
      },
      'source-layer': 'road',
    },
    {
      minzoom: 13,
      layout: { visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'road-network', 'mapbox:group': 'Road network, tunnels' },
      filter: [
        'all',
        ['==', ['get', 'structure'], 'tunnel'],
        [
          'step',
          ['zoom'],
          [
            'match',
            ['get', 'class'],
            [
              'motorway',
              'motorway_link',
              'trunk',
              'trunk_link',
              'primary',
              'secondary',
              'tertiary',
              'street',
              'street_limited',
              'primary_link',
              'track',
            ],
            true,
            false,
          ],
          14,
          [
            'match',
            ['get', 'class'],
            [
              'motorway',
              'motorway_link',
              'trunk',
              'trunk_link',
              'primary',
              'primary_link',
              'secondary',
              'secondary_link',
              'tertiary',
              'tertiary_link',
              'street',
              'street_limited',
              'service',
              'track',
            ],
            true,
            false,
          ],
        ],
        ['==', ['geometry-type'], 'LineString'],
      ],
      type: 'line',
      source: 'composite',
      id: 'tunnel-simple',
      paint: {
        'line-width': [
          'interpolate',
          ['exponential', 1.5],
          ['zoom'],
          13,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary'],
            4,
            ['secondary', 'tertiary'],
            2.5,
            ['motorway_link', 'trunk_link', 'street', 'street_limited', 'primary_link'],
            1,
            0.5,
          ],
          18,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary'],
            32,
            ['secondary', 'tertiary'],
            26,
            ['motorway_link', 'trunk_link', 'street', 'street_limited', 'primary_link'],
            18,
            12,
          ],
        ],
        'line-color': 'hsl(171, 38%, 93%)',
      },
      'source-layer': 'road',
    },
    {
      minzoom: 12,
      layout: { 'line-join': ['step', ['zoom'], 'miter', 14, 'round'], visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'walking-cycling', 'mapbox:group': 'Walking, cycling, etc., surface' },
      filter: [
        'all',
        ['==', ['get', 'class'], 'path'],
        [
          'step',
          ['zoom'],
          ['!', ['match', ['get', 'type'], ['steps', 'sidewalk', 'crossing'], true, false]],
          16,
          ['!=', ['get', 'type'], 'steps'],
        ],
        ['match', ['get', 'structure'], ['none', 'ford'], true, false],
        ['==', ['geometry-type'], 'LineString'],
      ],
      type: 'line',
      source: 'composite',
      id: 'road-path',
      paint: {
        'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 13, 0.5, 14, 1, 15, 1, 18, 4],
        'line-color': 'rgb(255, 255, 255)',
        'line-dasharray': [
          'step',
          ['zoom'],
          ['literal', [4, 0.3]],
          15,
          ['literal', [1.75, 0.3]],
          16,
          ['literal', [1, 0.3]],
          17,
          ['literal', [1, 0.25]],
        ],
      },
      'source-layer': 'road',
    },
    {
      minzoom: 14,
      layout: { 'line-join': 'round', visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'walking-cycling', 'mapbox:group': 'Walking, cycling, etc., surface' },
      filter: [
        'all',
        ['==', ['get', 'type'], 'steps'],
        ['match', ['get', 'structure'], ['none', 'ford'], true, false],
        ['==', ['geometry-type'], 'LineString'],
      ],
      type: 'line',
      source: 'composite',
      id: 'road-steps',
      paint: {
        'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 15, 1, 16, 1.6, 18, 6],
        'line-color': 'rgb(255, 255, 255)',
        'line-dasharray': [
          'step',
          ['zoom'],
          ['literal', [1, 0]],
          15,
          ['literal', [1.75, 1]],
          16,
          ['literal', [1, 0.75]],
          17,
          ['literal', [0.3, 0.3]],
        ],
      },
      'source-layer': 'road',
    },
    {
      minzoom: 12,
      layout: { 'line-join': ['step', ['zoom'], 'miter', 14, 'round'], visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'walking-cycling', 'mapbox:group': 'Walking, cycling, etc., surface' },
      filter: [
        'all',
        ['==', ['get', 'class'], 'pedestrian'],
        ['match', ['get', 'structure'], ['none', 'ford'], true, false],
        ['==', ['geometry-type'], 'LineString'],
      ],
      type: 'line',
      source: 'composite',
      id: 'road-pedestrian',
      paint: {
        'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 14, 0.5, 18, 12],
        'line-color': 'rgb(255, 255, 255)',
        'line-dasharray': ['step', ['zoom'], ['literal', [1, 0]], 15, ['literal', [1.5, 0.4]], 16, ['literal', [1, 0.2]]],
      },
      'source-layer': 'road',
    },
    {
      minzoom: 5,
      layout: {
        'line-cap': ['step', ['zoom'], 'butt', 14, 'round'],
        'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
        visibility: 'none',
      },
      metadata: { 'mapbox:featureComponent': 'road-network', 'mapbox:group': 'Road network, surface' },
      filter: [
        'all',
        [
          'step',
          ['zoom'],
          ['match', ['get', 'class'], ['motorway', 'trunk'], true, false],
          6,
          ['match', ['get', 'class'], ['motorway', 'trunk', 'primary'], true, false],
          8,
          ['match', ['get', 'class'], ['motorway', 'trunk', 'primary', 'secondary'], true, false],
          10,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary', 'secondary', 'tertiary', 'motorway_link', 'trunk_link'],
            true,
            false,
          ],
          11,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'motorway_link', 'trunk', 'trunk_link', 'primary', 'secondary', 'tertiary', 'street'],
            true,
            false,
          ],
          12,
          [
            'match',
            ['get', 'class'],
            [
              'motorway',
              'motorway_link',
              'trunk',
              'trunk_link',
              'primary',
              'secondary',
              'tertiary',
              'street',
              'street_limited',
              'primary_link',
            ],
            true,
            false,
          ],
          13,
          [
            'match',
            ['get', 'class'],
            [
              'motorway',
              'motorway_link',
              'trunk',
              'trunk_link',
              'primary',
              'secondary',
              'tertiary',
              'street',
              'street_limited',
              'primary_link',
              'track',
            ],
            true,
            false,
          ],
          14,
          [
            'match',
            ['get', 'class'],
            [
              'motorway',
              'motorway_link',
              'trunk',
              'trunk_link',
              'primary',
              'primary_link',
              'secondary',
              'secondary_link',
              'tertiary',
              'tertiary_link',
              'street',
              'street_limited',
              'service',
              'track',
            ],
            true,
            false,
          ],
        ],
        ['match', ['get', 'structure'], ['none', 'ford'], true, false],
        ['==', ['geometry-type'], 'LineString'],
      ],
      type: 'line',
      source: 'composite',
      id: 'road-simple',
      paint: {
        'line-width': [
          'interpolate',
          ['exponential', 1.5],
          ['zoom'],
          5,
          ['match', ['get', 'class'], ['motorway', 'trunk', 'primary'], 0.75, ['secondary', 'tertiary'], 0.1, 0],
          13,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary'],
            4,
            ['secondary', 'tertiary'],
            2.5,
            ['motorway_link', 'trunk_link', 'primary_link', 'street', 'street_limited'],
            1,
            0.5,
          ],
          18,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary'],
            32,
            ['secondary', 'tertiary'],
            26,
            ['motorway_link', 'trunk_link', 'primary_link', 'street', 'street_limited'],
            18,
            10,
          ],
        ],
        'line-color': [
          'match',
          ['get', 'class'],
          ['primary_link', 'secondary_link', 'tertiary_link', 'street', 'street_limited', 'service', 'track'],
          'hsl(171, 38%, 95%)',
          'hsl(171, 38%, 100%)',
        ],
      },
      'source-layer': 'road',
    },
    {
      minzoom: 14,
      layout: { 'line-join': 'round', visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'walking-cycling', 'mapbox:group': 'Walking, cycling, etc., barriers-bridges' },
      filter: [
        'all',
        ['==', ['get', 'structure'], 'bridge'],
        ['==', ['get', 'class'], 'path'],
        ['==', ['geometry-type'], 'LineString'],
        ['!=', ['get', 'type'], 'steps'],
      ],
      type: 'line',
      source: 'composite',
      id: 'bridge-path',
      paint: {
        'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 15, 1, 18, 4],
        'line-color': 'rgb(255, 255, 255)',
        'line-dasharray': [
          'step',
          ['zoom'],
          ['literal', [4, 0.3]],
          15,
          ['literal', [1.75, 0.3]],
          16,
          ['literal', [1, 0.3]],
          17,
          ['literal', [1, 0.25]],
        ],
      },
      'source-layer': 'road',
    },
    {
      minzoom: 14,
      layout: { 'line-join': 'round', visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'walking-cycling', 'mapbox:group': 'Walking, cycling, etc., barriers-bridges' },
      filter: ['all', ['==', ['get', 'type'], 'steps'], ['==', ['get', 'structure'], 'bridge'], ['==', ['geometry-type'], 'LineString']],
      type: 'line',
      source: 'composite',
      id: 'bridge-steps',
      paint: {
        'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 15, 1, 16, 1.6, 18, 6],
        'line-color': 'rgb(255, 255, 255)',
        'line-dasharray': [
          'step',
          ['zoom'],
          ['literal', [1, 0]],
          15,
          ['literal', [1.75, 1]],
          16,
          ['literal', [1, 0.75]],
          17,
          ['literal', [0.3, 0.3]],
        ],
      },
      'source-layer': 'road',
    },
    {
      minzoom: 13,
      layout: { 'line-join': 'round', visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'walking-cycling', 'mapbox:group': 'Walking, cycling, etc., barriers-bridges' },
      filter: [
        'all',
        ['==', ['get', 'structure'], 'bridge'],
        ['==', ['get', 'class'], 'pedestrian'],
        ['==', ['geometry-type'], 'LineString'],
      ],
      type: 'line',
      source: 'composite',
      id: 'bridge-pedestrian',
      paint: {
        'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 14, 0.5, 18, 12],
        'line-color': 'rgb(255, 255, 255)',
        'line-dasharray': ['step', ['zoom'], ['literal', [1, 0]], 15, ['literal', [1.5, 0.4]], 16, ['literal', [1, 0.2]]],
      },
      'source-layer': 'road',
    },
    {
      minzoom: 13,
      layout: { 'line-join': ['step', ['zoom'], 'miter', 14, 'round'], visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'road-network', 'mapbox:group': 'Road network, bridges' },
      filter: [
        'all',
        ['==', ['get', 'structure'], 'bridge'],
        [
          'step',
          ['zoom'],
          [
            'match',
            ['get', 'class'],
            [
              'motorway',
              'motorway_link',
              'trunk',
              'trunk_link',
              'primary',
              'secondary',
              'tertiary',
              'street',
              'street_limited',
              'primary_link',
              'track',
            ],
            true,
            false,
          ],
          14,
          [
            'match',
            ['get', 'class'],
            [
              'motorway',
              'motorway_link',
              'trunk',
              'trunk_link',
              'primary',
              'primary_link',
              'secondary',
              'secondary_link',
              'tertiary',
              'tertiary_link',
              'street',
              'street_limited',
              'service',
              'track',
            ],
            true,
            false,
          ],
        ],
        ['==', ['geometry-type'], 'LineString'],
      ],
      type: 'line',
      source: 'composite',
      id: 'bridge-case-simple',
      paint: {
        'line-width': [
          'interpolate',
          ['exponential', 1.5],
          ['zoom'],
          13,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary'],
            6,
            ['secondary', 'tertiary'],
            4,
            ['motorway_link', 'trunk_link', 'street', 'street_limited', 'primary_link'],
            2.5,
            1.25,
          ],
          18,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary'],
            36,
            ['secondary', 'tertiary'],
            30,
            ['motorway_link', 'trunk_link', 'street', 'street_limited', 'primary_link'],
            22,
            16,
          ],
        ],
        'line-color': 'rgb(235, 243, 245)',
      },
      'source-layer': 'road',
    },
    {
      minzoom: 13,
      layout: {
        'line-cap': ['step', ['zoom'], 'butt', 14, 'round'],
        'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
        visibility: 'none',
      },
      metadata: { 'mapbox:featureComponent': 'road-network', 'mapbox:group': 'Road network, bridges' },
      filter: [
        'all',
        ['==', ['get', 'structure'], 'bridge'],
        [
          'step',
          ['zoom'],
          ['match', ['get', 'class'], ['motorway', 'trunk'], true, false],
          13,
          [
            'match',
            ['get', 'class'],
            [
              'motorway',
              'motorway_link',
              'trunk',
              'trunk_link',
              'primary',
              'secondary',
              'tertiary',
              'street',
              'street_limited',
              'primary_link',
              'track',
            ],
            true,
            false,
          ],
          14,
          [
            'match',
            ['get', 'class'],
            [
              'motorway',
              'motorway_link',
              'trunk',
              'trunk_link',
              'primary',
              'primary_link',
              'secondary',
              'secondary_link',
              'tertiary',
              'tertiary_link',
              'street',
              'street_limited',
              'service',
              'track',
            ],
            true,
            false,
          ],
        ],
        ['==', ['geometry-type'], 'LineString'],
      ],
      type: 'line',
      source: 'composite',
      id: 'bridge-simple',
      paint: {
        'line-width': [
          'interpolate',
          ['exponential', 1.5],
          ['zoom'],
          13,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary'],
            4,
            ['secondary', 'tertiary'],
            2.5,
            ['motorway_link', 'trunk_link', 'street', 'street_limited', 'primary_link'],
            1,
            0.5,
          ],
          18,
          [
            'match',
            ['get', 'class'],
            ['motorway', 'trunk', 'primary'],
            32,
            ['secondary', 'tertiary'],
            26,
            ['motorway_link', 'trunk_link', 'street', 'street_limited', 'primary_link'],
            18,
            12,
          ],
        ],
        'line-color': [
          'match',
          ['get', 'class'],
          ['primary_link', 'secondary_link', 'tertiary_link', 'street', 'street_limited', 'service', 'track'],
          'hsl(171, 38%, 95%)',
          'hsl(171, 38%, 100%)',
        ],
      },
      'source-layer': 'road',
    },
    {
      minzoom: 15,
      layout: { visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'buildings', 'mapbox:group': 'Buildings, extruded' },
      filter: ['all', ['==', ['get', 'underground'], 'false'], ['!=', ['get', 'extrude'], 'false']],
      type: 'fill-extrusion',
      source: 'composite',
      id: 'building-extrusion',
      paint: { 'fill-extrusion-color': 'rgb(161, 199, 208)', 'fill-extrusion-height': ['get', 'height'], 'fill-extrusion-opacity': 0.3 },
      'source-layer': 'building',
    },
    {
      minzoom: 7,
      layout: { 'line-join': 'bevel', visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'admin-boundaries', 'mapbox:group': 'Administrative boundaries, admin' },
      filter: [
        'all',
        ['==', ['get', 'admin_level'], 1],
        ['==', ['get', 'maritime'], 'false'],
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
      ],
      type: 'line',
      source: 'composite',
      id: 'admin-1-boundary-bg',
      paint: {
        'line-color': ['interpolate', ['linear'], ['zoom'], 8, 'rgb(235, 243, 245)', 16, 'hsl(169, 14%, 94%)'],
        'line-width': ['interpolate', ['linear'], ['zoom'], 7, 3.75, 12, 5.5],
        'line-opacity': ['interpolate', ['linear'], ['zoom'], 7, 0, 8, 0.75],
        'line-dasharray': [1, 0],
        'line-blur': ['interpolate', ['linear'], ['zoom'], 3, 0, 8, 3],
      },
      'source-layer': 'admin',
    },
    {
      minzoom: 1,
      layout: { visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'admin-boundaries', 'mapbox:group': 'Administrative boundaries, admin' },
      filter: [
        'all',
        ['==', ['get', 'admin_level'], 0],
        ['==', ['get', 'maritime'], 'false'],
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
      ],
      type: 'line',
      source: 'composite',
      id: 'admin-0-boundary-bg',
      paint: {
        'line-width': ['interpolate', ['linear'], ['zoom'], 3, 3.5, 10, 8],
        'line-color': ['interpolate', ['linear'], ['zoom'], 6, 'rgb(235, 243, 245)', 8, 'hsl(169, 14%, 94%)'],
        'line-opacity': ['interpolate', ['linear'], ['zoom'], 3, 0, 4, 0.5],
        'line-blur': ['interpolate', ['linear'], ['zoom'], 3, 0, 10, 2],
      },
      'source-layer': 'admin',
    },
    {
      minzoom: 2,
      layout: { 'line-join': 'round', 'line-cap': 'round', visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'admin-boundaries', 'mapbox:group': 'Administrative boundaries, admin' },
      filter: [
        'all',
        ['==', ['get', 'admin_level'], 1],
        ['==', ['get', 'maritime'], 'false'],
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
      ],
      type: 'line',
      source: 'composite',
      id: 'admin-1-boundary',
      paint: {
        'line-dasharray': ['step', ['zoom'], ['literal', [2, 0]], 7, ['literal', [2, 2, 6, 2]]],
        'line-width': ['interpolate', ['linear'], ['zoom'], 7, 0.75, 12, 1.5],
        'line-opacity': ['interpolate', ['linear'], ['zoom'], 2, 0, 3, 1],
        'line-color': ['interpolate', ['linear'], ['zoom'], 3, 'hsl(169, 4%, 77%)', 7, 'hsl(169, 2%, 62%)'],
      },
      'source-layer': 'admin',
    },
    {
      minzoom: 1,
      layout: { 'line-join': 'round', 'line-cap': 'round', visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'admin-boundaries', 'mapbox:group': 'Administrative boundaries, admin' },
      filter: [
        'all',
        ['==', ['get', 'admin_level'], 0],
        ['==', ['get', 'disputed'], 'false'],
        ['==', ['get', 'maritime'], 'false'],
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
      ],
      type: 'line',
      source: 'composite',
      id: 'admin-0-boundary',
      paint: {
        'line-color': 'hsl(169, 2%, 51%)',
        'line-width': ['interpolate', ['linear'], ['zoom'], 3, 0.5, 10, 2],
        'line-dasharray': [10, 0],
      },
      'source-layer': 'admin',
    },
    {
      minzoom: 1,
      layout: { 'line-join': 'round', visibility: 'none' },
      metadata: { 'mapbox:featureComponent': 'admin-boundaries', 'mapbox:group': 'Administrative boundaries, admin' },
      filter: [
        'all',
        ['==', ['get', 'disputed'], 'true'],
        ['==', ['get', 'admin_level'], 0],
        ['==', ['get', 'maritime'], 'false'],
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
      ],
      type: 'line',
      source: 'composite',
      id: 'admin-0-boundary-disputed',
      paint: {
        'line-color': 'hsl(169, 2%, 51%)',
        'line-width': ['interpolate', ['linear'], ['zoom'], 3, 0.5, 10, 2],
        'line-dasharray': [
          'step',
          ['zoom'],
          ['literal', [3.25, 3.25]],
          6,
          ['literal', [2.5, 2.5]],
          7,
          ['literal', [2, 2.25]],
          8,
          ['literal', [1.75, 2]],
        ],
      },
      'source-layer': 'admin',
    },
    {
      minzoom: 12,
      layout: {
        'text-size': [
          'interpolate',
          ['linear'],
          ['zoom'],
          10,
          ['match', ['get', 'class'], ['motorway', 'trunk', 'primary', 'secondary', 'tertiary'], 10, 9],
          18,
          ['match', ['get', 'class'], ['motorway', 'trunk', 'primary', 'secondary', 'tertiary'], 16, 14],
        ],
        'text-max-angle': 30,
        'text-font': ['Arial'],
        'symbol-placement': 'line',
        'text-padding': 1,
        visibility: 'none',
        'text-rotation-alignment': 'map',
        'text-pitch-alignment': 'viewport',
        'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
        'text-letter-spacing': 0.01,
      },
      metadata: { 'mapbox:featureComponent': 'road-network', 'mapbox:group': 'Road network, road-labels' },
      filter: [
        'match',
        ['get', 'class'],
        ['motorway', 'trunk', 'primary', 'secondary', 'tertiary', 'street', 'street_limited'],
        true,
        false,
      ],
      type: 'symbol',
      source: 'composite',
      id: 'road-label-simple',
      paint: { 'text-color': 'rgb(79, 142, 158)', 'text-halo-color': 'hsl(171, 38%, 100%)', 'text-halo-width': 1 },
      'source-layer': 'road',
    },
    {
      minzoom: 12,
      layout: {
        'text-size': [
          'interpolate',
          ['linear'],
          ['zoom'],
          10,
          ['match', ['get', 'class'], 'pedestrian', 9, 6.5],
          18,
          ['match', ['get', 'class'], 'pedestrian', 14, 13],
        ],
        'text-max-angle': 30,
        'text-font': ['Arial'],
        'symbol-placement': 'line',
        'text-padding': 1,
        visibility: 'none',
        'text-rotation-alignment': 'map',
        'text-pitch-alignment': 'viewport',
        'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
        'text-letter-spacing': 0.01,
      },
      metadata: { 'mapbox:featureComponent': 'walking-cycling', 'mapbox:group': 'Walking, cycling, etc., walking-cycling-labels' },
      filter: [
        'step',
        ['zoom'],
        ['match', ['get', 'class'], ['pedestrian'], true, false],
        15,
        ['match', ['get', 'class'], ['path', 'pedestrian'], true, false],
      ],
      type: 'symbol',
      source: 'composite',
      id: 'path-pedestrian-label',
      paint: { 'text-color': 'rgb(79, 142, 158)', 'text-halo-color': 'rgb(255, 255, 255)', 'text-halo-width': 1, 'text-halo-blur': 1 },
      'source-layer': 'road',
    },
    {
      minzoom: 13,
      layout: {
        'text-font': ['Arial'],
        'text-max-angle': 30,
        'symbol-spacing': ['interpolate', ['linear', 1], ['zoom'], 15, 250, 17, 400],
        'text-size': ['interpolate', ['linear'], ['zoom'], 13, 12, 18, 16],
        'symbol-placement': 'line',
        'text-pitch-alignment': 'viewport',
        'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
        visibility: 'none',
      },
      metadata: { 'mapbox:featureComponent': 'natural-features', 'mapbox:group': 'Natural features, natural-labels' },
      filter: [
        'all',
        [
          'match',
          ['get', 'class'],
          ['canal', 'river', 'stream'],
          ['match', ['get', 'worldview'], ['all', 'US'], true, false],
          ['disputed_canal', 'disputed_river', 'disputed_stream'],
          ['all', ['==', ['get', 'disputed'], 'true'], ['match', ['get', 'worldview'], ['all', 'US'], true, false]],
          false,
        ],
        ['==', ['geometry-type'], 'LineString'],
      ],
      type: 'symbol',
      source: 'composite',
      id: 'waterway-label',
      paint: { 'text-color': 'hsl(197, 1%, 57%)' },
      'source-layer': 'natural_label',
    },
    {
      minzoom: 4,
      layout: {
        'text-size': ['step', ['zoom'], ['step', ['get', 'sizerank'], 18, 5, 12], 17, ['step', ['get', 'sizerank'], 18, 13, 12]],
        'text-max-angle': 30,
        'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
        'text-font': ['Arial'],
        'symbol-placement': 'line-center',
        'text-pitch-alignment': 'viewport',
        visibility: 'none',
      },
      metadata: { 'mapbox:featureComponent': 'natural-features', 'mapbox:group': 'Natural features, natural-labels' },
      filter: [
        'all',
        [
          'match',
          ['get', 'class'],
          ['glacier', 'landform'],
          ['match', ['get', 'worldview'], ['all', 'US'], true, false],
          ['disputed_glacier', 'disputed_landform'],
          ['all', ['==', ['get', 'disputed'], 'true'], ['match', ['get', 'worldview'], ['all', 'US'], true, false]],
          false,
        ],
        ['==', ['geometry-type'], 'LineString'],
        ['<=', ['get', 'filterrank'], 2],
      ],
      type: 'symbol',
      source: 'composite',
      id: 'natural-line-label',
      paint: {
        'text-halo-width': 0.5,
        'text-halo-color': 'rgb(255, 255, 255)',
        'text-halo-blur': 0.5,
        'text-color': [
          'step',
          ['zoom'],
          ['step', ['get', 'sizerank'], 'hsl(202, 0%, 66%)', 5, 'hsl(202, 4%, 56%)'],
          17,
          ['step', ['get', 'sizerank'], 'hsl(202, 0%, 66%)', 13, 'hsl(202, 4%, 56%)'],
        ],
      },
      'source-layer': 'natural_label',
    },
    {
      minzoom: 4,
      layout: {
        'text-size': ['step', ['zoom'], ['step', ['get', 'sizerank'], 18, 5, 12], 17, ['step', ['get', 'sizerank'], 18, 13, 12]],
        'icon-image': ['step', ['zoom'], ['concat', ['get', 'maki'], '-11'], 15, ['concat', ['get', 'maki'], '-15']],
        'text-font': ['Arial'],
        'text-offset': [
          'step',
          ['zoom'],
          ['step', ['get', 'sizerank'], ['literal', [0, 0]], 5, ['literal', [0, 0.75]]],
          17,
          ['step', ['get', 'sizerank'], ['literal', [0, 0]], 13, ['literal', [0, 0.75]]],
        ],
        'text-anchor': [
          'step',
          ['zoom'],
          ['step', ['get', 'sizerank'], 'center', 5, 'top'],
          17,
          ['step', ['get', 'sizerank'], 'center', 13, 'top'],
        ],
        'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
        visibility: 'none',
      },
      metadata: { 'mapbox:featureComponent': 'natural-features', 'mapbox:group': 'Natural features, natural-labels' },
      filter: [
        'all',
        [
          'match',
          ['get', 'class'],
          ['dock', 'glacier', 'landform', 'water_feature', 'wetland'],
          ['match', ['get', 'worldview'], ['all', 'US'], true, false],
          ['disputed_dock', 'disputed_glacier', 'disputed_landform', 'disputed_water_feature', 'disputed_wetland'],
          ['all', ['==', ['get', 'disputed'], 'true'], ['match', ['get', 'worldview'], ['all', 'US'], true, false]],
          false,
        ],
        ['==', ['geometry-type'], 'Point'],
        ['<=', ['get', 'filterrank'], 2],
      ],
      type: 'symbol',
      source: 'composite',
      id: 'natural-point-label',
      paint: {
        'icon-opacity': ['step', ['zoom'], ['step', ['get', 'sizerank'], 0, 5, 1], 17, ['step', ['get', 'sizerank'], 0, 13, 1]],
        'text-halo-color': 'rgb(255, 255, 255)',
        'text-halo-width': 0.5,
        'text-halo-blur': 0.5,
        'text-color': [
          'step',
          ['zoom'],
          ['step', ['get', 'sizerank'], 'hsl(202, 0%, 66%)', 5, 'hsl(202, 4%, 56%)'],
          17,
          ['step', ['get', 'sizerank'], 'hsl(202, 0%, 66%)', 13, 'hsl(202, 4%, 56%)'],
        ],
      },
      'source-layer': 'natural_label',
    },
    {
      id: 'water-line-label',
      type: 'symbol',
      metadata: { 'mapbox:featureComponent': 'natural-features', 'mapbox:group': 'Natural features, natural-labels' },
      source: 'composite',
      'source-layer': 'natural_label',
      filter: [
        'all',
        [
          'match',
          ['get', 'class'],
          ['bay', 'ocean', 'reservoir', 'sea', 'water'],
          ['match', ['get', 'worldview'], ['all', 'US'], true, false],
          ['disputed_bay', 'disputed_ocean', 'disputed_reservoir', 'disputed_sea', 'disputed_water'],
          ['all', ['==', ['get', 'disputed'], 'true'], ['match', ['get', 'worldview'], ['all', 'US'], true, false]],
          false,
        ],
        ['==', ['geometry-type'], 'LineString'],
      ],
      layout: {
        'text-size': [
          'interpolate',
          ['linear'],
          ['zoom'],
          7,
          ['step', ['get', 'sizerank'], 20, 6, 18, 12, 12],
          10,
          ['step', ['get', 'sizerank'], 15, 9, 12],
          18,
          ['step', ['get', 'sizerank'], 15, 9, 14],
        ],
        'text-max-angle': 30,
        'text-letter-spacing': ['match', ['get', 'class'], 'ocean', 0.25, ['sea', 'bay'], 0.15, 0],
        'text-font': ['Arial'],
        'symbol-placement': 'line-center',
        'text-pitch-alignment': 'viewport',
        'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
        visibility: 'none',
      },
      paint: { 'text-color': ['match', ['get', 'class'], ['bay', 'ocean', 'sea'], 'hsl(197, 29%, 61%)', 'hsl(197, 1%, 57%)'] },
    },
    {
      id: 'water-point-label',
      type: 'symbol',
      source: 'composite',
      'source-layer': 'natural_label',
      filter: [
        'all',
        [
          'match',
          ['get', 'class'],
          ['bay', 'ocean', 'reservoir', 'sea', 'water'],
          ['match', ['get', 'worldview'], ['all', 'US'], true, false],
          ['disputed_bay', 'disputed_ocean', 'disputed_reservoir', 'disputed_sea', 'disputed_water'],
          ['all', ['==', ['get', 'disputed'], 'true'], ['match', ['get', 'worldview'], ['all', 'US'], true, false]],
          false,
        ],
        ['==', ['geometry-type'], 'Point'],
      ],
      layout: {
        'text-line-height': 1.3,
        'text-size': [
          'interpolate',
          ['linear'],
          ['zoom'],
          7,
          ['step', ['get', 'sizerank'], 20, 6, 15, 12, 12],
          10,
          ['step', ['get', 'sizerank'], 15, 9, 12],
        ],
        'text-font': ['Arial'],
        'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
        'text-letter-spacing': ['match', ['get', 'class'], 'ocean', 0.25, ['bay', 'sea'], 0.15, 0.01],
        'text-max-width': ['match', ['get', 'class'], 'ocean', 4, 'sea', 5, ['bay', 'water'], 7, 10],
        visibility: 'none',
      },
      paint: { 'text-color': ['match', ['get', 'class'], ['bay', 'ocean', 'sea'], 'hsl(197, 29%, 61%)', 'hsl(197, 1%, 57%)'] },
      metadata: { 'mapbox:featureComponent': 'natural-features', 'mapbox:group': 'Natural features, natural-labels' },
    },
    {
      minzoom: 6,
      layout: {
        'text-size': ['step', ['zoom'], ['step', ['get', 'sizerank'], 18, 5, 12], 17, ['step', ['get', 'sizerank'], 18, 13, 12]],
        'icon-image': [
          'step',
          ['zoom'],
          ['case', ['has', 'maki_beta'], ['image', ['concat', ['get', 'maki_beta'], '-11']], ['image', ['concat', ['get', 'maki'], '-11']]],
          15,
          ['case', ['has', 'maki_beta'], ['image', ['concat', ['get', 'maki_beta'], '-15']], ['image', ['concat', ['get', 'maki'], '-15']]],
        ],
        'text-font': ['Arial'],
        'text-offset': [
          'step',
          ['zoom'],
          ['step', ['get', 'sizerank'], ['literal', [0, 0]], 5, ['literal', [0, 0.75]]],
          17,
          ['step', ['get', 'sizerank'], ['literal', [0, 0]], 13, ['literal', [0, 0.75]]],
        ],
        'text-anchor': [
          'step',
          ['zoom'],
          ['step', ['get', 'sizerank'], 'center', 5, 'top'],
          17,
          ['step', ['get', 'sizerank'], 'center', 13, 'top'],
        ],
        'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
        visibility: 'none',
      },
      metadata: { 'mapbox:featureComponent': 'point-of-interest-labels', 'mapbox:group': 'Point of interest labels, poi-labels' },
      filter: ['<=', ['get', 'filterrank'], ['+', ['step', ['zoom'], 0, 16, 1, 17, 2], 2]],
      type: 'symbol',
      source: 'composite',
      id: 'poi-label',
      paint: {
        'icon-opacity': ['step', ['zoom'], ['step', ['get', 'sizerank'], 0, 5, 1], 17, ['step', ['get', 'sizerank'], 0, 13, 1]],
        'text-halo-color': [
          'match',
          ['get', 'class'],
          'park_like',
          'hsl(133, 43%, 100%)',
          'education',
          'hsl(148, 22%, 100%)',
          'medical',
          'hsl(200, 16%, 100%)',
          'rgb(255, 255, 255)',
        ],
        'text-halo-width': 0.5,
        'text-halo-blur': 0.5,
        'text-color': [
          'step',
          ['zoom'],
          [
            'step',
            ['get', 'sizerank'],
            [
              'match',
              ['get', 'class'],
              'food_and_drink',
              'hsl(198, 9%, 79%)',
              'park_like',
              'hsl(134, 18%, 54%)',
              'education',
              'hsl(148, 0%, 52%)',
              'medical',
              'hsl(200, 2%, 53%)',
              'hsl(202, 0%, 66%)',
            ],
            5,
            [
              'match',
              ['get', 'class'],
              'food_and_drink',
              'hsl(198, 14%, 62%)',
              'park_like',
              'hsl(134, 17%, 43%)',
              'education',
              'hsl(148, 0%, 32%)',
              'medical',
              'hsl(200, 3%, 43%)',
              'hsl(202, 4%, 56%)',
            ],
          ],
          17,
          [
            'step',
            ['get', 'sizerank'],
            [
              'match',
              ['get', 'class'],
              'food_and_drink',
              'hsl(198, 9%, 79%)',
              'park_like',
              'hsl(134, 18%, 54%)',
              'education',
              'hsl(148, 0%, 52%)',
              'medical',
              'hsl(200, 2%, 53%)',
              'hsl(202, 0%, 66%)',
            ],
            13,
            [
              'match',
              ['get', 'class'],
              'food_and_drink',
              'hsl(198, 14%, 62%)',
              'park_like',
              'hsl(134, 17%, 43%)',
              'education',
              'hsl(148, 0%, 32%)',
              'medical',
              'hsl(200, 3%, 43%)',
              'hsl(202, 4%, 56%)',
            ],
          ],
        ],
      },
      'source-layer': 'poi_label',
    },
    {
      minzoom: 8,
      layout: {
        'text-line-height': 1.1,
        'text-size': ['step', ['get', 'sizerank'], 18, 9, 12],
        'icon-image': ['step', ['get', 'sizerank'], ['concat', ['get', 'maki'], '-15'], 9, ['concat', ['get', 'maki'], '-11']],
        'text-font': ['Arial'],
        visibility: 'none',
        'text-offset': [0, 0.75],
        'text-rotation-alignment': 'viewport',
        'text-anchor': 'top',
        'text-field': ['step', ['get', 'sizerank'], ['coalesce', ['get', 'name_en'], ['get', 'name']], 15, ['get', 'ref']],
        'text-letter-spacing': 0.01,
        'text-max-width': 9,
      },
      metadata: { 'mapbox:featureComponent': 'transit', 'mapbox:group': 'Transit, transit-labels' },
      filter: [
        'match',
        ['get', 'class'],
        ['military', 'civil'],
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
        ['disputed_military', 'disputed_civil'],
        ['all', ['==', ['get', 'disputed'], 'true'], ['match', ['get', 'worldview'], ['all', 'US'], true, false]],
        false,
      ],
      type: 'symbol',
      source: 'composite',
      id: 'airport-label',
      paint: { 'text-color': 'hsl(135, 6%, 40%)', 'text-halo-color': 'hsl(135, 53%, 100%)', 'text-halo-width': 1 },
      'source-layer': 'airport_label',
    },
    {
      minzoom: 10,
      layout: {
        'text-field': ['coalesce', ['get', 'name_ru'], ['get', 'name']],
        'text-transform': 'uppercase',
        'text-font': ['Arial'],
        'text-letter-spacing': ['match', ['get', 'type'], 'suburb', 0.15, 0.1],
        'text-max-width': 7,
        'text-padding': 3,
        'text-size': [
          'interpolate',
          ['cubic-bezier', 0.5, 0, 1, 1],
          ['zoom'],
          11,
          ['match', ['get', 'type'], 'suburb', 11, 10.5],
          15,
          ['match', ['get', 'type'], 'suburb', 15, 14],
        ],
        visibility: 'none',
      },
      metadata: { 'mapbox:featureComponent': 'place-labels', 'mapbox:group': 'Place labels, place-labels' },
      maxzoom: 15,
      filter: [
        'all',
        [
          'match',
          ['get', 'class'],
          'settlement_subdivision',
          ['match', ['get', 'worldview'], ['all', 'US'], true, false],
          'disputed_settlement_subdivision',
          ['all', ['==', ['get', 'disputed'], 'true'], ['match', ['get', 'worldview'], ['all', 'US'], true, false]],
          false,
        ],
        ['<=', ['get', 'filterrank'], 3],
      ],
      type: 'symbol',
      source: 'composite',
      id: 'settlement-subdivision-label',
      paint: {
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': 1,
        'text-color': 'hsl(169, 8%, 55%)',
        'text-halo-blur': 0.5,
      },
      'source-layer': 'place_label',
    },
    {
      layout: {
        'text-line-height': 1.1,
        'text-size': [
          'interpolate',
          ['cubic-bezier', 0.2, 0, 0.9, 1],
          ['zoom'],
          3,
          ['step', ['get', 'symbolrank'], 12, 9, 11, 10, 10.5, 12, 9.5, 14, 8.5, 16, 6.5, 17, 4],
          13,
          ['step', ['get', 'symbolrank'], 23, 9, 21, 10, 19, 11, 17, 12, 16, 13, 15, 15, 13],
        ],
        'icon-image': '',
        'text-font': ['Arial'],
        visibility: 'none',
        'text-offset': ['step', ['zoom'], ['literal', [0, 0]], 8, ['literal', [0, 0]]],
        'text-anchor': ['step', ['zoom'], 'center', 8, 'center'],
        'text-field': ['coalesce', ['get', 'name_ru'], ['get', 'name']],
        'text-max-width': 7,
      },
      metadata: { 'mapbox:featureComponent': 'place-labels', 'mapbox:group': 'Place labels, place-labels' },
      maxzoom: 15,
      filter: [
        'all',
        ['<=', ['get', 'filterrank'], 3],
        [
          'match',
          ['get', 'class'],
          'settlement',
          ['match', ['get', 'worldview'], ['all', 'US'], true, false],
          'disputed_settlement',
          ['all', ['==', ['get', 'disputed'], 'true'], ['match', ['get', 'worldview'], ['all', 'US'], true, false]],
          false,
        ],
        [
          'step',
          ['zoom'],
          true,
          8,
          ['>=', ['get', 'symbolrank'], 11],
          10,
          ['>=', ['get', 'symbolrank'], 12],
          11,
          ['>=', ['get', 'symbolrank'], 13],
          12,
          ['>=', ['get', 'symbolrank'], 15],
          13,
          ['>=', ['get', 'symbolrank'], 11],
          14,
          ['>=', ['get', 'symbolrank'], 13],
        ],
      ],
      type: 'symbol',
      source: 'composite',
      id: 'settlement-minor-label',
      paint: {
        'text-color': 'hsl(169, 8%, 34%)',
        'text-halo-color': 'rgb(255, 255, 255)',
        'text-halo-width': 1,
        'icon-opacity': ['step', ['zoom'], 1, 8, 0],
        'text-halo-blur': 1,
      },
      'source-layer': 'place_label',
    },
    {
      layout: {
        'text-line-height': 1.1,
        'text-size': [
          'interpolate',
          ['cubic-bezier', 0.2, 0, 0.9, 1],
          ['zoom'],
          8,
          ['step', ['get', 'symbolrank'], 18, 9, 17, 10, 15],
          15,
          ['step', ['get', 'symbolrank'], 23, 9, 22, 10, 20, 11, 18, 12, 16, 13, 15, 15, 13],
        ],
        'icon-image': '',
        'text-font': ['Arial'],
        visibility: 'none',
        'text-offset': ['step', ['zoom'], ['literal', [0, 0]], 8, ['literal', [0, 0]]],
        'text-anchor': ['step', ['zoom'], 'center', 8, 'center'],
        'text-field': ['coalesce', ['get', 'name_ru'], ['get', 'name']],
        'text-max-width': 7,
      },
      metadata: { 'mapbox:featureComponent': 'place-labels', 'mapbox:group': 'Place labels, place-labels' },
      maxzoom: 15,
      filter: [
        'all',
        ['<=', ['get', 'filterrank'], 3],
        [
          'match',
          ['get', 'class'],
          'settlement',
          ['match', ['get', 'worldview'], ['all', 'US'], true, false],
          'disputed_settlement',
          ['all', ['==', ['get', 'disputed'], 'true'], ['match', ['get', 'worldview'], ['all', 'US'], true, false]],
          false,
        ],
        [
          'step',
          ['zoom'],
          false,
          8,
          ['<', ['get', 'symbolrank'], 11],
          10,
          ['<', ['get', 'symbolrank'], 12],
          11,
          ['<', ['get', 'symbolrank'], 13],
          12,
          ['<', ['get', 'symbolrank'], 15],
          13,
          ['>=', ['get', 'symbolrank'], 11],
          14,
          ['>=', ['get', 'symbolrank'], 13],
        ],
      ],
      type: 'symbol',
      source: 'composite',
      id: 'settlement-major-label',
      paint: {
        'text-color': 'hsl(169, 8%, 34%)',
        'text-halo-color': 'rgb(255, 255, 255)',
        'text-halo-width': 1,
        'icon-opacity': ['step', ['zoom'], 1, 8, 0],
        'text-halo-blur': 1,
      },
      'source-layer': 'place_label',
    },
    {
      minzoom: 3,
      layout: {
        'text-size': [
          'interpolate',
          ['cubic-bezier', 0.85, 0.7, 0.65, 1],
          ['zoom'],
          4,
          ['step', ['get', 'symbolrank'], 10, 6, 9.5, 7, 9],
          9,
          ['step', ['get', 'symbolrank'], 21, 6, 16, 7, 13],
        ],
        'text-transform': 'uppercase',
        'text-font': ['Arial'],
        'text-field': ['coalesce', ['get', 'name_ru'], ['get', 'name']],
        'text-letter-spacing': 0.15,
        'text-max-width': 6,
        visibility: 'none',
      },
      metadata: { 'mapbox:featureComponent': 'place-labels', 'mapbox:group': 'Place labels, place-labels' },
      maxzoom: 9,
      filter: [
        'match',
        ['get', 'class'],
        'state',
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
        'disputed_state',
        ['all', ['==', ['get', 'disputed'], 'true'], ['match', ['get', 'worldview'], ['all', 'US'], true, false]],
        false,
      ],
      type: 'symbol',
      source: 'composite',
      id: 'state-label',
      paint: { 'text-color': 'hsl(169, 8%, 34%)', 'text-halo-color': 'rgb(255, 255, 255)', 'text-halo-width': 1 },
      'source-layer': 'place_label',
    },
    {
      minzoom: 1,
      layout: {
        'icon-image': '',
        'text-field': ['coalesce', ['get', 'name_ru'], ['get', 'name']],
        'text-line-height': 1.1,
        'text-max-width': 6,
        'text-font': ['Arial'],
        'text-offset': ['literal', [0, 0]],
        'text-size': [
          'interpolate',
          ['cubic-bezier', 0.2, 0, 0.7, 1],
          ['zoom'],
          1,
          ['step', ['get', 'symbolrank'], 11, 4, 9, 5, 8],
          9,
          ['step', ['get', 'symbolrank'], 22, 4, 19, 5, 17],
        ],
        visibility: 'none',
      },
      metadata: { 'mapbox:featureComponent': 'place-labels', 'mapbox:group': 'Place labels, place-labels' },
      maxzoom: 10,
      filter: [
        'match',
        ['get', 'class'],
        'country',
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
        'disputed_country',
        ['all', ['==', ['get', 'disputed'], 'true'], ['match', ['get', 'worldview'], ['all', 'US'], true, false]],
        false,
      ],
      type: 'symbol',
      source: 'composite',
      id: 'country-label',
      paint: {
        'icon-opacity': ['step', ['zoom'], ['case', ['has', 'text_anchor'], 1, 0], 7, 0],
        'text-color': 'hsl(169, 8%, 34%)',
        'text-halo-color': ['interpolate', ['linear'], ['zoom'], 2, 'rgba(255, 255, 255, 0.75)', 3, 'rgb(255, 255, 255)'],
        'text-halo-width': 1.25,
      },
      'source-layer': 'place_label',
    },
    {
      id: 'custom-road',
      minzoom: 5,
      type: 'line',
      source: 'composite',
      'source-layer': 'road',
      paint: { 'line-color': 'hsl(0, 0%, 32%)' },
      layout: { visibility: 'none' },
    },
  ],
  created: '2021-02-17T11:35:35.621Z',
  modified: '2021-08-16T19:08:50.168Z',
  id: 'ckl9czve40tc817mx7hz9gaig',
  owner: 'soutjuseppe',
  visibility: 'private',
  draft: true,
};
