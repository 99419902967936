interface IAttributeData {
  id: string;
  authorId: string;
  data: string;
  createDate: number;
  isAccepted: boolean;
}


type TLinkedDataType = 'CLASSIFIER' | 'CLASSIFIER_DATA_REQUEST' | 'DICTIONARY_DATA_REQUEST' | 'TEXT' | 'STATIC' | 'SPIUI';

interface IAttributes {
  id: string;
  attributeOrder: number;
  title: string;
  linkedDataType: TLinkedDataType;
  linkedDataId: string;
  approveStatus: 'NOT_ACCEPTED' | 'ACCEPTED' | 'REJECTED ';
  data: IAttributeData[];
}

interface IDocumentController {
  id?: string;
  projectId: string;
  taskDefId: string;
  name?: string;
  description?: string;
  attributes: IAttributes[];
}

interface ICreationRequest {
  projectId: string;
  taskDefId: string;
  userId?: string;
}

interface IDataDocumentAttributeData {
  id?: string;
  authorId?: string;
  dataId?: string;
  data?: string;
}

interface IDataDocumentAttributeRes {
  id: string;
  authorId: string;
  data: string;
  createDate: string;
  isAccepted: boolean;
}



export declare namespace IDocumentControllerService {
  type IGetDocuments = IDocumentController;
  type ICreateTemplateDocuments = IDocumentController;
  type ICreateDocumentAttributesData = IDataDocumentAttributeRes;
  type IListDocumentAttributesData = IDataDocumentAttributeRes[];


  declare namespace Body {
    type ICreateTemplateDocuments = ICreationRequest;
    type ICreateDocumentAttributesData = IDataDocumentAttributeData;
  }

}
