import React, { ReactElement } from 'react';
import tw from 'twin.macro';

export enum ButtonSize {
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'Large',
}

export enum ButtonTheme {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Dark = 'DARK',
  Light = 'LIGHT',
}

const ButtonSizeMap = {
  [ButtonSize.Small]: tw`px-3 py-2`,
  [ButtonSize.Medium]: tw`px-4 py-2.5`,
  [ButtonSize.Large]: tw`px-6 py-3.5`,
};

const ButtonThemeMap = {
  [ButtonTheme.Primary]: tw`bg-primary-dark text-white`,
  [ButtonTheme.Dark]: tw`bg-gray-700 text-white`,
  [ButtonTheme.Light]: tw`hover:bg-gray-200`,
  [ButtonTheme.Secondary]: tw`bg-grey-500 text-white`,
};

const fullWidthStyle = tw`w-full`;

type ButtonProps = {
  size?: ButtonSize;
  theme?: ButtonTheme;
  label?: string;
  children?: ReactElement;
  isFullWidth?: boolean;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
};

/**
 * Кнопка из нового UI Kit'a
 * Дизайн: https://www.figma.com/file/zBoCWyq2cLDDrZG0KaZojU/РЖД-UI-Kit?node-id=5%3A106
 *
 * @param size - Button size. Default - Medium
 * @param children - Children element - icon
 * @param label - Text label
 * @param onClick - Click callback
 * @param type - Button type ("button" | "submit" | "reset"). Default - "button"
 * @param disabled - Disable button. Default - false
 */
export const ButtonNew = React.memo<ButtonProps>(
  ({ children, onClick, label, isFullWidth, size = ButtonSize.Medium, theme = ButtonTheme.Light, type = 'button', disabled = false }) => {
    return (
      <Wrapper
        disabled={disabled}
        type={type}
        onClick={onClick}
        css={[ButtonSizeMap[size], ButtonThemeMap[theme], isFullWidth && fullWidthStyle]}
      >
        <Icon>{children}</Icon>
        {label && <Label>{label}</Label>}
      </Wrapper>
    );
  },
);

const Wrapper = tw.button`flex justify-center items-center border-0 rounded`;
const Icon = tw.span`flex justify-center items-center`;
const Label = tw.span`text-base`;
