import { uriPrefix } from '@asu/services';

export const statusInfo = {
  IN_PROGRESS: {
    color: '#575757',
    text: 'Проводится',
  },
  CLOSED: {
    color: '#38ff53',
    text: 'Завершена',
  },
  NOT_STARTED: {
    color: '#575757',
    text: 'Ожидает старта',
  },
  UNDER_APPROVAL: {
    color: '#8cf0ff',
    text: 'Согласование',
  },
  EXPERT_APPROVE: {
    color: '#0015ff',
    text: 'Проводится проверка',
  },
  APPROVER_APPROVE: {
    color: '#ac24ce',
    text: 'Утверждено инспектором',
  },
  REPETEAD: {
    color: '#ff2724',
    text: 'Повторная инспекция',
  },
  CONFIRMER_APPROVE: {
    color: '#8cf0ff',
    text: 'Согласование результатов инспекции',
  },
  WAITING_DEPENDENCY: {
    color: '#2429ce',
    text: 'Проводится проверка',
  },
};

export const camundaStatusInfo = {
  IN_PROGRESS: {
    text: 'В процессе',
  },
  CLOSED: {
    text: 'Закрыто',
  },
  NOT_STARTED: {
    text: 'Ожидает старта',
  },
  UNDER_APPROVAL: {
    text: 'Задача на согласовании',
  },
  EXPERT_APPROVE: {
    text: 'Задача ожидает согласования экспертом(ами)',
  },
  APPROVER_APPROVE: {
    text: 'Задача ожидает согласования',
  },
  CONFIRMER_APPROVE: {
    text: 'Задача ожидает утверждения',
  },
};

export const capitalize = name => {
  if (name) return name.charAt(0).toUpperCase() + name.slice(1);
  return name;
};

export const getUrl = (projectId, id) => {
  const splitId = id.split(' ');
  return `/project/${projectId}/process/process-planning/task/${splitId[0]}`;
};

export const getViewerUrl = (type, projectId, additionally) => {
  const usrlsViewer = {
    button: `${uriPrefix}/viewer/#/${projectId}/attach-tasks?${additionally}`,
    element: `${uriPrefix}/viewer/#/${projectId}?${additionally}`,
    file: `${uriPrefix}/viewer/#/${projectId}/pointcloud?${additionally}`,
    'make-camera-angle': `${uriPrefix}/viewer/#/${projectId}/make-camera-angle?${additionally}`,
  };
  return usrlsViewer[type];
};

export const onAsyncAction = async (promise: Promise<any>): Promise<any> => {
  const response = await promise;
  const result = await response.data;

  return result;
};
