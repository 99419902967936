export enum ProjectStages {
  NOT_STARTED = 'NOT_STARTED', //желтый
  ACTIVE = 'ACTIVE', //красный
  DONE = 'DONE', //зеленый
}

export enum AccessRole {
  NONE = 'NONE',
  READ = 'READ',
  READ_WRITE = 'READ_WRITE',
}

export type IDirectory = {
  projectId: number;
  baseName: string;
  parentId: string;
};

export type IDirectoryTemplate = {
  projectId: string;
  type: string;
};

export type IDirectoryRename = {
  id: string;
  newName: string;
};

export type IDocument = {
  document: unknown;
  parentId: string;
  projectId: string;
};

export type IDocumentRename = {
  id: string;
  newName: string;
};
