import React from 'react';
import { theme } from 'twin.macro';
import styled from 'styled-components';
import Radio from 'antd/lib/radio';
import { RadioGroupButtonStyle } from 'antd/lib/radio/interface';
import 'antd/lib/radio/style/index.css';

type Button = {
  value: string;
  label: string;
};

interface RadioButtonProps {
  onChange: (value: string) => void;
  currentValue: string;
  buttonStyle: RadioGroupButtonStyle;
  buttons: Button[];
}

const RadioGroup = React.forwardRef((props: RadioButtonProps, ref: React.LegacyRef<HTMLInputElement>) => {
  const { onChange, currentValue, buttonStyle, buttons } = props;

  return (
    <Radio.Group buttonStyle={buttonStyle} onChange={e => onChange(e.target.value)} value={currentValue}>
      {buttons.map((item, index) => (
        <RadioButtonWrapper key={index}>
          <Radio.Button value={item.value}>{item.label}</Radio.Button>
        </RadioButtonWrapper>
      ))}
    </Radio.Group>
  );
});

const RadioButtonWrapper = styled.span`
  .ant-radio-button-wrapper-checked {
    background-color: ${theme`colors.primary.light`}!important;
    border-color: ${theme`colors.primary.light`}!important;
  }

  .ant-radio-button-wrapper:hover {
    color: ${theme`colors.primary.light`}!important;
  }

  .ant-radio-button-wrapper-checked:hover {
    color: #ffffff !important;
  }
`;

export default RadioGroup;
