import React from 'react';
import tw, { styled } from 'twin.macro';
import { useNavigate } from 'react-router-dom';
import { IconBack } from '@asu/ui';

type BackButtonProps = {
  className?: string;
};

const BackButton = React.memo<BackButtonProps>(({ className }) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(-1);
  };

  return (
    <Button className={className} type="button" onClick={onClick}>
      <IconBack tw="w-5 h-3 mr-2" />
      <span tw="font-medium text-sm leading-none">Назад</span>
    </Button>
  );
});

const Button = styled.button`
  ${tw`flex items-center rounded text-grey-900 cursor-pointer transition-colors duration-150 p-3`}
  ${tw`hocus:(outline-none)`}

  ${({ disabled }) =>
    disabled ? tw`cursor-not-allowed opacity-30` : tw`opacity-100 hover:(text-grey-500 bg-grey-100) active:(text-grey-900 bg-grey-300)`}
`;

export default BackButton;
