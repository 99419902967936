import type { AxiosRequestConfig } from 'axios';
import { DEPRECATEDApiCaller } from '../utils';
import { ShiftPoint } from './ModelsService.d';

const BASE_URL = '/';

export class ModelsService {
  public static getProperties(modelId: string, globalId: string, config?: AxiosRequestConfig) {
    return new DEPRECATEDApiCaller(BASE_URL).get<any>(`models/properties/${modelId}/property/${globalId}`, config);
  }

  public static fetchVersionsDiff = async (oldModelId: string, newModelId: string, config?: AxiosRequestConfig) => {
    return await new DEPRECATEDApiCaller(BASE_URL).get<any>(
      `models/properties/difference/?firstVersionId=${oldModelId}&secondVersionId=${newModelId}`,
      config,
    );
  };

  public static fetchGeometry = async (id: string, config?: AxiosRequestConfig) => {
    const res = await new DEPRECATEDApiCaller(BASE_URL).get<Blob>(`models/geometry/${id}`, { ...config, responseType: 'blob' });

    const data = await res.data;

    if (!data.size) throw Error(`Получен пустой файл геометрии для модели ${id}`);

    return URL.createObjectURL(data);
  };

  public static fetchGeometrySimplified = async (id: string, config?: AxiosRequestConfig) => {
    const res = await new DEPRECATEDApiCaller(BASE_URL).get<Blob>(`models/geometry/${id}/simplified`, { ...config, responseType: 'blob' });

    const data = res.data;

    if (!(await data).size) throw Error(`Получен пустой файл геометрии для модели ${id}`);

    return URL.createObjectURL(await data);
  };

  public static fetchShiftPoint = async (modelId: string) => {
    const defaultShiftPoint = {
      modelId,
      x: 0,
      y: 0,
      z: 0,
    };

    const res = await new DEPRECATEDApiCaller(BASE_URL).get(`/viewer/viewer/models/${modelId}/shift_point`);
    const data = await res.data;

    const isTextResponse = data.headers.get('content-type') === 'text/plain';

    if (isTextResponse || !data) {
      console.warn(`Не удалось найти точку смещения  для модели ${modelId}`);

      return defaultShiftPoint;
    }

    try {
      const shiftPoint = (await data.json()) as ShiftPoint;

      return shiftPoint;
    } catch (err) {
      console.warn(`Не валидный json точки смещения для модели ${modelId}`);
      console.warn(err);

      return defaultShiftPoint;
    }
  };
}
