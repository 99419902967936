import React from 'react';
import type { DropDownProps } from 'antd/lib/dropdown';
import { default as AntdDropdown } from 'antd/lib/dropdown';
import 'antd/lib/dropdown/style/index.css';

const Dropdown: React.FC<DropDownProps & React.RefAttributes<unknown>> = React.memo(props => {
  return <AntdDropdown {...props} />;
});

export default Dropdown;
