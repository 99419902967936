import React from 'react';
import tw, { styled } from 'twin.macro';
import type { PopconfirmProps } from 'antd/lib/popconfirm';
import { default as AntdPopConfirm } from 'antd/lib/popconfirm';
import 'antd/lib/popover/style/index.css';
import 'antd/lib/popconfirm/style/index.css';

const StyledAntdPopConfirm = styled(AntdPopConfirm)`
  &&& .ant-btn-primary {
    ${tw`bg-primary border-primary-light`}
  }
`;

const PopConfirm: React.FC<PopconfirmProps & React.RefAttributes<unknown>> = StyledAntdPopConfirm;

export default PopConfirm;
