import React from 'react';
import tw, { styled } from 'twin.macro';
import { default as AntdBadge, BadgeProps } from 'antd/lib/badge';
import 'antd/lib/badge/style/index.css';

const StyledAntdBadge = styled(AntdBadge)``;

const Badge: React.FC<BadgeProps> = StyledAntdBadge;

export default Badge;
