import axios, { AxiosRequestConfig } from 'axios';
import { uriPrefix } from '../config';
import ApiRequest from './DEPRECATED_api-request';

export class DEPRECATEDApiCaller {
  private _baseUrl: string;

  constructor(_baseUrl: string) {
    this._baseUrl = uriPrefix + '/api' + _baseUrl;
  }

  static get newTokenSource() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    return source;
  }

  async request<T = any>(cfg: AxiosRequestConfig): Promise<ApiRequest<T>> {
    const source = DEPRECATEDApiCaller.newTokenSource;
    const request = axios.request<T>({ ...cfg, baseURL: this._baseUrl, cancelToken: source.token });
    return new ApiRequest<T>(request, source);
  }

  async get<T = any>(url: string, cfg?: AxiosRequestConfig): Promise<ApiRequest<T>> {
    const source = DEPRECATEDApiCaller.newTokenSource;
    const request = axios.get<T>(this._baseUrl + url, {
      ...cfg,
      cancelToken: source.token,
    });
    return new ApiRequest<T>(request, source);
  }

  async post<T = any>(url: string, data: any, cfg?: AxiosRequestConfig): Promise<ApiRequest<T>> {
    const source = DEPRECATEDApiCaller.newTokenSource;
    const request = axios.post<T>(this._baseUrl + url, data, {
      ...cfg,
      cancelToken: source.token,
    });
    return new ApiRequest<T>(request, source);
  }

  async patch<T = any>(url: string, data: any, cfg?: AxiosRequestConfig): Promise<ApiRequest<T>> {
    const source = DEPRECATEDApiCaller.newTokenSource;
    const request = axios.patch<T>(this._baseUrl + url, data, {
      ...cfg,
      cancelToken: source.token,
    });
    return new ApiRequest<T>(request, source);
  }

  async put<T = any>(url: string, data: any, cfg?: AxiosRequestConfig): Promise<ApiRequest<T>> {
    const source = DEPRECATEDApiCaller.newTokenSource;
    const request = axios.put<T>(this._baseUrl + url, data, {
      ...cfg,
      cancelToken: source.token,
    });
    return new ApiRequest<T>(request, source);
  }

  async delete<T = any>(url: string, cfg?: AxiosRequestConfig): Promise<ApiRequest<T>> {
    const source = DEPRECATEDApiCaller.newTokenSource;
    const request = axios.delete<T>(this._baseUrl + url, {
      ...cfg,
      cancelToken: source.token,
    });
    return new ApiRequest<T>(request, source);
  }
}
