export enum StatusEnum {
  pending = 'pending',
  reserved = 'reserved',
  accepted = 'accepted',
  paid = 'paid',
  rejected = 'rejected',
  unpaid = 'unpaid',
}

export enum PoliceTypeEnum {
  route = 'route',
}

export declare namespace IUsersService {
  interface IApplyBody {
    year: number;
    vin: string;
    plate: string;
    phone: string;
    patronymic: string;
    model: string;
    make: string;
    last_name: string;
    first_name: string;
    email: string;
    birthday: string;
    offer: {
      code: string;
      end_point: string;
      policy_offer: {
        policy: {
          company: string;
          company_id: number;
          cost: { currency: string; value: number };
          coverage: { damage: { currency: string; value: number } };
          deductible: { currency: string; value: number };
          expiration_ts: number;
          generation_ts: number;
          lifetime: number;
          policy_type: string;
        };
        signature: string;
      };
      start_point: string;
    };
  }
  interface IGetUsersResponse {
    vehicle: {
      year: number;
      vin: string;
      plate: string;
      model: string;
      make: string;
    };
    updated_at: string;
    status: StatusEnum;
    offer: {
      start_point: string;
      policy_offer: {
        signature: string;
        policy: {
          policy_type: PoliceTypeEnum;
          lifetime: number;
          deductible: {
            value: number;
            currency: string;
          };
          coverage: {
            damage: {
              value: number;
              currency: string;
            };
          };
          cost: {
            value: number;
            currency: string;
          };
          company_id: number;
          company: string;
        };
      };
      end_point: string;
      code: string;
    };
    inserted_at: string;
    id: string;
    client: {
      phone: string;
      patronymic: string;
      last_name: string;
      first_name: string;
      email: string;
      birthday: string;
    };
  }
  interface IPostOrderPayBody {
    details: {
      type: string | 'redirect';
      return_url: string;
    };
    handler: string;
  }
  interface IPostOrderPayResponse {
    confirmation: {
      confirmation_url: string;
      type: string;
    };
    id: string;
    status: string;
  }
}
