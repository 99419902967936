import React from 'react';
import projectStore from './store';

const StoreProjectContext = React.createContext(null);

export function useProjectState() {
  const context = React.useContext(StoreProjectContext);

  if (context === undefined) {
    throw new Error('useProjectState must be used within a ProjectProvider');
  }

  return context;
}

export const ProjectStoreProvider = React.memo(({ children }) => {
  return <StoreProjectContext.Provider value={projectStore}>{children}</StoreProjectContext.Provider>;
});
