import React from 'react';
import { useProjectState } from '../../../store';
import { useAuthState } from '@asu/auth';

export const useRole = id => {
  const [role, setRole] = React.useState<any | null>(null);
  const [allRoleInfo, setAllRoleInfo] = React.useState<any | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const store = useProjectState();

  const authStore = useAuthState();

  React.useEffect(() => {
    if (id) {
      (async () => {
        setIsLoading(true);

        const responseDataProject = store.project;

        responseDataProject.assignedUsers?.forEach(user => {
          if (user.userId === authStore.currentUser.id) {
            setAllRoleInfo(user.role.accessList);
            setRole(user.role.name);
            return null;
          }
          return null;
        });

        setIsLoading(false);
      })();
    }
  }, [id]);

  return [isLoading, role, allRoleInfo];
};
