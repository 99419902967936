import React, { Ref } from 'react';
import ReactDOM from 'react-dom';
import tw from 'twin.macro';

type Props = {
  event: React.MouseEvent<HTMLDivElement, MouseEvent>;
  content: React.ReactNode;
  onClickOutside: () => any;
};

const ContextMenu = ({ event, onClickOutside, content }: Props) => {
  const ref = React.useRef<any>(null);
  const [coords, setCoords] = React.useState(null);

  React.useLayoutEffect(() => {
    let x = event.clientX;
    let y = event.clientY;

    const refHeight = ref.current.clientHeight || 0;
    const refWidth = ref.current.clientWidth || 0;

    // // Open to other side if rest of space is too small
    // if (window.innerWidth - x < 200) x -= 120;
    // // TODO: Сделать динамический размер
    // if (window.innerHeight - y < 180) y -= 85;

    // Если контектсное меню вылезает за нижнюю или правую грань экрана, то мы прижимаем его к этой грани
    if (refWidth + x > window.innerWidth) {
      x = window.innerWidth - (window.innerWidth - x) - refWidth;
    }
    if (refHeight + y > window.innerHeight) {
      y = window.innerHeight - (window.innerHeight - y) - refHeight;
    }

    setCoords({ xPos: `${x}px`, yPos: `${y}px` });
  }, []);

  return ReactDOM.createPortal(
    <Container>
      <Backplate onMouseDown={onClickOutside} />
      <DropMenu ref={ref} style={{ top: coords?.yPos, left: coords?.xPos }} onClick={onClickOutside}>
        {content}
      </DropMenu>
    </Container>,
    document.getElementById('react-portal')!,
  );
};

const Container = tw.div`fixed inset-0 w-screen h-screen whitespace-nowrap`;
const Backplate = tw.div`absolute inset-0`;
const DropMenu = tw.div`min-width[180px] absolute z-50 select-none backdrop-filter backdrop-blur-sm border border-grey-300 border-opacity-50 bg-white bg-opacity-75 shadow-md rounded-lg py-2`;

export default ContextMenu;
