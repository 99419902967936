import { qParam } from './Service.d';
import { Service } from './Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import type { IDocumentControllerService } from './DocumentControllerService.d';
export * from './DocumentControllerService.d';

const BASE_URL = '/document';

export class DocumentControllerService implements Service {
  public static getDocuments(projectId: qParam, taskDefId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IDocumentControllerService.IGetDocuments>(`/document/${projectId}/${taskDefId}`, config);
  }

  public static createTemplateDocuments(
    templateId: qParam,
    body: IDocumentControllerService.Body.ICreateTemplateDocuments,
    config?: AxiosRequestConfig,
  ) {
    return new ApiCaller(BASE_URL).post<IDocumentControllerService.ICreateTemplateDocuments>(`/document/${templateId}`, body, config);
  }

  public static createDocumentAttributesData(
    body: IDocumentControllerService.Body.ICreateDocumentAttributesData,
    attributeDataId: qParam,
    config?: AxiosRequestConfig,
  ) {
    return new ApiCaller(BASE_URL).post<IDocumentControllerService.ICreateDocumentAttributesData>(
      `/attribute/data/${attributeDataId}`,
      body,
      config,
    );
  }

  public static listDocumentAttributesData(attributeDataId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IDocumentControllerService.IListDocumentAttributesData>(
      `/attribute/data/${attributeDataId}`,
      config,
    );
  }

  public static rejectTask(taskId: string, body: any, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<null>(
      `/tasks/${taskId}/reject`,
      {
        id: taskId,
        coreReject: {
          message: body.message,
        },
      },
      config,
    );
  }

  public static approveDocumentAttributesData(attributeDataId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<void>(`/attribute/data/${attributeDataId}/approve`, {}, config);
  }
  public static doneTask(taskId: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<void>(`/tasks/${taskId}/done`, {}, config);
  }

  public static acceptTask(taskId: string, body?: any, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<void>(`/tasks/${taskId}/approve`, body, config);
  }
}
