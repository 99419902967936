import { qParam } from './Service.d';

import { Service } from './Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import type { IBMRService } from './BuildingMeasureResultService.d';
export * from './BuildingMeasureResultService.d'

const BASE_URL = '/building';

export class BuildingMeasureResultService implements Service {
  public static getAllByTaskDefId(taskDefId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IBMRService.IBuildingMeasureResult[]>(`/measure/result/findAllByTaskDefId`, { taskDefId }, config);
  }

  public static createMeasureResult(body: IBMRService.IDtoCreateMeasureResult, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IBMRService.IBuildingMeasureResult>(`/measure/result/create`, body, config);
  }
}
