import { qParam } from './Service.d';
import { Service } from './Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import type { IGeoService } from './GeoService.d';
export * from './GeoService.d';

const BASE_URL = '/geoserverp';

export class GeoService implements Service {
  public static getLayers(config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IGeoService.IGetLayers>(`/geoserver/layers`, config);
  }
  public static getActiveProjectLayers(projectId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IGeoService.IGetActiveProjectLayers>(`/geoserver/layers/active/project/${projectId}`, config);
  }
  public static postShapes(data?: IGeoService.Body.IPostShapes, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IGeoService.IPostShapes>('/geoserver/shapes', data, config);
  }
  public static postProjectShapes(data: IGeoService.Body.IPostProjectShapes, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IGeoService.IPostProjectShapes>('/geoserver/shapes', data, config);
  }
  public static postShapeZip(data: IGeoService.Body.IPostShapeZip, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IGeoService.IPostShapeZip>('/geoserver/shapes/zip', data, config);
  }
  public static postProjectShapeZip(data: IGeoService.Body.IPostProjectShapeZip, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IGeoService.IPostProjectShapeZip>('/geoserver/shapes/zip', data, config);
  }
  public static getVersion(workspace: string, name: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<any>(`/geoserver/layers/active/${workspace}/${name}`, config);
  } // not found in swagger
  public static getProjectVersion(name: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IGeoService.IGetProjectVersion>(`/geoserver/layers/active/versions/${name}`, config);
  }
  public static setVersion(workspace: qParam, name: qParam, version: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).put<IGeoService.ISetVersion>(`/geoserver/layers/activate/${workspace}/${name}/${version}`, config);
  }
  public static putNoteProject(data: IGeoService.Body.IPutNoteProject | any, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).put<IGeoService.IPutNoteProject>(`/geoserver/note/project`, data, config);
  }
  public static getNoteProject(projectId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IGeoService.IGetNoteProject>(`/geoserver/note/project/${projectId}`, config);
  }
  public static deleteNoteProject(id: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).delete<IGeoService.IDeleteNoteProject>(`/geoserver/note/project/${id}`, config);
  }
  public static deleteNoteUsers(id: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).delete<IGeoService.IDeleteNoteUsers>(`/geoserver/note/user/${id}`, config);
  }
  public static deleteNoteRoles(id: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).delete<IGeoService.IDeleteNoteRoles>(`/geoserver/note/role/${id}`, config);
  }
  public static updateNoteProject(body: IGeoService.Body.IUpdateNoteProject, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<IGeoService.IUpdateNoteProject>(`/geoserver/note/project`, body, config);
  }
  public static updateNoteUsers(body: IGeoService.Body.IUpdateNoteUsers, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<IGeoService.IUpdateNoteUsers>(`/geoserver/note/user`, body, config);
  }
  public static updateNoteRoles(body: IGeoService.Body.IUpdateNoteRoles, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<IGeoService.IUpdateNoteRoles>(`/geoserver/note/role`, body, config);
  }
  public static putNoteUser(data: any, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).put<IGeoService.IPutNoteUser>(`/geoserver/note/user`, data, config);
  }
  public static getNoteUser(projectId: qParam, targetUser: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IGeoService.IGetNoteUser>(`/geoserver/note/user/${projectId}/${targetUser}`, config);
  }
  public static putNoteRole(data: any, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).put<IGeoService.IPutNoteRole>(`/geoserver/note/role`, data, config);
  }
  public static getNoteRole(projectId: qParam, targetRole: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IGeoService.IGetNoteRole>(`/geoserver/note/role/${projectId}/${targetRole}`, config);
  }
}
