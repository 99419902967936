import React from 'react';
import { Button, Modal, useManualModalState } from '@asu/ui';
import tw, { styled } from 'twin.macro';

import { MetaData, Versions, VersionsIFC } from './local-components';

export enum LayoutBlocks {
  MetaData = 'MetaData',
  Versions = 'Versions',
}

interface AddModalMetaProps {
  info: any;
  selectedFile: any;
  onUpdateVersion: any;
  onClickModel: any;
}

interface ModalMetaProps extends AddModalMetaProps {
  isOpen: boolean;
  setIsOpen: any;
  openModal: () => void;
  closeModal: () => void;
}

const ModalMeta = React.memo((props: ModalMetaProps) => {
  const { info, selectedFile, onUpdateVersion, onClickModel } = props;
  return (
    <Modal.Root manual {...props}>
      <AddModalMeta info={info} selectedFile={selectedFile} onUpdateVersion={onUpdateVersion} onClickModel={onClickModel} />
    </Modal.Root>
  );
});

const AddModalMeta = React.memo(({ info, selectedFile, onUpdateVersion, onClickModel }: AddModalMetaProps) => {
  const { closeModal } = useManualModalState();

  const [layout, setLayout] = React.useState(LayoutBlocks.MetaData);

  const onSelectLayout = (layout: LayoutBlocks) => {
    setLayout(layout);
  };

  const Menu = {
    [LayoutBlocks.MetaData]: <MetaData info={info} />,
    [LayoutBlocks.Versions]:
      info.type !== 'MODEL' ? (
        <Versions selectedId={info.id} />
      ) : (
        <VersionsIFC
          onUpdateVersion={onUpdateVersion}
          info={info}
          selectedFile={selectedFile}
          selectedId={info.id}
          onClickModel={onClickModel}
        />
      ),
  };

  return (
    <Modal.Content tw="pb-5">
      <Modal.Title>Информация</Modal.Title>
      <Header>
        <ButtonWrapper $isActive={layout === LayoutBlocks.MetaData}>
          <ButtonChoice onClick={() => onSelectLayout(LayoutBlocks.MetaData)}>Метаданные</ButtonChoice>
        </ButtonWrapper>

        <ButtonWrapper $isActive={layout === LayoutBlocks.Versions}>
          <ButtonChoice onClick={() => onSelectLayout(LayoutBlocks.Versions)}>Версии</ButtonChoice>
        </ButtonWrapper>
      </Header>
      {Menu[layout]}
    </Modal.Content>
  );
});

const Header = tw.div`flex bg-gray-200`;
const ButtonChoice = tw.button`flex justify-center items-center w-full`;

const ButtonWrapper = styled.div<any>`
  ${tw`w-32 mt-1`}
  ${({ $isActive }) => $isActive && tw`border-b-4 border-gray-500`}
`;

export default ModalMeta;
