import React from 'react';
import { CommentForm, FileListWrap } from '..';
import { IconEdit, IconTrash } from '../../../../assets';
import tw from 'twin.macro';
import Avatar from 'antd/lib/avatar';
import { default as AntdComment } from 'antd/lib/comment';

import 'antd/lib/avatar/style/index.css';
import 'antd/lib/comment/style/index.css';
import { AuthService, IAuthService } from '@asu/services';

interface CommentProps {
  comment: any;
  downloadFile: (e: any) => void;
  update: (e: any) => void;
  deleteFile: (e: any) => void;
  onDelete: (e: any) => void;
  save?: (e: any) => void;
}

const useUser = userId => {
  const [user, setUser] = React.useState<any | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (userId) {
      (async () => {
        setIsLoading(true);

        const response = await AuthService.getUser(userId).fetch();
        const responseData = response.data;
        const responseGroup = await AuthService.getGroupUser(userId).fetch();
        const responseGroupData = responseGroup.data;

        // responseData.group = responseGroupData[0].name;

        const responseDataCopy = { ...responseData, group: responseGroupData[0].name };

        setUser(responseDataCopy);
        setIsLoading(false);
      })();
    }
  }, [userId]);

  return { isLoading, user };
};

const Comment: React.FC<CommentProps> = ({ comment, downloadFile, save, update, deleteFile, onDelete }) => {
  const [showForm, setShowForm] = React.useState(false);
  const baseUrl = localStorage.getItem('baseUrl');

  const { user } = useUser(comment.authorId);

  const userName = `${user?.lastName} ${user?.firstName} `;

  if (showForm) return <CommentForm comment={comment} save={save} update={update} userName={userName} setShow={setShowForm} />;

  return (
    <AntdComment
      actions={[
        comment.permissions.delete && comment.permissions.edit && (
          <div tw="flex">
            <ButtonAction onClick={() => setShowForm(true)}>
              <IconEdit tw="w-4 h-4 mr-1" /> <span>Изменить</span>
            </ButtonAction>
            <ButtonAction tw="ml-2 " onClick={() => onDelete(comment.id)}>
              <IconTrash tw="w-5 h-5 mr-1" /> <span>Удалить</span>
            </ButtonAction>
          </div>
        ),
      ]}
      author={<p tw="text-black">{userName}</p>}
      avatar={<Avatar src={`${baseUrl}${comment.author?.pic}`} />}
      content={
        <div>
          {comment.text}
          <FileListWrap
            fileList={comment.files}
            deleteCallback={deleteFile}
            downloadCallback={downloadFile}
            showDownload
            showDelete={comment.permissions.delete}
          />
        </div>
      }
      datetime={<span>{new Date(comment.createdAt * 1000).toLocaleString()}</span>}
      key={comment.id}
    />
  );
};

const ButtonAction = tw.button`flex flex-row items-center`;

export default Comment;
