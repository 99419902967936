import { Service } from './Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import { IClassifier } from './ClassifierService.d';
export * from './ClassifierService.d';

const BASE_URL = '/classifierdr';

export class ClassifierService implements Service {
  public static getClassifiers(config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IClassifier[]>('/classifier', config);
  }
  public static getClassifier(id: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IClassifier[]>(`/classifier/${id}`, config);
  }
  public static getClassifierValues(id: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IClassifier[]>(`/classifier/${id}/values`, config);
  }
  public static putClassifierValues(classifierValueId: string, data: any, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).put<IClassifier[]>(`/classifier/value/${classifierValueId}`, data, config);
  }
}
