import { ProjectService } from '@asu/services';

export const getMyRights = async (projectId: string, userId: string): Promise<{ [key: string]: boolean }> => {
  const res = await ProjectService.getAssignedUsers(projectId).fetch();
  const myRights = res.data.assignedUsers.find(item => item.userId === userId)?.projectRole.rights;

  if (!myRights) {
    return {};
  }

  return myRights.reduce((acc, item) => {
    acc[item.rightType] = true;
    return acc;
  }, {});
};
