export { ReactComponent as IconBell } from './bell-ico.svg';
export { ReactComponent as IconFolder } from './folder-ico.svg';
export { ReactComponent as IconGrid } from './grid-ico.svg';
export { ReactComponent as IconTable } from './table-ico.svg';
export { ReactComponent as IconMap } from './map-ico.svg';
export { ReactComponent as IconGantt } from './gantt-ico.svg';
export { ReactComponent as IconSearch } from './search-ico.svg';
export { ReactComponent as IconPlus } from './plus-ico.svg';
export { ReactComponent as IconArrow } from './arrow-ico.svg';
export { ReactComponent as IconDashboard } from './dashboard-ico.svg';
export { ReactComponent as IconDocuments } from './documents-ico.svg';
export { ReactComponent as IconReferences } from './references-ico.svg';
export { ReactComponent as IconTasks } from './tasks-ico.svg';
export { ReactComponent as IconHistory } from './history-ico.svg';
export { ReactComponent as IconCollection } from './collection-ico.svg';
export { ReactComponent as IconWindows } from './windows-ico.svg';
export { ReactComponent as IconClose } from './close-ico.svg';
export { ReactComponent as IconMaximize } from './maximize-ico.svg';
export { ReactComponent as IconFullscreen } from './fullscreen-ico.svg';
export { ReactComponent as IconLogout } from './logout-ico.svg';
export { ReactComponent as IconLogoutGGE } from './logout-gge-ico.svg';
export { ReactComponent as IconMessenger } from './messenger-ico.svg';
export { ReactComponent as IconDots } from './v-dots-ico.svg';
export { ReactComponent as IconSnow } from './snow-flake-ico.svg';
export { ReactComponent as ActiveTask } from './active-task-ico.svg';
export { ReactComponent as IconList } from './icon-list.svg';
export { ReactComponent as IconArrowButton } from './arrow-button-ico.svg';
export { ReactComponent as IconEdit } from './edit-ico.svg';
export { ReactComponent as IconPrivacy } from './privacy-ico.svg';
export { ReactComponent as IconCloud } from './cloud-ico.svg';
export { ReactComponent as IconBugReport } from './bug-report.svg';
export { ReactComponent as IconDocument } from './document-ico.svg';
export { ReactComponent as IconTrash } from './trash-ico.svg';
export { ReactComponent as IconInformation } from './information-ico.svg';
export { ReactComponent as IconDatePicker } from './datepicker-ico.svg';
export { ReactComponent as IconGeoLocation } from './geolocation-ico.svg';
export { ReactComponent as IconArchive } from './archive-ico.svg';
