import { Service } from './Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import type { ITemplateService } from './TemplateService.d';
export * from './TemplateService.d';

const BASE_URL = '/document';

export class TemplateService implements Service {
  public static getTemplates(config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<ITemplateService.IGetTemplates>('/template', config);
  }
}
