import React from 'react';
import tw, { styled } from 'twin.macro';
import { notification } from '@asu/ui';
import { useAuthState } from '@asu/auth';
import { generateBugReport } from '@asu/utils';
import { Link, NavLink, useMatch, useNavigate } from 'react-router-dom';
import { ActiveTask, IconArchive, IconBell, IconBugReport, IconFolder, IconMessenger } from '../../assets/icons';
import { Overlay } from '../Overlay';
import { NotificationCenter } from '../NotificationCenter';
import { MessengerCenter } from '../MessengerCenter';
import { notificationsList } from '../NotificationCenter/notificationsList';
import { useTasks } from './hooks';
import { useNotifications } from '../NotificationCenter/hooks';
import { useProjectState } from '../../store';
import { AccountBlockGGE, ModalMatrixRole } from './local-components';
import { GGELogoBlue } from '../../assets/images';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';

const ActiveTasks = React.memo(() => {
  const navigate = useNavigate();

  const [isLoading, tasks] = useTasks();

  return (
    <ActiveTaskWrapper onClick={() => navigate(`/task`)}>
      {process.env.NX_SYSTEM_TYPE === 'RZD' && (
        <div tw="relative">
          <ActiveTask tw="text-gray-500 w-10 h-12" />
          {!isLoading ? (
            <Counter>{tasks?.reduce((acc, cur) => acc + cur.length, 0)}</Counter>
          ) : (
            <Counter tw="bg-grey-300 bg-opacity-75 animate-pulse">-</Counter>
          )}
        </div>
      )}
    </ActiveTaskWrapper>
  );
});

const ActiveTaskWrapper = tw.figure`h-full flex items-center justify-center cursor-pointer border-l border-grey-100 ml-auto px-6`;
const Counter = tw.div`absolute bottom-0 right-0 flex items-center justify-center bg-secondary-dark text-white text-xs font-light truncate rounded-full px-1`;

const ActiveProjectInfo = observer(() => {
  const navigate = useNavigate();
  const isProject = !!useMatch('/project/:projectId/*')?.params.projectId;

  const store = useProjectState();
  const project = store.project;
  const isLoading = store.isLoading;

  if (isLoading && isProject) {
    return (
      <TitleContainer>
        <figure tw="h-3 w-12 bg-grey-300 bg-opacity-30 rounded animate-pulse mb-2" />
        <figure tw="h-3 w-32 bg-grey-300 bg-opacity-30 rounded animate-pulse" />
      </TitleContainer>
    );
  }

  if (!isLoading && isProject) {
    return (
      <TitleContainer>
        <MatrixRoleComponent>
          <ModalMatrixRole />
        </MatrixRoleComponent>

        <TitleWrapper onClick={() => navigate(`/project/${project?.id}/dashboard`)}>
          <ProjectInfo tw="text-primary-dark ml-1">{project?.meta?.find(el => el.key === 'externalId')?.value || '-'}</ProjectInfo>
          <ProjectInfo tw="ml-1">{project?.title}</ProjectInfo>
        </TitleWrapper>
      </TitleContainer>
    );
  }

  return null;
});

const TitleWrapper = tw.div`flex flex-col flex-1 justify-center cursor-pointer overflow-hidden px-2`;
const ProjectInfo = tw.p`w-full font-medium text-sm flex truncate`;
const TitleContainer = tw.div`flex border-l border-grey-100 px-6 ml-6`;

const uriPrefix = process.env.NODE_ENV !== 'production' ? process.env.NX_APP_SERVER_WS_URI ?? '' : process.env.NX_APP_P_SERVER_WS_URI ?? '';

// eslint-disable-next-line @typescript-eslint/ban-types
type HeaderProps = {};

const Header: React.FC<HeaderProps> = React.memo(() => {
  const authStore = useAuthState();
  const isProject = useMatch('/project/:projectId/*');

  const [isLoadingHistoryNotifications, historyNotifications, onDismissNotification] = useNotifications(authStore.currentUser.id);
  const [wsNotifications, setWsNotifications] = React.useState([]);
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
  const [isMessengerOpen, setIsMessengerOpen] = React.useState(false);

  const onWsDismissSuccessCallback = id => {
    setWsNotifications(prevState => [...prevState.filter(el => el.id !== id)]);
  };

  React.useEffect(() => {
    if (authStore.currentUser) {
      const clientWebSocket = new WebSocket(uriPrefix + '/api/notification/notification');

      clientWebSocket.onopen = function () {
        const auth = {
          type: 'TOKEN',
          payload: {
            token: 'Bearer ' + localStorage.getItem('access_token'),
            userId: authStore.currentUser.id,
          },
        };

        clientWebSocket.send(JSON.stringify(auth));
      };
      clientWebSocket.onclose = function (error) {
        // console.log('clientWebSocket.onclose', clientWebSocket, error);
      };
      clientWebSocket.onerror = function (error) {
        // console.log('clientWebSocket.onerror', clientWebSocket, error);
      };
      clientWebSocket.onmessage = function (message) {
        const data = JSON.parse(message.data);
        const Not = notificationsList.get(data.name)?.body(data) || 'Неизвестное действие';

        if (process.env.NX_SYSTEM_TYPE !== 'GGE') {
          notification['info']({
            message: notificationsList.get(data.name)?.title || 'Новое уведомление',
            description: Not,
          });
          if (data) setWsNotifications(prevState => [{ ...data, isWs: true, timestamp: dayjs(data.timestamp).unix() }, ...prevState]);
        }
      };

      return () => {
        clientWebSocket.close();
      };
    }
  }, [authStore.currentUser]);

  return (
    <Wrapper>
      <Overlay>
        <Container>
          {process.env.NX_SYSTEM_TYPE === 'RZD' && (
            <>
              <Notification type="button" onClick={() => setIsNotificationOpen(true)}>
                <div tw="relative">
                  <IconBell tw="text-gray-200" />
                  {historyNotifications?.length > 0 && (
                    <Counter tw="-bottom-2 -right-2">{historyNotifications.length + wsNotifications.length}</Counter>
                  )}
                </div>
              </Notification>

              {isNotificationOpen && (
                <NotificationCenter
                  isLoadingHistoryNotifications={isLoadingHistoryNotifications}
                  historyNotifications={historyNotifications}
                  onWsDismissSuccessCallback={onWsDismissSuccessCallback}
                  onDismissSuccessCallback={onDismissNotification}
                  wsnotifications={wsNotifications}
                  isOpen={isNotificationOpen}
                  user={authStore.currentUser}
                  onClose={() => setIsNotificationOpen(false)}
                />
              )}
            </>
          )}
          {process.env.NX_SYSTEM_TYPE === 'RZD' && (
            <Messages>
              <UserAvatarContainer type="button" onClick={() => setIsMessengerOpen(true)}>
                <UserAvatarPlaceholder>
                  <IconMessenger tw="absolute inset-0 m-auto w-4 h-4 text-grey-500" />
                </UserAvatarPlaceholder>
              </UserAvatarContainer>
              <ButtonWrapper type="button" onClick={() => setIsMessengerOpen(true)}>
                <ButtonAddMessage />
              </ButtonWrapper>
            </Messages>
          )}
          {process.env.NX_SYSTEM_TYPE === 'GGE' && (
            <TopSection to={isProject ? `` : '/'}>
              <div tw="flex items-center justify-center h-full w-full bg-white border-r">
                <GGELogoBlue tw="w-[240px]" />
              </div>
            </TopSection>
          )}
          {process.env.NX_SYSTEM_TYPE === 'GGE' && (
            <div tw="flex gap-10 ml-4">
              <NavLink to="/" end={true} tw="flex items-center">
                <IconFolder tw="h-6 text-secondary-dark mt-1" />
                <p tw="ml-3 text-lg font-medium text-secondary-dark">Проекты</p>
              </NavLink>

              {process.env.NX_SYSTEM_TYPE === 'GGE' && (
                <NavLink to="/archive" end={true} tw="flex items-center">
                  <IconArchive tw="text-secondary-dark mt-0.5 flex-shrink-0 h-6" />
                  <p tw="ml-3 text-lg font-medium text-secondary-dark">Архив проектов</p>
                </NavLink>
              )}
            </div>
          )}

          {process.env.NX_SYSTEM_TYPE === 'RZD' && <ActiveProjectInfo />}

          <ActiveTasks />

          <BugReportButton onClick={generateBugReport}>
            <IconBugReport css="fill: #787878;" />
          </BugReportButton>

          {isMessengerOpen && <MessengerCenter onClose={() => setIsMessengerOpen(false)} />}
          {process.env.NX_SYSTEM_TYPE === 'GGE' && <AccountBlockGGE />}
        </Container>
      </Overlay>
    </Wrapper>
  );
});

const Wrapper = tw.div`relative height[81px] z-0 w-[98%]`;
const MatrixRoleComponent = tw.section`flex items-center mr-2`;
const Container = styled.header`
  ${process.env.NX_SYSTEM_TYPE === 'RZD'
    ? tw`fixed top-0 left[270px] right-0 flex height[81px] width[calc(100% - 270px)] bg-white border-b border-grey-100 z-40`
    : tw`top-0 left[270px] right-0 flex height[81px]  bg-white border-b border-gray-200 z-40 width[calc(100% - 20px)]`}
`;
const Notification = tw.button`width[78px] flex flex-shrink-0 items-center justify-center border-r border-grey-100 cursor-pointer focus:outline-none`;
const BugReportButton = styled.div`
  ${tw`absolute top[2px] right[2px] w-3 h-3 cursor-pointer`}
  &:hover svg {
    fill: #000;
  }
`;

const Messages = tw.section`flex items-center ml-8`;
const UserAvatarContainer = tw.button`relative`;

const MatrixRoleButton = tw.button`relative focus:outline-none`;
const UserAvatarPlaceholder = tw.div`h-10 w-10 rounded-full bg-gray-200`;
const MatrixRolePlaceholder = tw.div`h-10 w-10 rounded-full bg-gray-200`;

const ButtonWrapper = tw.button`relative flex items-center ml-4 cursor-pointer`;
const ButtonAddMessage = styled.figure`
  ${tw`w-10 h-10 border border-gray-300 border-dashed rounded-3xl`}
  ${tw`before:(content[''] absolute width[14px] height[1px] bg-grey-300 transform -translate-y-1/2 top-1/2 inset-x-0 mx-auto)`}
  ${tw`after:(content[''] absolute width[1px] height[14px] bg-grey-300 transform -translate-y-1/2 top-1/2 inset-x-0 mx-auto)`}
`;

const TopSection = tw(Link)`w-[270px] height[80px] flex flex-shrink-0 justify-center items-center bg-primary`;

export default Header;
