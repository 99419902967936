import { AxiosRequestConfig } from 'axios';
import { qParam, Service } from './Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { IAdministrationService } from './AdministrationService.d';

export * from './AdministrationService.d';

const BASE_URL = '/administration';

export class AdministrationService implements Service {
  public static getTemplates(config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<any>(`/template`, config);
  }
  public static deleteTemplate(templateId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).delete<any>(`/template/${templateId}/delete`, config);
  }
  public static createTemplate(body: IAdministrationService.Body.ICreateTemplate, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<any>(`/template`, body, config);
  }
  public static getProjects(config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<any>(`/project/projects`, config);
  }
  public static deleteProject(body, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<any>(`/project/delete`, body, config);
  }
  public static archiveProject(body, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<any>(`/project/archive`, body, config);
  }
}
