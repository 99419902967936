import { IconFolder } from '../../../assets';
import React from 'react';
import tw, { styled } from 'twin.macro';

type WrapperProps = {
  children: any;
};

const Wrapper = React.memo<WrapperProps>(({ children, ...rest }) => {
  return <FoldersGrid {...rest}>{children}</FoldersGrid>;
});

const FoldersGrid = styled.section`
  ${tw`mb-4`}
`;

type FolderProps = {
  data: any;
  onClick: (data: any) => void;
  onContextMenu: (e: any, data: any) => void;
};

const Folder = React.memo<FolderProps>(({ onClick, onContextMenu, data }) => {
  return (
    <Container onContextMenu={e => onContextMenu(e, data)} onClick={() => onClick(data)}>
      <IconWrapper>
        <StyledIconFolder />
      </IconWrapper>
      <Name>{data.baseName}</Name>
    </Container>
  );
});

const Name = tw.p`text-grey-900 text-sm leading-5 pt-3 pb-3 pl-3 pr-2`;
const Container = tw.div`cursor-pointer flex flex-row pl-2 hover:border-l-2 border-primary-dark`;
const StyledIconFolder = styled(IconFolder)`
  ${tw`text-yellow-300 w-4 h-4`}
`;
const IconWrapper = tw.div`flex items-center`;

const ListView = {
  Folder,
  Wrapper,
};

export default ListView;
