interface IMeta {
  key: string;
  value: string;
}

interface IAccessPTS {
  id: string;
  processTask: string;
  read: string;
  chat: string;
  dataRequest: string;
  dataInput: string;
  dataComment: string;
  expert: string;
  approve: string;
  accept: string
}

interface IRolePTS {
  roleId: string;
  assignedGroupId: string;
  role: {
    id: string;
    name: string;
    accessList: IAccessPTS[];
  }
}

interface IProjectTemplatePTS {
  id: string;
  templateName: string;
  externalId: string;
  objectName: string;
  startDate: string;
  endDate: string;
  lat: number;
  lon: number;
  totalCost: number;
  realisationCost: number;
  projectCostAtStart: number;
  isProjectStarted: true;
  meta: IMeta[];
  roles: IRolePTS[];
  type: string;
}

interface IProjectTemplateRole {
  roleId: string,
  assignedGroupId: string,
  role: IRolePTS;
}

export declare namespace IProjectTemplateService {
  type IGetTemplates = IProjectTemplatePTS[];
  type IGetTemplate = IProjectTemplatePTS;
  type IGetTemplateRoles = IProjectTemplateRole[];
}
