import axios from 'axios';
import type { AxiosRequestConfig } from 'axios';

const uriPrefix =
  process.env.NODE_ENV !== 'production' ? process.env.NX_APP_KEYCLOAK_SERVER_URI ?? '' : process.env.NX_APP_P_KEYCLOAK_SERVER_URI ?? '';

const BASE_URL = uriPrefix + '/auth/realms/asubim/protocol/openid-connect';

export class KeycloakService {
  public static getUserInfo(config?: AxiosRequestConfig) {
    return axios.get<any>(BASE_URL + '/userinfo', config);
  } // not found in swagger
}
