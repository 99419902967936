import React from 'react';
import ReactDOM from 'react-dom';
import tw, { styled } from 'twin.macro';
import { IconClose } from '@asu/ui';

type ModalProps = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: any;
  actions?: any[];
  bodyStyle?: React.CSSProperties;
};

const Modal = React.memo<ModalProps>(({ title, isOpen, onClose, bodyStyle, actions, children }) => {
  return isOpen
    ? ReactDOM.createPortal(
        <Wrapper>
          <Backdrop onMouseDown={onClose} />
          <Container>
            <HeaderWrapper>
              <Title>{title}</Title>
              <div tw="flex ml-auto">
                {actions?.map(({ onClick, element }, i) => (
                  <IconContainer key={i} onClick={onClick}>
                    {element}
                  </IconContainer>
                ))}
                <IconContainer onClick={onClose}>
                  <IconClose tw="w-3 h-3 text-white" />
                </IconContainer>
              </div>
            </HeaderWrapper>
            <Body style={bodyStyle}>{children}</Body>
          </Container>
        </Wrapper>,
        document.getElementById('react-portal')!,
      )
    : null;
});

const Wrapper = tw.div`fixed flex items-center justify-center inset-0 h-screen w-screen z-10`;
const Backdrop = tw.div`absolute inset-0 bg-black bg-opacity-25`;
const HeaderWrapper = tw.div`flex justify-between bg-gradient-to-r from-primary-dark to-primary-light opacity-70 rounded-t -m-px`;
const IconContainer = tw.figure`flex items-center h-full text-black hover:(bg-grey-300 bg-opacity-25) transition-all duration-150 cursor-pointer px-4`;
const Title = tw.h1`text-white text-xl py-2 px-4`;
const Body = tw.main`min-width[40vw]`;
const Container = styled.div`
  ${tw`border border-white border-opacity-10 rounded-md`}
  background: radial-gradient(100% 4700% at 0% 0%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.75) 100%);
  backdrop-filter: blur(22px);
`;

export default Modal;
