import { qParam } from './Service.d';
import { Service } from './Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import type { IFilesystemService } from './FilesystemService.d';
export * from './FilesystemService.d';

const BASE_URL = '/files';

export class FilesystemService implements Service {
  public static getCommentDocuments = ({ ids }: { ids: string[] }, access: string, config?: AxiosRequestConfig) => {
    return new ApiCaller(BASE_URL).post<any>(`/comment-document/getCommentDocuments`, ids, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  }; // deprecated

  public static uploadCommentDocument = (
    body: IFilesystemService.Body.IUploadCommentDocument,
    access: qParam,
    config?: AxiosRequestConfig,
  ) => {
    return new ApiCaller(BASE_URL).post<IFilesystemService.IUploadCommentDocument>(`/comment-document`, body, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  };

  public static downloadCommentDocument = (id: qParam, access: qParam, config?: AxiosRequestConfig) => {
    return new ApiCaller(BASE_URL).get<string>(`/comment-document/${id}`, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  };

  public static deleteCommentDocument = (id: string, access: string, config?: AxiosRequestConfig) => {
    return new ApiCaller(BASE_URL).delete<any>(`/comment-document/${id}`, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  }; // deprecated

  public static getCommentSectionFileMeta = (id: qParam, access: qParam, config?: AxiosRequestConfig) => {
    return new ApiCaller(BASE_URL).get<IFilesystemService.IGetCommentSectionFileMeta>(`/directory/getCommentSectionFileMeta/${id}`, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  };

  public static getDirectoryRoot(id: qParam, access: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IFilesystemService.IGetDirectoryRoot>(`/directory/root/${id}`, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  }
  public static getDirectories(id: qParam, access: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IFilesystemService.IGetDirectories>(`/directory/${id}`, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  }

  public static getDirectoryParentRootId(id: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IFilesystemService.IGetDirectories>(`/directory/getRoot/${id}`);
  }

  public static getVersions(id: qParam, access: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IFilesystemService.IGetVersions>(`/document/list/${id}`, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  }
  public static updateFile(
    fileData: IFilesystemService.Body.IUpdateFile,
    access: qParam,
    processTask?: qParam,
    config?: AxiosRequestConfig,
  ) {
    let url = '/document/update';

    if (processTask) url += `?processTask=${processTask}`;

    return new ApiCaller(BASE_URL).post<IFilesystemService.IUpdateFile>(url, fileData, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  }
  public static deleteDirectory(id: qParam, access: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).delete<IFilesystemService.IDeleteDirectory>(`/directory/${id}`, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  }
  // TODO: Добавить типизацию
  public static createDirectory(directoryData: IFilesystemService.Body.ICreateDirectory, access: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IFilesystemService.ICreateDirectory>('/directory', directoryData, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  }
  public static directoryTemplate(templateData: IFilesystemService.Body.IDirectoryTemplate, access: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IFilesystemService.IDirectoryTemplate>('/directory/template', templateData, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  }
  // TODO: Добавить типизацию
  public static renameDirectory(renameData: IFilesystemService.Body.IRenameDirectory, access: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).put<IFilesystemService.IRenameDirectory>('/directory/rename', renameData, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  }

  public static renameFile(body: any, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).put<any>(`/document/rename`, body);
  }

  public static getFile(id: qParam, access: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<unknown>(`/document/${id}`, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  }

  public static getProject(id: qParam, access: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IFilesystemService.IGetProject>(`/project/${id}`, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  }

  public static postFileBuilding(body: IFilesystemService.Body.IPostFileBuilding, access?: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IFilesystemService.IPostFileBuilding>('/building-management-document', body, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  }

  public static postFileWithPreviewBuilding(
    body: IFilesystemService.Body.IPostFileWithPreviewBuilding,
    access: qParam,
    config?: AxiosRequestConfig,
  ) {
    return new ApiCaller(BASE_URL).post<IFilesystemService.IPostFileWithPreviewBuilding>(
      '/building-management-document/uploadWithPreview',
      body,
      {
        headers: {
          'Fs-Access': access,
          ...config?.headers,
        },
        ...config,
      },
    );
  }

  public static getRootFolderProjectBuilding(id: qParam, access: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IFilesystemService.IGetRootFolderProjectBuilding>(
      `/directory/getBuildingManagementDocumentSectionFileMeta/${id}`,
      {
        headers: {
          'Fs-Access': access,
          ...config?.headers,
        },
        ...config,
      },
    );
  }

  public static getMetaInformationBuildingTaskFiles(id: qParam, access: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<IFilesystemService.IGetMetaInformationBuildingTaskFiles>(
      `/building-management-document/preview/${id}`,
      {
        headers: {
          'Fs-Access': access,
          ...config?.headers,
        },
        ...config,
      },
    );
  }

  public static getBuildingFile(id: qParam, access: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<unknown>(`/building-management-document/${id}`, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  }

  public static getBuildingFileMeta(body: IFilesystemService.Body.IGetBuildingFileMeta, access: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IFilesystemService.IGetBuildingFileMeta>(`/building-management-document/meta`, body, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  }

  public static getFileBuilding(id: qParam, access: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<unknown>(`/building-management-document/${id}`, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  }

  public static deleteFileBuilding(id: string, access: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).delete<any>(`/building-management-document/${id}`, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  } // deprecated

  public static getAllVersionMetaFilesBuilding(id: string, access: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<unknown>(`/building-management-document/list/${id}`, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  } // not found in swagger

  public static postCreateTask(fileData: IFilesystemService.Body.IPostCreateTask, access: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<void>('/task​/markup', fileData, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  }
  // TODO: Добавить типизацию
  public static postFile(fileData: any, access: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IFilesystemService.IPostFile>('/document', fileData, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  }

  public static postFileWithProcessTask(fileData: any, access: qParam, processTask: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<IFilesystemService.IPostFile>(`/document?processTask=${processTask}`, fileData, {
      headers: {
        'Fs-Access': access,
        ...config?.headers,
      },
      ...config,
    });
  }

  // TODO: Добавить типизацию
  public static deleteFiles(body: any, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<any>(`/document/deleteMany`, body);
  }
}
